import { Button, Form, Input, Modal, Select } from 'antd';
import { customAlert, customError } from '../../../api/Modals';
import CloseIcon from '../../../img/close.png';
import { bankCode, dwServiceTypeCode } from '../../../lib/util/codeUtil';

const { useRef, default: React } = require('react');
const { httpUrl, httpPost } = require('../../../api/httpClient');

const RegistAccountDialog = ({ close, data, getList }) => {
  const FormItem = Form.Item;
  const Option = Select.Option;
  const formRef = useRef();
  const handleSubmit = () => {
    Modal.confirm({
      title: '출금계좌 수정',
      content:
        data.bankAccount && data.bankAccount !== '0'
          ? '출금계좌을 수정하시겠습니까?'
          : '출금계좌을 등록하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        if (dwServiceTypeCode.KWANGWON == data.dwServiceType) {
          httpPost(
            data.bankAccount && data.bankAccount !== '0'
              ? httpUrl.frAccountModify
              : httpUrl.frAccountCreate,
            [],
            {
              userIdx: data.userIdx,
              ...formRef.current.getFieldsValue(),
              bankAccount: formRef.current
                .getFieldValue('bankAccount')
                .replace(/\-/gi, ''),
            }
          )
            .then((res) => {
              if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
                customAlert('새로운 출금계좌가 등록되었습니다.');
                getList();
              } else if (res.data === 'PG_NOT_REGISTERED') {
                Modal.info({
                  title: '설정실패',
                  content: 'pg 미등록 매장입니다. pg등록을 먼저 진행해주세요.',
                });
                return;
              } else if (res.data === 'WRONG_ACCOUNT_INFO') {
                Modal.info({
                  title: '설정실패',
                  content: '잘못된 계좌정보입니다.',
                });
                return;
              } else {
                Modal.info({
                  title: '설정실패',
                  content:
                    '출금계좌 설정에 실패했습니다. 관리자에게 문의해주세요.',
                });
                return;
              }
              close();
            })
            .catch((error) => {
              customError(
                '등록 오류',
                '오류가 발생하였습니다. 다시 시도해 주십시오.'
              );
            });
        } else if (dwServiceTypeCode.PAYSTORY == data.dwServiceType) {
          console.log({
            idx: data.userIdx,
            ...formRef.current.getFieldsValue(),
            bankAccount: formRef.current
              .getFieldValue('bankAccount')
              .replace(/\-/gi, ''),
          });
          httpPost(httpUrl.updateBank, [], {
            idx: data.userIdx,
            ...formRef.current.getFieldsValue(),
            bankAccount: formRef.current
              .getFieldValue('bankAccount')
              .replace(/\-/gi, ''),
          })
            .then((res) => {
              console.log(res);
              if (res.result === 'SUCCESS' && res.data.resultCd === 'SUCCESS') {
                customAlert('새로운 출금계좌가 등록되었습니다.');
                getList();
              } else {
                Modal.info({
                  title: '설정실패',
                  content: res.data.resultMessage,
                });
                return;
              }
              close();
            })
            .catch((e) => {
              console.error(e);
              customError(
                '등록 오류',
                '오류가 발생하였습니다. 다시 시도해 주십시오.'
              );
            });
        } else {
          customError(
            '입출금 서비스 코드 오류',
            '잘못된 입출금 서비스 코드 입니다. 창 새로고침 후 다시 진행해주세요.'
          );
        }
      },
    });
  };

  return (
    <>
      <div className="flex-modal-overlay" onClick={() => close()} />
      <div className="flex-wrapper">
        <div className="flex-header">
          출금계좌 관리{' '}
          <img
            onClick={close}
            style={{ display: 'inline' }}
            src={CloseIcon}
            className="close-btn"
            alt="close"
          />
        </div>

        <Form ref={formRef} onFinish={handleSubmit}>
          <div className="flex-inner">
            <div className="contentBox">
              <div className="form-item-label">은행</div>
              <FormItem
                name="bank"
                rules={[{ required: true, message: '은행을 선택해주세요' }]}
                initialValue={data ? data.bank : '기업은행'}>
                <Select className="regist-account-dialog-select">
                  {Object.keys(bankCode).map((key) => {
                    return (
                      <Option value={key + ',' + bankCode[key]}>{key}</Option>
                    );
                  })}
                </Select>
              </FormItem>
            </div>
            <div className="contentBox">
              <div className="form-item-label">계좌번호</div>
              <FormItem
                name="bankAccount"
                initialValue={data ? data.bankAccount : null == ''}>
                <Input placeholder="계좌번호를 입력해 주세요."></Input>
              </FormItem>
            </div>
            <div className="contentBox">
              <div className="form-item-label">예금주</div>
              <FormItem
                name="depositor"
                initialValue={data ? data.depositor : null == ''}>
                <Input placeholder="예금주를 입력해 주세요."></Input>
              </FormItem>
            </div>

            <Button type="primary" htmlType="submit">
              등록하기
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default RegistAccountDialog;
