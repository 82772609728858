import Icon from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { login } from '../actions/loginAction';
import { httpGet, httpPost, httpUrl } from '../api/httpClient';
import Const from '../const';
import LoginImage from '../img/login_img.png';
import { setAgencyBranch } from "../actions/branchAction";

const FormItem = Form.Item;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpVisible: false,
      isAgency: false,
      ncash: 0,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    // console.log(this.props.loginInfo);
  }

  handleSubmit = (e) => {
    let self = this;
    httpPost(httpUrl.idPwdCheck, [], {
      ...this.formRef.current.getFieldsValue(),
    })
      .then((res) => {
        console.log(res);
        if (res.data.idPw === true && res.data.otp === false) {
          Modal.confirm({
            title: 'OTP KEY 미등록',
            content:
              'OTP KEY 없이 로그인할 경우 이용에 제한이 있을 수 있습니다. \nOTP KEY는 메뉴바 상단의 [내 정보]에서 생성할 수 있습니다.',
            okText: '확인',
            onOk() {
              console.log({
                ...self.formRef.current.getFieldsValue(),
              });
              httpPost(httpUrl.login, [], {
                ...self.formRef.current.getFieldsValue(),
              })
                .then(async (res) => {
                  console.log('res.data.authorities');
                  console.log(res);
                  let menu = [...Const.menus, ...Const.agency_menu];
                  let adminUser = { ...res.data.adminUser };
                  let array = [];
                  menu.forEach((item) => {
                    if (item['submenuOrder'] !== undefined) {
                      array.push(item);
                    }
                  });
                  adminUser.adminAuth.forEach((parent) => {
                    // console.log(parent.subMenu);
                    for (let i = 0; i < parent.subMenu.length; i++) {
                      for (let j = 0; j < array.length; j++) {
                        if (array[j].idx === parent.subMenu[i].idx) {
                          parent.subMenu[i] = array[j];
                        }
                      }
                    }
                  });
                  if (res.data.isAgency) await self.getAgencyBranches();
                  if (res.data.result) {
                    let ncash = 0;
                    if (res.data.isAgency) {
                      ncash = await httpGet(httpUrl.hoBalance, [], {});
                    }
                    self.props.onLogin({
                      // ...res.data.adminUser,
                      ...adminUser,
                      authList: res.data.authorities,
                      isAgency: res.data.isAgency,
                      branch: res.data.branch,
                      ncash: ncash.data,
                    });
                    await self.checkMenu();
                    // if (res.data.adminUser.adminAuth.length === 0)
                    // if (res.data.isAgency) {
                    //   self.props.history.push('/agency/AgencyDashboard');
                    // } else
                      if (adminUser.adminAuth.length !== 0) {
                      self.props.history.push(
                        // res.data.adminUser.adminAuth[0].subMenu[0].path
                        adminUser.adminAuth[0].subMenu[0].path,
                      );
                    } else {
                      self.props.history.push('/main');
                    }
                  } else {
                    alert('아이디 또는 비밀번호가 잘못되었습니다.');
                  }
                })
                .catch((err) => {});
            },
          });
        } else if (res.data.idPw === true && res.data.otp === true) {
          this.setState({ otpVisible: true }, () => {
            console.log(this.state.otpVisible);
          });
        } else {
          alert('로그인이 실패하였습니다.\n아이디나 패스워드를 확인해주세요.');
        }
      })
      .catch((err) => {});
  };
  getAgencyBranches = async () => {
    const res = await httpGet(httpUrl.getBranchOfAgency, [], {});
    if (res) this.props.setAgencyBranch(res.data);
  };
  optLogin = (params = {}) => {
    let otp = params.otp || '';
    httpPost(httpUrl.otpLogin, [], {
      ...this.formRef.current.getFieldsValue(),
      key: otp,
    }).then(async (res) => {
      console.log('res.data.authorities');
      console.log(res);
      if (res.data.isAgency) await this.getAgencyBranches();
      if (res.data.result) {
        this.props.onLogin({
          ...res.data.adminUser,
          authList: res.data.authorities,
          isAgency: res.data.isAgency,
          branch: res.data.branch,
        });
        await this.checkMenu();
        // if (res.data.isAgency) {
        //   this.props.history.push('/agency/AgencyDashboard');
        // } else
          if (res.data.adminUser.adminAuth.length === 0)
          this.props.history.push('/main');
        else
          this.props.history.push(
            res.data.adminUser.adminAuth[0].subMenu[0].path
          );
      } else {
        if (res.data.reason === 'WRONG_IPADDRESS') {
          alert('접근이 제한된 IP주소 입니다.');
          this.setState({ otpVisible: false });
          this.props.form.resetFields(['id', 'password']);
        }
        if (res.data.reason === 'INVALID_LOGIN_ID') {
          alert('아이디가 잘못되었습니다.');
        }
        if (res.data.reason === 'INVALID_LOGIN_PASSWORD') {
          alert('비밀번호가 잘못되었습니다.');
        }
        if (res.data.reason === 'WRONG_OTP') {
          alert('OPT를 잘못입력하셨습니다. 다시 입력해주세요.');
        }
      }
    });
  };

  checkMenu = async () => {
    console.log('checking menu');
    const getAllMenuRes = await httpGet(httpUrl.getAllMenu, [], {});
    const dbMenu = getAllMenuRes.data;
    const clientMenu = [...Const.menus, ...Const.agency_menu];
    let createRequest = [];
    let deleteRequest = [];

    // 클라이언트에 있는데 db에 없는 메뉴 (create)
    clientMenu.forEach((clm) => {
      if (dbMenu.findIndex((dbm) => dbm.idx === clm.idx) < 0) {
        createRequest.push(clm);
      }
    });
    // db에 있는데 클라이언트에 없는 메뉴
    dbMenu.forEach((dbm) => {
      if (clientMenu.findIndex((clm) => dbm.idx === clm.idx) < 0) {
        deleteRequest.push(dbm);
      }
    });
    if (deleteRequest.length !== 0) {
      // console.log('deleteRequest');
      // console.log(deleteRequest);
      const deleteResCnt = await httpPost(httpUrl.deleteMenuList, [], {
        menus: deleteRequest,
      });

      if (deleteResCnt.data === deleteRequest.length) {
        console.log('delete menu synchronized');
      }
      // console.log('deleteResCnt');
      // console.log(deleteResCnt);
    }

    if (createRequest.length !== 0) {
      // console.log('createRequest');
      // console.log(createRequest);
      const resCnt = await httpPost(httpUrl.createMenuList, [], {
        menus: createRequest,
      });
      // console.log(resCnt);
      if (resCnt.data === createRequest.length) {
        console.log('create menu synchronized');
      }
    }
    // 제휴본사 유저인 경우 자동으로 agency_menu메뉴 권한 부여
    // if (this.props.loginInfo.isAgency) {
    //   const menuIdx = [];
    //   Const.agency_menu.forEach((menu) => menuIdx.push(menu.idx));
    //   await httpPost(httpUrl.agencyAuthCreate, [], {
    //     adminUserIdx: this.props.loginInfo.idx,
    //     menuIdx,
    //   });
    //   const getAgencyRes = await httpGet(httpUrl.getAdminAuth, [], {});
    //   this.props.onLogin({
    //     ...this.props.loginInfo,
    //     adminAuth: getAgencyRes.data,
    //   });
    // }
    if (createRequest.length !== 0 || deleteRequest.length !== 0) {
      if (this.props.loginInfo.adminType === 1) {
        const menuIdx = [];
        createRequest.forEach((menu) => menuIdx.push(menu.idx));
        await httpPost(httpUrl.adminAuthCreate, [], {
          adminUserIdx: this.props.loginInfo.idx,
          menuIdx,
        });
      }
      const getAdminAuthRes = await httpGet(httpUrl.getAdminAuth, [], {});
      console.log('getAdminAuthRes');
      console.log(getAdminAuthRes);
      this.props.onLogin({
        ...this.props.loginInfo,
        adminAuth: getAdminAuthRes.data,
      });
    }
  };

  render() {
    return (
      <div className="login-container">
        <Form
          ref={this.formRef}
          onFinish={this.state.otpVisible ? this.optLogin : this.handleSubmit}
          className="login-form">
          <div className="login-img">
            <img src={LoginImage} />
          </div>
          <div className="login-text">냠냠박스 관리자시스템</div>
          <FormItem
            name="id"
            rules={[{ required: true, message: '아이디를 입력해주세요' }]}>
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="아이디"
            />
          </FormItem>
          <FormItem
            name="password"
            rules={[{ required: true, message: '비밀번호를 입력해주세요.' }]}>
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="비밀번호"
            />
          </FormItem>

          {this.state.otpVisible && (
            <FormItem
              name="otp"
              rules={[{ required: true, message: 'OTP KEY를 입력해주세요.' }]}>
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="OTP KEY"
              />
            </FormItem>
          )}

          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: '100%' }}>
            Login
          </Button>
        </Form>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (loginInfo) => dispatch(login(loginInfo)),
    setAgencyBranch: (branches) => dispatch(setAgencyBranch(branches)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
