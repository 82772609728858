import { Button, DatePicker, Form, Input, Select, Table, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import xlsx from 'xlsx';
import { httpGet, httpGetRenewMakeUrl, httpUrl, httpPost } from '../../api/httpClient';
import '../../css/main.css';
import { comma } from '../../lib/util/numberUtil';
import Const from '../../const';
import ExcelIcon from '../../img/excel.png';
import moment from "moment";
import locale from "antd/es/date-picker/locale/ko_KR";
import { statusString, userTypeString } from '../../lib/util/codeUtil';

const { Option } = Select;
const Search = Input.Search;
const now = new Date();
const { RangePicker } = DatePicker;

const AdjBalanceDay = ({ props }) => {
    const formRef = React.createRef();
    const [list, setList] = useState([]);
    const [branchList, setbranchList] = useState([]);
    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    });
    const [searchName, setSearchName] = useState("");
    const [date, setDate] = useState({
        start: moment(now).format("YYYY-MM-DD"),
        end: moment(now).format("YYYY-MM-DD")
    });
    useEffect(async () => {
        await getAdjBalanceDayList();
    }, [pagination.current, pagination.pageSize, searchName, date]);
    useEffect(async () => {
        getBranchList();
    }, []);
    const getBranchList = async () => {
        const res = await httpGet(httpUrl.getBranchList, [1, 10000], {});
        setbranchList([{ idx: null, branchName: '전체' }, ...res.data.branch]);
    };
    const getAdjBalanceDayList = async () => {

        let params = {};
        if (formRef.current.getFieldValue('branchIdx'))
            params['branchIdx'] = formRef.current.getFieldValue('branchIdx');
        params['endDate'] = moment(date.end).add(1, 'd').format("YYYYMMDD");
        params['pageNum'] = pagination.current;
        params['pageSize'] = pagination.pageSize;
        params['startDate'] = moment(date.start).format("YYYYMMDD");
        params['searchName'] = searchName;
        try {
            const res = await httpGetRenewMakeUrl(httpUrl.adjBalanceList, params, {})
            if (!res.data) {
                console.log(`getListAsync failed. result: ${res.data.result}`);
                return;
            }
            console.log('!!!!!!!!!!!!!!!!!' + JSON.stringify(res.data));
            setList(res.data.adjBalanceDays);
            setPagination({
                ...pagination,
                current: res.data.currentPage,
                total: res.data.totalCount
            })
        } catch (e) {
            console.error(e);
        }
    };
    const handleTableChange = (page) => {
        if (page.current !== pagination.current) {
            setPagination({
                ...pagination,
                current: page.current
            });
        } else if (page.pageSize !== pagination.pageSize) {
            setPagination({
                ...pagination,
                current: 1,
                pageSize: page.pageSize
            });
        }

    };
    const onSearch = (value) => {
        setSearchName(value);
        setPagination({
            ...pagination,
            current: 1
        });
    };
    const onChangeRangePicker = async (e, selectDate, value) => {
        setDate({
            start: selectDate[0],
            end: selectDate[1]
        })
        await getAdjBalanceDayList(value);

    }
    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };



    const columns = [
        {
            title: '일자',
            dataIndex: 'logDate',
            className: 'table-column-center',
            render: (data) => <div>{moment(data).format("YYYY-MM-DD")}</div>
        },
        {
            title: '지점명',
            dataIndex: 'branchIdx',
            className: 'table-column-center',
            render: (data) => (
                <div>
                    {branchList.find((branch) => branch.idx === data)
                        ? branchList.find((branch) => branch.idx === data).branchName
                        : '-'}
                </div>
            ),
        },
        {
            title: '타입',
            dataIndex: 'userType',
            className: 'table-column-center',
            render: (data) => <div>{userTypeString[data]}</div>
        },
        {
            title: '가맹점/라이더명',
            dataIndex: 'frName',
            className: 'table-column-center',
            render: (data, row) => <div>{data ? data : row.riderName ? (row.riderName) : ""}</div>,
        },
        // {
        //     title: '가맹점명',
        //     dataIndex: 'frName',
        //     className: 'table-column-center',
        // },
        // {
        //     title: '라이더명',
        //     dataIndex: 'riderName',
        //     className: 'table-column-center',
        // },
        {
            title: '아이디',
            dataIndex: 'userId',
            className: 'table-column-center',
        },
        {
            title: '상태',
            dataIndex: 'userStatus',
            className: 'table-column-center',
            render: (data) => <div>{statusString[data]}</div>
        },
        {
            title: '잔액',
            dataIndex: 'ncash',
            className: 'table-column-center',
            render: (data, row) => <div>{comma(data)}원</div>,
        },

    ];

    const parseExcelJson = (data) => {
        let result = [];
        let headerJson = {};
        columns.forEach((column) => {
            headerJson[column.dataIndex] = column.title;
        });
        result.push(headerJson);

        data.forEach((item) => {
            let copiedItem = { ...item };
            Object.keys(item).forEach((key) => {
                if (columns.findIndex((column) => column.dataIndex === key) < 0) {
                    delete copiedItem[key];
                }
            });

            result.push({
                ...copiedItem,
                userStatus: item.userStatus == 1 ? "사용" : item.userStatus == 2 ? "중지" : "탈퇴",
                userType: item.userType == 1 ? "라이더" : item.userType == 2 ? "가맹점" : "관제",
                branchIdx: branchList.find((branch) => branch.idx === item.branchIdx)
                    ? branchList.find((branch) => branch.idx === item.branchIdx)
                        .branchName
                    : '-',
            });
        });
        return result;
    };

    const onDownload = async () => {
        let params = {};
        if (formRef.current.getFieldValue('branchIdx'))
            params['branchIdx'] = formRef.current.getFieldValue('branchIdx');
        params['endDate'] = moment(date.end).add(1, 'd').format("YYYYMMDD");
        params['pageNum'] = 1;
        params['pageSize'] = 1000000;
        params['startDate'] = moment(date.start).format("YYYYMMDD");
        params['searchName'] = searchName;
        console.log(params)
        const res = await httpGetRenewMakeUrl(httpUrl.adjBalanceList, params, {});
        console.log(res);

        const excelJson = parseExcelJson(res.data.adjBalanceDays);
        const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
        const wb = xlsx.utils.book_new();

        xlsx.utils.book_append_sheet(wb, ws, '일자별잔액_정산');
        xlsx.writeFile(wb, '일자별잔액_정산.xlsx');
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Form ref={formRef} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Form.Item style={{ width: 200, marginRight: 10 }} name="branchIdx">
                        <Select
                            onChange={() => getAdjBalanceDayList()} defaultValue={null}
                        >
                            {branchList.map((branch) => {
                                return (
                                    <Select.Option value={branch.idx}>
                                        {branch.branchName}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    <Search
                        placeholder="가맹점, 라이더명으로 검색"
                        className="search-input"
                        enterButton
                        allowClear
                        onSearch={onSearch}
                        style={{ width: 250, marginRight: 10 }}
                    />
                    <Form.Item name="date">
                        <RangePicker
                            locale={locale}
                            style={{ width: 280, justifyContent: 'flex-end' }}
                            format={"YYYY-MM-DD"}
                            disabledDate={disabledDate}
                            allowClear={false}
                            value={[
                                moment(date.start, "YYYYMMDD"),
                                moment(date.end, "YYYYMMDD"),
                            ]}
                            onChange={onChangeRangePicker}
                        />

                    </Form.Item>
                </Form>
                {Const.excelEnable && (
                    <Button
                        className="download-btn"
                        style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
                        onClick={() => onDownload()}>
                        <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
                        엑셀 다운로드
                    </Button>
                )}
            </div>

            <Table
                rowKey={(record) => record.idx}
                dataSource={list}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </>
    );
};

export default AdjBalanceDay;
