import { Button, DatePicker, Form, Input, Table } from 'antd';
import React, { Component } from 'react';
import xlsx from 'xlsx';
import { httpGet, httpGetWithoutLoading, httpUrl } from '../../api/httpClient';
import ProgressBar from '../../components/ProgressBar';
import '../../css/main.css';
import { formatDate, formatDates } from '../../lib/util/dateUtil';
import { accountFormat } from '../../lib/util/numberUtil';
import RegisterDuplicateOrder from './RegisterDuplicateOrder';
import moment from 'moment';
import Const from '../../const';
import ExcelIcon from '../../img/excel.png';

const FormItem = Form.Item;
const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

const initialPaginationExcel = {
  total: Number.MAX_VALUE,
  current: 1,
  pageSize: 1000,
};

class DeliveryCardPaymentHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },

      frName: '',
      riderName: '',
      registerDuplicateOrderOpen: false,
      //selectedRow: null,
      startDate: '',
      endDate: '',
      paginationExcel: initialPaginationExcel,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    let frName = this.state.frName;
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let riderName = this.state.riderName;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    httpGet(
      httpUrl.cardPaymentList,
      [frName, pageNum, pageSize, riderName, startDate, endDate],
      {}
    ).then((res) => {
      console.log(res);
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.cardpayments,
        pagination,
      });
    });
  };

  // 라이더 검색
  onSearchRider = (value) => {
    this.setState(
      {
        riderName: value,
      },
      () => {
        this.getList();
      }
    );
  };
  // 가맹점 검색
  onSearchFranchise = (value) => {
    value
      ? this.setState(
          {
            frName: value,
            pagination: {
              current: 1,
              pageSize: 5,
            },
          },
          () => {
            this.getList();
          }
        )
      : this.setState(
          {
            frName: value,
            pagination: {
              current: 1,
              pageSize: 10,
            },
          },
          () => {
            this.getList();
          }
        );
  };

  parseExcelJson = (data) => {
    let result = [
      {
        orderIdx: '주문번호',
        createDate: '날짜',
        riderName: '라이더명',
        frIdx: '가맹점idx',
        frName: '가맹점명',
        businessNumber: '사업자번호',
        tidNormalRate: '선결매장여부',
        authType: '승인여부',
        tid: '단말기(TID)',
        cardNo: '카드번호',
        authNum: '인증번호',
        message1: '카드명',
        trxId: 'trxId',
        purchaseCode: '결제코드',
        authdate: '인증날짜',
        custPhone: '고객전화번호',
        price: '요금',
      },
    ];
    data.forEach((item) => {
      result.push({
        orderIdx: item.orderIdx,
        createDate: formatDates(item.createDate),
        riderName: item.riderName,
        frIdx: item.frIdx,
        frName: item.frName,
        businessNumber: item.businessNumber,
        tidNormalRate: item.tidNormalRate == 0 ? 'O' : 'X',
        authType: item.authType == 0 ? '승인' : '취소',
        tid: item.tid,
        cardNo: JSON.parse(item.reserved1).cardNo,
        authNum: JSON.parse(item.reserved1).authNum,
        message1: JSON.parse(item.reserved1).message1,
        trxId: JSON.parse(item.reserved1).trxId,
        purchaseCode: JSON.parse(item.reserved1).purchaseCode,
        authdate: JSON.parse(item.reserved1).authdate,
        custPhone: item.custPhone,
        price: item.price + item.tax,
      });
    });

    return result;
  };

  onDownload = async () => {
    const data = await this.getExcelList();

    if (!data) return;

    const excelJson = this.parseExcelJson(data);
    const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
    xlsx.writeFile(wb, '카드결제목록.xlsx');
  };

  getExcelList = async () => {
    this.progressBarFlag = true;

    const loopGetList = async () => {
      const res = await httpGetWithoutLoading(
        httpUrl.cardPaymentList,
        [
          this.state.frName,
          this.state.paginationExcel.current,
          this.state.paginationExcel.pageSize,
          this.state.riderName,
          this.state.startDate,
          this.state.endDate,
        ],
        {}
      );
      console.log(res);
      return res;
    };

    let totalRes = [];
    let tryCnt = 1;

    let failedPages = [];

    this.setState({
      progressBarOn: true,
    });

    while (this.progressBarFlag) {
      if (this.state.paginationExcel.total < this.state.paginationExcel.current)
        break;

      const loopRes = await loopGetList();

      if (loopRes.data) {
        this.setState({
          paginationExcel: {
            ...this.state.paginationExcel,
            current: this.state.paginationExcel.current + 1,
            total:
              this.state.paginationExcel.current === 1
                ? loopRes.data.totalPage
                : this.state.paginationExcel.total,
          },
        });

        totalRes = totalRes.concat(loopRes.data.cardpayments);
      } else if (tryCnt <= 3) {
        tryCnt += 1;
      } else {
        failedPages.push(this.pageNum);

        this.setState({
          paginationExcel: {
            ...this.state.paginationExcel,
            current: this.state.paginationExcel.current + 1,
          },
        });

        tryCnt = 1;
      }
    }

    this.setState({
      progressBarOn: false,
      paginationExcel: initialPaginationExcel,
    });

    return this.progressBarFlag ? totalRes : null;
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  pressSearch = () => {
    this.setState(
      {
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  openRegisterDuplicateOrderModal = () => {
    this.setState({
      registerDuplicateOrderOpen: true,
    });
  };

  closeRegisterDuplicateOrderModal = () => {
    this.setState({
      registerDuplicateOrderOpen: false,
    });
  };

  quitProgressBar = () => {
    this.progressBarFlag = false;

    this.setState({
      progressBarOn: false,
      paginationExcel: initialPaginationExcel,
    });
  };

  render() {
    const columns = [
      {
        title: '주문번호',
        dataIndex: 'orderIdx',
        className: 'table-column-center',
      },
      {
        title: '날짜',
        dataIndex: 'createDate',
        className: 'table-column-center',
        render: (data) => <div>{formatDates(data)}</div>,
      },
      {
        title: '라이더명',
        dataIndex: 'riderName',
        className: 'table-column-center',
      },
      {
        title: '가맹점idx',
        dataIndex: 'frIdx',
        className: 'table-column-center',
      },
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
      },
      {
        title: '사업자번호',
        dataIndex: 'businessNumber',
        className: 'table-column-center',
      },
      {
        title: '선결매장여부',
        dataIndex: 'tidNormalRate',
        className: 'table-column-center',
        render: (data, row) => <div>{data == 0 ? 'O' : 'X'}</div>,
      },
      {
        title: '승인여부',
        dataIndex: 'authType',
        className: 'table-column-center',
        render: (data, row) => <div>{data == 0 ? '승인' : '취소'}</div>,
      },
      {
        title: '단말기(TID)',
        dataIndex: 'tid',
        className: 'table-column-center',
      },
      {
        title: '카드번호',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        render: (data, row) => <div>{JSON.parse(data).cardNo}</div>,
      },
      {
        title: '인증번호',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        render: (data, row) => <div>{JSON.parse(data).authNum}</div>,
      },
      {
        title: '카드명',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        render: (data, row) => <div>{JSON.parse(data).message1}</div>,
      },
      {
        title: 'trxId',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        render: (data, row) => <div>{JSON.parse(data).trxId}</div>,
      },
      {
        title: '결제코드',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        render: (data, row) => <div>{JSON.parse(data).PurchaseCode}</div>,
      },
      {
        title: '인증날짜',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        render: (data, row) => <div>{JSON.parse(data).authdate}</div>,
      },
      {
        title: '고객전화번호',
        dataIndex: 'custPhone',
        className: 'table-column-center',
      },
      {
        title: '요금',
        dataIndex: 'price',
        className: 'table-column-center',
        render: (data, row) => <div>{accountFormat(row.price + row.tax)}</div>,
      },
    ];

    return (
      <>
        {this.state.progressBarOn && (
          <ProgressBar
            current={this.state.paginationExcel.current}
            total={this.state.paginationExcel.total}
            quit={this.quitProgressBar}
          />
        )}

        <Search
          placeholder="가맹점명 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ frName: e.target.value })}
          onSearch={this.onSearchFranchise}
          style={{
            width: 220,
            marginBottom: 20,
          }}
        />
        <Search
          placeholder="라이더명 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ riderName: e.target.value })}
          onSearch={this.onSearchRider}
          style={{
            width: 220,
            marginLeft: 20,
          }}
        />

        <Button
          className="download-btn"
          style={{ float: 'right', marginBottom: 20 }}
          onClick={() => this.openRegisterDuplicateOrderModal()}>
          수동등록
        </Button>

        {this.state.registerDuplicateOrderOpen && (
          <RegisterDuplicateOrder
            data={this.state.list}
            close={this.closeRegisterDuplicateOrderModal}
            //callback={this.onRefresh}
          />
        )}

        {Const.excelEnable && (
          <Button
            className="download-btn"
            style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
            onClick={() => this.onDownload()}>
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            엑셀 다운로드
          </Button>
        )}

        <RangePicker
          style={{ width: 300, float: 'right', marginRight: 10 }}
          placeholder={['시작일', '종료일']}
          onChange={(_, dateStrings) => {
            if (dateStrings[(0, 1)]) {
              this.setState(
                {
                  startDate: dateStrings[0],
                  endDate: dateStrings[1],
                  pagination: {
                    current: 1,
                    pageSize: 10,
                  },
                },
                () => {
                  console.log('시작      ' + this.state.startDate);
                  console.log('끝      ' + this.state.endDate);
                  this.getList();
                }
              );
            } else {
              // console.log('test')
              this.setState(
                {
                  startDate: '',
                  endDate: '',
                  pagination: {
                    current: 1,
                    pageSize: 10,
                  },
                },
                () => {
                  this.getList();
                }
              );
            }
          }}
        />

        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

export default DeliveryCardPaymentHistory;
