import React, { Component } from 'react';
import moment from 'moment';
import { Form, Input, Table, Select, DatePicker } from 'antd';
import { connect } from 'react-redux';
import { comma } from '../../lib/util/numberUtil';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import xlsx from 'xlsx';
import Const from '../../const';

const Search = Input.Search;
const FormItem = Form.Item;
const Option = Select.Option;

class FranchiseDuesHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      // paginationExcel: {
      //   total: 0,
      //   current: 1,
      //   pageSize: 100,
      // },
      frName: '',
      branchName: '',
      startDate: new moment('2021-07-01').format('YYYY-MM-DD'),
      endDate: new moment().format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    this.getList();
    // this.getExcelList();
  }

  getList = () => {
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let frName = this.state.frName;
    let branchName = this.state.branchName;
    let startDate = this.state.startDate;
    let endDate = moment(this.state.endDate).add(1, 'd').format("YYYY-MM-DD");
    httpGet(
      httpUrl.franchiseDuesHistory,
      [pageNum, pageSize, frName, branchName, startDate, endDate],
      {}
    ).then((res) => {
      console.log('This is FranchiseDuesHistory');
      console.log(res);
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.franchises,
        pagination,
      });
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onSearchBranchName = (value) => {
    value
      ? this.setState(
          {
            branchName: value,
            pagination: {
              current: 1,
              pageSize: 10,
            },
          },
          () => {
            this.getList();
          }
        )
      : this.setState(
          {
            pagination: {
              current: 1,
              pageSize: 10,
            },
          },
          () => this.getList()
        );
  };

  onSearchFranchise = (value) => {
    value
      ? this.setState(
          {
            frName: value,
            pagination: {
              current: 1,
              pageSize: 10,
            },
          },
          () => {
            this.getList();
          }
        )
      : this.setState(
          {
            pagination: {
              current: 1,
              pageSize: 10,
            },
          },
          () => {
            this.getList();
          }
        );
  };

  onChangeDate = (dateString) => {
    this.setState(
      {
        startDate:
          dateString != null
            ? moment(dateString[0]).format('YYYY-MM-DD')
            : new moment('2021-07-01').format('YYYY-MM-DD'),
        endDate:
          dateString != null
            ? moment(dateString[1]).format('YYYY-MM-DD')
            : new moment().format('YYYY-MM-DD'),
        pagination: {
          current: 1,
          // pageSize: dateString != null ? 5 : 10,
          pageSize: 10,
        },
      },
      () => this.getList()
    );
  };

  //   getExcelList = () => {
  //     let branchIdx = this.props.branchIdx;
  //     let pageNum = this.state.paginationExcel.current;
  //     let pageSize = this.state.paginationExcel.pageSize;
  //     httpGet(
  //       httpUrl.franchiseDuesHistory,
  //       [branchIdx, pageNum, pageSize],
  //       {}
  //     ).then((res) => {
  //       const pagination = { ...this.state.pagination };
  //       pagination.current = res.data.currentPage;
  //       pagination.total = res.data.totalCount;
  //       this.setState({
  //         listExcel: res.data.franchises,
  //         pagination,
  //       });
  //     });
  //   };

  //   onDownload = (data) => {
  //     // let col6=["PG 사용"];
  //     // for(let i=0; i<=data.length-1; i++) {
  //     //   col6.push(data[i].tidNormalRate == 100 ? '미사용' : '사용')
  //     // };
  //     const ws = xlsx.utils.json_to_sheet(data);
  //     const wb = xlsx.utils.book_new();
  //     [
  //       "useridx",
  //       "branchName",
  //       "가맹점명",
  //       "주소",
  //       "상세주소",
  //       "addr3",
  //       "tidNormalRate",
  //       "가맹점번호",
  //       "지갑주소",
  //       "tidNormal",
  //       "tidPrepay",
  //       "businessNumber",
  //       "가맹주",
  //       "userId",
  //       "userEmail",
  //       "userPhone",
  //       "가맹비",
  //       "예금주",
  //       "은행",
  //       "계좌번호",
  //     ].forEach((x, idx) => {
  //       const cellAdd = xlsx.utils.encode_cell({ c: idx, r: 0 });
  //       ws[cellAdd].v = x;
  //     });
  //     // col6.forEach((x, idx) => {
  //     //     const cellAdd = xlsx.utils.encode_cell({c:6, r:idx});
  //     //     ws[cellAdd].v = x;
  //     //     ws[cellAdd].t = "string";
  //     //   })
  //     ws["!cols"] = [];
  //     ws["!cols"][0] = { hidden: true };
  //     ws["!cols"][1] = { hidden: true };
  //     ws["!cols"][5] = { hidden: true };
  //     ws["!cols"][6] = { hidden: true };
  //     ws["!cols"][9] = { hidden: true };
  //     ws["!cols"][10] = { hidden: true };
  //     ws["!cols"][11] = { hidden: true };
  //     ws["!cols"][13] = { hidden: true };
  //     ws["!cols"][14] = { hidden: true };
  //     ws["!cols"][15] = { hidden: true };
  //     ws["!cols"][2] = { width: 15 };
  //     ws["!cols"][3] = { width: 30 };
  //     ws["!cols"][4] = { width: 15 };
  //     ws["!cols"][7] = { width: 20 };
  //     ws["!cols"][8] = { width: 20 };
  //     ws["!cols"][19] = { width: 20 };
  //     xlsx.utils.book_append_sheet(wb, ws, "sheet1");
  //     xlsx.writeFile(wb, "가맹비내역.xlsx");
  //   };

  render() {
    const columns = [
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
      },
      {
        title: '가맹비',
        dataIndex: 'ncashDelta',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}원</div>,
      },
      {
        title: '가입기간',
        dataIndex: 'registDate',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '가맹점번호',
        dataIndex: 'frPhone',
        className: 'table-column-center',
      },
      {
        title: '가맹점주소',
        className: 'table-column-center',
        render: (data, row) => <div>{row.addr1 + ' ' + row.addr2}</div>,
      },
      // {
      //   title: "가맹주",
      //   dataIndex: "ownerName",
      //   className: "table-column-center",
      // },
      // {
      //   title: "은행",
      //   dataIndex: "vaccountBank",
      //   className: "table-column-center",
      // },
      // {
      //   title: "예금주",
      //   dataIndex: "vaccountDepositor",
      //   className: "table-column-center",
      // },
      // {
      //   title: "계좌번호",
      //   dataIndex: "vaccountNumber",
      //   className: "table-column-center",
      // },
    ];

    return (
      <>
        <div style={{ marginBottom: '10px' }}>
          <Search
            placeholder="지점 검색"
            enterButton
            allowClear
            onChange={(e) => this.setState({ branchName: e.target.value })}
            onSearch={this.onSearchBranchName}
            style={{
              width: 220,
            }}
          />
          <Search
            placeholder="가맹점 검색"
            enterButton
            allowClear
            onChange={(e) => this.setState({ frName: e.target.value })}
            onSearch={this.onSearchFranchise}
            style={{
              width: 220,
              marginLeft: 10,
            }}
          />
          <>
            <Input
              placeholder="가입기간 검색"
              style={{
                width: 130,
                marginLeft: 10,
              }}
            />
            <DatePicker.RangePicker
              placeholder={['시작일', '종료일']}
              value={[
                moment(this.state.startDate, 'YYYY-MM-DD'),
                moment(this.state.endDate, 'YYYY-MM-DD'),
              ]}
              onChange={this.onChangeDate}
              style={{ width: 350 }}
            />
          </>
          {/* {Const.excelEnable && (
        <Button
          className="download-btn"
          style={{ float: "right", marginLeft: 10, marginBottom: 20 }}
          onClick={() => this.onDownload(this.state.listExcel)}
        >
          <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
          엑셀 다운로드
        </Button>)} */}
        </div>
        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) =>
            record.status === 'COMPLETE' ? 'table-disabled' : ''
          }
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={this.expandedRowRender}
          expandRowByClick={true}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};
export default connect(mapStateToProps, null)(FranchiseDuesHistory);
