import { Button, Form, Input, Modal, Select } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { httpPost, httpUrl, makeUrl } from '../../api/httpClient';
import { customAlert, customError } from '../../api/Modals';
import '../../css/main.css';
import { apiResponseCode } from '../../lib/util/codeUtil';
import CloseIcon from '../../img/close.png';

const Search = Input.Search;
const FormItem = Form.Item;
const Option = Select.Option;

class ModifyCenterDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 1,
      },
      centerImage: '',
      thumbnail: null,
      deleted: 0,
      uploadFileIdxList: [],

      centerFiles: [],
      centerFilesUrls: [],
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.data) {
      console.log(this.props.data);
      this.setState({
        centerFiles: this.props.data.uploadFiles,
      });
    }
  }

  handleSubmit = () => {
    let self = this;
    let { data } = this.props;
    console.log(
      'asdfasdfaasdfsa ' +
        JSON.stringify(
          {
            ...self.formRef.current.getFieldsValue(),
            deleted: self.state.deleted,
          },
          null,
          4
        )
    );
    console.log(data);
    Modal.confirm({
      title: <div> {data ? '지점 수정' : '지점 등록'} </div>,
      content: (
        <div>
          {' '}
          {data
            ? '지점을 수정하시겠습니까?'
            : '새 지점을 등록하시겠습니까?'}{' '}
        </div>
      ),
      okText: '확인',
      cancelText: '취소',
      async onOk() {
        try {
          let failedFiles = [];
          let thumbnail;

          if (self.state.thumbnail) {
            const thumbFile = self.state.thumbnail;
            const thumbFileUpload = await self.handleThumbnail(thumbFile);
            console.log('fileUploadRes');
            console.log(thumbFileUpload);
            thumbnail = self.state.thumbnailIdx;
            console.log('ASdfasdfasdfsadasasfasf' + thumbnail);
          }
          if (self.state.centerFiles.length > 0) {
            for (let i = 0; i < self.state.centerFiles.length; i++) {
              const file = self.state.centerFiles[i];
              const fileUploadRes = await self.handleFileUpload(file);
              console.log('fileUploadRes');
              console.log(fileUploadRes);
              if (!fileUploadRes.data.data.result) failedFiles.push(file);
            }
          }

          if (failedFiles.length > 0) {
            Modal.warn({
              title: '파일 업로드 실패',
              content: `${failedFiles.map(
                (file) => file.name
              )} 파일 업로드에 실패했습니다.`,
            });
            return;
          }

          const submitRes = data
            ? await httpPost(httpUrl.updateCenter, [], {
                ...self.formRef.current.getFieldsValue(),
                idx: data.idx,
                uploadFileIdx: self.state.uploadFileIdxList,
                thumbnailIdx: thumbnail,
              })
            : await httpPost(httpUrl.registCenter, [], {
                ...self.formRef.current.getFieldsValue(),
                deleted: self.state.deleted,
                uploadFileIdx: self.state.uploadFileIdxList,
                thumbnailIdx: thumbnail,
              });

          if (submitRes.data === apiResponseCode.SUCCESS) {
            customAlert('완료', `지점이 ${data ? '수정' : '등록'}되었습니다.`);
            self.props.close();
          } else
            customError(
              '실패',
              `지점 ${data ? '수정' : '등록'}에 실패했습니다.`
            );
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  handleFileUpload = async (file) => {
    if (!file) return;
    if (file.idx) {
      this.setState({
        uploadFileIdxList: this.state.uploadFileIdxList.concat(file.idx),
      });
      return {
        result: 'SUCCESS',
        data: {
          data: { result: true },
        },
      };
    }
    var formData = new FormData();
    formData.append('file', file);
    console.log(file);

    const res = await axios.post(makeUrl(httpUrl.FileUpload, []), formData, {
      withCredentials: true,
    });
    if (
      res.status === 200 &&
      res.data.result === 'SUCCESS' &&
      res.data.data.result
    ) {
      this.setState({
        uploadFileIdxList: this.state.uploadFileIdxList.concat(
          res.data.data.idx
        ),
      });
      return res;
    } else {
      return {
        result: 'SUCCESS',
        data: {
          data: false,
        },
      };
    }
  };

  handleThumbnail = async (file) => {
    if (!file) return;
    if (file.idx) {
      this.setState({
        thumbnailIdx: file.idx,
      });
      return;
    }
    var formData = new FormData();
    formData.append('file', file);
    console.log(file);

    const res = await axios.post(makeUrl(httpUrl.FileUpload, []), formData, {
      withCredentials: true,
    });
    if (
      res.status === 200 &&
      res.data.result === 'SUCCESS' &&
      res.data.data.result
    ) {
      this.setState({
        thumbnailIdx: res.data.data.idx,
      });
      return res;
    } else {
      return {
        result: 'SUCCESS',
        data: {
          data: false,
        },
      };
    }
  };

  render() {
    const { close, data } = this.props;

    return (
      <React.Fragment>
        {' '}
        <div className="Dialog-overlay" onClick={close} />
        <div className="modifyfran-Dialog">
          <div className="modifyfran-content">
            <div className="modifyfran-title">
              {data ? ' 지점 수정' : ' 지점 등록'}
            </div>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="dialog-close"
              alt="img"
            />
            <div className="modifyfran-inner">
              <Form ref={this.formRef} onFinish={this.handleSubmit}>
                <div className="modifyfran-innerbox">
                  <div className="contentBlock">
                    <div className="mainTitle">지점명</div>
                    <div className="formItem">
                      <FormItem
                        name="name"
                        className="selectItem"
                        style={{ marginLeft: 20 }}
                        rules={[
                          {
                            required: true,
                            message: '지점명을 입력해주세요.',
                          },
                        ]}
                        initialValue={data && data.name}>
                        <Input
                          placeholder="지점명을 입력하세요"
                          className="override-input"
                          style={{ width: 300 }}
                        />
                      </FormItem>
                    </div>
                  </div>

                  <div className="contentBlock">
                    <div className="mainTitle">대표사진</div>

                    <div className="formItem">
                      <FormItem
                        name="thumbnailIdx"
                        className="selectItem"
                        style={{
                          marginLeft: 20,
                        }}>
                        <Input
                          style={{ height: 38, width: 300 }}
                          className="override-input"
                          type="file"
                          accept="image/*"
                          onChange={(e) =>
                            this.setState(
                              {
                                thumbnail: e.target.files[0],
                              },
                              () => console.log(this.state.thumbnail)
                            )
                          }
                        />
                      </FormItem>
                      <div className="fileName">
                        {data && (
                          <div
                            style={{
                              color: 'red',
                              marginTop: '-15px',
                            }}>
                            {' '}
                            *수정할 시에만 파일을 선택해주세요.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="contentBlock">
                    <div className="mainTitle">내부사진</div>

                    <div className="formItem">
                      <FormItem
                        name="centerImage"
                        enctype="multipart/form-data"
                        className="selectItem"
                        style={{
                          marginLeft: 20,
                        }}>
                        <Input
                          style={{ height: 38, width: 300 }}
                          className="override-input"
                          type="file"
                          name="fileName"
                          accept="image/*"
                          multiple
                          onChange={(e) => {
                            let newCenterFiles = [];
                            for (let i = 0; i < e.target.files.length; i++) {
                              newCenterFiles.push(e.target.files.item(i));
                            }
                            this.setState(
                              {
                                centerFiles: newCenterFiles,
                              },
                              () => console.log(this.state.centerFiles)
                            );
                          }}
                        />
                      </FormItem>

                      <div className="fileName">
                        <div style={{ color: 'red', marginTop: '-15px' }}>
                          {' '}
                          *여러사진을 첨부할 경우 한번에 선택해주세요.
                        </div>
                        {this.state.centerFiles.map((file) => {
                          return (
                            <>
                              <div>
                                <div className="circle" />
                                {file.name}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="contentBlock">
                    <div className="mainTitle">주소</div>
                    <div className="formItem">
                      <FormItem
                        name="addr"
                        className="selectItem"
                        style={{
                          marginLeft: 20,
                        }}
                        rules={[
                          {
                            required: true,
                            message: '주소를 입력해주세요.',
                          },
                        ]}
                        initialValue={data && data.addr}>
                        <Input
                          placeholder="주소를 입력해주세요."
                          className="override-input"
                          style={{ width: 300 }}
                        />
                      </FormItem>
                    </div>
                  </div>

                  <div className="contentBlock">
                    <div className="mainTitle">연락처</div>
                    <div className="formItem">
                      <FormItem
                        name="tel"
                        className="selectItem"
                        style={{ marginLeft: 20 }}
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: "연락처를 입력해주세요.",
                        //     }
                        // ]}
                        initialValue={data && data.tel}>
                        <Input
                          placeholder="연락처를 입력해주세요."
                          className="override-input"
                          style={{ width: 300 }}
                        />
                      </FormItem>
                    </div>
                  </div>
                </div>
                <Button
                  style={{ float: 'right', marginTop: 10 }}
                  htmlType="submit">
                  저장하기
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ModifyCenterDialog;
