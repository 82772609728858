import { Button, DatePicker, Input, Modal, Radio, Table } from 'antd';
import locale from 'antd/lib/date-picker/locale/en_US';
import moment from 'moment';
import React, { Component } from 'react';
import XLSX from 'xlsx';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import RiderPointModifyDialog from '../../components/dialog/RiderPointModifyDialog';
import SelectBox from '../../components/input/SelectBox';
import '../../css/main.css';
import ExcelIcon from '../../img/excel.png';
import { riderPointType } from '../../lib/util/codeUtil';
import { formatDate } from '../../lib/util/dateUtil';
import { comma } from '../../lib/util/numberUtil';

const { Search } = Input;
const dateFormat = 'YYYY-MM-DD';
const today = moment(new Date()).format(dateFormat);
const oneYearAgo = moment(new Date()).subtract(1, 'years').format(dateFormat);
const oneMonthAgo = moment(new Date()).subtract(1, 'months').format(dateFormat);

const columns = [
  {
    title: '일시',
    dataIndex: 'dateTime',
    className: 'table-column-center',
    render: (data) => {
      formatDate(data);
    },
  },
  {
    title: '지점명',
    dataIndex: 'branchName',
    className: 'table-column-center',
  },
  {
    title: 'ID명',
    dataIndex: 'riderId',
    className: 'table-column-center',
  },
  {
    title: '기사명',
    dataIndex: 'riderName',
    className: 'table-column-center',
    render: (data, row) => (
      <div
        style={{ textDecoration: 'underline' }}
        onClick={() => this.getRiderCurrentPoint(row)}>
        {data}
      </div>
    ),
  },
  {
    title: '항목',
    dataIndex: 'pointType',
    className: 'table-column-center',
    render: (data) => riderPointType[data],
  },
  {
    title: '추천인ID',
    dataIndex: 'receiveId',
    className: 'table-column-center',
  },
  {
    title: '이전점수',
    dataIndex: 'beforePoint',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '추가점수',
    dataIndex: 'deltaPoint',
    className: 'table-column-center',
    render: (data) => (data >= 0 ? '+' + comma(data) : 0),
  },
  {
    title: '차감점수',
    dataIndex: 'deltaPoint',
    className: 'table-column-center',
    render: (data) => (data < 0 ? comma(data) : 0),
  },
  {
    title: '누적점수',
    dataIndex: 'afterPoint',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '레벨',
    dataIndex: 'pointLevel',
    className: 'table-column-center',
  },
  {
    title: '비고',
    dataIndex: 'memo',
    className: 'table-column-center',
  },
];

class RiderLevelList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        beginDate: today,
        endDate: today,
      },
      branchNameList: { 0: '전체지점' },
      branchNameValue: 0,
      categoryValue: 0,
      riderId: '',
      riderName: '',
      sumPlus: 0,
      sumMinus: 0,
      riderInfo: {},
    };
  }

  componentDidMount() {
    this.getBranchInfo();
    this.getList(false);
  }

  getBranchInfo = async () => {
    let branchNameList = this.state.branchNameList;
    await httpPost(httpUrl.branchListInfo, [], {}).then((res) => {
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data);
        res.data.branch.map(
          (item) => (branchNameList[item.idx] = item.branchName)
        );
        this.setState(
          {
            branchNameList: branchNameList,
          },
          () => {
            console.log('branchNameList');
            console.log(this.state.branchNameList);
          }
        );
      }
    });
  };

  getList = async (isForExcel) => {
    const pagination = this.state.pagination;
    let pageNum = pagination.current;
    let pageSize = pagination.pageSize;
    let beginDate = pagination.beginDate;
    let endDate = pagination.endDate;
    let branchIdx = this.state.branchNameValue;
    let pointType = this.state.categoryValue;
    let riderId = this.state.riderId;
    let riderName = this.state.riderName;

    const res = await httpGet(
      httpUrl.getRiderLevelList,
      [
        pageNum,
        pageSize,
        beginDate,
        endDate,
        branchIdx,
        pointType,
        riderId,
        riderName,
        isForExcel,
      ],
      {}
    );
    if (isForExcel) return res;
    if (res.result === 'SUCCESS') {
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        pagination: pagination,
        list: res.data.historyList,
        sumPlus: res.data.sumPlus,
        sumMinus: res.data.sumMinus,
        riderInfo: {
          riderIdx: res.data.riderIdx,
          branchName: res.data.branchName,
          riderName: res.data.riderName,
          afterPoint: 0,
        },
      });
    }
  };

  getRiderCurrentPoint = (row) => {
    try {
      console.log(row);
      const riderIdx = row.riderIdx;
      httpGet(httpUrl.getRiderCurrentPoint, [riderIdx], {})
        .then((res) => {
          console.log('getRiderCurrentPoint');
          console.log(res);
          if (
            res.result === 'SUCCESS' &&
            res.data !== null &&
            res.data !== undefined
          ) {
            this.setState({
              riderInfo: res.data,
              modifyRiderPoint: true,
            });
          } else if (res.result === 'NOT_FOUND_POINT_INFO') {
            Modal.warn({
              title: '정보조회 에러',
              content: '포인트 정보를 조회할 수 없습니다.',
            });
          } else {
            Modal.warn({
              title: '지급차감 에러',
              content:
                '해당 라이더에게 지급차감을 할 수 없습니다. 관리자에게 문의해주세요.',
            });
            return;
          }
        })
        .catch((e) => {
          console.warn(e);
          Modal.warn({
            title: '지급차감 에러',
            content:
              '해당 라이더에게 지급차감을 할 수 없습니다. 관리자에게 문의해주세요.',
          });
        });
    } catch (error) {
      console.error('getRiderCurrentPoint ERROR: {}', error);
    }
  };

  onRadioChange = (e) => {
    let value = e.target.value;
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          beginDate:
            value === 1 ? oneYearAgo : value === 2 ? oneMonthAgo : today,
          endDate: today,
        },
      },
      () => this.getList(false)
    );
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList(false)
    );
  };

  onChangeDatePickerStart = (_, dateString) => {
    const { pagination } = this.state;
    if (dateString) {
      if (new Date(pagination.endDate) - new Date(dateString) > 0) {
        this.setState(
          {
            pagination: {
              ...pagination,
              current: 1,
              beginDate: dateString,
            },
          },
          () => this.getList(false)
        );
      } else {
        this.setState(
          {
            pagination: {
              ...pagination,
              current: 1,
              beginDate: pagination.endDate,
              endDate: dateString,
            },
          },
          () => this.getList(false)
        );
      }
    } else {
      this.setState(
        {
          pagination: {
            ...pagination,
            current: 1,
            beginDate: today,
            endDate: today,
          },
        },
        () => this.getList(false)
      );
    }
  };

  onChangeDatePickerEnd = (_, dateString) => {
    const { pagination } = this.state;
    if (dateString) {
      if (new Date(pagination.beginDate) - new Date(dateString) < 0) {
        this.setState(
          {
            pagination: {
              ...pagination,
              current: 1,
              endDate: dateString,
            },
          },
          () => this.getList(false)
        );
      } else {
        this.setState(
          {
            pagination: {
              ...pagination,
              current: 1,
              beginDate: dateString,
              endDate: pagination.beginDate,
            },
          },
          () => this.getList(false)
        );
      }
    } else {
      this.setState(
        {
          pagination: {
            ...pagination,
            current: 1,
            beginDate: today,
            endDate: today,
          },
        },
        () => this.getList(false)
      );
    }
  };

  onDownload = async () => {
    const res = await this.getList(true);

    if (res.result === 'SUCCESS') {
      const excelJson = this.parseExcelJson(res.data.historyList);

      const ws = XLSX.utils.json_to_sheet(excelJson, {
        skipHeader: true,
      });

      const wb = XLSX.utils.book_new();

      const fileName = '레벨업내역.xlsx';

      XLSX.utils.book_append_sheet(wb, ws, fileName);
      await XLSX.writeFile(wb, fileName);
    } else {
      Modal.warn({
        title: '다운로드 실패',
        content: '데이터를 가져오는데 실패했습니다.',
      });
    }
  };

  parseExcelJson = (data) => {
    let result = [];
    let header = {};
    const exceptionCols = ['riderName'];

    columns.forEach((col) => {
      header[col.dataIndex] = col.title;
    });

    result.push(header);

    data.forEach((row) => {
      let rowJson = {};
      columns.forEach((col) => {
        if (exceptionCols.includes(col.dataIndex)) {
          rowJson[col.dataIndex] = row[col.dataIndex];
        } else {
          rowJson[col.dataIndex] = col.render
            ? col.render(row[col.dataIndex])
            : row[col.dataIndex];
        }
      });
      result.push(rowJson);
    });

    return result;
  };

  render() {
    const columns = [
      {
        title: '일시',
        dataIndex: 'dateTime',
        className: 'table-column-center',
        render: (data) => <div>{formatDate(data)}</div>,
      },
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: 'ID명',
        dataIndex: 'riderId',
        className: 'table-column-center',
      },
      {
        title: '기사명',
        dataIndex: 'riderName',
        className: 'table-column-center',
        render: (data, row) => (
          <div
            style={{ textDecoration: 'underline' }}
            onClick={() => this.getRiderCurrentPoint(row)}>
            {data}
          </div>
        ),
      },
      {
        title: '항목',
        dataIndex: 'pointType',
        className: 'table-column-center',
        render: (data) => <div>{riderPointType[data]}</div>,
      },
      {
        title: '추천인ID',
        dataIndex: 'receiveId',
        className: 'table-column-center',
      },
      {
        title: '이전점수',
        dataIndex: 'beforePoint',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '추가점수',
        dataIndex: 'deltaPoint',
        className: 'table-column-center',
        render: (data) => <div>{data >= 0 ? '+' + comma(data) : 0}</div>,
      },
      {
        title: '차감점수',
        dataIndex: 'deltaPoint',
        className: 'table-column-center',
        render: (data) => <div>{data < 0 ? comma(data) : 0}</div>,
      },
      {
        title: '누적점수',
        dataIndex: 'afterPoint',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '레벨',
        dataIndex: 'pointLevel',
        className: 'table-column-center',
      },
      {
        title: '비고',
        dataIndex: 'memo',
        className: 'table-column-center',
      },
    ];

    const { branchNameList, pagination, list } = this.state;
    return (
      <>
        <div>
          <SelectBox
            value={branchNameList[this.state.branchNameValue]}
            code={Object.keys(branchNameList)}
            codeString={branchNameList}
            onChange={(value) => {
              if (parseInt(value) !== this.state.branchNameValue) {
                this.setState(
                  {
                    branchNameValue: parseInt(value),
                  },
                  () => this.getList(false)
                );
              }
            }}
          />

          <SelectBox
            style={{ marginLeft: '5px' }}
            value={riderPointType[this.state.categoryValue]}
            code={Object.keys(riderPointType)}
            codeString={riderPointType}
            onChange={(value) => {
              if (parseInt(value) !== this.state.categoryValue) {
                this.setState(
                  {
                    categoryValue: parseInt(value),
                  },
                  () => this.getList(false)
                );
              }
            }}
          />

          <Search
            placeholder="ID 명 검색"
            enterButton
            allowClear
            onChange={(e) => {
              this.setState({
                riderId: e.target.value,
              });
            }}
            onSearch={() => this.getList(false)}
            style={{
              width: 220,
              marginLeft: '5px',
            }}
          />

          <Search
            placeholder="기사명 검색"
            enterButton
            allowClear
            onChange={(e) => {
              this.setState({
                riderName: e.target.value,
              });
            }}
            onSearch={() => this.getList(false)}
            style={{
              width: 220,
              marginLeft: '5px',
            }}
          />

          <Button
            className="download-btn"
            style={{ float: 'right' }}
            onClick={async () => await this.onDownload()}>
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            엑셀 다운로드
          </Button>
        </div>

        <div>
          <Radio.Group
            defaultValue={3}
            onChange={this.onRadioChange}
            style={{ marginTop: '15px', marginBottom: '15px' }}>
            <Radio value={1}>1년</Radio>
            <Radio value={2}>한달</Radio>
            <Radio value={3}>오늘</Radio>
          </Radio.Group>

          <DatePicker
            placeholder={'시작일'}
            locale={locale}
            style={{
              height: '32px',
              borderRadius: '6px',
              marginTop: '5px',
              marginBottom: '5px',
            }}
            size="middle"
            format={dateFormat}
            value={moment(pagination.beginDate, dateFormat)}
            onChange={this.onChangeDatePickerStart}
          />
          <DatePicker
            placeholder={'종료일'}
            locale={locale}
            style={{
              height: '32px',
              borderRadius: '6px',
              marginTop: '5px',
              marginBottom: '5px',
              marginLeft: '5px',
            }}
            size="middle"
            format={dateFormat}
            value={moment(pagination.endDate, dateFormat)}
            onChange={this.onChangeDatePickerEnd}
          />
          <div style={{ float: 'right' }}>
            * 기사명 클릭 시 해당 기사에게 포인트를 지급/차감 가능합니다.
          </div>
        </div>
        {this.state.modifyRiderPoint && (
          <RiderPointModifyDialog
            data={this.state.riderInfo}
            close={() =>
              this.setState(
                {
                  modifyRiderPoint: false,
                },
                () => this.getList(false)
              )
            }
          />
        )}
        <Table
          rowKey={(record) => record.idx}
          dataSource={list}
          columns={columns}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
        <div style={{ fontSize: '22px', fontWeight: 'bold' }}>
          기간한정 추가점수 합계:{'    '} {comma(this.state.sumPlus)}
          <br />
          기간한정 차감점수 합계:{'    '} {comma(this.state.sumMinus)}
        </div>
      </>
    );
  }
}

export default RiderLevelList;
