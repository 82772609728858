import { Button, Form, Table } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { formatDates } from '../../lib/util/dateUtil';
import { accountFormat } from '../../lib/util/numberUtil';
import CardDetailModal from '../settlement/CardDetailModal';
import Const from '../../const';

const FormItem = Form.Item;

class RemainAmountMinus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },

      frName: '',
      riderName: '',
      selectedRow: null,
      cardDetailModalOpen: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  onRefresh = () => {
    this.setState(
      {
        pagination: {
          total: 0,
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  openCardDetailModal = (row) => {
    this.setState({
      selectedRow: row,
      cardDetailModalOpen: true,
    });
  };
  closeCardDetailModal = () => {
    this.setState({
      cardDetailModalOpen: false,
      selectedRow: null,
    });
  };

  getList = () => {
    let frName = this.state.frName;
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let riderName = this.state.riderName;
    httpGet(
      httpUrl.cardPaymentRasList,
      [frName, pageNum, pageSize, riderName],
      {}
    ).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.cardpayments,
        pagination,
      });
    });
  };

  // 라이더 검색
  onSearchRider = (value) => {
    this.setState(
      {
        riderName: value,
      },
      () => {
        this.getList();
      }
    );
  };
  // 가맹점 검색
  onSearchFranchisee = (value) => {
    this.setState(
      {
        frName: value,
        pagination: {
          current: 1,
          pageSize: 5,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  pressSearch = () => {
    this.setState(
      {
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  render() {
    const columns = [
      {
        title: '상세조회',
        dataIndex: 'orderIdx',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            <Button
              style={{ marginLeft: '10px' }}
              className="tabBtn"
              onClick={() => this.openCardDetailModal(row)}>
              상세
            </Button>
          </div>
        ),
      },
      {
        title: '날짜',
        dataIndex: 'createDate',
        className: 'table-column-center',
        render: (data) => <div>{formatDates(data)} </div>,
      },
      {
        title: '라이더명',
        dataIndex: 'riderName',
        className: 'table-column-center',
      },
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
      },
      {
        title: '고객주소',
        dataIndex: 'custAddr1',
        className: 'table-column-center',
      },
      {
        title: '상세주소',
        dataIndex: 'custAddr2',
        className: 'table-column-center',
      },
      {
        title: '고객전화번호',
        dataIndex: 'custPhone',
        className: 'table-column-center',
      },
      // {
      //     title: "카드정보",
      //     dataIndex: "reserved1",
      //     className: "table-column-center",
      //     width: "10%",
      // },
      {
        title: '카드번호',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        render: (data, row) => <div>{JSON.parse(data).cardNo}</div>,
      },
      {
        title: '인증번호',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        render: (data, row) => <div>{JSON.parse(data).authNum}</div>,
      },
      {
        title: '카드명',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        render: (data, row) => <div>{JSON.parse(data).message1}</div>,
      },
      {
        title: '요금',
        dataIndex: 'price',
        className: 'table-column-center',
        render: (data, row) => <div>{accountFormat(row.price + row.tax)}</div>,
      },
    ];

    return (
      <React.Fragment>
        <div className="taskScheduler-Dialog">
          <div className="taskScheduler-content">
            <div className="taskScheduler-inner">
              <FormItem>
                <div className="taskScheduler-btn">
                  <div className="taskScheduler-btn-01">
                    <CardDetailModal
                      data={this.state.selectedRow}
                      isOpen={this.state.cardDetailModalOpen}
                      close={this.closeCardDetailModal}
                      callback={this.onRefresh}
                    />
                  </div>
                </div>

                {/* <Button
                    className="download-btn"
                    style={{ float: "right", marginLeft: 10, marginBottom: 20 }}
                    onClick={() => this.onDownload(this.state.listExcel)}
                >
                    <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
                    엑셀 다운로드
                </Button> */}

                <Table
                  rowKey={(record) => record.idx}
                  dataSource={this.state.list}
                  columns={columns}
                  pagination={this.state.pagination}
                  onChange={this.handleTableChange}
                />
              </FormItem>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RemainAmountMinus;
