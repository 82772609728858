const apiResponseCode = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

// 바이크 - 상태(조회)
const bikeStatusSearch = {
  0: '전체',
  1: '미배정',
  2: '운행중',
  3: '수리입고',
  4: '배정완료',
  5: '기타',
  6: '이관',
};

// 바이크 - 상태
const bikeStatusType = {
  1: '미배정',
  2: '운행중',
  3: '수리입고',
  4: '배정완료',
  5: '기타',
  6: '이관',
};

const bikeHistoryType = {
  1: '미배정',
  2: '생성',
  3: '배정',
  4: '수령',
  5: '수리',
};

// 바이크 - 명의자
const ownerType = {
  1: '냠냠바이크',
  // 2: "지입",
  3: '냠냠바이크경기',
};

// 미배정바이크위치
const bikeLocationType = {
  20001: '석모센터',
  20002: '송도센터',
  20003: '풍무센터',
  20004: '연수센터',
  20005: '주안센터',
  20006: '덕양센터',
};

// 바이크 관련 실패 코드
const bikeResponseErrorCode = {
  INVALID_MODEL_NAME: '차종',
  INVALID_BIKE_NUMBER: '차량번호',
  INVALID_VI_NUMBER: '차대번호',
  INVALID_MODEL_YEAR: '연식',
  INVALID_OWNER: '명의자',
  INVALID_INSURANCE_COMPANY: '보험회사',
  INVALID_INSURANCE_AGE: '보험나이',
  INVALID_INSURANCE_END_DATE: '보험만기일',
  INVALID_BIKE_STATUS: '상태',
  INVALID_MEMO: '비고',
  INVALID_BIKE_IDX: '바이크 정보',
  INVALID_BRANCH_NAME: '브랜치명',
  INVALID_RIDER_NAME: '라이더명',
  INVALID_RIDER_IDX: '라이더 정보',
  INVALID_RENT_FEE: '렌트비',
  INVALID_UNASSGIN_BIKE_LOCATION: '미배정바이크위치',
  INVALID_REGISTRED_RENT_FEE: '렌트비 설정에 등록 된 바이크 정보',
  INVALID_DELETED_ONLY_UNASSIGNED:
    '바이크가 미배정일때만 삭제가 가능. 미배정 여부',
  FAIL: '',
};

const insuranceCompanyType = {
  1: 'KB',
  2: '현대해상',
};

const bikeSearchType = {
  0: '차종',
  1: '차량번호',
};

const feeType = ['정량', '정률'];

const deliveryPriceFeeType = ['정률', '정액', '일차감'];

const riderGroup = {
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
  5: 'E',
};

const insuranceAgeType = {
  0: '전연령',
  1: '21',
  2: '24',
  3: '26',
  4: '30',
};

const pgUseRate = {
  0: '사용',
  100: '미사용',
};

const riderLevel = [
  '',
  '라이더',
  '팀장',
  '본부장',
  '부본부장',
  '수석본부장',
  '부지점장',
  '지점장',
  '부센터장',
  '센터장',
];

//  예치금 지급
const searchType = ['라이더', '가맹점'];
const deletedStatus = ['등록', '삭제'];
const bikeType = {
  1: 'PCX',
  2: 'NMAX',
};

const categoryStatus = {
  NONE: '전체',
  BATCH_WORK_NCASH: '일차감',
  BATCH_WORK_NCASH_MONTHLY: '월차감',
  BUY_PRODUCT: '몰 상품 구매',
  BUY_PRODUCT_CANCEL: '몰 상품 구매 취소',
  CASH_ORDER_PRICE_SEND: '현금결제분 전송',
  CASH_ORDER_PRICE_SEND_RESTORE: '현금결제분 전송 복구',
  CASH_ORDER_PRICE_RECEIVE: '현금결제분 수령',
  CASH_ORDER_PRICE_RECEIVE_RESTORE: '현금결제분 수령 복구',
  DELIVERY_PRICE_RECEIVE: '배송비 받기',
  DELIVERY_PRICE_SEND: '배송비 보내기',
  DELIVERY_PRICE_SEND_RESTORE: '배송비 보내기 복원',
  DELIVERY_PRICE_RECEIVE_RESTORE: '배송비 받기 복원',
  DUES_PAY: '가맹비 지급',
  DUES_PAY_RESTORE: '가맹비 반환',
  NCASH_CHARGE_REQ: '충전',
  NCASH_CHARGE_PROC: '충전 처리',
  NCASH_SEND: '예치금 전송',
  NCASH_SEND_BY_ADMIN: '관리자 예치금 추가/차감',
  WITHDRAW_PROC: '출금 처리',
  WITHDRAW_REQ: '출금 요청',
  INCENTIVE_PAYED: '인센티브 지급',
  RIDER_ASSIGN_CANCEL: '배취 페널티',
  RIDER_LEVEL_UP_GIVE_NCASH: '레벨업 축하 지원금',
  RIDER_LEVEL_UP_GIVE_NCASH_SEND: '냠냠박스 예치금 차감(레벨업 축하 지원금)',
  RIDER_LEVEL_UP_GIVE_NCASH_TAX:
    '냠냠박스 예치금 세금 추가(레벨업 축하 지원금)',
  BATCH_WORK_NCASH_OF_RIDER_BIKE_RENT_FEE: '라이더 바이크 렌트비',
  HOURLY_INSURANCE: '시간제 보험',
  AGENCY_FEE: '프로그램 사용료',
  AGENCY_FEE_RESTORE: '프로그램 사용료 복원',
  DELIVERY_PRICE_PENALTY: '페널티 처리(페널티콜 취소)',
  DELIVERY_SUBSIDY_SEND: '배달 지원금 보내기',
  DELIVERY_SUBSIDY_RECEIVE: '배달 지원금 받기',
  DELIVERY_SUBSIDY_SEND_RESTORE: '배달 지원금 보내기 복원',
  DELIVERY_SUBSIDY_RECEIVE_RESTORE: '배달 지원금 받기 복원',
};

const bankCharge = {
  NONE: '전체',
  NCASH_CHARGE_PROC: '충전',
  WITHDRAW_REQ: '출금',
};

// 접수현황 - 결재방식
const paymentMethod = ['', '카드', '현금', '선결'];

const paymentStatus = ['', '요청', '완료', '취소', '에러', '삭제'];

const representativePhoneAgency = {
  0: 'SKT',
  1: 'KT',
  2: 'LG U+',
  3: 'SKT(알뜰폰)',
  4: 'KT(알뜰폰)',
  5: 'LG U+(알뜰폰)',
};

const extCode = {
  all: '전체',
  nn: '냠냠주문',
  bm: '배달의민족',
  ft: '푸드테크',
  bp: '바나나포스',
  de: '배달특급',
  dg: '배달천재',
  ps: '포스피드',
};

const kindString = [
  '전체',
  '렌트비(냠냠바이크)',
  '대출상환',
  '산재보험비',
  '수리비(냠냠바이크)',
  '경기바이크',
  '대여금 회수',
  '대출상환(아우라)',
  '렌트비(냠냠바이크경기)',
  '수리비(냠냠바이크경기)',
  '렌트비 회수',
  '수리비 회수',
  '단체상해보험',
  '포스구매',
  '기사수수료',
  '라이더그룹(일차감)',
  '대출상환(대양)',
  '과태료 회수',
  '냠냠몰비품구입',
  '가맹비',
  '기타',
  '음식보험 회수',
  '선충전 회수',
  '라이더간 회수',
];

const btachWorkKindForAgency = [
  { label: '렌트비 회수', value: 10 },
  { label: '대여금 회수', value: 6 },
  { label: '기타', value: 20 },
];
const btachWorkKindForFr = [
  { label: '선충전 회수', value: 22 },
  { label: '기타', value: 20 },
];
const batchWorkCategoryForAgency = [
  { label: '전체', value: 999 },
  { label: '렌트비 회수', value: 10 },
  { label: '대여금 회수', value: 6 },
  { label: '선충전 회수', value: 22 },
  { label: '기타', value: 20 },
];
const batchWorkDetailCategoryForAgency = [
  { label: '전체', value: null },
  { label: '선충전', value: 'PRECHARGE' },
  { label: '렌트비', value: 'BIKE_RENT' },
  { label: '대여금', value: 'LOAN' },
  { label: '기타', value: 'ETC' },
];
const batchWorkCategoryForAgencyCode = {
  999: '전체',
  10: '렌트비 회수',
  6: '대여금 회수',
  22: '선충전 회수',
  20: '기타',
};

const batchWorkKindForAgencyCode = {
  PRECHARGE: 22,
  BIKE_RENT: 10,
  BIKE_REPAIR: 11,
  BIKE_FINE: 17,
  FOOD_INSURE: 21,
  LOAN: 6,
  DUES: 19,
  ETC: 20,
};
const batchWorkCategoryForBranch = [
  { label: '전체', value: null },
  { label: '렌트비', value: 'BIKE_RENT' },
  { label: '수리비', value: 'BIKE_REPAIR' },
  { label: '과태료', value: 'BIKE_FINE' },
  { label: '음식보험', value: 'FOOD_INSURE' },
  { label: '대여금', value: 'LOAN' },
  { label: '가맹비', value: 'DUES' },
  { label: '기타', value: 'ETC' },
];
const batchWorkCategoryString = ['', '일차감', '월차감', '라이더그룹(일차감)'];
const adminSendKindForAgency = [
  { label: '대여금 지급', value: 7 },
  { label: '프로모션 지급', value: 4 },
  { label: '기타', value: 0 },
];
const dminSendKindKindForFr = [
  { label: '선충전 지급', value: 1 },
  { label: '프로모션 지급', value: 4 },
  { label: '기타', value: 0 },
];
const adminSendKindString = [
  '기타',
  '선충전 지급',
  '음식배상',
  '코인이관',
  '프로모션 지급',
  '산재보험비',
  '대출상환',
  '대여금 지급',
  '대출상환(아우라)',
  '수리비(냠냠바이크)',
  '수리비(냠냠바이크경기)',
  '수리비 납부',
  '렌트비(냠냠바이크)',
  '렌트비(냠냠바이크경기)',
  '렌트비 납부',
  '경기바이크',
  '단체상해보험',
  '포스구매',
  '냠냠몰구매',
  '기사수수료',
  '라이더그룹(일차감)',
  '선충전(B2B)',
  '대출상환(대양)',
  '이벤트상금지급',
  '과태료 납부',
];

const adminSendKindStringForSearch = ['전체', ...adminSendKindString];

const bankCode = {
  경남은행: '039',
  광주은행: '034',
  국민은행: '004',
  기업은행: '003',
  농협: '011',
  단위농협: '012',
  대구은행: '031',
  부산은행: '032',
  산림조합: '064',
  산업은행: '002',
  상호저죽: '050',
  새마을금고: '045',
  수협: '007',
  신한은행: '088',
  신협: '048',
  우리은행: '020',
  우체국: '071',
  전북은행: '037',
  제주은행: '035',
  KEB하나은행: '081',
  한국시티은행: '027',
  SC제일은행: '023',
  K뱅크: '089',
  카카오뱅크: '090',
};

const statusString = {
  1: '사용',
  2: '중지',
  3: '탈퇴',
};
const statusCode = {
  0: '전체',
  1: '사용',
  2: '중지',
  3: '탈퇴',
};
const userTypeString = {
  1: '라이더',
  2: '가맹점',
  3: '',
  4: '관제',
};

const batchCategory = ['', '일차감', '월차감'];

const cancelCategory = ['취소완료', '정상결제'];

const mallProductStatusCode = {
  1: '판매중',
  2: '판매중지',
};

const frStatusString = {
  0: '',
  1: '사용',
  2: '중지',
  3: '탈퇴',
};

const dwServiceTypeCode = {
  KWANGWON: 1,
  PAYSTORY: 2,
};

const mapPaystoryVacntResultCd = {
  ALREADY_MAPPED: '이미 가상계좌가 발급된 계정입니다.',
  INSUFFICIENT_VACNT: '발급가능한 가상계좌가 없습니다. 관리자에게 문의하세요.',
  USER_NOT_FOUND: '잘못된 유저정보입니다.',
  USER_TYPE_ERROR: '잘못된 유저타입입니다.',
  USER_UPDATE_ERROR: '계정 정보 갱신에 실패했습니다. 관리자에게 문의하세요.',
  PV_UPDATE_ERROR: '가상계좌 정보 갱신에 실패했습니다. 관리자에게 문의하세요.',
  VACNT_LIMIT_SETTING_ERROR:
    '가상계좌 한도 설정에 실패했습니다. 관리자에게 문의하세요.',
  FR_NAME_EXTRA_NOT_VALID:
    '실가맹점명이 잘못되었습니다. 실가맹점 인증 후 가상계좌를 발급해주세요.',
  SET_DEPOSITOR_ERROR:
    '가상계좌 예금주 설정에 실패했습니다. 관리자에게 문의하세요.',
  SUCCESS: '가상계좌 발급에 성공했습니다.',
};

const approvalCategory = {
  DUES: '가맹비',
  WITHDRAW_REQ: '출금승인요청',
};

const approvalStatus = {
  0: '요청 중',
  1: '승인',
  2: '거절',
};

const rawData = {
  DUES: '가맹비',
  CHARGE_DATE: '최초가맹비',
};
const itemCode = [
  { value: 'all', label: '전체' },
  { value: 'delivery', label: '배달건별' },
  { value: 'fr', label: '가맹비(월)' },
  { value: 'promotion', label: '프로모션' },
  { value: 'employee', label: '고용보험' },
  { value: 'riderFee', label: '기사수수료' },
  { value: 'preCharge', label: '선충전' },
];

const accidentInsuranceString = {
  0: '전체',
  1: '가입요청',
  2: '해지요청',
};

const tableStatusString = {
  0: '',
  1: '1',
  2: '2',
  3: '3',
};

// const StaticCategory = [
//   { value : "DELIVERY_PRICE_SEND", label : "가맹점_배달비" },
//   { value : "DELIVERY_PRICE_RECEIVE", label : "라이더_배달비" },
//   { value : "DUES_PAY", label: "가맹점_가맹비" },
//   { value : "BATCH_WORK_NCASH", label :"라이더_일차감" },
//   { value : "BUY_PRODUCT", label:"라이더_건별차감"},
//   { value : "BATCH_WORK_NCASH_OF_RIDER_BIKE_RENT_FEE", label:"라이더_건별차감"},
//   { value : "", label:"가맹점_프로모션" },
//   { value : "", label:"라이더_프로모션" },
//   { value : "NCASH_CHARGE_PROC", lebel :"가맹점_선충전" },
//   { value : "", label : "가맹점_기타" },
//   { value : "DELIVERY_PRICE_PENULTY", label : "라이더_기타"}
// ];

const StaticCategory = {
  DELIVERY_PRICE_SEND: '가맹점_배달비',
  DELIVERY_PRICE_RECEIVE: '라이더_배달비',
  DUES_PAY: '가맹점_가맹비',
  DUES_PAY_RESTORE: '가맹점_가맹비_복원',
  BATCH_WORK_NCASH: '라이더_일차감',
  BUY_PRODUCT: '라이더_건별차감',
  BUY_PRODUCT_CANCEL: '라이더_건별차감',
  BATCH_WORK_NCASH_OF_RIDER_BIKE_RENT_FEE: '라이더_건별차감',
  NCASH_SEND_BY_ADMIN: '라이더_건별차감',
  a: '가맹점_프로모션',
  b: '라이더_프로모션',
  NCASH_CHARGE_PROC: '가맹점_선충전',
  NCASH_SEND_TO_FR_BY_ADMIN: '가맹점_기타',
  NCASH_SEND_TO_RIDER_BY_ADMIN: '라이더_기타',
  DELIVERY_PRICE_PENULTY: '라이더_기타',
  DELIVERY_PRICE_RECEIVE_RESTORE: '라이더_배송비_복원',
  DELIVERY_PRICE_SEND_RESTORE: '가맹점_배송비_복원',
  PROMOTION_SEND_TO_FR_BY_ADMIN: '가맹점_프로모션',
  PROMOTION_SEND_TO_RIDER_BY_ADMIN: '라이더_프로모션',
};

const FrNcashCategory = ['기타', '선충전', '프로모션'];

const RiderNcashCategory = [
  '프로모션',
  '기타',
  '수리비',
  '상해보험',
  '과태료',
  '물품대',
];

const rowColorName = ['', 'table-blue'];

const riderPointType = {
  0: '전체항목',
  1: '관리자 수정',
  2: '1콜 달성',
  3: '출근(일35콜 달성)',
  4: '우천배달수행',
  5: '1레벨 달성',
  6: '똥콜 달성',
  7: '추천인 1레벨 달성',
  101: '배달완료복구',
};

const userTypeToString = ['', '라이더', '가맹점', '관제'];

const approveWithdrawResultCode = {
  ALREADY_HANDLED_APPROVAL: '이미 처리된 요청입니다.',
  INVALID_USER_IDX: '잘못된 출금대상 정보입니다.',
  INVALID_USER_TYPE: '잘못된 출금대상 구분입니다.',
  FAIL: '출금처리에 실패했습니다. 관리자에게 문의하세요.',
  PG_NOT_REGISTERED: 'PG 미등록 유저입니다.',
  WITHDRAW_ACCOUNT_NOT_REGISTERED: '출금계좌가 등록되지 않은 유저입니다.',
  NO_BALANCE: '유저의 예치금이 부족합니다.',
};

const rejectWithdrawResultCode = {
  ALREADY_HANDLED_APPROVAL: '이미 처리된 요청입니다.',
  APPROVAL_NOT_EXIST: '존재하지 않는 승인번호입니다.',
  FAIL: '출금거절 처리에 실패했습니다. 관리자에게 문의하세요.',
};

//가맹점 배달현황 통계 노출여부
const deliveryStatShow = {
  0: 'OFF',
  1: 'ON',
};

//라이더앱 거리설정 노출여부
const distShowSetting = {
  0: 'OFF',
  1: 'ON',
};

//배차완료 후 도착지 수정가능여부
const addrUpdateEnabled = {
  0: '불가능',
  1: '가능',
};

//정렬방식
const sortOrder = {
  1: '픽업시간순',
  2: '거리순',
  3: '주문시간순',
};

const RiderApprovedCode = {
  NOT_APPROVED: 1,
  APPROVED: 2,
};

const agencyNameToPosCode = {
  배달천재: 'dgCode',
  바나나포스: 'bpCode',
  포스피드: 'psCode',
  푸드테크: 'ftCode',
  KFC: 'kfc',
  CJ: 'cjo',
  아이노포스: 'ain',
  지니포스: 'gen',
  스파이더: 'spd',
  코나아이: 'kon',
  롯데리아: 'lte',
  먹깨비: 'mkb',
  이디야: 'edy',
  선택안함: '',
};

const skinPopupCategoryCode = {
  1: '가맹점로그인스킨',
  2: '가맹점로고스킨',
  3: '가맹점로고배경스킨',
  4: '가맹점배달현황스킨',
  5: '가맹점접수요청스킨',
  6: '가맹점예치금내역스킨',
  7: '가맹점설정스킨',
  8: '가맹점로그아웃스킨',
  9: '가맹점배너배경스킨',
  10: '가맹점팝업',
  11: '라이더로그인스킨',
  12: '라이더주문상단스킨',
  13: '라이더메뉴상단스킨',
  14: '라이더팝업',
};

const skinPopupStatusCode = {
  0: '노출',
  1: '삭제',
};

const branchSettlementElementTypeCode = {
  0: 'NONE',
  1: '총 매출',
  2: '관리자 배달비 * 11.7%',
  4: '라이더 배달수수료',
  5: '가맹비',
  6: '총 비용',
  7: '보조금',
  8: '임차료',
  9: '관리비',
  10: '렌탈료',
  12: '광고/홍보비',
  13: '영업인센티브',
  14: '소모품비',
  15: '기타',
  16: '기타2',
  20: '음식변상료',
  22: '바이크 관리비',
  25: '스테이션장 수익',
  26: '스테이션 실입금액',
  29: '보조금',
  30: '산재',
  31: '고용',
  33: '명함',
  34: '명찰',
  35: '리플렛',
  36: '선충전 미회수',
  37: '지점 인수 금액',
  46: '보존 금액',
  47: '이월 예상 프로모션 금액',
  48: '법인 카드 금액',
};

//거리요금방식
const distType = {
  1: '직선거리',
  2: '네비거리',
};

// 가입/탈퇴 통계 타입
const joinExitStaticsType = {
  1: '총원',
  2: '신규',
  3: '탈퇴',
  4: '정지',
  5: '복귀',
  6: '사고',
};

const BranchVisible = {
  0: '미사용',
  1: '사용',
};

const branchHeadOfficeHistoryType = {
  1: '신규 대리점 등록',
  2: '즉시 출금 가능 대리점으로 승인',
  3: '즉시 출금 불가 대리점으로 변경',
  4: '출금 요청 승인',
};

// category 가 1인 notice가 전체용 공지
// 라이더 선택시 1+2 / 가맹점 선택시 1+3을 노출
const noticeCategoryCode = {
  구분: null,
  전체: 5,
  라이더: 6,
  가맹점: 7,
  관제: 4,
};

const noticeUpdateResponseCode = {
  SUCCESS: '공지사항 수정에 성공했습니다.',
  FAIL: '공지사항 수정에 실패했습니다.',
  SMS_FAIL: 'SMS 전송에 일부 실패했습니다.',
};

const noticeCreateResponseCode = {
  SUCCESS: '공지사항 등록에 성공했습니다.',
  FAIL: '공지사항 등록에 실패했습니다.',
  SMS_FAIL: 'SMS 전송에 일부 실패했습니다.',
};

const distTypeFr = {
  0: '지점에서 설정한 방식',
  1: '직선거리',
  2: '네비거리',
};

const fixedFeePayTargetCode ={
  'RIDER': '라이더지점',
  'FRANCHISE': '상점지점',
  'HALF': '반반',
};

const withdrawStatus = {
  1: '진행중',
  2: '완료',
  3: '실패',
};

export {
  BranchVisible,
  FrNcashCategory,
  RiderApprovedCode,
  RiderNcashCategory,
  StaticCategory,
  accidentInsuranceString,
  addrUpdateEnabled,
  adminSendKindString,
  adminSendKindStringForSearch,
  agencyNameToPosCode,
  apiResponseCode,
  approvalCategory,
  approvalStatus,
  approveWithdrawResultCode,
  bankCharge,
  bankCode,
  batchCategory,
  batchWorkCategoryString,
  bikeHistoryType,
  bikeLocationType,
  bikeResponseErrorCode,
  bikeSearchType,
  bikeStatusSearch,
  bikeStatusType,
  bikeType,
  branchHeadOfficeHistoryType,
  branchSettlementElementTypeCode,
  cancelCategory,
  categoryStatus,
  deletedStatus,
  deliveryPriceFeeType,
  deliveryStatShow,
  distShowSetting,
  distType,
  dwServiceTypeCode,
  extCode,
  feeType,
  frStatusString,
  insuranceAgeType,
  insuranceCompanyType,
  itemCode,
  joinExitStaticsType,
  kindString,
  mallProductStatusCode,
  mapPaystoryVacntResultCd,
  noticeCategoryCode,
  noticeCreateResponseCode,
  noticeUpdateResponseCode,
  ownerType,
  paymentMethod,
  paymentStatus,
  pgUseRate,
  rawData,
  rejectWithdrawResultCode,
  representativePhoneAgency,
  riderGroup,
  riderLevel,
  riderPointType,
  rowColorName,
  searchType,
  skinPopupCategoryCode,
  skinPopupStatusCode,
  sortOrder,
  statusCode,
  statusString,
  batchWorkCategoryForAgencyCode,
  userTypeString,
  userTypeToString,
  btachWorkKindForAgency,
  btachWorkKindForFr,
  batchWorkCategoryForAgency,
  batchWorkCategoryForBranch,
  adminSendKindForAgency,
  dminSendKindKindForFr,
  batchWorkDetailCategoryForAgency,
  batchWorkKindForAgencyCode,
  distTypeFr,
  fixedFeePayTargetCode,
  withdrawStatus,
};
