import { Button, DatePicker, Form, Modal } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import xlsx from 'xlsx';
import {
  httpGet,
  httpGetRenewMakeUrl,
  httpUrl,
  httpGetRenewMakeUrlWithoutLoading,
} from '../../api/httpClient';
import ProgressBar from '../../components/ProgressBar';
import VirtualTable from '../../components/VirtualTable';
import Const from '../../const';
import ExcelIcon from '../../img/excel.png';
import { riderLevel } from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';

const DailyOrderStat = () => {
  const formRef = React.createRef();
  const yesterday = new moment().clone().add('-1', 'days').format('YYYY-MM-D');

  const [list, setList] = useState([]);
  const [statDate, setStatDate] = useState(new moment());
  const [branchList, setbranchList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 100,
  });
  const [excelTotal, setExcelTotal] = useState(1);
  const [excelCurrent, setExcelCurrent] = useState(1);
  const [progressBarOn, setProgressBarOn] = useState(false);
  const progressFlag = useRef(false);

  // useEffect(async () => {
  //   getBranchList();
  // }, []);

  useEffect(async () => {
    await getList();
  }, [pagination.current, pagination.pageSize, statDate]);

  const getList = async () => {
    let params = {};

    params['statDate'] = statDate.format('YYYY-MM-DD');
    params['pageNum'] = pagination.current;
    params['pageSize'] = pagination.pageSize;

    try {
      const res = await httpGetRenewMakeUrl(httpUrl.dailyOrderStat, params, []);

      if (res.result === 'SUCCESS') {
        console.log(res);
        setList(res.data.dailyOrderStats);
        setPagination({
          ...pagination,
          total: res.data.totalCount,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
  const getBranchList = async () => {
    const res = await httpGet(httpUrl.getBranchList, [1, 10000], {});
    setbranchList([{ idx: null, branchName: '전체' }, ...res.data.branch]);
  };

  const columns = [
    {
      title: '구분',
      dataIndex: 'category',
      className: 'table-column-center-no-padding',
      width: 100,
      render: (data) => (data === 'ORDER_COMPLETE' ? '완료' : '취소'),
    },
    {
      title: '주문번호',
      dataIndex: 'orderIdx',
      width: 100,
      className: 'table-column-center',
    },
    {
      title: '가맹점지점',
      dataIndex: 'franchiseBranchName',
      width: 100,
      className: 'table-column-center',
    },
    {
      title: '가맹점명',
      dataIndex: 'frName',
      width: 300,
      className: 'table-column-center',
    },
    {
      title: '라이더명',
      dataIndex: 'riderName',
      width: 100,
      className: 'table-column-center',
    },
    {
      title: '직급',
      dataIndex: 'riderLevel',
      width: 100,
      className: 'table-column-center',
      render: (data) => riderLevel[data],
    },
    {
      title: '접수일시',
      dataIndex: 'orderDate',
      width: 200,
      className: 'table-column-center',
    },
    {
      title: '완료일시',
      dataIndex: 'completeDate',
      width: 200,
      className: 'table-column-center',
    },
    {
      title: '도착지',
      dataIndex: 'destAddr',
      width: 300,
      className: 'table-column-center',
    },
    {
      title: '금액',
      dataIndex: 'orderPrice',
      width: 100,
      className: 'table-column-center',
    },
    {
      title: '기본배달료',
      dataIndex: 'basicDeliveryPrice',
      width: 100,
      className: 'table-column-center',
    },
    {
      title: '할증배달료',
      dataIndex: 'extraDeliveryPrice',
      width: 100,
      className: 'table-column-center',
    },
    {
      title: '총배달요금',
      dataIndex: 'deliveryPrice',
      width: 100,
      className: 'table-column-center',
    },
    {
      title: '배달부가세',
      dataIndex: 'deliveryPriceVat',
      width: 100,
      className: 'table-column-center',
    },
    {
      title: '배달수수료',
      dataIndex: 'deliveryPriceFee',
      width: 100,
      className: 'table-column-center',
      render: (data) => comma(data) || 0,
    },
    {
      title: '라이더수수료',
      dataIndex: 'feeRiderBranch',
      width: 100,
      className: 'table-column-center',
      render: (data, row) =>
        comma(row.deliveryPriceFee - row.feeFrBranch - row.feeHeadOffice),
    },
    {
      title: '가맹점수수료',
      dataIndex: 'feeFrBranch',
      width: 100,
      className: 'table-column-center',
    },
    {
      title: '취소페널티',
      dataIndex: 'riderCancelPenalty',
      width: 100,
      className: 'table-column-center',
    },
    {
      title: '건별가맹비',
      dataIndex: 'duesDeliveryPrice',
      width: 100,
      render: (data) => comma(data),
      className: 'table-column-center',
    },
    {
      title: '건별수수료',
      dataIndex: 'deliveryPriceFeeByDpBasic',
      width: 100,
      render: (data) => comma(data),
      className: 'table-column-center',
    },
    {
      title: '할증건별수수료',
      dataIndex: 'deliveryPriceFeeByDpRegion',
      width: 100,
      render: (data) => comma(data),
      className: 'table-column-center',
    },
    {
      title: '사업자번호',
      dataIndex: 'businessNo',
      width: 100,
      className: 'table-column-center',
    },
    {
      title: '소속',
      dataIndex: 'riderBranchName',
      className: 'table-column-center',
    },
  ];

  // const getExcelList = async () => {
  //   const statDate = formRef.current
  //     .getFieldValue("statDate")
  //     .format("YYYY-MM-DD");

  //   const loopGetList = async () => {
  //     console.log(paginationExcel.current);
  //     console.log(paginationExcel.pageSize);

  //     let params = {};

  //     params["statDate"] = statDate;
  //     params["pageNum"] = paginationExcel.current;
  //     params["pageSize"] = paginationExcel.pageSize;

  //     const res = await httpGetRenewMakeUrlWithoutLoading(
  //       httpUrl.dailyOrderStat,
  //       params,
  //       []
  //     );
  //     console.log(res);
  //     return res;
  //   };

  //   let totalRes = [];
  //   let tryCnt = 1;

  //   let failedPages = [];

  //   setProgressBarOn(true);

  //   while (progressBarFlag) {
  //     if (paginationExcel.total < paginationExcel.current) break;

  //     const loopRes = await loopGetList();

  //     if (loopRes.data) {
  //       setPaginationExcel({
  //         ...paginationExcel,
  //         current: paginationExcel.current + 1,
  //         total: loopRes.data.totalPage,
  //       });

  //       console.log({
  //         ...paginationExcel,
  //         current: paginationExcel.current + 1,
  //         total: loopRes.data.totalPage,
  //       });

  //       totalRes = totalRes.concat(loopRes.data.dailyOrderStats);
  //     } else if (tryCnt <= 3) {
  //       tryCnt += 1;
  //     } else {
  //       failedPages.push(paginationExcel.current);

  //       setPaginationExcel({
  //         ...paginationExcel,
  //         current: paginationExcel.current + 1,
  //       });

  //       tryCnt = 1;
  //     }
  //   }

  //   setProgressBarOn(false);
  //   setPaginationExcel(initialPaginationExcel);

  //   return progressBarFlag ? totalRes : null;
  // };

  const getExcelList = async () => {
    let params = {};

    params['statDate'] = statDate.format('YYYY-MM-DD');
    params['pageNum'] = 1;
    params['pageSize'] = 100;

    //total count용 한번 불러온다
    const res = await httpGetRenewMakeUrl(httpUrl.dailyOrderStat, params, []);
    setExcelTotal(parseInt((res.data.totalCount - 1) / params.pageSize) + 1);
    setExcelCurrent(1);
    setProgressBarOn(true);
    progressFlag.current = true;
    let totalRes = res.data.dailyOrderStats;
    let tryCnt = 0;
    while (progressFlag.current) {
      try {
        params.pageNum++;
        const res = await httpGetRenewMakeUrlWithoutLoading(
          httpUrl.dailyOrderStat,
          params,
          []
        );
        if (res.data.dailyOrderStats instanceof Array) {
          if (res.data.dailyOrderStats.length <= 0) break;
          totalRes = totalRes.concat(res.data.dailyOrderStats);
          tryCnt = 0;
          setExcelCurrent(params.pageNum);
        } else {
          tryCnt++;
        }
      } catch (e) {
        console.error(e);
        tryCnt++;
      }
      if (tryCnt > 5) break;
    }
    setProgressBarOn(false);
    progressFlag.current = false;

    if (tryCnt > 5) {
      Modal.info({
        title: '오류',
        content: <div>다운로드 오류</div>,
        onOk() {},
      });
      return null;
    } else {
      return totalRes;
    }
  };

  const onDownload = async () => {
    const data = await getExcelList();

    if (!data) return;

    const excelJson = parseExcelJson(data);
    const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
    const wb = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(wb, ws, '일자별_주문_정산');
    xlsx.writeFile(wb, '일자별_주문_정산.xlsx');
  };
  const parseExcelJson = (data) => {
    let result = [
      {
        isAgency: '대리점구분',
        category: '구분',
        riderIdx: '라이더idx',
        frIdx: '가맹점idx',
        orderIdx: '주문번호',
        headBranchName: '제휴본사',
        franchiseBranchName: '가맹점지점',
        frName: '가맹점명',
        riderName: '라이더명',
        riderLevel: '직급',
        orderDate: '접수일시',
        completeDate: '완료일시',
        destAddr: '도착지',
        orderPrice: '금액',
        basicDeliveryPrice: '기본배달료',
        extraDeliveryPrice: '할증배달료',
        deliveryPrice: '총배달요금',
        deliveryPriceVat: '배달부가세',
        deliveryPriceFee: '배달수수료',
        feeRiderBranch: '라이더수수료',
        feeFrBranch: '가맹점수수료',
        feeHeadOffice: '상위지점수수료',
        riderCancelPenalty: '취소페널티',
        duesDeliveryPrice: '건별가맹비',
        deliveryPriceFeeByDpBasic: '건별수수료',
        supportFund: '콜수수료',
        supportFundTax: '콜수수료부가세',
        deliveryPriceFeeByDpRegion: '할증건별수수료',
        businessNo: '사업자번호',
        ownerName: '대표자명',
        frAddr: '주소',
        email: '이메일',
        registrationNo: '주민번호',
        phone: '연락처',
        count: '건수',
        riderBranchName: '소속',
        riderCategory: '라이더구분',
        isForeigner: '내국인/외국인',
        registrationNoConfirmed: '주민번호확인',
        deliveryPriceFeeKind: '수수료구분',
        completeDateConfirmed: '완료일자(수정)',
        isValidated: '유효성검사',
        foreignerException: '외국인제외검증',
        simplifiedTaxPayer: '간이사업자여부',
        fullTimeEmployee: '정직원 여부',
      },
    ];

    data.forEach((item) => {
      let supportFund = 0;
      let supportFundTax = 0;
      const feeRiderBranch =
        item.deliveryPriceFee - item.feeFrBranch - item.feeHeadOffice;
      if (item.simplifiedTaxPayer === 1) {
        supportFund = item.supportFund;
      } else {
        supportFund = Math.round(item.supportFund / 1.1);
        supportFundTax = Math.round(item.supportFund / 11);
      }

      result.push({
        isAgency: item.companyId === 'connect9' ? '직영점' : '대리점',
        category: item.category === 'ORDER_COMPLETE' ? '완료' : '취소',
        riderIdx: item.riderIdx,
        frIdx: item.frIdx,
        orderIdx: item.orderIdx,
        headBranchName: item.headBranchName,
        franchiseBranchName: item.franchiseBranchName,
        frName: item.frName,
        riderName: item.riderName,
        riderLevel: riderLevel[item.riderLevel],
        orderDate: item.orderDate,
        completeDate: item.completeDate,
        destAddr: item.destAddr,
        orderPrice: item.orderPrice,
        basicDeliveryPrice: item.basicDeliveryPrice,
        extraDeliveryPrice: item.extraDeliveryPrice,
        deliveryPrice: item.deliveryPrice,
        deliveryPriceVat: item.deliveryPriceVat,
        deliveryPriceFee: item.deliveryPriceFee || 0,
        feeRiderBranch: feeRiderBranch,
        feeFrBranch: item.feeFrBranch,
        feeHeadOffice: item.feeHeadOffice || 0,
        riderCancelPenalty: item.riderCancelPenalty,
        duesDeliveryPrice: item.duesDeliveryPrice,
        deliveryPriceFeeByDpBasic: item.deliveryPriceFeeByDpBasic,
        supportFund: supportFund,
        supportFundTax: supportFundTax,
        deliveryPriceFeeByDpRegion: item.deliveryPriceFeeByDpRegion,
        businessNo: item.businessNo,
        ownerName: item.ownerName,
        frAddr: item.frAddr,
        email: item.email,
        registrationNo: item.registrationNo,
        phone: item.phone,
        count: item.count,
        riderBranchName: item.riderBranchName,
        riderCategory: item.riderCategory,
        isForeigner: item.isForeigner ? '외국인' : '내국인',
        registrationNoConfirmed: item.registrationNoConfirmed,
        deliveryPriceFeeKind: item.deliveryPriceFeeKind,
        completeDateConfirmed: item.completeDateConfirmed,
        isValidated: item.isValidated,
        foreignerException: item.foreignerException,
        simplifiedTaxPayer: item.simplifiedTaxPayer
          ? '간이사업자'
          : '일반사업자',
        fullTimeEmployee: item.fullTimeEmployee ? '정직원' : '',
      });
    });

    return result;
  };

  const quitProgressBar = () => {
    setProgressBarOn(false);
    progressFlag.current = false;
    setExcelTotal(1);
    setExcelCurrent(1);
  };

  return (
    <>
      {progressBarOn && (
        <ProgressBar
          current={excelCurrent}
          total={excelTotal}
          quit={quitProgressBar}
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Form ref={formRef} style={{ display: 'flex', flexDirection: 'row' }}>
          {/* <Form.Item style={{ width: 200 }} name="branchIdx">
            <Select
              onChange={() => {
                setPagination({
                  total: 0,
                  current: 1,
                  pageSize: 100,
                });
                getList();
              }}
              defaultValue={null}
            >
              {branchList.map((branch) => {
                return (
                  <Select.Option value={branch.idx}>
                    {branch.branchName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item> */}
          <Form.Item
            name="statDate"
            value={statDate}
            initialValue={new moment()}>
            <DatePicker onChange={setStatDate} placeholder={'일자'} />
          </Form.Item>
        </Form>
        {Const.excelEnable && (
          <div>
            <Button
              className="download-btn"
              style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
              onClick={() => onDownload()}>
              <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
              엑셀 다운로드
            </Button>
          </div>
        )}
      </div>

      <VirtualTable
        rowKey={(record) =>
          record.riderName + record.category + record.completeDate
        }
        dataSource={list}
        columns={columns}
        pagination={pagination}
        onChange={setPagination}
        scroll={{ x: 3000, y: 600 }}
        style={{ paddingLeft: 10 }}
      />
    </>
  );
};

export default DailyOrderStat;
