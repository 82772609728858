import { Button, Table, Typography } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { kindString, StaticCategory } from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');

const { Text } = Typography;

class StaticsTableRiderFee extends Component {
  constructor(props) {
    super(props);
    const data = this.props;
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
      },
      startDate: data.startDate,
      endDate: data.endDate,
      changeFilter: data.changeFilter,
      selectBranch: data.selectBranch,
      businessNumber: data.businessNumber,
      frName: data.frName,
      riderName: data.riderName,
      registrationNumber: data.registrationNumber,

      copyList: [],
    };
    this.change_fr = ['DUES_PAY_RESTORE', 'DELIVERY_PRICE_RECEIVE'];
    this.change_deliveryPrice = ['DELIVERY_PRICE_SEND'];
    this.columns = [
      {
        title: '일시',
        dataIndex: 'createDate',
        className: 'tableCellCenter',
        render: (data) => <>{moment(data).format('YYYY-MM-DD HH:mm:ss')}</>,
        width: 160,
      },
      {
        title: '관리지점',
        dataIndex: 'branchName',
        className: 'tableCellCenter',
        width: 100,
      },
      {
        title: '주문번호',
        dataIndex: 'orderIdx',
        className: 'tableCellCenter',
        width: 100,
      },
      {
        title: '분류',
        dataIndex: 'category',
        className: 'tableCellCenter',
        width: 140,
        render: (data) => <>{StaticCategory[data]}</>,
      },
      {
        title: '비고',
        className: 'statics-table-border tableCellCenter',
        dataIndex: 'adminId',
        render: (data) => <>{data ? `변경자 : ${data}` : ''}</>,
        width: 100,
      },
      {
        title: '라이더',
        className: 'statics-table-orange statics-table-border',
        children: [
          {
            title: '정보',
            children: [
              {
                title: '라이더명',
                dataIndex: 'riderName',
                className: 'tableCellCenter',
                width: 140,
              },
              {
                title: '주민번호',
                dataIndex: 'registrationNumber',
                className: 'tableCellCenter',
                width: 130,
                render: (data, row) => {
                  if (row.riderName && data) {
                    const regiNum1 = data.substring(0, 6);
                    const regiNum2 = data.substring(6, data.length);
                    return <div>{regiNum1 + '-' + regiNum2}</div>;
                  }
                },
              },
            ],
          },
          {
            title: '배달건별',
            className: 'statics-table-border',
            children: [
              {
                title: '제외',
                className: 'statics-table-border',
                children: [
                  {
                    title: '기사수수료',
                    dataIndex: 'riderDeliveryFee',
                    className: 'statics-table-border tableCellRight',
                    width: 100,
                    render: (data, row) => {
                      if (row.riderLevel > 2) return <></>;
                      const riderDeliveryFee = row.category.includes('_RESTORE')
                        ? data * -1
                        : data;
                      return (
                        <div>
                          {row.riderName && data && comma(riderDeliveryFee)}
                        </div>
                      );
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: '본사 예치금',
        className: 'statics-table-orange2',
        width: 100,
        children: [
          {
            title: '입금',
            dataIndex: 'riderDeliveryFee',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => {
              if (row.riderLevel > 2) return <></>;
              if (!row.category.includes('_RESTORE')) {
                return <div> {comma(data)} </div>;
              }
            },
          },
          {
            title: '출금',
            dataIndex: 'riderDeliveryFee',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => {
              if (row.riderLevel > 2) return <></>;
              if (row.category.includes('_RESTORE')) {
                return <div> {comma(data)} </div>;
              }
            },
          },
        ],
      },
    ];
  }

  componentDidMount() {
    console.log('기사수수료');
    this.getList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.endDate !== this.props.endDate ||
      prevProps.changeFilter !== this.props.changeFilter ||
      prevProps.selectBranch !== this.props.selectBranch ||
      prevProps.businessNumber !== this.props.businessNumber ||
      prevProps.frName !== this.props.frName ||
      prevProps.riderName !== this.props.riderName ||
      prevProps.registrationNumber !== this.props.registrationNumber
    ) {
      this.getList();
    }
  }

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({ pagination: pager }, () => this.getList());
  };

  getList = () => {
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let branchIdx = this.props.selectBranch;
    let businessNumber = this.props.businessNumber;
    let category = 'riderFee';
    let frName = this.props.frName;
    let riderName = this.props.riderName;
    let registrationNumber = this.props.registrationNumber;
    let startDate = this.props.startDate;
    let endDate = moment(this.props.endDate).add(1, 'd').format('YYYY-MM-DD');
    httpGet(
      httpUrl.staticsCostList,
      [
        pageNum,
        pageSize,
        branchIdx,
        businessNumber,
        category,
        frName,
        riderName,
        registrationNumber,
        startDate,
        endDate,
      ],
      {}
    ).then((res) => {
      console.log(res.data.stCost);
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.stCost,
          copyList: res.data.stCost,
          pagination: {
            ...this.state.pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        });
      }
      if (this.state.changeFilter > 0) {
        this.selectList(this.state.changeFilter);
      }
    });
  };

  selectList = (type) => {
    const selectType =
      type === 1 ? this.state.change_deliveryPrice : this.state.change_fr;
    let changeList = this.state.setList.filter((x) =>
      selectType.includes(x.category)
    );
    this.setState({ copyList: changeList });
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ dispaly: 'flex', alignItems: 'center' }}>
          {/* <Button
                            className="download-btn"
                            style={{ width: 160}}
                            onClick={() => this.onDownload()}>
                            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
                            엑셀 다운로드
                        </Button>    */}
        </div>
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.copyList}
          columns={this.columns}
          className="staticsCost-table"
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          bordered
          summary={(pageData) => {
            let totalRiderDeliveryFee = 0;
            let totalc9NcashDelta = 0;
            let totalc9AfterNcash = 0;
            pageData.forEach(({ category, riderDeliveryFee }) => {
              totalRiderDeliveryFee += category.includes('_RESTORE')
                ? riderDeliveryFee * -1
                : riderDeliveryFee;
              totalc9NcashDelta +=
                !category.includes('_RESTORE') && riderDeliveryFee;
              totalc9AfterNcash +=
                category.includes('_RESTORE') && riderDeliveryFee;
            });
            return (
              <>
                <Table.Summary.Row
                  style={{
                    backgroundColor: '#cbedfa',
                    color: '#000',
                    fontWeight: 'bold',
                    borderColor: '#000',
                  }}>
                  <Table.Summary.Cell
                    index={1}
                    colSpan={5}
                    style={{ textAlign: 'center' }}>
                    합계
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger">
                      {totalRiderDeliveryFee ? comma(totalRiderDeliveryFee) : 0}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">
                      {totalc9NcashDelta ? comma(totalc9NcashDelta) : 0}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">
                      {totalc9AfterNcash ? comma(totalc9AfterNcash) : 0}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginInfo: state.login.loginInfo,
  };
};
export default connect(mapStateToProps, null)(StaticsTableRiderFee);
