import { Button, DatePicker, Form, Input, Select, Table, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import xlsx from 'xlsx';
import { httpGet, httpGetRenewMakeUrl, httpUrl, httpPost } from '../../api/httpClient';
import '../../css/main.css';
import { comma } from '../../lib/util/numberUtil';
import Const from '../../const';
import ExcelIcon from '../../img/excel.png';
import moment from "moment";
import locale from "antd/es/date-picker/locale/ko_KR";

const { Option } = Select;
const Search = Input.Search;
const now = new Date();
const { RangePicker } = DatePicker;

const AdjDeliveryDay = ({ props }) => {
    const formRef = React.createRef();
    const [list, setList] = useState([]);
    const [branchList, setbranchList] = useState([]);
    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    });
    const [riderName, setRiderName] = useState("");
    const [date, setDate] = useState({
        start: moment(now).format("YYYY-MM-DD"),
        end: moment(now).format("YYYY-MM-DD")
    });
    useEffect(async () => {
        await getAdjDeliveryDayList();
    }, [pagination.current, pagination.pageSize, riderName, date]);

    const getAdjDeliveryDayList = async () => {
        let params = {};
        params['endDate'] = moment(date.end).add(1, 'd').format("YYYYMMDD");
        params['pageNum'] = pagination.current;
        params['pageSize'] = pagination.pageSize;
        params['startDate'] = moment(date.start).format("YYYYMMDD");
        params['riderName'] = riderName;
        try {
            const res = await httpGetRenewMakeUrl(httpUrl.adjDeliveryList, params, {})
            if (!res.data) {
                console.log(`getListAsync failed. result: ${res.data.result}`);
                return;
            }
            setList(res.data.adjDeliveryDays);
            setPagination({
                ...pagination,
                current: res.data.currentPage,
                total: res.data.totalCount
            })
        } catch (e) {
            console.error(e);
        }
    };
    const handleTableChange = (page) => {
        if (page.current !== pagination.current) {
            setPagination({
                ...pagination,
                current: page.current
            });
        } else if (page.pageSize !== pagination.pageSize) {
            setPagination({
                ...pagination,
                current: 1,
                pageSize: page.pageSize
            });
        }

    };
    const onSearch = (value) => {
        setRiderName(value);
        setPagination({
            ...pagination,
            current: 1
        });
    };
    const onChangeRangePicker = async (e, selectDate, value) => {
        setDate({
            start: selectDate[0],
            end: selectDate[1]
        })
        await getAdjDeliveryDayList(value);

    }
    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    const update = async (data) => {
        const res = await httpPost(httpUrl.adjDeliveryUpdate, [], {
            logDate: data.logDate,
            userIdx: data.userIdx,
            memo: data.memo,
            ncashCustom: data.ncashCustom,
        });
        if (!res) {
            Modal.info({
                title: '수정 실패',
                content:
                    '수정을 실패했습니다. 관리자에게 문의해주세요.',
            });
        } else {
            Modal.info({
                title: '수정 완료',
                content: '수정을 완료했습니다.',
            });
            getAdjDeliveryDayList();
        }
    }

    const openMemoModal = (data) => {
        Modal.confirm({
            title: '수정할 내용을 입력해주세요',
            content: (
                <div>
                    <Input.TextArea
                        placeholder="내용을 입력하세요"
                        allowClear
                        autoSize={{ minRows: 5, maxRows: 5 }}
                        style={{ height: 200, width: 300 }}
                        onChange={(e) => { data.memo = e.target.value }}
                    />
                </div>
            ),
            onOk() {
                update(data);
            },
            onCancel() { }
        });
    }
    const openNcashModal = (data, err) => {
        Modal.confirm({
            title: '수정할 금액을 입력해주세요',
            content: (
                <div>
                    조정금액 :
                    <Input
                        placeholder="금액을 입력하세요"
                        style={{ width: 200, marginLeft: 12 }}
                        onChange={(e) => { data.ncashCustom = e.target.value }}
                    />
                    {err && <div>*숫자만 입력해주세요</div>}
                </div>
            ),
            onOk() {
                const regex = /^[0-9]/g;
                if (regex.test(data.ncashCustom)) {
                    update(data);
                } else { openNcashModal(data, true); }
            },
            onCancel() { }
        });
    }
    const columns = [
        {
            title: '일자',
            dataIndex: 'logDate',
            className: 'table-column-center',
            render: (data) => <div>{moment(data).format("YYYY-MM-DD")}</div>
        },
        {
            title: '라이더명',
            dataIndex: 'riderName',
            className: 'table-column-center',
        },
        {
            title: '배달비',
            dataIndex: 'ncash',
            className: 'table-column-center',
            render: (data) => <div>{comma(data)}원</div>,
        },
        {
            title: '조정 금액',
            dataIndex: 'ncashCustom',
            className: 'table-column-center',
            render: (data, row) => <Button onClick={() => openNcashModal(row, false)}>{comma(data)}원</Button>,
        },
        {
            title: '최종 배달비',
            dataIndex: 'totalNcash',
            className: 'table-column-center',
            render: (data, row) => <div>{comma(row.ncash + row.ncashCustom)}원</div>,
        },
        {
            title: '메모',
            dataIndex: 'memo',
            className: 'table-column-center',
        },
        {
            title: '메모수정',
            dataIndex: '',
            className: 'table-column-center',
            render: (row) => (
                <Button onClick={() => openMemoModal(row)}
                >수정</Button>
            )
        },

    ];

    const parseExcelJson = (data) => {
        let result = [];
        let headerJson = {};
        columns.forEach((column) => {
            headerJson[column.dataIndex] = column.title;
        });
        result.push(headerJson);

        data.forEach((item) => {
            let copiedItem = { ...item };
            Object.keys(item).forEach((key) => {
                if (columns.findIndex((column) => column.dataIndex === key) < 0) {
                    delete copiedItem[key];
                }
            });

            result.push({
                ...copiedItem,
                branchIdx: branchList.find((branch) => branch.idx === item.branchIdx)
                    ? branchList.find((branch) => branch.idx === item.branchIdx)
                        .branchName
                    : '-',
                totalNcash: item.ncash + item.ncashCustom
            });
        });
        return result;
    };

    const onDownload = async () => {
        let params = {};
        params['endDate'] = moment(date.end).add(1, 'd').format("YYYYMMDD");
        params['pageNum'] = 1;
        params['pageSize'] = 1000000;
        params['startDate'] = moment(date.start).format("YYYYMMDD");
        params['riderName'] = riderName;
        console.log(params)
        const res = await httpGetRenewMakeUrl(httpUrl.adjDeliveryList, params, {});
        console.log(res);

        const excelJson = parseExcelJson(res.data.adjDeliveryDays);
        const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
        const wb = xlsx.utils.book_new();

        xlsx.utils.book_append_sheet(wb, ws, '일별_베달비_정산');
        xlsx.writeFile(wb, '일별_베달비_정산.xlsx');
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Form ref={formRef} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Search
                        placeholder="라이더명으로 검색"
                        className="search-input"
                        enterButton
                        allowClear
                        onSearch={onSearch}
                        style={{ width: 200, marginRight: 10 }}
                    />
                    <Form.Item name="date">
                        <RangePicker
                            locale={locale}
                            style={{ width: 280, justifyContent: 'flex-end' }}
                            format={"YYYY-MM-DD"}
                            disabledDate={disabledDate}
                            allowClear={false}
                            value={[
                                moment(date.start, "YYYYMMDD"),
                                moment(date.end, "YYYYMMDD"),
                            ]}
                            onChange={onChangeRangePicker}
                        />

                    </Form.Item>
                </Form>
                {Const.excelEnable && (
                    <Button
                        className="download-btn"
                        style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
                        onClick={() => onDownload()}>
                        <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
                        엑셀 다운로드
                    </Button>
                )}
            </div>

            <Table
                rowKey={(record) => record.idx}
                dataSource={list}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </>
    );
};

export default AdjDeliveryDay;
