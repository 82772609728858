import {
  Button,
  Form,
  DatePicker,
  Checkbox,
  Radio,
  Select,
  Input,
  Typography,
  Pagination,
} from 'antd';
import React, { Component } from 'react';
import '../../css/main.css';
import { itemCode } from '../../lib/util/codeUtil';
import moment from 'moment';
import { httpUrl, httpGet } from '../../api/httpClient';
import { connect } from 'react-redux';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from '../../const';

import StaticsTableAll from './StaticsTableAll';
import StaticsTableDelivery from './StaticsTableDelivery';
import StaticsTableFr from './StaticsTableFr';
import StaticsTablePro from './StaticsTablePro';
import StaticsTableInsurance from './StaticsTableInsurance';
import StaticsTableRiderFee from './StaticsTableRiderFee';
import StaticsTableCharge from './StaticsTableCharge';

import { Link, withRouter } from 'react-router-dom';

import 'moment/locale/ko';

moment.locale('ko');

const Option = Select.Option;
const RangePicker = DatePicker.RangePicker;

const today = new Date();
const dateFormat = 'YYYY/MM/DD';
const year = today.getFullYear();
const month = today.getMonth();

class StaticsCostStat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //branch
      branch: [],
      selectBranch: null,
      businessNumber: '',
      frName: '',
      riderName: '',
      registrationNumber: '',
      inputName: '',
      inputNum: '',

      //userType
      selectedUserType: null,

      // fillter
      checkItem: 'all',
      itemCodefilter: [],
      checkAll: true,

      // changeFilter
      disabled: true,
      changeFilter: 0,

      //date
      startDate: moment(new Date(year, month, 1)).format('YYYY-MM-DD'),
      endDate: moment(today).format('YYYY-MM-DD'),
      monthActive: 'this',
    };

    this.userTypeSelect = ['라이더', '가맹점'];
    this.change_fr = ['DUES_PAY_RESTORE', 'DELIVERY_PRICE_RECEIVE'];
    this.change_deliveryPrice = ['DELIVERY_PRICE_SEND'];
  }

  componentDidMount() {
    this.getBranchList();
    itemCode.map((x) => this.state.itemCodefilter.push(x.value));
  }

  getBranchList = () => {
    httpGet(httpUrl.getBranchList, [1, 10000], {}).then((res) => {
      this.setState({ branch: res.data.branch });
    });
  };

  toggleDisabled = () => {
    this.setState({ disabled: !this.state.disabled, changeFilter: 0 });
  };

  onChangeRadio = (e) => {
    console.log('radio checked', e.target.value);
    this.setState({ changeFilter: e.target.value });
  };

  onChangeDate = (_, dateString) => {
    console.log(dateString);
    if (dateString[(0, 1)]) {
      this.setState({
        startDate:
          dateString !== null
            ? moment(dateString[0]).format('YYYY-MM-DD 00:00')
            : moment(today).format('YYYY-MM-DD'),
        endDate:
          dateString !== null
            ? moment(dateString[1]).format('YYYY-MM-DD')
            : moment(today).format('YYYY-MM-DD'),
      });
    } else {
      this.setState({
        startDate: '',
        endDate: '',
      });
    }
  };

  onChangeMonthDate = (type) => {
    if (type === 'thisMonth') {
      this.setState({
        startDate: moment(new Date(year, month, 1)).format('YYYY-MM-DD'),
        endDate: moment(today).format('YYYY-MM-DD'),
        monthActive: 'this',
      });
    } else {
      this.setState({
        startDate:
          month !== 1
            ? moment(new Date(year, month - 1, 1)).format('YYYY-MM-DD')
            : moment(new Date(year - 1, 12, 1)).format('YYYY-MM-DD'),
        endDate: moment(new Date(year, month, 1))
          .add(-1, 'd')
          .format('YYYY-MM-DD'),
        monthActive: 'last',
      });
    }
  };

  handleFilterToggle = (item) => {
    if (item.value === 'all') {
      this.setState({ checkAll: true, checkItem: item.value });
    } else {
      this.setState({ checkAll: false, checkItem: item.value });
    }
  };

  serachName = (value) => {
    console.log(value);
    if (!this.state.selectedUserType) {
      this.setState({ inputName: value });
    } else {
      this.setState({ inputName: value });
    }
  };

  onClickButton = () => {
    if (!this.state.selectedUserType) {
      this.setState({
        riderName: this.state.inputName,
        registrationNumber: this.state.inputNum,
        frName: '',
        businessNumber: '',
      });
    } else {
      this.setState({
        frName: this.state.inputName,
        businessNumber: this.state.inputNum,
        riderName: '',
        registrationNumber: '',
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="staticsCost">
          <div className="staticsCost-header">
            <div className="staticsCost-box">
              <div className="sc-mainbox"> 발생일시</div>
              <div className="date-box">
                <Button
                  onClick={() => {
                    this.onChangeMonthDate('thisMonth');
                  }}
                  className={
                    this.state.monthActive === 'this'
                      ? 'monthActive'
                      : 'monthBtn'
                  }>
                  이번달
                </Button>
                <Button
                  onClick={() => {
                    this.onChangeMonthDate('lastMonth');
                  }}
                  className={
                    this.state.monthActive === 'last'
                      ? 'monthActive'
                      : 'monthBtn'
                  }>
                  저번달
                </Button>
              </div>
              <div>
                <RangePicker
                  value={[
                    moment(
                      this.state.startDate
                        ? this.state.startDate
                        : moment(new Date(year, month, 1)).format('YYYY-MM-DD'),
                      'YYYY-MM-DD'
                    ),
                    moment(
                      this.state.endDate
                        ? this.state.endDate
                        : moment(today).format('YYYY-MM-DD'),
                      'YYYY-MM-DD'
                    ),
                  ]}
                  format={'YYYY-MM-DD'}
                  onChange={this.onChangeDate}
                  placeholder={['시작일', '종료일']}
                  defaultValue={[
                    moment(new Date(year, month, 1), dateFormat),
                    moment(today, dateFormat),
                  ]}
                />
              </div>
            </div>
            <div className="staticsCost-box">
              <div className="sc-mainbox"> 항목 필터</div>
              <div className="filtering-box">
                {itemCode.map((obj) => {
                  return (
                    <Checkbox
                      key={obj.value}
                      checked={
                        this.state.checkAll
                          ? this.state.itemCodefilter
                          : this.state.checkItem === obj.value
                      }
                      onChange={() => this.handleFilterToggle(obj)}
                      className="item-filter-checkbox">
                      {obj.label}
                    </Checkbox>
                  );
                })}
              </div>
            </div>
            <div className="staticsCost-box">
              <div className="sc-mainbox">
                <Checkbox
                  type="primary"
                  onClick={this.toggleDisabled}
                  checked={!this.state.disabled}
                  color="red">
                  변경내용 필터
                </Checkbox>
              </div>
              <div>
                <Radio.Group
                  onChange={this.onChangeRadio}
                  value={this.state.changeFilter}
                  disabled={this.state.disabled}>
                  <Radio value={1}> 배달비 변경 </Radio>
                  <Radio value={2}> 가맹비 변경 </Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="staticsCost-box">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="sc-mainbox"> 대상</div>
                <div>
                  <Select
                    style={{ width: 200, marginRight: 10 }}
                    placeholder="지점명"
                    onChange={(value) => {
                      this.setState({
                        selectBranch: value,
                      });
                    }}>
                    {this.state.branch.map((item) => {
                      if (item.idx === 1)
                        return (
                          <Option
                            style={{ display: 'none' }}
                            key={item.idx}></Option>
                        );
                      return (
                        <Option value={item.idx} key={item.idx}>
                          {item.branchName}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div style={{ display: 'flex' }}>
                  <Select
                    style={{ width: 200, marginRight: 10 }}
                    placeholder="라이더/가맹점 선택"
                    onChange={(value) => {
                      this.setState({
                        selectedUserType: value,
                        businessNumber: '',
                        frName: '',
                        riderName: '',
                        registrationNumber: '',
                      });
                    }}>
                    {this.userTypeSelect.map((item, index) => {
                      return (
                        <Option value={index} key={item}>
                          {' '}
                          {item}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                  <div style={{ display: 'flex' }}>
                    {this.state.selectedUserType !== null && (
                      <div style={{ width: 200, marginRight: 10 }}>
                        <Input
                          placeholder={
                            !this.state.selectedUserType ? '라이더명' : '상호'
                          }
                          onChange={(e) =>
                            this.setState({ inputName: e.target.value })
                          }
                        />
                      </div>
                    )}
                    {this.state.selectedUserType !== null ? (
                      <div style={{ width: 200, marginRight: 10 }}>
                        <Input
                          placeholder={
                            !this.state.selectedUserType
                              ? '-제외한 주민번호 전체 입력'
                              : '사업자번호 전체 입력'
                          }
                          onChange={(e) =>
                            this.setState({ inputNum: e.target.value })
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                  <Button
                    onClick={() => {
                      this.onClickButton();
                    }}>
                    검색
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {this.state.checkItem === 'all' && (
            <StaticsTableAll
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              changeFilter={this.state.changeFilter}
              selectBranch={this.state.selectBranch}
              riderName={this.state.riderName}
              registrationNumber={this.state.registrationNumber}
              frName={this.state.frName}
              businessNumber={this.state.businessNumber}
            />
          )}
          {this.state.checkItem === 'delivery' && (
            <StaticsTableDelivery
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              changeFilter={this.state.changeFilter}
              selectBranch={this.state.selectBranch}
              riderName={this.state.riderName}
              registrationNumber={this.state.registrationNumber}
              frName={this.state.frName}
              businessNumber={this.state.businessNumber}
            />
          )}
          {this.state.checkItem === 'fr' && (
            <StaticsTableFr
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              changeFilter={this.state.changeFilter}
              selectBranch={this.state.selectBranch}
              riderName={this.state.riderName}
              registrationNumber={this.state.registrationNumber}
              frName={this.state.frName}
              businessNumber={this.state.businessNumber}
            />
          )}
          {this.state.checkItem === 'promotion' && (
            <StaticsTablePro
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              changeFilter={this.state.changeFilter}
              selectBranch={this.state.selectBranch}
              riderName={this.state.riderName}
              registrationNumber={this.state.registrationNumber}
              frName={this.state.frName}
              businessNumber={this.state.businessNumber}
            />
          )}
          {this.state.checkItem === 'employee' && (
            <StaticsTableInsurance
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              changeFilter={this.state.changeFilter}
              selectBranch={this.state.selectBranch}
              riderName={this.state.riderName}
              registrationNumber={this.state.registrationNumber}
              frName={this.state.frName}
              businessNumber={this.state.businessNumber}
            />
          )}
          {this.state.checkItem === 'riderFee' && (
            <StaticsTableRiderFee
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              changeFilter={this.state.changeFilter}
              selectBranch={this.state.selectBranch}
              riderName={this.state.riderName}
              registrationNumber={this.state.registrationNumber}
              frName={this.state.frName}
              businessNumber={this.state.businessNumber}
            />
          )}
          {this.state.checkItem === 'preCharge' && (
            <StaticsTableCharge
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              changeFilter={this.state.changeFilter}
              selectBranch={this.state.selectBranch}
              riderName={this.state.riderName}
              registrationNumber={this.state.registrationNumber}
              frName={this.state.frName}
              businessNumber={this.state.businessNumber}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(StaticsCostStat);
