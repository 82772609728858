import React, { Component } from 'react';
import { Form, Input, Table, Button, Radio } from 'antd';
import {
  httpGet,
  httpUrl,
  httpDownload,
  httpPost,
  httpPut,
} from '../../api/httpClient';
import '../../css/main.css';
import { statusString, riderLevel } from '../../lib/util/codeUtil';
import moment from 'moment';
import CloseIcon from '../../img/close.png';

const Search = Input.Search;
const today = new Date();

class RiderNotUploadFileDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: today,
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },

      dataIdxs: [],
      riderName: '',
    };
    this.columns = [
      {
        title: '상태',
        dataIndex: 'userStatus',
        className: 'table-column-center',
        render: (data, row) => <div>{statusString[data]}</div>,
      },
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '라이더명',
        dataIndex: 'riderName',
        className: 'table-column-center',
      },
      {
        title: '아이디',
        dataIndex: 'userId',
        className: 'table-column-center',
      },
      {
        title: '직급',
        dataIndex: 'riderLevel',
        className: 'table-column-center',
        render: (data) => <div>{riderLevel[data]}</div>,
      },
    ];
  }

  componentDidMount() {
    this.getList();
  }

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  getList = () => {
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    httpGet(httpUrl.riderNotUploadFile, [pageNum, pageSize], {}).then((res) => {
      console.log(res);
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.riders,
        pagination: pagination,
      });
    });
  };

  render() {
    const { close } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="deposit-Dialog">
          <div className="deposit-content">
            <div>
              <div className="deposit-title">서류 미제출자 목록</div>
              <img
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="deposit-close"
                alt="닫기"
              />
            </div>

            <div className="deposit-inner">
              <Table
                className="searchRider-table"
                rowKey={(record) => record.idx}
                dataSource={this.state.list}
                columns={this.columns}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RiderNotUploadFileDialog;
