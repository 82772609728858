import { Button, Checkbox, Input, Modal, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { httpPost, httpUrl } from "../../../api/httpClient";
import CloseIcon from '../../../img/close.png';
import { getStringByteSize } from "../../../lib/util/StringUtil";
import { noticeCategoryCode, noticeCreateResponseCode, noticeUpdateResponseCode } from "../../../lib/util/codeUtil";

const newFormError = {
    branchIdx: null,
    category: null,
    sortOrder: null,
    title: null,
    content: null,
};

const NoticeFormDialog = ({ selectedNotice, setSelectedNotice, branchList, BranchListSelectBox, getNoticeList }) => {
    const [sendSms, setSendSms] = useState(false);
    const [formError, setFormError] = useState(newFormError);

    const onSubmit = async () => {
        let formError = {
            ...newFormError
        };
        
        // if (!selectedNotice?.branchIdx) {
        //     formError = {
        //         ...formError,
        //         branchIdx: '지점을 선택해주세요.'
        //     };
        // }
        if (!selectedNotice?.category) {
            formError = {
                ...formError,
                category: '구분을 선택해주세요.'
            };
        }
        if (!selectedNotice?.sortOrder) {
            formError = {
                ...formError,
                sortOrder: '노출순위를 입력해주세요.'
            };
        }
        if (!selectedNotice?.title) {
            formError = {
                ...formError,
                title: '제목을 입력해주세요.'
            };
        } else if (selectedNotice?.title && selectedNotice?.title.length > 50) {
            formError = {
                ...formError,
                title: '50자 이내로 입력해주세요'
            }
        }
        if (!selectedNotice?.content) {
            formError = {
                ...formError,
                content: '내용을 입력해주세요.'
            };
        } else {
            const stringByteSize = getStringByteSize(selectedNotice?.content);
            console.log(stringByteSize);
            if (stringByteSize > 8000) {
                formError = {
                    ...formError,
                    content: '공지사항 내용 길이 제한이 초과되었습니다.'
                };
            }
        }

        let error = false;
        for (let i in Object.keys(formError)) {
            const key = Object.keys(formError)[i];
            if (formError[key]) {
                console.log(key, formError[key]);
                error = true;
            }
        }
        console.log("isError", error);
        if (error) {
            setFormError(formError);
            return;
        }
        

        if (selectedNotice?.idx) {
            await updateNotice();
        } else {
            await createNotice();
        }
    }

    const updateNotice = async () => {
        if (!selectedNotice.idx) {
            Modal.warn({
                title: "공지사항 수정 실패",
                content: "공지사항 수정에 실패했습니다. 다시 시도해주세요."
            });

            // 창 닫아서 다시 설정될 수 있게
            setSelectedNotice(null);
        }

        try {
            const res = await httpPost(httpUrl.updateAdminNotice, [], selectedNotice);

            if (
                res?.data
            ) {
                if (res?.data === 'SUCCESS') {
                    Modal.info({
                        title: '공지사항 수정 성공',
                        content: noticeUpdateResponseCode[res?.data]
                    });

                    await getNoticeList();
                    setSelectedNotice(null);
                } else {
                    Modal.warn({
                        title: '공지사항 수정 실패',
                        content: noticeUpdateResponseCode[res?.data]
                    });
                }
            } else {
                console.error("Error while udpate notice / res.data is null : ", res);

                Modal.warn({
                    title: "공지사항 수정 실패",
                    content: "공지사항 수정에 실패했습니다. 관리자에게 문의하세요."
                });
            }
        } catch (e) {
            console.error("Error while udpate notice : ", e);
            Modal.warn({
                title: "공지사항 수정 실패",
                content: "공지사항 수정에 실패했습니다. 관리자에게 문의하세요."
            });
        }
    };

    const createNotice = async () => {
        if (selectedNotice.idx) {
            Modal.warn({
                title: "공지사항 등록 실패",
                content: "공지사항 등록에 실패했습니다. 다시 시도해주세요."
            });

            // 창 닫아서 다시 설정될 수 있게
            setSelectedNotice(null);
        }

        try {
            const res = await httpPost(httpUrl.createAdminNotice, [], selectedNotice);

            if (
                res?.data
            ) {
                if (res?.data === 'SUCCESS') {
                    Modal.info({
                        title: '공지사항 등록 성공',
                        content: noticeCreateResponseCode[res?.data]
                    });

                    await getNoticeList();
                    setSelectedNotice(null);

                } else {
                    Modal.warn({
                        title: '공지사항 등록 실패',
                        content: noticeCreateResponseCode[res?.data]
                    });
                }
            } else {
                console.error("Error while udpate notice / res.data is null : ", res);

                Modal.warn({
                    title: "공지사항 등록 실패",
                    content: "공지사항 등록에 실패했습니다. 관리자에게 문의하세요."
                });
            }
        } catch (e) {
            console.error("Error while udpate notice : ", e);
            Modal.warn({
                title: "공지사항 등록 실패",
                content: "공지사항 등록에 실패했습니다. 관리자에게 문의하세요."
            });
        }

    };

    useEffect(() => {
        console.log(formError);
    }, [formError]);


    return (
        <React.Fragment>
            <div className="Dialog-overlay" onClick={() => setSelectedNotice(null)} />
            <div className="charge-Dialog">
                <div className="charge-content">
                    <div className="charge-title" style={{ marginBottom: 10 }}>{`공지사항 ${selectedNotice.idx ? '수정' : '등록'}`}</div>
                    <img
                        onClick={() => setSelectedNotice(null)}
                        style={{ display: 'inline' }}
                        src={CloseIcon}
                        className="dialog-close"
                        alt="img"
                    />

                    <div
                        style={{
                            width: '100%',
                            borderWidth: 1,
                            borderBlockColor: '#ccc',
                            borderStyle: 'solid',
                            padding: 20,
                        }}>
                        <div
                            style={{
                                width: '100%',
                                borderBottomWidth: 1,
                                borderBottomColor: '#ccc',
                                borderBottomStyle: 'solid',
                                paddingBottom: 10,
                                display: 'flex',
                            }}>
                            <div style={{ display: 'inline-block' }}>
                                <div>
                                    <label style={{ fontWeight: 'bold', display: 'block', marginBottom: 10 }}>지점</label>
                                    <BranchListSelectBox branchList={branchList} branchIdx={selectedNotice.branchIdx || 0} setBranchIdx={(branchIdx) => setSelectedNotice({
                                        ...selectedNotice,
                                        branchIdx: branchIdx,
                                    })} />
                                </div>
                                {formError?.branchIdx && <p style={{color: 'red'}}>{formError?.branchIdx}</p>}
                            </div>
                            <div style={{ display: 'inline-block' }}>
                            <div>
                                    <label style={{ fontWeight: 'bold', display: 'block', marginBottom: 10 }}>구분</label>
                                    <Select value={selectedNotice.category} style={{ width: 200 }} onChange={(category) => setSelectedNotice({
                                        ...selectedNotice,
                                        category: category,
                                    })}>
                                        {Object.entries(noticeCategoryCode).map(([key, value]) => {
                                            if (value === 1) return <></>;
                                            return (
                                                <Select.Option key={value} value={value}>
                                                    {key}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                                {formError?.category && <p style={{color: 'red'}}>{formError?.category}</p>}
                            </div>
                        </div>
                        {/* <div
                            style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                                borderBottomWidth: 1,
                                borderBottomColor: '#ccc',
                                borderBottomStyle: 'solid',
                            }}>
                            <label style={{ fontWeight: 'bold' }}>SMS 발송</label>
                            <Checkbox style={{ marginLeft: 10 }} checked={sendSms} onChange={e => setSendSms(e.target.checked)} />
                            <p>체크 시, 대상자에게 SMS문자 발송됩니다.</p>
                        </div> */}
                        <div
                            style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                                borderBottomWidth: 1,
                                borderBottomColor: '#ccc',
                                borderBottomStyle: 'solid',
                            }}>
                            <label style={{ fontWeight: 'bold', display: 'block', marginBottom: 10 }}>노출순위</label>
                            <Input
                                placeholder="숫자가 클수록 상단에 노출됩니다"
                                type="number"
                                value={selectedNotice.sortOrder}
                                onChange={e =>
                                    setSelectedNotice({
                                        ...selectedNotice,
                                        sortOrder: e.target.value,
                                    })} />
                            {formError?.sortOrder && <p style={{color: 'red'}}>{formError?.sortOrder}</p>}
                        </div>
                        <div
                            style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                                borderBottomWidth: 1,
                                borderBottomColor: '#ccc',
                                borderBottomStyle: 'solid',
                            }}>
                            <label style={{ fontWeight: 'bold', display: 'block', marginBottom: 10 }}>제목</label>
                            <Input
                                placeholder="제목을 입력해 주세요(띄어쓰기 포함 50자이내)"
                                value={selectedNotice.title}
                                onChange={e => setSelectedNotice({
                                    ...selectedNotice,
                                    title: e.target.value,
                                })} />
                            {formError?.title && <p style={{color: 'red'}}>{formError?.title}</p>}
                        </div>
                        <div
                            style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                            }}>
                            <label style={{ fontWeight: 'bold', display: 'block', marginBottom: 10 }}>내용</label>
                            <TextArea
                                style={{
                                    height: '10rem',
                                }}
                                placeholder="내용을 입력해 주세요"
                                value={selectedNotice.content}
                                onChange={e => setSelectedNotice({
                                    ...selectedNotice,
                                    content: e.target.value,
                                })} />
                            {formError?.content && <p style={{color: 'red'}}>{formError?.content}</p>}
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <Button onClick={async () => await onSubmit()} type="primary">{selectedNotice.idx ? '수정하기' : '등록하기'}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default NoticeFormDialog;