import React, { Component } from 'react'
import { httpGet, httpUrl, httpPost } from '../../api/httpClient';
import {Table, Button, Radio, DatePicker, Input} from 'antd'
import moment from "moment";
import '../../css/main.css';
import StatisticsIcon from '../../img/statistics.png';
import SelectBox from "../../components/input/SelectBox";
import StaticsJoinExitGraphDialog from "../../components/dialog/StaticsJoinExitGraphDialog";
import {comma} from "../../lib/util/numberUtil";
const { RangePicker } = DatePicker;

var finalObject = {
  idx: 0,
  date: "",
  branchName: "",
  userType: "",
  joinCount0: 0,
  exitCount0: 0,
  stopCount0: 0,
  comebackCount0: 0,
  accidentCount0: 0,
  totalMemberCount0: 0,
  joinCount1: 0,
  exitCount1: 0,
  stopCount1: 0,
  comebackCount1: 0,
  accidentCount1: 0,
  totalMemberCount1: 0,
  joinCount2: 0,
  exitCount2: 0,
  stopCount2: 0,
  comebackCount2: 0,
  accidentCount2: 0,
  totalMemberCount2: 0,
  joinCount3: 0,
  exitCount3: 0,
  stopCount3: 0,
  comebackCount3: 0,
  accidentCount3: 0,
  totalMemberCount3: 0,
  joinCount4: 0,
  exitCount4: 0,
  stopCount4: 0,
  comebackCount4: 0,
  accidentCount4: 0,
  totalMemberCount4: 0,
  joinCount5: 0,
  exitCount5: 0,
  stopCount5: 0,
  comebackCount5: 0,
  accidentCount5: 0,
  totalMemberCount5: 0,
  joinCount6: 0,
  exitCount6: 0,
  stopCount6: 0,
  comebackCount6: 0,
  accidentCount6: 0,
  totalMemberCount6: 0,
  joinCount7: 0,
  exitCount7: 0,
  stopCount7: 0,
  comebackCount7: 0,
  accidentCount7: 0,
  totalMemberCount7: 0,
  joinCount8: 0,
  exitCount8: 0,
  stopCount8: 0,
  comebackCount8: 0,
  accidentCount8: 0,
  totalMemberCount8: 0,
  joinCount9: 0,
  exitCount9: 0,
  stopCount9: 0,
  comebackCount9: 0,
  accidentCount9: 0,
  totalMemberCount9: 0,
  joinCount10: 0,
  exitCount10: 0,
  stopCount10: 0,
  comebackCount10: 0,
  accidentCount10: 0,
  totalMemberCount10: 0,
  joinCount11: 0,
  exitCount11: 0,
  stopCount11: 0,
  comebackCount11: 0,
  accidentCount11: 0,
  totalMemberCount11: 0,
  joinCount12: 0,
  exitCount12: 0,
  stopCount12: 0,
  comebackCount12: 0,
  accidentCount12: 0,
  totalMemberCount12: 0,
};

class StaticsJoinExit extends Component {
    constructor(props) {
      super(props);
      this.state = {
        pagination: {
            total: 10,
            current: 10,
            pageSize: 100,
        },
        branchNameList: {0: "전체지점"},
        userTypeList: {0: "없음", 1: "라이더", 2: "가맹점"},
        branchNameValue: 0,
        list: [],
        dateRange: 1,
        dateList: [],
        selectDate: 0,
        statDate: ["", "", "", "", "", "", ""],
        finalList: [],
        startDate: "",
        endDate: "",
        totalRiderCount: 0,
        totalFrCount: 0,
      };
    }

    componentDidMount() {
        this.getBranchInfo();
        this.getDateInfo(this.state.dateRange);
    }

    getBranchInfo = async () => {
        let branchNameList = this.state.branchNameList;
        await httpPost(httpUrl.branchListInfo, [], {}).then((res) => {
            if (res.result === "SUCCESS") {
                console.log("res");
                console.log(res.data);
                res.data.branch.map((item) => (branchNameList[item.idx] = item.branchName));
                this.setState(
                    {
                        branchNameList: branchNameList,
                    },
                    () => {
                        console.log("branchNameList");
                        console.log(this.state.branchNameList);
                    }
                );
            }
        });
    };

  getDateInfo = async (dateRange) => {
    let branchIdx = this.state.branchNameValue;
    await httpGet(httpUrl.getJoinExitStatDateList, [dateRange, branchIdx], {}).then((res) => {
      if (res.result === "SUCCESS") {
        console.log("res");
        console.log(res.data);
        res.data.list.sort(function (a, b) {
          return a < b ? 1 : a > b ? -1: 0;
        });
        this.setState(
          {
            dateList: res.data.list,
          },
        );
        this.getList();
      }
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

    setDate = (date) => {
        console.log(date)
    }

    getList = async () => {
      let idx = 1;
      let branchIdx = this.state.branchNameValue;
      let dateRange = this.state.dateRange;
      let weekDate = this.state.dateList[this.state.selectDate];
      let year = weekDate.substring(0, 4);
      let month = weekDate.substring(6, 8) - 1; // 1월이 0부터
      let week = weekDate.substring(10, 11) - 1; // 1주차가 0부터
      let date = new Date();
      let startDate;
      let endDate;


      if (dateRange == 1) {
        date.setFullYear(year, month, 1); //해당 월의 1일로 세팅
        if (date.getDay() == 0) { // 1일이 일요일이면 따로 처리
          startDate = moment(date).add(week - 1 , "w").format("YYYY-MM-DD");
        } else {
          startDate = moment(date).add(week , "w").format("YYYY-MM-DD");
        }
        startDate = moment(startDate).set("weekday", 1).format("YYYY-MM-DD");
        endDate = moment(startDate).add(6, "d").format("YYYY-MM-DD");
      } else if (dateRange == 2) {
        date.setFullYear(year, month, 1); //해당 월의 1일로 세팅
        if (date.getDay() == 0) { // 1일이 일요일이면 따로 처리
          startDate = moment(date).add(-1 , "w").format("YYYY-MM-DD");
        } else {
          startDate = moment(date).format("YYYY-MM-DD");
        }
        startDate = moment(startDate).set("weekday", 1).format("YYYY-MM-DD");
        date.setFullYear(year, month+1, 0);
        endDate = moment(date).format("YYYY-MM-DD");
        endDate = moment(endDate).set("weekday", 0).format("YYYY-MM-DD");
      } else {
        date.setFullYear(year, 0, 1);
        startDate = moment(date).format("YYYY-MM-DD");
        date.setFullYear(year, 11, 31);
        endDate = moment(date).format("YYYY-MM-DD");
      }
      this.setState({
        startDate: startDate,
        endDate: endDate,
      });

      const res = await httpGet(httpUrl.getJoinExitStatList, [dateRange, branchIdx, 0, startDate, endDate], {});
      if (!res) {
        console.log(`getJoinExitStatList failed. result: ${res.result}`);
        return;
      }
      console.log(res);
      this.setState({
        list: res.data.list,
        totalRiderCount: res.data.currentTotalRiderCount,
        totalFrCount: res.data.currentTotalFrCount,
        finalList: [],
      });
      let dayCount = 0;
      let tempDate = [];
      let tempDateByKey = [];
      let obj = {...finalObject};
      let tempFianlList = [];
      for (let i = 0; i < this.state.list.length; i++) {
        if (tempDateByKey[this.state.list[i].statDate] == null) {
          tempDateByKey[this.state.list[i].statDate] = dayCount;
          if (tempDate[dayCount] == null) {
            tempDate[dayCount] = this.state.list[i].statDate;
          }
          dayCount++;
        }
      }

      tempDate.sort(function (a, b){
        if (a < b) return 1;
        if (a > b) return -1;
        if (a === b) return 0;
      });

      for (const d in tempDate) {
        if (tempDateByKey[tempDate[d]] == null) continue;
        tempDateByKey[tempDate[d]] = parseInt(d);
      }

      for (let i = 0; i < this.state.list.length; i++) {
        if (tempDateByKey[this.state.list[i].statDate] == null) continue;
        dayCount = tempDateByKey[this.state.list[i].statDate];
        obj.branchName = this.state.branchNameList[this.state.list[i].branchIdx];
        obj.userType = this.state.userTypeList[this.state.list[i].userType];

        // eval 사용은 여기서만 (결과를 받아서 뿌려주는 용도)
        eval("obj.joinCount"+(dayCount+1) + "=this.state.list[i].joinCount");
        eval("obj.exitCount"+(dayCount+1) + "=this.state.list[i].exitCount");
        eval("obj.stopCount"+(dayCount+1) + "=this.state.list[i].stopCount");
        eval("obj.comebackCount"+(dayCount+1) + "=this.state.list[i].comebackCount");
        eval("obj.accidentCount"+(dayCount+1) + "=this.state.list[i].accidentCount");
        eval("obj.totalMemberCount"+(dayCount+1) + "=this.state.list[i].totalMemberCount");

        obj.joinCount0 += this.state.list[i].joinCount;
        obj.exitCount0 += this.state.list[i].exitCount;
        obj.stopCount0 += this.state.list[i].stopCount;
        obj.comebackCount0 += this.state.list[i].comebackCount;
        obj.accidentCount0 += this.state.list[i].accidentCount;
        obj.totalMemberCount0 += this.state.list[i].totalMemberCount;
        obj.idx = idx++;

        if (((i < this.state.list.length - 1) && (this.state.list[i].userType != this.state.list[i+1].userType)) ||
          ((i < this.state.list.length - 1) && (this.state.list[i].branchIdx != this.state.list[i+1].branchIdx)) ||
          (i == this.state.list.length - 1)) {
          tempFianlList.push(obj);
          obj = {...finalObject};
        }
      }
      let totalRiderObj = {...finalObject};
      totalRiderObj.branchName = "지점 합계";
      totalRiderObj.userType = this.state.userTypeList[1];
      let totalFrObj = {...finalObject};
      totalFrObj.branchName = "지점 합계";
      totalFrObj.userType = this.state.userTypeList[2];

      for (const obj of tempFianlList) {
        // eval 사용은 여기서만 (결과를 받아서 뿌려주는 용도)
        if (obj.userType == this.state.userTypeList[1]) {
          for (let i = 0; i < 13; i++) {
            eval("totalRiderObj.joinCount"+(i) + "+=obj.joinCount"+(i));
            eval("totalRiderObj.exitCount"+(i) + "+=obj.exitCount"+(i));
            eval("totalRiderObj.stopCount"+(i) + "+=obj.stopCount"+(i));
            eval("totalRiderObj.comebackCount"+(i) + "+=obj.comebackCount"+(i));
            eval("totalRiderObj.accidentCount"+(i) + "+=obj.accidentCount"+(i));
            eval("totalRiderObj.totalMemberCount"+(i) + "+=obj.totalMemberCount"+(i));
          }
        } else {
          for (let i = 0; i < 13; i++) {
            eval("totalFrObj.joinCount"+(i) + "+=obj.joinCount"+(i));
            eval("totalFrObj.exitCount"+(i) + "+=obj.exitCount"+(i));
            eval("totalFrObj.stopCount"+(i) + "+=obj.stopCount"+(i));
            eval("totalFrObj.comebackCount"+(i) + "+=obj.comebackCount"+(i));
            eval("totalFrObj.accidentCount"+(i) + "+=obj.accidentCount"+(i));
            eval("totalFrObj.totalMemberCount"+(i) + "+=obj.totalMemberCount"+(i));
          }
        }
      }
      tempFianlList.unshift(totalFrObj);
      tempFianlList.unshift(totalRiderObj);
      this.setState({
        statDate: tempDate,
        finalList: tempFianlList,
      })
    };

    onChange = (e) => {
      this.setState({
        dateRange: e.target.value,
        selectDate: 0,
      })
      this.getDateInfo(e.target.value);
    };

    openStaticsJoinExitGraphModal = () => {
      this.setState({ staticsJoinExitGraphOpen: true });
    };
    closeStaticsJoinExitGraphModal = () => {
      this.setState({ staticsJoinExitGraphOpen: false });
    };

    render() {

      const columns = [
        {
          title: "지점",
          dataIndex: "branchName",
          className: "table-column-content",
        },
        {
          title: "분류",
          dataIndex: "userType",
          className: "table-column-content",
        },
        {
          title: "기간합계",
          className: "table-column-content color",
          children: [
            {
              title: "신규",
              dataIndex: "joinCount0",
              className: "table-column-center border",
              render: (data) => <div>{comma(data)}</div>,
            },
            {
              title: "탈퇴",
              dataIndex: "exitCount0",
              className: "table-column-center border",
              render: (data) => <div>{comma(data)}</div>,
            },
            {
              title: "정지",
              dataIndex: "stopCount0",
              className: "table-column-center border",
              render: (data) => <div>{comma(data)}</div>,
            },
            {
              title: "복귀",
              dataIndex: "comebackCount0",
              className: "table-column-center border",
              render: (data) => <div>{comma(data)}</div>,
            },
            {
              title: "사고",
              dataIndex: "accidentCount0",
              className: "table-column-center border",
              render: (data) => <div>{comma(data)}</div>,
            },
          ],
        },
      ];
      const columnsDay = [];
      columnsDay.push(...columns);

      const columnsWeek = [];
      columnsWeek.push(...columns);

      const columnsMonth = [];
      columnsMonth.push(...columns);

      for (let i = 0; i < 12; i++) {
        let addCol = {
          title: this.state.statDate[i],
          className: "table-column-content",
          children: [
            {
              title: "신규",
              dataIndex: "joinCount"+(i+1),
              className: "table-column-center border",
              render: (data) => <div>{comma(data)}</div>,
            },
            {
              title: "탈퇴",
              dataIndex: "exitCount"+(i+1),
              className: "table-column-center border",
              render: (data) => <div>{comma(data)}</div>,
            },
            {
              title: "정지",
              dataIndex: "stopCount"+(i+1),
              className: "table-column-center border",
              render: (data) => <div>{comma(data)}</div>,
            },
            {
              title: "복귀",
              dataIndex: "comebackCount"+(i+1),
              className: "table-column-center border",
              render: (data) => <div>{comma(data)}</div>,
            },
            {
              title: "사고",
              dataIndex: "accidentCount"+(i+1),
              className: "table-column-center border",
              render: (data) => <div>{comma(data)}</div>,
            },
            {
              title: "총원",
              dataIndex: "totalMemberCount"+(i+1),
              className: "table-column-center border",
              render: (data) => <div>{comma(data)}</div>,
            },
          ],
        }
        if (i < 7) {
          columnsDay.push(addCol);
        }

        if (i < 5) {
          columnsWeek.push(addCol);
        }
        columnsMonth.push(addCol);
      };

        const {branchNameList, dateList, pagination, finalList} = this.state;
        return (
            <>
                <div>
                  <SelectBox
                      value={branchNameList[this.state.branchNameValue]}
                      code={Object.keys(branchNameList)}
                      codeString={branchNameList}
                      onChange={(value) => {
                          if (parseInt(value) !== this.state.branchNameValue) {
                              this.setState(
                                  {
                                      branchNameValue: parseInt(value),
                                  },
                                  () => this.getList()
                              );
                          }
                      }}
                  />

                  {this.state.staticsJoinExitGraphOpen && (
                    <StaticsJoinExitGraphDialog
                      data={{startDate: this.state.startDate,
                        endDate: this.state.endDate,
                        branchNameValue: this.state.branchNameValue,
                        dateRange: this.state.dateRange,
                      }}
                      close={this.closeStaticsJoinExitGraphModal} />
                  )}

                  <Radio.Group onChange={this.onChange} defaultValue={this.state.dateRange}  style={{ width: 250, marginLeft: 20 }}>
                      <Radio value={1}>일별</Radio>
                      <Radio value={2}>주별</Radio>
                      <Radio value={3}>월별</Radio>
                  </Radio.Group>

                  <SelectBox
                    value={dateList[this.state.selectDate]}
                    code={dateList}
                    codeString={dateList}
                    style={{width:150}}
                    onChange={(value) => {
                        this.setState(
                          {
                            selectDate: value,
                          },
                          () => this.getList()
                        );
                    }}
                  />

                  &nbsp;&nbsp; 검색 된 기간:&nbsp;&nbsp;
                  <Input.TextArea
                    value={this.state.startDate + " ~ " + this.state.endDate}
                    disabled={true}
                    style={{ width: 190, height: 10}}
                  />
                </div>
              *매일 05시 기준으로 집계 됩니다*
              <Button
                className="graph-btn"
                style={{ float: "right", marginLeft: 10, marginBottom: 20 }}
                onClick={() => this.openStaticsJoinExitGraphModal()}
              >
                <img style={{ display: 'inline' }} src={StatisticsIcon} alt="" />
                그래프 통계
              </Button>

              <div style={{ fontSize: "22px", fontWeight: "bold" }}>
                현재 라이더 총원 : {"    "} {comma(this.state.totalRiderCount)}{" / "}
                현재 가맹점 총원 : {"    "} {comma(this.state.totalFrCount)}
              </div>

                <Table
                  className="stats-table-inner"
                    rowKey={(record) => record}
                    rowClassName={""}
                    dataSource={finalList}
                    style={{height: 850}}
                    pagination={{
                      total: 0,
                      pageSize: 10000,
                      hideOnSinglePage: true
                    }}
                    columns={this.state.dateRange == 1 ? columnsDay : this.state.dateRange == 2 ? columnsWeek : columnsMonth}
                    onChange={this.handleTableChange}
                />

            </>
        )
    }
}
export default StaticsJoinExit;
