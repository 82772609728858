import { Table, DatePicker } from 'antd';
import { Component } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { comma } from '../../lib/util/numberUtil';
import { formatDates } from '../../lib/util/dateUtil';
import moment from 'moment';

const today = new Date();
const { RangePicker } = DatePicker;

class FranchiseDuesAggregation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      startDate: moment(today).format('YYYY-MM-DD 00:00'),
      endDate: moment(today).format('YYYY-MM-DD 23:59'),
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    const { startDate, endDate } = this.state;
    httpPost(httpUrl.franchiseDuesAggregation, [], { startDate, endDate }).then(
      (res) => {
        console.log('This is FranchiseDuesAggregation');
        console.log(res.data);
        this.setState({
          list: res.data.list,
        });
      }
    );
  };

  onChangeDate = (dateString) => {
    this.setState(
      {
        startDate:
          dateString != null
            ? moment(dateString[0]).format('YYYY-MM-DD 00:00')
            : '',
        endDate:
          dateString != null
            ? moment(dateString[1]).format('YYYY-MM-DD 23:59')
            : '',
      },
      () => this.getList()
    );
  };

  render() {
    const columns = [
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '날짜',
        dataIndex: 'logDate',
        className: 'table-column-center',
        render: (data) => <div>{formatDates(data)}</div>,
      },
      {
        title: '가맹비총액',
        dataIndex: 'totalDues',
        className: 'table-column-center',
        render: (data, row) => <div>{comma(data)}원</div>,
      },
      {
        title: '총가맹점수',
        dataIndex: 'totalFranchise',
        className: 'table-column-center',
      },
      {
        title: '가맹비납부율(정상납부+고정비)',
        className: 'table-column-center',
        render: (row) => (
          <div>
            {Math.round(
              ((row.normal + row.fixed) / row.totalFranchise) * 10000
            ) /
              100 +
              '%'}
          </div>
        ),
      },
      {
        title: '미납가맹점수',
        dataIndex: 'free',
        className: 'table-column-center',
      },
      {
        title: '고정비가맹점수',
        dataIndex: 'fixed',
        className: 'table-column-center',
      },
      {
        title: '미수금존재가맹점수',
        dataIndex: 'misu',
        className: 'table-column-center',
      },
      {
        title: '1개월뒤납부율(면제가맹점제외)',
        className: 'table-column-center',
        render: (row) => (
          <div>
            {Math.round(
              ((row.normal1Month + row.fixed1Month) / row.totalFranchise) *
                10000
            ) /
              100 +
              '%'}
          </div>
        ),
      },
      {
        title: '2개월뒤납부율(면제가맹점제외)',
        className: 'table-column-center',
        render: (row) => (
          <div>
            {Math.round(
              ((row.normal2Month + row.fixed2Month) / row.totalFranchise) *
                10000
            ) /
              100 +
              '%'}
          </div>
        ),
      },
      {
        title: '3개월뒤납부율(면제가맹점제외)',
        className: 'table-column-center',
        render: (row) => (
          <div>
            {Math.round(
              ((row.normal3Month + row.fixed3Month) / row.totalFranchise) *
                10000
            ) /
              100 +
              '%'}
          </div>
        ),
      },

      // {
      //   title: "평균가맹비",
      //   className: "table-column-center",
      //   render: (data, row) => (
      //     <div>
      //       {comma(
      //         Math.round(row.duesSum / (row.notZeroDues - row.threeMonth))
      //       )}
      //       원
      //     </div>
      //   ),
      // },
    ];

    return (
      <>
        <RangePicker
          placeholder={['시작일', '종료일']}
          value={[
            moment(this.state.startDate, 'YYYY-MM-DD'),
            moment(this.state.endDate, 'YYYY-MM-DD'),
          ]}
          onChange={this.onChangeDate}
          style={{ width: 350, marginBottom: '10px', float: 'right' }}
        />
        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) =>
            record.status === 'COMPLETE' ? 'table-disabled' : ''
          }
          dataSource={this.state.list}
          columns={columns}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};
export default connect(mapStateToProps, null)(FranchiseDuesAggregation);
