import {Button, DatePicker, Form, Modal} from "antd";
import React, { Component } from "react";
import {httpGet, httpPost, httpUrl} from "../../api/httpClient";
import CloseIcon from "../../img/close.png";
import SelectBox from "../input/SelectBox";

class RegistBranchHeadOfficeListDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      branchNameList: {},
      branchNameValue: 0,
      headOfficeNameList: {},
      headOfficeNameValue: 0,

    };
  }
  componentDidMount() {
    this.getBranchInfo();
  }

  getBranchInfo = async () => {
    let headOfficeNameList = this.state.headOfficeNameList;
    let branchNameList = this.state.branchNameList;
    await httpGet(httpUrl.isHeadOfficeList, [], {}).then((res) => {
      if (res.result === "SUCCESS") {
        console.log("res");
        console.log(res.data);
        res.data.map((item) => (headOfficeNameList[item.idx] = item.branchName));
        this.setState(
          {
            headOfficeNameList: headOfficeNameList,
          },
          () => {
            console.log("headOfficeNameList");
            console.log(this.state.headOfficeNameList);
          }
        );
      }
    });
    await httpPost(httpUrl.branchListInfo, [], {}).then((res) => {
      if (res.result === "SUCCESS") {
        console.log("res");
        console.log(res.data);
        res.data.branch.map((item) => (branchNameList[item.idx] = item.branchName));
        this.setState(
            {
              branchNameList: branchNameList,
            },
            () => {
              console.log("branchNameList");
              console.log(this.state.branchNameList);
            }
        );
      }
    });
  };

  onClickCreate = () => {
    httpPost(httpUrl.createBranchHeadOfficeList, [], {
      branchIdx: this.state.branchNameValue,
      headBranchIdx: this.state.headOfficeNameValue,
    }).then((res) => {
      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '등록 성공',
          content: '총판 지점 등록에 성공했습니다.',
        });
        this.props.close();
      } else if (res.data === 'DUPLICATE') {
        Modal.warn({
          title: '총판 지점 등록 실패',
          content: '동일한 총판 지점 설정이 있습니다.',
        });
      } else if (res.data === 'NOT_HEAD_OFFICE') {
        Modal.warn({
          title: '총판 지점 등록 실패',
          content: '총판 지점이 아닙니다. 해당 지점을 총판으로 설정 해야합니다.',
        });
      } else if (res.data === 'INVALID_BRANCH') {
        Modal.warn({
          title: '총판 지점 등록 실패',
          content: '지점 설정이 잘못되어 있습니다.',
        });
      } else if (res.data === 'ALREADY_EXIST') {
        Modal.warn({
          title: '총판 지점 등록 실패',
          content: '이미 존재하는 하위 지점 입니다.',
        });
      } else {
        Modal.warn({
          title: '등록 실패',
          content: '총판 지점 등록에 실패했습니다.',
        });
      }
    });
  };

  render() {
    const { close } = this.props;
    const {branchNameList, headOfficeNameList} = this.state;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} onWheel={false} />
        <div className="systemKey-Dialog">
          <div className="systemKey-content">
            <div className="regist-header">
              <div className="systemKey-title">
                {'총판 지점 생성'}
              </div>
              <img
                  onClick={close}
                  src={CloseIcon}
                  className="dialog-close"
                  alt="img"
              />
            </div>
            <div className="systemKey-inner">
              <div className="taskWork-list">

                <div>
                  <div className="twl-text">총판</div>
                  <SelectBox
                      value={headOfficeNameList[this.state.headOfficeNameValue]}
                      code={Object.keys(headOfficeNameList)}
                      codeString={headOfficeNameList}
                      onChange={(value) => {
                        if (parseInt(value) !== this.state.headOfficeNameValue) {
                          this.setState(
                              {
                                headOfficeNameValue: parseInt(value),
                              }
                          );
                        }
                      }}
                  />
                </div>
                <div>
                  <div className="twl-text">하위지점</div>
                  <SelectBox
                      value={branchNameList[this.state.branchNameValue]}
                      code={Object.keys(branchNameList)}
                      codeString={branchNameList}
                      onChange={(value) => {
                        if (parseInt(value) !== this.state.branchNameValue) {
                          this.setState(
                              {
                                branchNameValue: parseInt(value),
                              }
                          );
                        }
                      }}
                  />
                </div>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="create-control-submit-btn"
                    style={{ width: '100%' }}
                    onClick={() => {this.onClickCreate()}}
                >
                  {'등록하기'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegistBranchHeadOfficeListDialog;
