import { Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { comma } from '../../lib/util/numberUtil';

class DeliveryPriceExtraRain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      list: [],
      branchNameList: {},
    };
  }

  componentDidMount() {
    this.getBranchInfo();
    this.getList();
  }

  getBranchInfo = async () => {
    let branchNameList = this.state.branchNameList;
    try {
      const res = await httpPost(httpUrl.branchListInfo, [], {});
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data);
        res.data.branch.map(
          (item) => (branchNameList[item.idx] = item.branchName)
        );
        this.setState({
          branchNameList: branchNameList,
        });
      }
    } catch (error) {
      console.warn('getBranchInfo error: {}', error);
    }
  };

  getList = () => {
    const { pagination } = this.state;
    const pageNum = pagination.current;
    const pageSize = pagination.pageSize;
    httpGet(httpUrl.dpeRain, [pageNum, pageSize], {}).then((res) => {
      console.log('res');
      console.log(res);
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.list,
        pagination: pagination,
      });
    });
  };

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  render() {
    const { branchNameList } = this.state;
    const columns = [
      {
        title: '지점명',
        dataIndex: 'branchIdx',
        className: 'table-column-center',
        render: (data) => <div>{branchNameList[data]}</div>,
      },
      {
        title: '지점번호',
        dataIndex: 'branchIdx',
        className: 'table-column-center',
      },
      {
        title: '요금',
        dataIndex: 'extraPrice',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}원</div>,
      },
      {
        title: '사용여부',
        dataIndex: 'enabled',
        className: 'table-column-center',
        render: (data) => <div>{data === true ? 'ON' : 'OFF'}</div>,
      },
      {
        title: '적용시간',
        dataIndex: 'type',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            {row.type == 0
              ? row.startDate + ' ~ ' + row.endDate
              : '매일 ' +
                moment(row.startDate).format('HH:mm') +
                ' ~ ' +
                moment(row.endDate).format('HH:mm')}
          </div>
        ),
      },
      {
        title: '노출여부',
        dataIndex: 'visible',
        className: 'table-column-center',
        render: (data) => <div>{data === true ? 'ON' : 'OFF'}</div>,
      },
    ];

    return (
      <Table
        rowKey={(record) => record.idx}
        dataSource={this.state.list}
        columns={columns}
        pagination={this.state.pagination}
        onChange={this.handleTableChange}
      />
    );
  }
}

export default DeliveryPriceExtraRain;
