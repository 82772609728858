import React from 'react';
import { Layout, Modal } from 'antd';
import { connect } from 'react-redux';
import { reactLocalStorage } from 'reactjs-localstorage';

import { httpGet, httpPost, httpUrl } from '../api/httpClient';
import { logout, login } from '../actions/loginAction';
import con from '../const';
import { setAgencyBranch } from '../actions/branchAction';
import { comma } from '../lib/util/numberUtil';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  initializeUserInfo = () => {
    const userInfo = reactLocalStorage.getObject(con.appName + '#adminUser');
    if (!userInfo || !userInfo.id) return;
    this.props.onLogin(userInfo);
    const agencyBranch = reactLocalStorage.getObject(
      con.appName + '#agencyBranch'
    );
    // console.log(agencyBranch);
    if (!agencyBranch || agencyBranch === null) return;
    this.props.setAgencyBranch(agencyBranch);
    // 잔액갱신
    httpGet(httpUrl.hoBalance, [], {}).then((res) => {
      this.props.onLogin({
        ...userInfo,
        ncash: res.data,
      });
    });
  };

  logout = () => {
    httpPost(httpUrl.logout, [], {})
      .then(() => {
        this.props.onLogout();
        global.location.href = '/';
      })
      .catch((e) => {
        global.location.href = '/';
      });
  };

  componentDidMount() {
    this.initializeUserInfo();
  }

  render() {
    return (
      <Layout.Header style={{ background: '#fff', padding: 0, height: '5vh' }}>
        <div
          style={{
            backgroundColor: '#000',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#fff',
            textAlign: 'right',
            paddingRight: '20px',
          }}>
          {this.props.loginInfo.isAgency && (
            <div style={{ display: 'inline-block' }}>
              예치금 잔액 {comma(this.props.loginInfo.ncash)} 원
              &nbsp;&nbsp;&nbsp;|
              {/* {this.props.loginInfo.userId}&nbsp;&nbsp;&nbsp;| */}
            </div>
          )}
          <div style={{ display: 'inline-block' }}>
            &nbsp;&nbsp;&nbsp;관리자&nbsp;&nbsp;&nbsp;|
            {/* {this.props.loginInfo.userId}&nbsp;&nbsp;&nbsp;| */}
          </div>
          <div
            style={{ display: 'inline-block', cursor: 'pointer' }}
            onClick={() => {
              this.setState({ visible: true });
            }}>
            &nbsp;&nbsp;&nbsp;로그아웃
          </div>
        </div>
        <Modal
          visible={this.state.visible}
          title="로그아웃"
          okText="확인"
          cancelText="취소"
          onOk={this.logout}
          onCancel={() => {
            this.setState({ visible: false });
          }}
          destroyOnClose={true}>
          <div>로그아웃 하시겠습니까?</div>
        </Modal>
      </Layout.Header>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (userinfo) => dispatch(login(userinfo)),
    onLogout: () => dispatch(logout()),
    setAgencyBranch: (branches) => dispatch(setAgencyBranch(branches)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
