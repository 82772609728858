import { Button, Modal, Table } from 'antd';
import moment from 'moment';
import 'moment/locale/ko';
import React, { useEffect, useState } from 'react';
import xlsx from 'xlsx';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import Const from '../../const';
import '../../css/main.css';
import ExcelIcon from '../../img/excel.png';
import { approvalCategory, approvalStatus } from '../../lib/util/codeUtil';

moment.locale('ko');

const BranchApproval = () => {
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });
  const [paginationExcel, setPaginationExcel] = useState({
    total: 0,
    current: 1,
    pageSize: 100000,
  });
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isMemoView, setIsMemoView] = useState(null);

  const getList = async () => {
    const pageSize = pagination.pageSize;
    const pageNum = pagination.current;
    const res = await httpGet(
      httpUrl.getApprovalList,
      ['DUES', pageNum, pageSize],
      {}
    );
    if (!res) {
      console.log(`getListAsync failed. result: ${res.result}`);
      return;
    }
    console.log(res);
    setList(res.data.approval);
    setPagination({
      ...pagination,
      current: res.data.currentPage,
      total: res.data.totalCount,
    });
  };

  const handleSubmit = (category, value, status) => {
    const obj = JSON.parse(value.rawData);
    console.log(value.memo);
    switch (category) {
      case 'DUES':
        const statusText = status === 1 ? '승인' : '거절';
        const duesAmount = obj.data >= 0 ? obj.data : -1;
        Modal.confirm({
          title: '요청 ' + statusText + ' 확인',
          content: (
            <div>
              요청을 {statusText} 하시겠습니까?
              {/* {status === 2 &&(
                            <>
                            <br/>
                            <Input.TextArea
                                allowClear
                                autoSize={{ minRows: 5, maxRows: 5 }}
                                placeholder="사유를 입력하세요"
                                className="override-input"
                                style={{marginTop: 6}}
                                onChange={(e) => { value.memo = e.target.value}}
                                />
                            </>
                        )} */}
            </div>
          ),
          okText: '확인',
          cancelText: '취소',
          onOk() {
            httpPost(httpUrl.updateApproval, [], {
              idx: value.idx,
              userIdx: value.userIdx,
              changeData: duesAmount,
              changeDate: obj.date,
              status: status,
              duesMemo: value.memo,
            }).then((res) => {
              if (res) {
                Modal.info({
                  title: '요청 ' + statusText + ' 완료',
                  content: '요청 ' + statusText + '을 완료했습니다.',
                });
                getList();
              } else {
                Modal.info({
                  title: '요청 ' + statusText + ' 실패',
                  content:
                    '요청 ' +
                    statusText +
                    '을 실패했습니다. 관리자에게 문의해주세요.',
                });
              }
            });
          },
        });
        break;
    }
  };
  const handleTableChange = (page) => {
    setPagination({
      ...pagination,
      current: page.current,
    });
  };

  const memoDetailView = (data) => {
    Modal.info({
      title: '거절 사유',
      content: <div>{data}</div>,
      onOk() {},
    });
  };

  const parseExcelJson = (data) => {
    let result = [
      {
        userIdx: '순번',
        status: '상태',
        branchName: '지점명',
        frName: '가맹점명',
        category: '카테고리',
        registDate: '등록일자',
        rawData: '요청내용',
        createDate: '요청날짜',
        adminId: '처리인',
        memo: '사유',
        updateDate: '처리날짜',
      },
    ];
    data.forEach((item) => {
      const obj = JSON.parse(item.rawData);
      const price = obj.data ? obj.data : 0;
      const objDate =
        moment(obj.date).format('YYYY') > 2900
          ? '무제한'
          : moment(obj.date).format('YYYY-MM-DD');
      const regist = new Date(item.registDate).getTime();
      const questDate = new Date(obj.date).getTime();
      const dateDiff = Math.floor(
        (questDate - regist) / (1000 * 3600 * 24 * 30)
      );

      result.push({
        userIdx: item.idx,
        status: approvalStatus[item.status],
        branchName: item.branchName,
        frName: item.frName,
        category: approvalCategory[item.category],
        registDate: moment(item.registDate).format('YYYY-MM-DD'),
        rawData:
          objDate === '무제한'
            ? price + ' / ' + objDate
            : price + ' / ' + objDate + '(' + dateDiff + '개월)',
        createDate: moment(item.createDate).format('YYYY-MM-DD'),
        adminId: item.adminId ?? '-',
        memo: item.memo,
        updateDate:
          item.updateDate === null
            ? '-'
            : moment(item.updateDate).format('YYYY-MM-DD'),
      });
    });

    return result;
  };

  const onDownload = () => {
    httpGet(
      httpUrl.getApprovalList,
      [paginationExcel.current, paginationExcel.pageSize],
      {}
    ).then((res) => {
      const excelJson = parseExcelJson(res.data.approval);
      const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
      const wb = xlsx.utils.book_new();
      ws['!cols'] = [];
      ws['!cols'][2] = { width: 20 };
      ws['!cols'][3] = { width: 15 };
      ws['!cols'][4] = { width: 20 };
      ws['!cols'][5] = { width: 15 };
      ws['!cols'][8] = { width: 20 };
      xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
      xlsx.writeFile(wb, '지점승인관리목록.xlsx');
    });
  };

  const columns = [
    {
      title: '순번',
      dataIndex: 'idx',
      className: 'table-column-center',
    },
    {
      title: '지점',
      dataIndex: 'branchName',
      className: 'table-column-center',
    },
    {
      title: '가맹점명',
      dataIndex: 'frName',
      className: 'table-column-center',
    },
    {
      title: '상태',
      dataIndex: 'status',
      className: 'table-column-center',
      render: (data) => <>{approvalStatus[data]}</>,
    },
    {
      title: '카테고리',
      dataIndex: 'category',
      className: 'table-column-center',
      render: (data) => <>{approvalCategory[data]}</>,
    },
    {
      title: '등록일자',
      dataIndex: 'registDate',
      className: 'table-column-center',
      render: (data) => <>{moment(data).format('YYYY-MM-DD')}</>,
    },
    {
      title: '요청내용',
      dataIndex: 'rawData',
      className: 'table-column-center',
      render: (data, row) => {
        const obj = JSON.parse(data);
        const price = obj.data ? obj.data : 0;
        const objDate =
          moment(obj.date).format('YYYY') > 2900
            ? '무제한'
            : moment(obj.date).format('YYYY-MM-DD');
        const regist = new Date(row.registDate).getTime();
        const questDate = new Date(obj.date).getTime();
        const dateDiff = Math.floor(
          (questDate - regist) / (1000 * 3600 * 24 * 30)
        );
        return (
          <div>
            {price} / {objDate}
            <span> {objDate === '무제한' ? '' : '(' + dateDiff + '개월)'}</span>
          </div>
        );
      },
    },
    {
      title: '요청날짜',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => <>{moment(data).format('YYYY-MM-DD')}</>,
    },
    {
      title: '승인여부',
      className: 'table-column-center',
      render: (data, row) => {
        return (
          <>
            <Button
              onClick={() => {
                handleSubmit(row.category, row, 1);
              }}
              style={{ marginRight: 6 }}
              disabled={row.status !== 0}>
              요청 승인
            </Button>
            <Button
              onClick={() => {
                handleSubmit(row.category, row, 2);
              }}
              style={{ marginLeft: 6 }}
              disabled={row.status !== 0}>
              요청 거절
            </Button>
          </>
        );
      },
    },
    {
      title: '처리인',
      dataIndex: 'adminId',
      className: 'table-column-center',
    },
    {
      title: '사유',
      dataIndex: 'memo',
      className: 'table-column-center approval-memo',
      render: (data) => (
        <div
          className="approval-memo"
          onClick={() => memoDetailView(data)}
          style={{ cursor: 'pointer' }}>
          {data}
        </div>
      ),
    },
    {
      title: '처리날짜',
      dataIndex: 'updateDate',
      className: 'table-column-center',
      render: (data) => <>{data && moment(data).format('YYYY-MM-DD')}</>,
    },
  ];

  useEffect(() => {
    getList();
  }, [pagination.current]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 20,
        }}
      />
      {Const.excelEnable && (
        <Button
          className="download-btn"
          style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
          onClick={() => onDownload()}>
          <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
          엑셀 다운로드
        </Button>
      )}
      <Table
        rowKey={(record) => record.idx}
        dataSource={list}
        columns={columns}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default BranchApproval;
