import { Button, DatePicker, Input, Radio, Table } from 'antd';
import React, { Component } from 'react';
import xlsx from 'xlsx';
import { httpGet, httpGetWithoutLoading, httpUrl } from '../../api/httpClient';
import DepositWithdrawDialog from '../../components/dialog/DepositWithdrawDialog';
import ProgressBar from '../../components/ProgressBar';
import Const from '../../const';
import '../../css/main.css';
import ExcelIcon from '../../img/excel.png';
import { formatDate } from '../../lib/util/dateUtil';
import { comma } from '../../lib/util/numberUtil';

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

const initialPaginationExcel = {
  total: Number.MAX_VALUE,
  current: 1,
  pageSize: 1000,
};

class DepositWithdrawHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      paginationExcel: initialPaginationExcel,
      userId: '',
      list: [],
      searchType: 1,

      progressBarOn: false,
    };
    this.progressBarFlag = false;
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let userId = this.state.userId;
    let userType = this.state.searchType;
    httpGet(
      httpUrl.depositWithdrawList,
      [pageNum, pageSize, userId, userType],
      {}
    ).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.withdraws,
        pagination,
      });
    });
  };

  getExcelList = async () => {
    let userId = this.state.userId;
    let userType = this.state.searchType;

    this.progressBarFlag = true;

    const loopGetList = async () => {
      const res = await httpGetWithoutLoading(
        httpUrl.depositWithdrawList,
        [
          this.state.paginationExcel.current,
          this.state.paginationExcel.pageSize,
          userId,
          userType,
        ],
        {}
      );

      return res;
    };

    let totalRes = [];
    let tryCnt = 1;

    let failedPages = [];

    this.setState({
      progressBarOn: true,
    });

    while (this.progressBarFlag) {
      if (this.state.paginationExcel.total < this.state.paginationExcel.current)
        break;

      const loopRes = await loopGetList();

      if (loopRes.data) {
        this.setState({
          paginationExcel: {
            ...this.state.paginationExcel,
            current: this.state.paginationExcel.current + 1,
            total: loopRes.data.totalPage,
          },
        });

        totalRes = totalRes.concat(loopRes.data.withdraws);
      } else if (tryCnt <= 3) {
        tryCnt += 1;
      } else {
        failedPages.push(this.pageNum);

        this.setState({
          paginationExcel: {
            ...this.state.paginationExcel,
            current: this.state.paginationExcel.current + 1,
          },
        });

        tryCnt = 1;
      }
    }

    this.setState({
      progressBarOn: false,
      paginationExcel: initialPaginationExcel,
    });

    return this.progressBarFlag ? totalRes : null;
  };

  quitProgressBar = () => {
    this.progressBarFlag = false;

    this.setState({
      progressBarOn: false,
      paginationExcel: initialPaginationExcel,
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  pressSearch = () => {
    this.setState(
      {
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  onChange = (e) => {
    this.setState(
      {
        searchType: e.target.value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  parseExcelJson = (listExcel) => {
    let result = [
      {
        userId: '아이디',
        userName: '이름',
        bank: '은행명',
        depositor: '예금주명',
        bankAccount: '출금계좌',
        ncashDelta: '출금금액',
        createDate: '출금일시',
      },
    ];
    listExcel.forEach((item) => {
      result.push({
        userId: item.userId,
        userName: item.userName,
        bank: item.bank,
        depositor: item.depositor,
        bankAccount: item.bankAccount,
        ncashDelta: item.ncashDelta,
        createDate: item.createDate,
      });
    });

    return result;
  };

  onDownload = async () => {
    const data = await this.getExcelList();

    if (!data) return;

    const excelJson = this.parseExcelJson(data);
    const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
    const wb = xlsx.utils.book_new();

    ws['!cols'] = [];
    ws['!cols'][0] = { width: 15 };
    ws['!cols'][1] = { width: 15 };
    ws['!cols'][2] = { width: 12 };
    ws['!cols'][4] = { width: 15 };
    ws['!cols'][6] = { width: 20 };
    xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
    xlsx.writeFile(wb, '예치금출금.xlsx');
  };

  render() {
    const columns = [
      {
        title: '아이디',
        dataIndex: 'userId',
        className: 'table-column-center',
      },
      {
        title: '이름',
        dataIndex: 'frName',
        className: 'table-column-center',
      },
      {
        title: '은행명',
        dataIndex: 'bank',
        className: 'table-column-center',
        render: (data) => <div>{data === null ? '' : data.split(',')[0]}</div>,
      },
      {
        title: '예금주명',
        dataIndex: 'depositor',
        className: 'table-column-center',
      },
      {
        title: '출금계좌',
        dataIndex: 'bankAccount',
        className: 'table-column-center',
      },
      {
        title: '출금금액',
        dataIndex: 'withdrawReqAmount',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}원</div>,
      },
      {
        title: '출금일시',
        dataIndex: 'createDate',
        className: 'table-column-center',
        render: (data) => <div>{formatDate(data)}</div>,
      },
    ];
    return (
      <>
        <Radio.Group
          defaultValue={1}
          onChange={this.onChange}
          style={{ marginTop: 5 }}>
          <Radio value={1}>라이더</Radio>
          <Radio value={2}>가맹점</Radio>
        </Radio.Group>

        <Search
          placeholder="아이디 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ userId: e.target.value })}
          onSearch={this.pressSearch}
          style={{
            width: 220,
            marginBottom: 20,
          }}
        />

        {this.state.depositWithdrawOpen && (
          <DepositWithdrawDialog close={this.closeDepositWithdrawModal} />
        )}
        {this.state.progressBarOn && (
          <ProgressBar
            current={this.state.paginationExcel.current}
            total={this.state.paginationExcel.total}
            quit={this.quitProgressBar}
          />
        )}

        {Const.excelEnable && (
          <Button
            className="download-btn"
            style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
            onClick={() => this.onDownload()}>
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            엑셀 다운로드
          </Button>
        )}
        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) =>
            record.status === 'COMPLETE' ? 'table-disabled' : ''
          }
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={this.expandedRowRender}
          expandRowByClick={true}
        />
      </>
    );
  }
}

export default DepositWithdrawHistory;
