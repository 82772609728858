import { Button, Form, Input, Modal, Radio, Table } from 'antd';
import React, { Component } from 'react';
import { httpDelete, httpGet, httpPost, httpUrl } from '../../api/httpClient';
import SelectBox from '../../components/input/SelectBox';
import '../../css/main.css';
import { deliveryPriceFeeType } from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';
import moment from 'moment';
import { customAlert, customError } from '../../api/Modals';
import RiderGroupDialog from '../../components/dialog/rider/RiderGroupDialog';

const FormItem = Form.Item;
const today = new Date();

class RiderGroupManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      rowId: 1,
      selectedGroup: null,
      allBranchList: [],
      branchNameList: {},
      selBranchIdx: 0,
      firstDeliveryFeeType: -1,
      startDate: moment(today).format('YYYY-MM-DD 00:00'),
      endDate: moment(today).add(1, 'M').format('YYYY-MM-DD 23:59'),
      riderGroupOpen: false, // 기사 그룹 관리
      selectedRiderGroup: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onClickRow = (row) => {
    return {
      onClick: () => {
        this.openUpdateRiderGroupModal(row);
      },
    };
  };

  setRowClassName = (row, index) => {
    if (this.state.selectedGroup) {
      return index + 1 === this.state.selectedGroup.idx ? 'clickRowStyl' : '';
    } else {
      return '';
    }
  };

  getList = () => {
    const pagination = this.state.pagination;
    const branchIdx = this.state.selBranchIdx;

    httpGet(httpUrl.getBranchList, [1, 1000], {}).then((res) => {
      console.log('branchList');
      console.log(res);
      this.setState({
        allBranchList: res.data.branch,
      });

      let branchNameList = { 0: '전체지점' };
      branchNameList = this.state.allBranchList.map((item) => {
        branchNameList[item.idx] = item.branchName;
        return branchNameList;
      });
      this.setState({
        branchNameList: branchNameList[0],
      });
    });

    httpGet(
      httpUrl.getRiderGroup,
      [branchIdx, pagination.current, pagination.pageSize],
      {}
    ).then((res) => {
      console.log('getRiderGroup');
      console.log(res);
      if (res.result === 'SUCCESS') {
        this.setState(
          {
            list:
              this.state.selBranchIdx === 0
                ? res.data.riderSettingGroups
                : res.data.riderSettingGroups.filter(
                    (item) => item.branchIdx === this.state.selBranchIdx
                  ),
            pagination: {
              ...this.state.pagination,
              current: res.data.currentPage,
              total: res.data.totalCount,
            },
          },
          () => {
            console.log('this.state.list');
            console.log(this.state.list);
          }
        );
      }
    });
  };

  setBranchIdx = (num) => {
    const pager = { ...this.state.pagination };
    pager.current = 1;
    pager.pageSize = this.state.pagination.pageSize;
    this.setState(
      {
        selBranchIdx: num,
        pagination: pager,
      },
      () => this.getList()
    );
  };

  closeRiderGroupModal = () => {
    this.setState({ riderGroupOpen: false, selectedRiderGroup: null });
    this.getList();
  };

  openCreateRiderGroupModal = () => {
    this.setState({ riderGroupOpen: true });
  };
  openUpdateRiderGroupModal = (data) => {
    this.setState({ riderGroupOpen: true, selectedRiderGroup: data });
  };

  render() {
    const columns = [
      {
        title: '그룹명',
        dataIndex: 'settingGroupName',
        className: 'table-column-center',
        render: (data, row) => {
          return (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => this.setState({ selectedGroup: row })}>
              {data}
            </div>
          );
        },
      },
      {
        title: '처리건수',
        dataIndex: 'amountPerOneTime',
        className: 'table-column-center',
        render: (data, row) => {
          return (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => this.setState({ selectedGroup: row })}>
              {data}
            </div>
          );
        },
      },
      {
        title: '배달 수수료 형식',
        dataIndex: 'deliveryPriceFeeType',
        className: 'table-column-center',
        render: (data, row) => {
          return (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => this.setState({ selectedGroup: row })}>
              {deliveryPriceFeeType[data]}
            </div>
          );
        },
      },
      {
        title: '배달수수료',
        dataIndex: 'deliveryPriceFeeType',
        className: 'table-column-center',
        render: (data, row) => {
          return (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => this.setState({ selectedGroup: row })}>
              {data === 0
                ? comma(row.deliveryPriceFeeAmount) + ' %'
                : comma(row.deliveryPriceFeeAmount) + ' 원'}
            </div>
          );
        },
      },
      {
        title: '원천세율',
        dataIndex: 'deliveryPriceTax',
        className: 'table-column-center',
        render: (data, row) => {
          return (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => this.setState({ selectedGroup: row })}>
              {data + '%'}
            </div>
          );
        },
      },

      {
        title: '고용보험료율',
        dataIndex: 'deliveryPriceInsurance',
        className: 'table-column-center',
        render: (data, row) => {
          return (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => this.setState({ selectedGroup: row })}>
              {data + '%'}
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <div className="riderGroup-Dialog">
          <div className="riderGroup-content">
            <div className="flex">
              <SelectBox
                value={this.state.branchNameList[this.state.selBranchIdx]}
                code={Object.keys(this.state.branchNameList)}
                codeString={this.state.branchNameList}
                onChange={(value) => {
                  if (parseInt(value) !== this.state.selBranchIdx) {
                    this.setBranchIdx(parseInt(value));
                  }
                }}
              />
              <div>
                {this.state.riderGroupOpen && (
                  <RiderGroupDialog
                    data={this.state.selectedRiderGroup}
                    onCloseDialog={this.closeRiderGroupModal}
                  />
                )}
                <Button
                  onClick={this.openCreateRiderGroupModal}
                  style={{ marginLeft: 20 }}>
                  기사 그룹 등록
                </Button>
              </div>
            </div>
            <div className="riderGroup-inner">
              <div className="listBlock">
                <Table
                  dataSource={this.state.list}
                  columns={columns}
                  onChange={this.handleTableChange}
                  onRow={this.onClickRow}
                  rowClassName={(row, index) =>
                    this.setRowClassName(row, index)
                  }
                  pagination={this.state.pagination}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RiderGroupManagement;
