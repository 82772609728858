import React, { useEffect, useRef, useState } from 'react';
import '../../../css/main.css';
import CloseIcon from '../../../img/close.png';
import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { httpPost, httpUrl } from '../../../api/httpClient';
import { customAlert, customError, updateError } from '../../../api/Modals';

const CopyBranchDongRateDialog = ({
  close,
  branchList,
  dongRateList,
  selectDongRate,
}) => {
  const formRef = useRef();
  const [editBranchList, setEditBranchList] = useState([]);
  const [selectCopyBranchIdx, setSelectCopyBranchIdx] = useState(null);
  const [selectPasteBranchIdx, setSelectPasteBranchIdx] = useState(null);
  const [isCopyPrice, setIsCopyPrice] = useState(false);

  useEffect(() => {
    const branchs = branchList.filter((branch) => branch.idx !== '');
    setEditBranchList(branchs);
    console.log(selectDongRate);
  }, []);

  const handleClose = () => {
    close();
  };

  const handelSubmit = () => {
    const formBody = formRef.current.getFieldValue();
    console.log(formBody);
    if (formBody.branchIdx === '') {
      customError('복사할 지점을 선택해주세요.');
      return false;
    }
    if (formBody.targetBranchIdx === undefined) {
      customError('붙여넣을 지점을 선택해주세요.');
      return false;
    }
    Modal.confirm({
      title: '지점간 동별 요금제 복사',
      content: '요금제를 복사 하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        if (selectDongRate === null) {
          httpPost(httpUrl.copyDongRateAll, [], {
            ...formBody,
            isCopyPrice
          })
            .then((result) => {
              const resultMessage = result.data;
              if (resultMessage === 'SUCCESS') {
                Modal.info({ title: '복사 성공' });
                close();
              } else if (resultMessage === 'NO_DONG_RATE') {
                Modal.error({
                  title: '복사 실패',
                  content: '해당 지점에 동별요금제가 없습니다.',
                });
              } else if (resultMessage === 'DONG_RATE_INSERT_FAIL') {
                Modal.error({
                  title: '복사 실패',
                  content: '서버오류로 요금제 복사에 실패 했습니다. ',
                });
              }
            })
            .catch((error) => {
              customError(
                '복사 오류',
                '서버 에러가 발생하였습니다. 다시 시도해주세요.'
              );
            });
        } else {
          httpPost(httpUrl.copyDongRate, [], {
            ...formBody,
            dongRateIdx: selectDongRate.idx,
            branchIdx: null,
            isCopyPrice
          })
            .then((result) => {
              const resultMessage = result.data;
              if (resultMessage === 'SUCCESS') {
                Modal.info({ title: '복사 성공' });
                close();
              } else if (resultMessage === 'NO_DONG_RATE') {
                Modal.error({
                  title: '복사 실패',
                  content: '해당 지점에 동별요금제가 없습니다.',
                });
              } else if (resultMessage === 'DONG_RATE_INSERT_FAIL') {
                Modal.error({
                  title: '복사 실패',
                  content: '서버오류로 요금제 복사에 실패 했습니다. ',
                });
              }
            })
            .catch((error) => {
              customError(
                '복사 오류',
                '서버 에러가 발생하였습니다. 다시 시도해주세요.'
              );
            });
        }
      },
    });
  };

  return (
    <>
      <div className="Dialog-overlay" onClick={() => handleClose()} />
      <div className="systemKey-Dialog">
        <div className="systemKey-content">
          <div className="systemKey-title">지점 간 동별요금제 복사</div>
          <img
            onClick={handleClose}
            style={{ display: 'inline' }}
            src={CloseIcon}
            className="dialog-close"
            alt="img"
          />
          <div className="systemKey-inner">
            <Form ref={formRef} onFinish={handelSubmit}>
              {/*<div>지점감 동별 요금제 전체복사</div>*/}
              <div>복사할 지점</div>
              <FormItem
                name="branchIdx"
                initialValue={selectDongRate ? selectDongRate.branchName : ''}>
                <Select
                  // listHeight={60}
                  // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  style={{
                    width: 200,
                  }}
                  disabled={selectDongRate}
                  onChange={(value) => setSelectCopyBranchIdx(value)}>
                  {editBranchList.map((branch) => {
                    return (
                      <Select.Option key={branch.idx} value={branch.idx}>
                        {branch.branchName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormItem>
              {selectDongRate && (
                <div>
                  <div>복사할 요금제</div>
                  <FormItem
                    initialValue={selectDongRate.name}
                    name="dongRateName">
                    <Input
                      disabled={true}
                      value={selectDongRate.name}
                      style={{ width: 200 }}
                      className="nn-modal-input"
                      placeholder="요금제를 선택해주세요."
                    />
                  </FormItem>
                </div>
              )}
              <div>붙여넣을 지점</div>
              <FormItem name="targetBranchIdx">
                <Select
                  // listHeight={134}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  style={{
                    width: 200,
                  }}
                  value={selectPasteBranchIdx}
                  onChange={(value) => setSelectPasteBranchIdx(value)}>
                  {editBranchList.map((branch) => {
                    return (
                      <Select.Option key={branch.idx} value={branch.idx}>
                        {branch.branchName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormItem>
              {/* <div>요금복사여부</div> */}
              <Checkbox
                style={{marginBottom: 20}}
                checked={isCopyPrice}
                onChange={(e)=>setIsCopyPrice(e.target.checked)}
              >
                요금복사여부
              </Checkbox>
              <div>
                <Button htmlType={'submit'}>복사</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyBranchDongRateDialog;
