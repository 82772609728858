import {
  Table,
} from 'antd';
import React, { Component } from 'react';

import '../../css/main.css';
import Const from "../../const";
import CountUp from 'react-countup';


class BranchSettlementDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        hideOnSinglePage: true,
        pageSize: 20,
        current: 1,
      },
      isSales: true,
      list: [],
    };
    this.formRef = React.createRef();

  }

  componentDidMount() {
    this.getList();
    this.branchSettlementDetailInfoInterval = setInterval(
        () => this.getList(),
        Const.branchSettlementDetailPollingInterval
    );
  }

  componentWillUnmount() {
    if (this.branchSettlementDetailInfoInterval) clearInterval(this.branchSettlementDetailInfoInterval);
  }

  getList = () => {
    if (this.props.data) {
      const totalSales = this.props.data.totalSales;
      const deliveryPrice = this.props.data.deliveryPrice;
      const deliveryFeeForRider = this.props.data.deliveryFeeForRider;
      const franchiseFee = this.props.data.franchiseFee;
      const totalCost = this.props.data.totalCost;
      const subsidizeCost = this.props.data.subsidizeCost;
      const industrialAccidentCost = this.props.data.industrialAccidentCost;
      const employmentInsuranceCost = this.props.data.employmentInsuranceCost;
      const hireCost = this.props.data.hireCost;
      const maintenanceCost = this.props.data.maintenanceCost;
      const rentalCost = this.props.data.rentalCost;
      const salesIncentivesCost = this.props.data.salesIncentivesCost;
      const consumableCost = this.props.data.consumableCost;
      const bikeMaintenanceCost = this.props.data.bikeMaintenanceCost;
      const refundFood = this.props.data.refundFood;
      const businessCardCost = this.props.data.businessCardCost;
      const nameTagCost = this.props.data.nameTagCost;
      const leafletCost = this.props.data.leafletCost;
      const prechargeUncollectedCost = this.props.data.prechargeUncollectedCost;
      const etcCost = this.props.data.etcCost;
      const beforeData = this.props.data.beforeData;

      if (this.props.isSales) {
        const tempList = [
          {
            type: '총 매출',
            isLive: true,
            amount: totalSales,
            before: beforeData.totalSales,
            per: totalSales != 0 ? '100%' : '',
          },
          {
            type: '관리자 배달비 * 11.7%',
            isLive: true,
            amount: deliveryPrice,
            before: beforeData.deliveryPrice,
            per: totalSales != 0 ? Math.round(((deliveryPrice / totalSales) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '배달 수수료(라이더)',
            isLive: true,
            amount: deliveryFeeForRider,
            before: beforeData.deliveryFeeForRider,
            per: totalSales != 0 ? Math.round(((deliveryFeeForRider / totalSales) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '가맹비',
            isLive: true,
            amount: franchiseFee,
            before: beforeData.franchiseFee,
            per: totalSales != 0 ? Math.round(((franchiseFee / totalSales) * 100) * 1000) / 1000 + '%' : '',
          },
        ];
        this.setState({
          list: tempList,
        });
      } else {
        const tempList = [
          {
            type: '총 비용',
            isLive: false,
            amount: totalCost != null ? totalCost: '정산 전',
            before: beforeData.totalCost,
            per: totalCost != 0 ? '100%' : '',
          },
          {
            type: '보조금',
            isLive: false,
            amount: subsidizeCost != null ? subsidizeCost : '정산 전',
            before: beforeData.subsidizeCost,
            per: totalCost != 0 ? Math.round(((subsidizeCost / totalCost) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '산재',
            isLive: false,
            amount: industrialAccidentCost != null ? industrialAccidentCost : '정산 전',
            before: beforeData.industrialAccidentCost,
            per: totalCost != 0 ? Math.round(((industrialAccidentCost / totalCost) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '고용',
            isLive: false,
            amount: employmentInsuranceCost != null ? employmentInsuranceCost : '정산 전',
            before: beforeData.employmentInsuranceCost,
            per: totalCost != 0 ? Math.round(((employmentInsuranceCost / totalCost) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '임차료',
            isLive: false,
            amount: hireCost != null ? hireCost : '정산 전',
            before: beforeData.hireCost,
            per: totalCost != 0 ? Math.round(((hireCost / totalCost) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '관리비(전기료 포함)',
            isLive: false,
            amount: maintenanceCost != null ? maintenanceCost : '정산 전',
            before: beforeData.maintenanceCost,
            per: totalCost != 0 ? Math.round(((maintenanceCost / totalCost) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '렌탈료',
            isLive: false,
            amount: rentalCost != null ? rentalCost : '정산 전',
            before: beforeData.rentalCost,
            per: totalCost != 0 ? Math.round(((rentalCost / totalCost) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '영업 인센티브',
            isLive: false,
            amount: salesIncentivesCost != null ? salesIncentivesCost : '정산 전',
            before: beforeData.salesIncentivesCost,
            per: totalCost != 0 ? Math.round(((salesIncentivesCost / totalCost) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '소모품비',
            isLive: false,
            amount: consumableCost != null ? consumableCost : '정산 전',
            before: beforeData.consumableCost,
            per: totalCost != 0 ? Math.round(((consumableCost / totalCost) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '바이크 관리',
            isLive: false,
            amount: bikeMaintenanceCost != null ? bikeMaintenanceCost : '정산 전',
            before: beforeData.bikeMaintenanceCost,
            per: totalCost != 0 ? Math.round(((bikeMaintenanceCost / totalCost) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '음식변상',
            isLive: false,
            amount: refundFood != null ? refundFood : '정산 전',
            before: beforeData.refundFood,
            per: totalCost != 0 ? Math.round(((refundFood / totalCost) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '명함',
            isLive: false,
            amount: businessCardCost != null ? businessCardCost : '정산 전',
            before: beforeData.businessCardCost,
            per: totalCost != 0 ? Math.round(((businessCardCost / totalCost) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '명찰',
            isLive: false,
            amount: nameTagCost != null ? nameTagCost : '정산 전',
            before: beforeData.nameTagCost,
            per: totalCost != 0 ? Math.round(((nameTagCost / totalCost) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '리플렛',
            isLive: false,
            amount: leafletCost != null ? leafletCost : '정산 전',
            before: beforeData.leafletCost,
            per: totalCost != 0 ? Math.round(((leafletCost / totalCost) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '선충전 미회수',
            isLive: false,
            amount: prechargeUncollectedCost != null ? prechargeUncollectedCost : '정산 전',
            before: beforeData.prechargeUncollectedCost,
            per: totalCost != 0 ? Math.round(((prechargeUncollectedCost / totalCost) * 100) * 1000) / 1000 + '%' : '',
          },
          {
            type: '기타',
            isLive: false,
            amount: etcCost != null ? etcCost : '정산 전',
            before: beforeData.etcCost,
            per: totalCost != 0 ? Math.round(((etcCost / totalCost) * 100) * 1000) / 1000 + '%' : '',
          },
        ];
        this.setState({
          list: tempList,
        });
      }
    };
  }

  render() {
    const { close, dateRange } = this.props;
    const columns = [
      {
        dataIndex: 'type',
        className: 'table-column-center',
        render(data, row, rowIndex) {
          return {
            props: {
              style: { background: rowIndex == 0 ? 'black' : ''}
            },
            children: <div style={{color: rowIndex == 0 ? 'white' : 'black', fontSize: '17px'}}><div style={{ display: 'inline-block'}}>{data}</div>&nbsp;
              <div style={{ display: 'inline-block', color: row.isLive ? 'blue' : 'red'}}>{dateRange == 0 ? (row.isLive ? '(실시간)' : '(예상)'): ''}</div> </div>
          };
        }
      },
      {
        dataIndex: 'amount',
        className: 'table-column-center',
        render(data, row, rowIndex) {
          return {
            props: {
              style: { background: rowIndex == 0 ? 'black' : ''},
            },
            children: <div style={{color: rowIndex == 0 ? 'white' : 'black', fontSize: '17px'}}>{
              <CountUp start={row.before} end={data} separator={','}></CountUp>
            }</div>
          };
        }
      },
      {
        dataIndex: 'per',
        className: 'table-column-center',
        render(data, row, rowIndex) {
          return {
            props: {
              style: { background: rowIndex == 0 ? 'black' : ''},
            },
            children: <div style={{color: rowIndex == 0 ? 'white' : 'black', fontSize: '17px'}}>{data}</div>
          };
        }
      }
    ];

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div
          className="systemKey-Dialog">
          <div className="systemKey-content" style={{ height: '100%' }}>
              <div className="settlement-container">
                <Table
                    showHeader={false}
                    className="searchSettlement-table"
                    dataSource={this.state.list}
                    columns={columns}
                    pagination={this.state.pagination}
                    style={{ border: '1px solid black'}}
                />
              </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BranchSettlementDialog;
