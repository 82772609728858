import {
  Button,
  Form,
  Input, Radio,
} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import {httpPost, httpUrl} from '../../api/httpClient';
import '../../css/main.css';
import CloseIcon from '../../img/close.png';
import {customAlert, customError} from "../../api/Modals";
import {
  branchSettlementElementTypeCode,
} from "../../lib/util/codeUtil";
import {comma} from "../../lib/util/numberUtil";

const today = new moment();
const dateFormat = 'YYYY-MM-01';
const lastMonthString = today.subtract(1, 'M').format(dateFormat);
const FormItem = Form.Item;

class ModifyBranchSettlementDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        current: 1,
        pageSize: 10,
        hideOnSinglePage: true,
      },
      startDate: moment(lastMonthString).format('YYYY-MM-01'),
      endDate: moment(lastMonthString).format('YYYY-MM-') + moment(lastMonthString).daysInMonth(),
      typeName: '',
      branchSettlementIdx: 0,
      changeFilter: 0,
    };
    this.formRef = React.createRef();

  }


  componentDidMount() {
    if (this.props.data) {
      this.setState({
        typeName: this.props.data.type,
        branchSettlementIdx: this.props.data.branchSettlementIdx,
      });
    }
  }

  onClickModifyElement = () => {
    let self = this;
    console.log(this.formRef.current.getFieldsValue());
    let fieldsValue = self.formRef.current.getFieldsValue();
    fieldsValue.settlementType = self.state.typeName;
    fieldsValue.ncashAmount = fieldsValue.ncashAmount.replaceAll(',', '');
    if (this.state.changeFilter == 1) {
      fieldsValue.ncashAmount *= -1;
    }

    if (fieldsValue.settlementMemo == undefined || fieldsValue.settlementMemo.length == 0) {
      customError(
          '수정 에러',
          '메모를 입력해 주세요.'
      );
      return;
    }

    httpPost(httpUrl.modifyBranchSettlementElement, [], {
      branchSettlementIdx: self.state.branchSettlementIdx,
      ...fieldsValue,
    })
    .then((result) => {
      if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
        customAlert(
            '수정 완료',
            '수정 완료하였습니다.'
        );
        self.props.closeModify();
      } else if (result.data === 'INVALID_SETTLEMENT_DAY') {
        customError(
            '수정 에러',
            '수정 가능 기간이 아닙니다.'
        );
      } else if (result.data === 'NOT_EXIST_GUARANTEED_AMOUNT') {
        customError(
            '수정 실패',
            '보존 금액을 먼저 입력 하세요.'
        );
      }
      else {
        customError(
            '수정 에러',
            '수정에 실패하였습니다'
        );
      }
    })
    .catch((e) => {
      customError(
          '수정 에러',
          '수정에 실패하였습니다'
      );
    });
  };

  handleChangeInput = (e) => {
    let self = this;
    let value = e.target.value;
    value = Number(value.replaceAll(',', ''));
    const formatValue = comma(value);
    let fieldsValue = self.formRef.current.getFieldsValue();
    self.formRef.current.setFieldsValue({
      ...fieldsValue,
      ncashAmount: formatValue,
        }
    );
  };

  onChangeRadio = (e) => {
    console.log('radio checked', e.target.value);
    this.setState({ changeFilter: e.target.value });
  };

  render() {
    const { closeModify, data } = this.props;
    return (
        <React.Fragment>
          <div className="Dialog-overlay" onClick={closeModify} />

          <div className="deposit-Dialog">
            <div className="deposit-content">
              <div className="deposit-title">수정</div>
              <img
                  onClick={closeModify}
                  style={{ display: 'inline' }}
                  src={CloseIcon}
                  className="dialog-close"
                  alt="img"
              />

              <div className="deposit-inner">
                <Form ref={this.formRef}>
                  <div className="contentBlock">
                    <div className="mainTitle">수정 내용</div>
                    <div className="formItem">
                      <FormItem
                          name="settlementType"
                          className="formItem"
                          style={{ marginLeft: 25, width: 230 }}
                          initialValue={data.type ? branchSettlementElementTypeCode[data.type] : ''}
                          value={data.type}>
                        <Input
                            style={{fontWeight: 'bold', color: 'black',}}
                            disabled={true}
                            className="override-input"
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">증가/차감</div>
                    <div className="formItem">
                      <FormItem
                          className="selectItem"
                          style={{ marginLeft: 25, width: 230 }}>
                        <Radio.Group
                            onChange={this.onChangeRadio}
                            value={this.state.changeFilter}>
                          <Radio value={0}> 증가 </Radio>
                          <Radio value={1}> 차감 </Radio>
                        </Radio.Group>
                      </FormItem>

                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">금액</div>
                    <div className="formItem">
                      <FormItem
                          name="ncashAmount"
                          className="selectItem"
                          style={{ marginLeft: 25, width: 230 }}
                          value={this.state.ncashAmount}
                      >
                        <Input
                            onChange={(e) => {
                              console.log(e);
                              this.handleChangeInput(e)
                            }}
                            placeholder="금액을 입력해주세요."
                            className="override-input"
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">메모</div>
                    <div className="formItem">
                      <FormItem
                          name="settlementMemo"
                          className="selectItem"
                          style={{ marginLeft: 25, width: 230 }}>
                        <Input
                            placeholder="메모를 입력해주세요."
                            className="override-input"
                        />
                      </FormItem>
                    </div>
                  </div>

                  <Button
                      onClick={() => this.onClickModifyElement()}
                      style={{ float: 'right' }}>
                    수정하기
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </React.Fragment>
    );
  }
}

export default ModifyBranchSettlementDialog;
