import React, { useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Input, Select, Table } from 'antd';
import moment from 'moment/moment';
import { comma } from '../../lib/util/numberUtil';
import {
  agencyNcashAllForAgency,
  agencyNcashAllForBranch,
  agencyNcashDeliveryColumns,
  agencyTaxReturnForDeliveryColumns,
  agencyTaxReturnForDuesColumns,
  allBranchList,
} from '../../lib/util/agencyUtil';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from '../../const';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import Const from '../../const';
import AgencyTaxReturnFr from './AgencyTaxReturnFr';
import AgencyTaxReturnRider from './AgencyTaxReturnRider';
import AgencyTaxReturnShareCall from './AgencyTaxReturnShareCall';

const AgencyTaxReturn = () => {
  const loginInfo = reactLocalStorage.getObject(con.appName + '#adminUser');
  const CancelToken = axios.CancelToken;
  const source = useRef(null);
  const Option = Select.Option;
  const today = moment().format('YYYY-MM-DD');
  const [branches] = useState(allBranchList());
  const [belongBranch, setBelongBranch] = useState(loginInfo.branch.companyId);
  const [list, setList] = useState([]);
  const [totalVat, setTotalVat] = useState(0);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState('fr');
  const [search, setSearch] = useState('');
  const [searchBtn, setSearchBtn] = useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 30,
  });

  const onChangeDate = (type, _, date) => {
    if (type === 'start') {
      setStartDate(moment(date).format('YYYY-MM-DD'));
      const temp = moment(date).add(30, 'd').format('YYYY-MM-DD');
      if (moment(endDate).isAfter(temp)) setEndDate(temp);
    } else {
      setEndDate(moment(date).format('YYYY-MM-DD'));
    }
  };
  const disabledDate = (current, from) => {
    if (startDate) {
      return Math.abs(current.diff(startDate, 'days')) > 30;
    }
    return false;
  };

  return (
    <div className={'agency-tr-layout'}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexBasis: 80,
          flexGrow: 0,
        }}>
        <h1 className={'agency-page-title'}>세금내역</h1>
        <div className={'ncl-menu-wrap'}>
          <div
            onClick={() => {
              setType('fr');
            }}
            className={type === 'fr' ? 'ncl-ctn-btn active' : 'ncl-ctn-btn'}>
            가맹점
          </div>
          <div
            onClick={() => {
              setType('rider');
            }}
            className={type === 'rider' ? 'ncl-ctn-btn active' : 'ncl-ctn-btn'}>
            라이더
          </div>
          <div
            onClick={() => {
              setType('shareCall');
            }}
            className={
              type === 'shareCall' ? 'ncl-ctn-btn active' : 'ncl-ctn-btn'
            }>
            공유콜
          </div>

          <div className={'ncl-input-box'} style={{ width: 140 }}>
            <DatePicker
              style={{ border: 'none', flex: 1 }}
              onChange={(_, date) => onChangeDate('start', _, date)}
              value={moment(startDate)}
              allowClear={false}
            />
          </div>
          <span style={{ marginRight: 10 }}>-</span>
          <div
            className={'ncl-input-box'}
            style={{ width: 140, marginLeft: 0 }}>
            <DatePicker
              style={{ border: 'none', flex: 1 }}
              onChange={(_, date) => onChangeDate('end', _, date)}
              value={moment(endDate)}
              disabledDate={disabledDate}
              allowClear={false}
            />
          </div>
          <Button
            style={{
              height: 40,
              borderColor: '#c1c1c1',
              borderRadius: 5,
              marginRight: 10,
            }}
            onClick={() => setSearchBtn(!searchBtn)}>
            검색
          </Button>
        </div>
      </div>
      <div style={{ flexGrow: 1 }}>
        {type === 'fr' && (
          <AgencyTaxReturnFr
            date={{ startDate, endDate }}
            loginInfo={loginInfo}
            searchBtn={searchBtn}
          />
        )}
        {type === 'rider' && (
          <AgencyTaxReturnRider
            date={{ startDate, endDate }}
            loginInfo={loginInfo}
            searchBtn={searchBtn}
          />
        )}
        {type === 'shareCall' && (
          <AgencyTaxReturnShareCall
            date={{ startDate, endDate }}
            loginInfo={loginInfo}
            searchBtn={searchBtn}
          />
        )}
      </div>
      {/*<div className={"ncl-ctg-box"}>*/}
      {/*  <div className={'ncl-select-box'}>*/}
      {/*    <Select*/}
      {/*      defaultValue={'대리점 전체'}*/}
      {/*      style={{*/}
      {/*        flex: 1,*/}
      {/*      }}*/}
      {/*      onChange={(value) => {*/}
      {/*        setList([]);*/}
      {/*        setBelongBranch(value);*/}
      {/*        setPagination({*/}
      {/*          ...pagination,*/}
      {/*          current: 1,*/}
      {/*        });*/}
      {/*      }}>*/}
      {/*      {branches.map((value) => {*/}
      {/*        return (*/}
      {/*          <Option key={value.branchName} value={value.companyId}>*/}
      {/*            {value.branchName}*/}
      {/*          </Option>*/}
      {/*        );*/}
      {/*      })}*/}
      {/*    </Select>*/}
      {/*  </div>*/}
      {/*  <div className={'ncl-input-box'} style={{ width: 240 }}>*/}
      {/*    <Input*/}
      {/*      onPressEnter={() => getData(true)}*/}
      {/*      onChange={(e) => setSearch(e.target.value)}*/}
      {/*      placeholder={'사업자번호/가맹점명'}*/}
      {/*      style={{*/}
      {/*        border: 'none',*/}
      {/*        paddingLeft: 10,*/}
      {/*        width: 200,*/}
      {/*      }}*/}
      {/*    />*/}
      {/*    <SearchOutlined*/}
      {/*      style={{ padding: '0 10px', cursor: 'pointer' }}*/}
      {/*      onClick={() => getData(true)}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className={'ncl-table'}>*/}
      {/*  <Table*/}
      {/*    rowKey={(record) => record.idx}*/}
      {/*    dataSource={list}*/}
      {/*    columns={columns}*/}
      {/*    pagination={pagination}*/}
      {/*    loading={isLoading}*/}
      {/*    rowClassName={(record, index) => index % 2 !== 0 && 'ncl-table-row'}*/}
      {/*    onChange={(pagination) => setPagination(pagination)}*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
};
export default AgencyTaxReturn;
