import { DatePicker, Table } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import moment from 'moment';
import { comma } from '../../lib/util/numberUtil';
import FrTotalDialog from '../../components/dialog/frachise/FrTotalDialog';

const RangePicker = DatePicker.RangePicker;
const dateFormat = 'YYYY/MM';

class StaticsDues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      list: [],
      frTotal: null,
      riderTotal: null,
      connectTotal: null,
      frTotalDialogOpen: false,
      selectedRow: null,
    };
  }

  componentDidMount() {
    this.getList();
  }

  openFrTotalDialog = (row) => {
    this.setState({ selectedRow: row, frTotalDialogOpen: true });
  };
  closeFrTotalDialog = () => {
    this.setState({ selectedRow: null, frTotalDialogOpen: false });
  };

  getList = () => {
    const pageNum = this.state.pagination.current;
    const pageSize = this.state.pagination.pageSize;
    const startDate = this.state.startDate;
    const endDate = moment(this.state.endDate).format('YYYY-MM-DD 23:59:59');
    httpGet(
      httpUrl.staticsDues,
      [pageNum, pageSize, startDate, endDate],
      {}
    ).then((res) => {
      if (res.result === 'SUCCESS') {
        // console.log("1111111111111111111111 " + JSON.stringify(res.data))
        console.log(
          'DDDDDDDDDDDDDDDDDDDDDD ' + JSON.stringify(res.data.duesPayStatistics)
        );
        this.setState({
          list: res.data.duesPayStatistics,
          pagination: {
            ...this.state.pagination,
            total: res.data.totalCount,
            current: res.data.currentPage,
          },
        });
      }
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      }
      // () => this.getList()
    );
  };

  onChangeDate = (_, dateString) => {
    console.log('eeeeeeeeee ' + dateString);
    this.setState(
      {
        startDate:
          dateString != null
            ? moment(dateString[0]).format('YYYY-MM-DD')
            : new moment().format('YYYY-MM-DD'),
        endDate:
          dateString != null
            ? moment(dateString[1]).format('YYYY-MM-DD')
            : new moment().format('YYYY-MM-DD'),
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => this.getList()
    );
  };

  render() {
    const columns = [
      {
        title: '일시',
        dataIndex: 'createDate',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{moment(data).format('YYYY-MM-DD')}</div>,
      },
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
        width: 100,
        render: (data, row) => (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              this.openFrTotalDialog(row);
            }}>
            {data}
          </div>
        ),
      },
      {
        title: '예외상점 수',
        dataIndex: 'frApproval',
        className: 'table-column-center',
        width: 100,
      },
      {
        title: '백분율',
        className: 'table-column-center',
        width: 100,
        render: (row) => (
          <div>{((row.frApproval / row.frTotal) * 100).toFixed(2)} %</div>
        ),
      },
      {
        title: '1개월뒤',
        className: 'table-column-center',
        width: 100,
        children: [
          {
            title: '예외상점 수',
            dataIndex: 'frApprovalOneMonth',
            width: 100,
          },
          {
            title: '백분율',
            width: 100,
            render: (row) => (
              <div>
                {((row.frApprovalOneMonth / row.frTotal) * 100).toFixed(2)} %
              </div>
            ),
          },
        ],
      },
      {
        title: '2개월뒤',
        className: 'table-column-center',
        width: 100,
        children: [
          {
            title: '예외상점 수',
            dataIndex: 'frApprovalTwoMonth',
            width: 100,
          },
          {
            title: '백분율',
            width: 100,
            render: (row) => (
              <div>
                {((row.frApprovalTwoMonth / row.frTotal) * 100).toFixed(2)} %
              </div>
            ),
          },
        ],
      },
      {
        title: '3개월뒤',
        className: 'table-column-center',
        width: 100,
        children: [
          {
            title: '예외상점 수',
            dataIndex: 'frApprovalThreeMonth',
            width: 100,
          },
          {
            title: '백분율',
            width: 100,
            render: (row) => (
              <div>
                {((row.frApprovalThreeMonth / row.frTotal) * 100).toFixed(2)} %
              </div>
            ),
          },
        ],
      },
    ];
    return (
      <>
        {this.state.frTotalDialogOpen && (
          <FrTotalDialog
            close={this.closeFrTotalDialog}
            data={this.state.selectedRow}
          />
        )}

        <RangePicker
          style={{ width: 300, float: 'right', marginBottom: 20 }}
          placeholder={['시작일', '종료일']}
          value={[
            moment(this.state.startDate, 'YYYY-MM-DD'),
            moment(this.state.endDate, 'YYYY-MM-DD'),
          ]}
          onChange={(_, dateStrings) => {
            if (dateStrings[(0, 1)]) {
              this.setState(
                {
                  startDate: dateStrings[0],
                  endDate: dateStrings[1],
                  pagination: {
                    current: 1,
                    pageSize: 10,
                  },
                },
                () => {
                  this.getList();
                }
              );
            } else {
              this.setState(
                {
                  startDate: '',
                  endDate: '',
                  pagination: {
                    current: 1,
                    pageSize: 10,
                  },
                },
                () => {
                  this.getList();
                }
              );
            }
          }}
        />

        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          bordered
          className="staticsDues-table"
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

export default StaticsDues;
