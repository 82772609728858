import moment from 'moment';
import { comma } from './numberUtil';
import React from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from '../../const';
const allBranchList = () => {
  const agencyBranch = reactLocalStorage.getObject(
    con.appName + '#agencyBranch'
  );
  const loginInfo = reactLocalStorage.getObject(con.appName + '#adminUser');
  let all = {
    idx: 0,
    branchName: '대리점 전체',
    companyId: loginInfo.branch.companyId,
  };
  let arr = [];
  if (Object.keys(agencyBranch).length !== 0) {
    arr = [...agencyBranch];
  }
  arr.unshift(all);
  return arr;
};

const agencySelfList = () => {
  const agencyBranch = reactLocalStorage.getObject(
    con.appName + '#agencyBranch'
  );
  const loginInfo = reactLocalStorage.getObject(con.appName + '#adminUser');
  let all = {
    idx: loginInfo.branch.idx,
    branchName: loginInfo.branch.branchName,
    companyId: loginInfo.branch.companyId,
  };
  let arr = [];
  if (Object.keys(agencyBranch).length !== 0) {
    arr = [...agencyBranch];
  }
  arr.unshift(all);
  return arr;
};
const userTypeString = {
  RIDER: '라이더',
  FRANCHISE: '가맹점',
  AGENCY: '대리점',
  AGENCY_HEADQUARTER: '제휴본사',
  HEADQUARTER: '본사',
};

const agencyNcashAllForAgency = [
  {
    title: '일시',
    dataIndex: 'createDate',
    className: 'table-column-center',
    render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: '금액',
    dataIndex: 'total',
    className: 'table-column-center',
    render: (data, row) => (
      <div
        style={{
          textAlign: 'right',
          paddingRight: 10,
          color: data < 0 ? '#f00000' : '#1890ff',
          fontWeight: 'bold',
        }}>
        {comma(data)}
      </div>
    ),
  },
  {
    title: '잔액',
    dataIndex: 'afterNcash',
    className: 'table-column-center',
    render: (data) => (
      <div style={{ textAlign: 'right', paddingRight: 10 }}>{comma(data)}</div>
    ),
  },
  {
    title: '구분',
    dataIndex: 'categoryName',
    className: 'table-column-center',
  },
  {
    title: '대상지사',
    dataIndex: 'targetBranchName',
    className: 'table-column-center',
  },
  {
    title: '대상명',
    dataIndex: 'targetName',
    className: 'table-column-center',
  },
  {
    title: '사업자번호',
    dataIndex: 'businessNum',
    className: 'table-column-center',
  },
  {
    title: '주문번호',
    dataIndex: 'orderIdx',
    className: 'table-column-center',
    render: (data) => data > 0 && data,
  },
  {
    title: '비고',
    dataIndex: 'memo',
    className: 'table-column-center',
    render: (data, row) => row.category === 'NCASH_SEND_BY_ADMIN' ? row.adminId : data,
  },
];

const agencyNcashAllForBranch = [
  {
    title: '일시',
    dataIndex: 'createDate',
    className: 'table-column-center',
    render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: '주문번호',
    dataIndex: 'orderIdx',
    className: 'table-column-center',
    render: (data, row) => (row.category !== 'NCASH_SEND_BY_ADMIN' &&  row.category !== 'BATCH_WORK_NCASH') && data,
  },
  {
    title: '지급자명',
    dataIndex: 'payoutName',
    className: 'table-column-center',
  },
  // {
  //   title: '사업자번호',
  //   dataIndex: 'businessNum',
  //   className: 'table-column-center',
  // },
  // {
  //   title: '라이더 소속 대리점',
  //   dataIndex: 'riderBranch',
  //   className: 'table-column-center',
  // },
  {
    title: '구분',
    dataIndex: 'targetUserTypeString',
    className: 'table-column-center',
    render: (data) => userTypeString[data],
  },
  {
    title: '대상명',
    dataIndex: 'targetName',
    className: 'table-column-center',
  },
  {
    title: '사업자번호',
    dataIndex: 'businessNum',
    className: 'table-column-center',
  },
  {
    title: '카테고리',
    dataIndex: 'categoryName',
    className: 'table-column-center',
  },
  {
    title: '금액',
    dataIndex: 'total',
    className: 'table-column-center',
    render: (data, row) => (
      <div
        style={{
          textAlign: 'right',
          paddingRight: 10,
          color: data < 0 ? '#f00000' : '#1890ff',
          fontWeight: 'bold',
        }}>
        {comma(data)}
      </div>
    ),
  },
  {
    title: '예치금 잔액',
    dataIndex: 'afterNcash',
    className: 'table-column-center',
    render: (data) => (
      <div style={{ textAlign: 'right', paddingRight: 10 }}>{comma(data)}</div>
    ),
  },
  {
    title: '메모',
    dataIndex: 'memo',
    className: 'table-column-center',
  },
];
const selectCategoryForAgency = [
  { label: '전체', category: '', kind: '' },
  { label: '제휴본사 수수료', category: 'DELIVERY_PRICE_RECEIVE', kind: '' },
  { label: '프로그램 사용료', category: 'AGENCY_FEE', kind: '' },
  { label: '배달비 부가세', category: 'DELIVERY_VAT', kind: '' },
  { label: '가맹비 부가세', category: 'DUES_VAT', kind: '' },
  { label: '선충전 회수', category: 'BATCH_WORK_NCASH', kind: 22 },
  { label: '렌트비 회수', category: 'BATCH_WORK_NCASH', kind: 10 },
  { label: '대여금 회수', category: 'BATCH_WORK_NCASH', kind: 6 },
  { label: '기타', category: 'BATCH_WORK_NCASH', kind: 20 },
  { label: '선충전 지급', category: 'NCASH_SEND_BY_ADMIN', kind: 1 },
  { label: '대여금 지급', category: 'NCASH_SEND_BY_ADMIN', kind: 7 },
  { label: '프로모션 지급', category: 'NCASH_SEND_BY_ADMIN', kind: 4 },
  { label: '기타 비용', category: 'NCASH_SEND_BY_ADMIN', kind: 0 },
  { label: '예치금 입금', category: 'NCASH_DEPOSIT', kind: '' },
  { label: '예치금 출금', category: 'NCASH_WITHDRAW', kind: '' },
];
const selectCategoryForBranch = [
  { label: '전체', category: '', kind: '' },
  { label: '배달 수수료', category: 'DELIVERY_PRICE_RECEIVE', kind: '' },
  { label: '배달 지원금', category: 'DELIVERY_SUBSIDY_SEND', kind: '' },
  { label: '콜수수료', category: 'CALL_FEE', kind: '' },
  { label: '프로그램 사용료', category: 'AGENCY_FEE', kind: '' },
  { label: '렌트비 회수', category: 'BATCH_WORK_NCASH', kind: 10 },
  { label: '과태료 회수', category: 'BATCH_WORK_NCASH', kind: 17 },
  { label: '수리비 회수', category: 'BATCH_WORK_NCASH', kind: 11 },
  { label: '대여금 회수', category: 'BATCH_WORK_NCASH', kind: 6 },
  { label: '선충전 회수', category: 'BATCH_WORK_NCASH', kind: 22 },
  { label: '음식보험 회수', category: 'BATCH_WORK_NCASH', kind: 21 },
  { label: '가맹비 회수', category: 'BATCH_WORK_NCASH', kind: 19 },
  { label: '기타', category: 'BATCH_WORK_NCASH', kind: 20 },
  { label: '렌트비 납부', category: 'NCASH_SEND_BY_ADMIN', kind: 14 },
  { label: '과태료 납부', category: 'NCASH_SEND_BY_ADMIN', kind: 24 },
  { label: '수리비 납부', category: 'NCASH_SEND_BY_ADMIN', kind: 11 },
  { label: '프로모션 지급', category: 'NCASH_SEND_BY_ADMIN', kind: 4 },
  { label: '대여금 지급', category: 'NCASH_SEND_BY_ADMIN', kind: 7 },
  { label: '선충전 지급', category: 'NCASH_SEND_BY_ADMIN', kind: 1 },
  { label: '음식배상', category: 'NCASH_SEND_BY_ADMIN', kind: 2 },
  { label: '기타 비용', category: 'NCASH_SEND_BY_ADMIN', kind: 0 },
];
const selectCategoryForBranchForRider = [
  { label: '전체', category: 'All', kind: '' },
  { label: '렌트비 회수', category: 'BATCH_WORK_NCASH', kind: 10 },
  { label: '과태료 회수', category: 'BATCH_WORK_NCASH', kind: 17 },
  { label: '수리비 회수', category: 'BATCH_WORK_NCASH', kind: 11 },
  { label: '대여금 회수', category: 'BATCH_WORK_NCASH', kind: 6 },
  { label: '음식보험 회수', category: 'BATCH_WORK_NCASH', kind: 21 },
  { label: '기타', category: 'BATCH_WORK_NCASH', kind: 20 },
  { label: '렌트비 납부', category: 'NCASH_SEND_BY_ADMIN', kind: 14 },
  { label: '과태료 납부', category: 'NCASH_SEND_BY_ADMIN', kind: 24 },
  { label: '수리비 납부', category: 'NCASH_SEND_BY_ADMIN', kind: 11 },
  { label: '대여금 지급', category: 'NCASH_SEND_BY_ADMIN', kind: 7 },
  { label: '기타 비용', category: 'NCASH_SEND_BY_ADMIN', kind: 0 },
];
const selectCategoryForBranchForFr = [
  { label: '전체', category: 'All', kind: '' },
  { label: '선충전 회수', category: 'BATCH_WORK_NCASH', kind: 22 },
  { label: '가맹비 회수', category: 'BATCH_WORK_NCASH', kind: 19 },
  { label: '기타', category: 'BATCH_WORK_NCASH', kind: 20 },
  { label: '프로모션 지급', category: 'NCASH_SEND_BY_ADMIN', kind: 4 },
  { label: '선충전 지급', category: 'NCASH_SEND_BY_ADMIN', kind: 1 },
  { label: '음식배상', category: 'NCASH_SEND_BY_ADMIN', kind: 2 },
  { label: '기타 비용', category: 'NCASH_SEND_BY_ADMIN', kind: 0 },
];
const agencyNcashDeliveryColumns = [
  {
    title: '콜 구분',
    dataIndex: 'callType',
    className: 'table-column-center',
  },
  {
    title: '배달상태',
    dataIndex: 'deliveryStatus',
    className: 'table-column-center',
  },
  {
    title: '접수일시',
    dataIndex: 'orderDate',
    className: 'table-column-center',
    render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: '완료일시',
    dataIndex: 'completeDate',
    className: 'table-column-center',
    render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: '주문번호',
    dataIndex: 'orderIdx',
    className: 'table-column-center',
  },
  {
    title: '가맹점 소속 대리점(A)',
    dataIndex: 'frBranch',
    className: 'table-column-center',
  },
  {
    title: '실가맹점명',
    dataIndex: 'frNameExtra',
    className: 'table-column-center',
  },
  {
    title: '사업자번호',
    dataIndex: 'businessNum',
    className: 'table-column-center',
  },
  {
    title: '대표자명',
    dataIndex: 'ownerName',
    className: 'table-column-center',
  },
  {
    title: '라이더 소속 대리점(B)',
    dataIndex: 'riderBranch',
    className: 'table-column-center',
  },
  {
    title: '라이더 실명',
    dataIndex: 'exactRiderName',
    className: 'table-column-center',
  },
  {
    title: '직급',
    dataIndex: 'riderLevel',
    className: 'table-column-center',
  },
  {
    title: '이메일',
    dataIndex: 'riderEmail',
    className: 'table-column-center',
  },
  {
    title: '주민번호',
    dataIndex: 'riderRegistNum',
    className: 'table-column-center',
  },
  {
    title: '연락처',
    dataIndex: 'riderPhoneNum',
    className: 'table-column-center',
  },
  {
    title: '도착지',
    dataIndex: 'addr',
    className: 'table-column-center',
  },
  {
    title: '금액',
    dataIndex: 'orderPrice',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '기본배달비(A)',
    dataIndex: 'basicDeliveryPrice',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '할증배달비(A)',
    dataIndex: 'extraDeliveryPrice',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '총 배달비(A)',
    dataIndex: 'deliveryPrice',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '총 배달비 부가세(A)',
    dataIndex: 'deliveryPriceVat',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '총 배달비 수수료(A+B)',
    dataIndex: 'riderFee',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '공유콜 수수료(A)',
    dataIndex: 'shareCallFeeFrBranch',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '공유콜 수수료 부가세(A)',
    dataIndex: 'shareCallFeeVatFrBranch',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '공유콜 수수료(B)',
    dataIndex: 'shareCallFeeRiderBranch',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '공유콜 수수료 부가세(B)',
    dataIndex: 'shareCallFeeVatRiderBranch',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '공유콜 배달비(B)',
    dataIndex: 'shareCallPrice',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '공유콜 배달비 매입 부가세(A)',
    dataIndex: 'shareCallPriceBuyingVat',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '공유콜 배달비 매출 부가세(B)',
    dataIndex: 'shareCallPriceSalesVat',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '건별 가맹비(A)',
    dataIndex: 'duesDeliveryPrice',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '건별 가맹비 부가세(A)',
    dataIndex: 'duesDeliveryPriceVat',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '제휴본사 수수료(A)',
    dataIndex: 'agencyHeadquarterFee',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '건별 수수료(A)',
    dataIndex: 'deliveryFeeByDpBasic',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '할증 건별 수수료(A)',
    dataIndex: 'deliveryFeeByDpRegion',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '콜수수료(A)',
    dataIndex: 'callFee',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '콜수수료 부가세(A)',
    dataIndex: 'callFeeVat',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '프로그램 사용료(A)',
    dataIndex: 'agencyFee',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '프로그램 사용료 매입 부가세(A)',
    dataIndex: 'agencyFeeVat',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '고용(대리점)(B)',
    dataIndex: 'employInsurance',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '산재(대리점)(B)',
    dataIndex: 'accidentInsurance',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '취소패널티(B)',
    dataIndex: 'cancelPenalty',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '라이더수익(B)',
    dataIndex: 'riderIncome',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '라이더원천세(B)',
    dataIndex: 'riderTax',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
];
const agencyOrderSettlementFrColumns = [
  {
    title: '일시',
    dataIndex: 'createDate',
    className: 'table-column-center',
    render: (data) => moment(data).format('YYYY-MM-DD'),
  },
  {
    title: '대리점명',
    dataIndex: 'branchName',
    className: 'table-column-center',
  },
  {
    title: '가맹점명',
    dataIndex: 'frNameExtra',
    className: 'table-column-center',
  },
  {
    title: '총 주문건 수',
    dataIndex: 'totalOrderCount',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '배달 완료 수',
    dataIndex: 'deliveryCompletedCount',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '배달 취소 수',
    dataIndex: 'canceledOrderCount',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
];
const agencyOrderSettlementRiderColumns = [
  {
    title: '일시',
    dataIndex: 'createDate',
    className: 'table-column-center',
    render: (data) => moment(data).format('YYYY-MM-DD'),
  },
  {
    title: '대리점명',
    dataIndex: 'branchName',
    className: 'table-column-center',
  },
  {
    title: '라이더명',
    dataIndex: 'riderNameReal',
    className: 'table-column-center',
  },
  {
    title: '배달 완료 수',
    dataIndex: 'deliveryCompletedCount',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '라이더 수익',
    dataIndex: 'riderRevenues',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
];
const agencyTaxReturn = [
  {
    title: '일시',
    dataIndex: 'createDate',
    className: 'table-column-center',
    render: (data) => moment(data).format('YYYY-MM-DD'),
  },
  {
    title: '대리점명',
    dataIndex: 'branchName',
    className: 'table-column-center',
  },
  {
    title: '가맹점명',
    dataIndex: 'frNameExtra',
    className: 'table-column-center',
    render: (data, row) => data ? data: row.frName,
  },
  {
    title: '사업자번호',
    dataIndex: 'businessNumber',
    className: 'table-column-center',
  },
];
const agencyTaxReturnForDeliveryColumns = [
  ...agencyTaxReturn,
  {
    title: '완료건수',
    dataIndex: 'deliveryCount',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '총 배달비',
    dataIndex: 'deliveryPrice',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '부가세',
    dataIndex: 'deliveryPriceVat',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
];
const agencyTaxReturnForDuesColumns = [
  ...agencyTaxReturn,
  {
    title: '완료건수',
    dataIndex: 'duesCount',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '총 원가맹비',
    dataIndex: 'actualCollectAmount',
    className: 'table-column-center',
    render: (data, row) => comma(data + row.duesVat),
  },
  {
    title: '총 가맹비',
    dataIndex: 'actualCollectAmount',
    className: 'table-column-center',
    render: (data) => comma(data),
  },
  {
    title: '부가세',
    dataIndex: 'duesVat',
    className: 'table-column-center',
    render: (data) => data === 0 ? '간이사업자' : comma(data),
  },
];
export {
  agencyNcashAllForAgency,
  agencyNcashAllForBranch,
  selectCategoryForAgency,
  selectCategoryForBranch,
  agencyNcashDeliveryColumns,
  allBranchList,
  agencySelfList,
  agencyOrderSettlementRiderColumns,
  agencyOrderSettlementFrColumns,
  agencyTaxReturnForDeliveryColumns,
  agencyTaxReturnForDuesColumns,
  selectCategoryForBranchForRider,
  selectCategoryForBranchForFr,
};
