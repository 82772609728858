import { Button, Input, Modal, Table } from 'antd';
import { default as React, useEffect, useState } from 'react';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { withdrawStatus } from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';
import moment from 'moment/moment';

const WithdrawManage = (props) => {
  const Search = Input.Search;

  const statusStyles = [
    { color: 'black' },
    { color: 'blue' },
    { color: 'red' },
  ];

  const [list, setList] = useState([]);
  const [branchName, setBranchName] = useState('');
  const [riderName, setRiderName] = useState('');

  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });

  useEffect(async () => {
    // current나 pageSize가 변경될 때마다 getList호출
    await getList();
  }, [pagination.current, pagination.pageSize]);

  useEffect(async () => {
    // branchName이 변경될 때마다 getList를 호출
    await getList();
  }, [branchName]);

  useEffect(async () => {
    // riderName이 변경될 때마다 getList를 호출
    await getList();
  }, [riderName]);

  const columns = [
    {
      title: '승인번호',
      dataIndex: 'idx',
      className: 'table-column-center',
    },
    {
      title: '지점명',
      dataIndex: 'branchName',
      className: 'table-column-center',
    },
    {
      title: '기사명',
      dataIndex: 'riderName',
      className: 'table-column-center',
    },
    {
      title: '출금계좌',
      dataIndex: 'bank',
      className: 'table-column-center',
      render: (data, row) => {
        const [bank, code] = data.split(',');
        return `${bank} / ${row.bankAccount}`;
      },
    },
    {
      title: '출금자명',
      dataIndex: 'depositor',
      className: 'table-column-center',
    },
    {
      title: '신청날짜',
      dataIndex: 'reqDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '출금신청액',
      dataIndex: 'reqAmount',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '승인상태',
      dataIndex: 'withdrawStatus',
      className: 'table-column-center',
      render: (data) => (
        <div style={{ fontWeight: 'bold', ...statusStyles[data] }}>
          {withdrawStatus[data]}
        </div>
      ),
    },
    {
      title: '출금처리',
      dataIndex: 'withdrawStatus',
      className: 'table-column-center',
      render: (data, row) =>
        data === 1 ? (
          <Button onClick={() => onClickApprove(row.idx)}>출금실패처리</Button>
        ) : (
          '-'
        ),
    },
  ];

  const getList = async () => {
    const res = await httpGet(httpUrl.getListFromWithdrawTable, [
      pagination.current,
      pagination.pageSize,
      branchName,
      riderName,
    ]);

    if (res.result !== 'SUCCESS') {
      Modal.warn({
        title: '조회실패',
        content: '출금관리내역 조회에 실패했습니다.',
      });
      return;
    }

    setList(res.data.list);
    setPagination({
      ...pagination,
      total: res.data.totalCount,
    });
  };

  const onClickApprove = (withdrawIdx) => {
    Modal.confirm({
      title: '실패처리',
      content: '해당 출금내역을 실패처리하시겠습니까?',
      onOk: () => updateWithdrawStatusToFail(withdrawIdx),
      onCancel: () => {},
    });
  };

  const updateWithdrawStatusToFail = async (withdrawIdx) => {
    try {
      const res = await httpPost(httpUrl.updateWithdrawStatusToFail, [], {
        idx: withdrawIdx,
      });

      if (res.result === 'SUCCESS' && res.data) {
        await getList();
        Modal.info({
          title: '출금내역 실패처리성공',
          content: '출금내역 실패처리에 성공했습니다.',
        });
      } else {
        Modal.warn({
          title: '출금내역 실패처리 실패',
          content: '출금처리에 실패했습니다. 관리자에게 문의하세요.',
        });
      }
    } catch (e) {
      Modal.warn({
        title: '출금내역 실패처리 실패',
        content: '출금처리에 실패했습니다. 관리자에게 문의하세요.',
      });
    }
  };

  const onSearchBranch = async (value) => {
    setBranchName(value);
    setPagination({
      total: 0,
      current: 1,
      pageSize: 10,
    });
  };

  const onSearchRider = async (value) => {
    setRiderName(value);
    setPagination({
      total: 0,
      current: 1,
      pageSize: 10,
    });
  };

  return (
    <>
      <Search
        placeholder="지점명 검색"
        enterButton
        allowClear
        onSearch={onSearchBranch}
        style={{
          width: 200,
          marginBottom: 20,
        }}
      />

      <Search
        placeholder="기사명 검색"
        enterButton
        allowClear
        onSearch={onSearchRider}
        style={{
          width: 200,
          marginBottom: 20,
          marginLeft: 10,
        }}
      />

      <Table
        rowKey={(record) => record.idx}
        dataSource={list}
        columns={columns}
        pagination={pagination}
        onChange={(pagination) => setPagination(pagination)}
      />
    </>
  );
};

export default WithdrawManage;
