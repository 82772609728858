import { Button, Input, Modal, Table } from 'antd';
import { default as React, useEffect, useState } from 'react';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { comma } from '../../lib/util/numberUtil';
import BranchOfRiderFixedCommissionDialog from "../../components/dialog/branch/BranchOfRiderFixedCommissionDialog";

const BranchOfRiderFixedCommissionFee = (props) => {
    const Search = Input.Search;

    const [list, setList] = useState([]);
    const [branchName, setBranchName] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    });

    useEffect(async () => {
        // current나 pageSize가 변경될 때마다 getList호출
        await getList();
    }, [pagination.current, pagination.pageSize]);

    useEffect(async () => {
        // branchName이 변경될 때마다 getList를 호출
        await getList();
    }, [branchName]);

    const columns = [
        {
            title: '설정지점명',
            dataIndex: 'branchName',
            className: 'table-column-center',
            render: (data, row) => row.branchName
        },
        {
            title: '대상지점명',
            dataIndex: 'targetBranchName',
            className: 'table-column-center',
            render: (data, row) => row.targetBranchName
        },
        {
            title: '고정수수료',
            dataIndex: 'targetCommission',
            className: 'table-column-center',
            render: (data) => comma(data),
        },
        {
            className: 'table-column-center',
            render: (data, row) =>
                <>
                    <Button onClick={() => updateData(row)}>수정</Button>
                    <Button onClick={() => deleteData(row)}>삭제</Button>
                </>,
        },
    ];

    const getList = async () => {
        const res = await httpGet(httpUrl.getBranchOrderShareCommission, [
            pagination.current,
            pagination.pageSize,
            branchName,
        ]);

        if (res.result !== 'SUCCESS') {
            Modal.warn({
                title: '조회실패',
                content: '라이더지점 고정수수료 내역 조회에 실패했습니다.',
            });
            return;
        }

        setList(res.data.list);
        setPagination({
            ...pagination,
            total: res.data.totalCount,
        });
    };

    const onSearchBranch = async (value) => {
        setBranchName(value);
        setPagination({
            total: 0,
            current: 1,
            pageSize: 10,
        });
    };

    const deleteData = (row) => {
        Modal.confirm({
            title: '라이더지점 고정수수료 삭제',
            content: '해당 데이터를 삭제하시겠습니까?',
            onOk: () => deleteBranchOrderShareCommission(row),
            onCancel: () => {},
        });
    };

    const deleteBranchOrderShareCommission = async (row) => {
        try {
            const res = await httpPost(httpUrl.deleteBranchOrderShareCommission, [], {
                branchIdx: row.branchIdx,
                targetBranchIdx: row.targetBranchIdx,
            });

            if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
                await getList();
                Modal.info({
                    title: '삭제 성공',
                    content: '라이더지점 고정수수료 삭제에 성공했습니다.',
                });
            } else if (res.data === 'NO_DATA') {
                Modal.warn({
                    title: '삭제 실패',
                    content: '삭제할 데이터가 없어서 삭제에 실패했습니다. 관리자에게 문의하세요.',
                });
            } else {
                Modal.warn({
                    title: '삭제 실패',
                    content: '라이더지점 고정수수료 삭제에 실패했습니다. 관리자에게 문의하세요.',
                });
            }
        } catch (e) {
            Modal.warn({
                title: '삭제 실패',
                content: '라이더지점 고정수수료 삭제에 실패했습니다. 관리자에게 문의하세요.',
            });
        }
    };

    const createData = () => {
        setModalOpen(true);
    };

    const updateData = (row) => {
        setModalOpen(true);
        setSelectedData(row);
    }

    const closeModal = () => {
        setModalOpen(false);
        setSelectedData(null);
    }

    return (
        <>
            <Search
                placeholder="설정지점명 검색"
                enterButton
                allowClear
                onSearch={onSearchBranch}
                style={{
                    width: 200,
                    marginBottom: 20,
                }}
            />

            {modalOpen && (
                <BranchOfRiderFixedCommissionDialog
                    data={selectedData}
                    close={closeModal}
                    getList={getList}
                />
            )}

            <Button
                onClick={() => createData()}
                style={{ marginLeft: 20 }}>
                라이더지점 고정수수료 등록
            </Button>

            <Table
                rowKey={(record) => record.idx}
                dataSource={list}
                columns={columns}
                pagination={pagination}
                onChange={(pagination) => setPagination(pagination)}
            />
        </>
    );
};

export default BranchOfRiderFixedCommissionFee;
