import { Button, Input, Table, Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { httpGet, httpUrl, httpPost } from '../../api/httpClient';
import { customError } from '../../api/Modals';
import AdminMenuAuthDialog from '../../components/dialog/AdminMenuAuthDialog';
import RegistAdminDialog from '../../components/dialog/RegistAdminDialog';
import '../../css/main.css';

const Search = Input.Search;

class OperatorManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      adminId: '',
      systemKeyDialogOpen: false, //메뉴권한설정
      registAdminUserModalOpen: false, //유저등록
      modifyAdminUserModalOpen: false, //정보변경
      rowUserList: [],
    };
    this.columns = [
      {
        title: '지점',
        dataIndex: 'branchName',
        className: 'table-column-center',
        render: (data, row) => <div>{data}</div>,
      },
      {
        title: '아이디',
        dataIndex: 'id',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '핸드폰',
        dataIndex: 'phone',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '이메일',
        dataIndex: 'email',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '메뉴권한',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn"
              disabled={this.props.adminType === 1 ? false : true}
              onClick={() => {
                this.openSystemKeyDialogModal(row);
              }}>
              메뉴권한 설정
            </Button>
          </div>
        ),
      },
      {
        title: '정보수정',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn"
              disabled={this.props.adminType === 1 ? false : true}
              onClick={() => {
                this.openModifyAmdinUserModal(row);
              }}>
              정보 변경
            </Button>
          </div>
        ),
      },
      {
        title: 'OTP 리셋',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn"
              disabled={this.props.adminType === 1 ? false : true}
              onClick={() => {
                this.updateOtpReset(row);
              }}>
              OTP 리셋
            </Button>
          </div>
        ),
      },
    ];
    this.systemKeyDialogData = null;
  }

  componentDidMount() {
    this.getList();
  }

  // 리스트 조회 api
  getList = () => {
    const pagination = this.state.pagination;
    let branchIdx = this.props.loginInfo.branchIdx;
    httpGet(
      httpUrl.adminUserList,
      [branchIdx, this.state.adminId, pagination.current, pagination.pageSize],
      {}
    )
      .then((res) => {
        console.log(res);
        if (res.result === 'SUCCESS') {
          this.setState({
            list: res.data.adminUsers,
            pagination: {
              ...this.state.pagination,
              current: res.data.currentPage,
              total: res.data.totalCount,
            },
          });
        }
      })
      .catch((e) => {
        customError('목록 에러', '에러가 발생하여 목록을 불러올수 없습니다.');
      });
  };

  // otp리셋
  updateOtpReset = (row) => {
    console.log(row.idx);
    httpPost(httpUrl.otpReset, [], {
      idx: row.idx,
    }).then((res) => {
      console.log(res);
      if (res.data === 'SUCCESS') {
        Modal.info({
          title: 'otp 리셋 성공',
          content: `${row.id} 님의 otp 리셋을 성공했습니다.`,
        });
      } else {
        Modal.warn({
          title: 'otp 리셋 실패',
          content: `${row.id} 님의 otp 리셋을 실패했습니다.`,
        });
      }
    });
  };

  // 페이징
  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  // 메뉴권한 설정 modal
  openSystemKeyDialogModal = (row) => {
    this.systemKeyDialogData = row;
    this.setState({ systemKeyDialogOpen: true });
  };
  closeSystemKeyDialogModal = () => {
    this.systemKeyDialogData = null;
    this.setState({ systemKeyDialogOpen: false });
  };
  // 어드민(운영자) 등록 modal
  openCreateAmdinUserModal = (row) => {
    this.setState({ registAdminUserModalOpen: true });
  };
  closeCreateAmdinUserModal = () => {
    this.setState({ registAdminUserModalOpen: false }, () => {
      this.getList();
    });
  };
  // 정보 변경 modal
  openModifyAmdinUserModal = (row) => {
    this.setState({ modifyAdminUserModalOpen: true, rowUserList: row });
  };
  closeModifyAmdinUserModal = () => {
    this.setState({ modifyAdminUserModalOpen: false }, () => {
      this.getList();
    });
  };

  // 운영자 검색
  onSearchId = (value) => {
    this.setState(
      {
        adminId: value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  render() {
    return (
      <>
        {/* 유저메뉴권한모달 */}
        {/* {this.state.systemKeyDialogOpen && (
          <SystemKeyDialog
            data={this.systemKeyDialogData}
            close={this.closeSystemKeyDialogModal}
          />
        )} */}
        {this.state.systemKeyDialogOpen && (
          <AdminMenuAuthDialog
            data={this.systemKeyDialogData}
            close={this.closeSystemKeyDialogModal}
          />
        )}

        {/* 유저등록모달 */}
        {this.state.registAdminUserModalOpen && (
          <RegistAdminDialog close={this.closeCreateAmdinUserModal} />
        )}
        {/* 유저정보수정모달 */}
        {this.state.modifyAdminUserModalOpen && (
          <RegistAdminDialog
            data={this.state.rowUserList}
            close={this.closeModifyAmdinUserModal}
          />
        )}
        <Search
          placeholder="아이디 검색"
          enterButton
          allowClear
          onSearch={this.onSearchId}
          style={{
            width: 220,
            marginBottom: 20,
          }}
        />
        <Button
          className="tabBtn"
          disabled={this.props.adminType === 1 ? false : true}
          onClick={() => {
            this.openCreateAmdinUserModal();
          }}
          style={{
            marginLeft: 20,
          }}>
          운영자 등록
        </Button>
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={this.columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminType: state.login.loginInfo.adminType,
    loginInfo: state.login.loginInfo,
  };
};

export default withRouter(connect(mapStateToProps, null)(OperatorManage));
