import { Form, Input, Table, Button } from 'antd';
import React, { Component } from 'react';
import {
  httpGet,
  httpUrl,
  httpDownload,
  httpPost,
  httpPut,
} from '../../api/httpClient';

import Modal from 'antd/lib/modal/Modal';
import '../../css/main.css';
import SendBikeRepairDialog from '../../components/dialog/SendBikeRepairDialog';

const FormItem = Form.Item;
const Search = Input.Search;

class BikeRepair extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      riderName: '',
      isopenSendRepairDialog: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  onSearchRider = (value) => {
    this.setState(
      {
        riderName: value,
      },
      () => {
        this.getList();
      }
    );
  };

  getList = () => {
    const pagination = this.state.pagination;
    httpGet(
      httpUrl.getBikeRepairList,
      [pagination.current, pagination.pageSize, this.state.riderName],
      {}
    ).then((res) => {
      console.log(res);
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.bikeRepairLog,
          pagination: {
            ...this.state.pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        });
      }
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  openSendBikeRepairDialog = () => {
    this.setState({
      isopenSendRepairDialog: true,
    });
  };
  closeSendBikeRepairDialog = () => {
    this.setState({
      isopenSendRepairDialog: false,
    });
  };

  render() {
    const columns = [
      {
        title: '라이더 이름(아이디)',
        dataIndex: 'riderName',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            {data} ({row.userId})
          </div>
        ),
      },
      {
        title: '이전 금액',
        dataIndex: 'beforeNcash',
        className: 'table-column-center',
      },
      {
        title: '지급 금액',
        dataIndex: 'sendAmount',
        className: 'table-column-center',
      },
      {
        title: '잔액',
        dataIndex: 'afterNcash',
        className: 'table-column-center',
      },
      {
        title: '지급일',
        dataIndex: 'createDate',
        className: 'table-column-center',
      },
      // {
      //   title: "수정",
      //   dataIndex: "updateBike",
      //   className: "table-column-center",
      //   render: (data, row) => (
      //     <div>
      //       <Button onClick={this.openRegistBikeDialog}>수정</Button>
      //     </div>
      //   ),
      // },
    ];

    return (
      <FormItem>
        <Search
          placeholder="라이더명 검색"
          enterButton
          allowClear
          onSearch={this.onSearchRider}
          style={{
            width: 220,
            marginBottom: 20,
          }}
        />

        <Button
          onClick={this.openSendBikeRepairDialog}
          style={{ marginLeft: 20 }}>
          수리비 지급
        </Button>

        {this.state.isopenSendRepairDialog && (
          <SendBikeRepairDialog close={this.closeSendBikeRepairDialog} />
        )}

        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </FormItem>
    );
  }
}

export default BikeRepair;
