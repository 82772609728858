import React, { useEffect, useState, useRef } from 'react';
import { Table, Input, DatePicker, Button, Select, Modal } from "antd";
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ko_KR';
import axios from 'axios';
import Const from '../../const';
import FileSaver from 'file-saver';
import { httpGet, httpUrl } from '../../api/httpClient';
const today = moment().format('YYYY-MM-DD');
const BranchTaxInvoiceDownload = () => {
  const CancelToken = axios.CancelToken;
  const source = useRef(null);

  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  // const [startDate, setStartDate] = useState(
  //   moment('2024-03-01').format('YYYY-MM-DD')
  // );
  const [startDate, setStartDate] = useState(
    moment('2024-03-01').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(today);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 20,
  });
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const res = await httpGet(
      httpUrl.getBranchForTaxBook,
      [search, pagination.pageSize, pagination.current],
      {}
    );
    if (res) {
      setData(res.data.branches);
      setPagination({
        ...pagination,
        current: res.data.currentPage,
        total: res.data.totalCount,
      });
    }
  };
  const disabledDate = (current) => {
    return (
      current &&
      (current < moment('2024-03-01') ||
        current > moment(today, 'YYYY-MM-DD').endOf(today))
    );
  };
  const getDataFromMongodb = async (params, url) => {
    if (source.current !== null) {
      source.current.cancel();
    }
    source.current = CancelToken.source();
    try {
      return await axios.get(url, {
        baseURL: Const.settlementUrl,
        params: params,
        responseType: 'arraybuffer',
        cancelToken: source.current.token,
      });
    } catch (e) {
      console.error('getting tax-invoice-excel-data is failed: ' + e);
    }
  };
  const onDownloadTaxinvoice = async (row) => {
    if (isLoading) return;
    let params = {
      startDate: startDate,
      endDate: endDate,
      companyId: row.companyId,
    };
    let branchName = row.branchName;
    const url = '/tax-return/delegated/excel';
    setIsLoading(true);
    const response = await getDataFromMongodb(params, url);
    if (response) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(
        blob,
        `${branchName}_${moment(startDate).format('YYMMDD')}_${moment(
          endDate
        ).format('YYMMDD')}.xlsx`
      );
      Modal.success({
        content: '엑셀파일 다운로드가 완료되었습니다.',
      });
      setIsLoading(false);
    } else {
      Modal.error({
        content:
          '다운로드에 실패했습니다. 관리자에게 문의해주세요.',
      });
    }
    setIsLoading(false);
  };
  const columns = [
    {
      title: '신청 대리점',
      dataIndex: 'branchName',
      className: 'table-column-center',
    },
    {
      title: '대표자명',
      dataIndex: 'ownerName',
      className: 'table-column-center',
    },
    {
      title: '핸드폰번호',
      dataIndex: 'branchPhone',
      className: 'table-column-center',
    },
    {
      title: '사업자번호',
      dataIndex: 'branchBusinessNumber',
      className: 'table-column-center',
    },
    {
      title: '이메일 주소',
      dataIndex: 'branchEmail',
      className: 'table-column-center',
    },
    {
      title: '위수탁 세금계산서 다운로드(100건 이하)',
      dataIndex: 'companyId',
      className: 'table-column-center',
      render: (data, row) => (
        <Button onClick={() => onDownloadTaxinvoice(row)}>
          다운로드 <VerticalAlignBottomOutlined />
        </Button>
      ),
    },
  ];
  return (
    <div>
      <div
        style={{
          marginBottom: 20,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Input.Search
          placeholder="대리점명/대표자명/사업자번호"
          enterButton
          allowClear
          onSearch={(e) => setSearch(e.target.value)}
          style={{
            width: 260,
            marginRight: 10,
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 'auto',
          }}>
          <DatePicker.RangePicker
            locale={locale}
            style={{ width: 300, marginRight: 10 }}
            placeholder={['시작일', '종료일']}
            format={'YYYY-MM-DD'}
            value={[
              moment(startDate, 'YYYY-MM-DD'),
              moment(endDate, 'YYYY-MM-DD'),
            ]}
            onChange={(_, dateStrings) => {
              setStartDate(dateStrings[0]);
              setEndDate(dateStrings[1]);
              setPagination({ ...pagination, current: 1 });
            }}
            disabledDate={disabledDate}
          />
          <Button
            style={{
              borderColor: '#d9d9d9',
            }}
            onClick={() => getData(true)}>
            검색
          </Button>
        </div>
      </div>
      <Table
        rowKey={(record) => record.idx}
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={pagination}
        onChange={(pagination) => setPagination(pagination)}
      />
    </div>
  );
};
export default BranchTaxInvoiceDownload;
