import React, { Component } from 'react';
import { Button, Input, Table } from 'antd';
import { connect } from 'react-redux';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { frStatusString, statusString } from '../../lib/util/codeUtil';
import ModifyDuesSettingDialog from '../../components/dialog/ModifyDuesSettingDialog';
import moment from 'moment';
import Const from '../../const';
import ExcelIcon from '../../img/excel.png';
import xlsx from 'xlsx';

const Search = Input.Search;

class FranchiseDuesSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      paginationExcel: {
        total: 0,
        current: 1,
        pageSize: 100000,
      },
      branchName: '',
      frName: '',
      businessNumber: '',
      frData: [],
      modalOpen: false,
    };
    this.columns = [
      {
        title: 'idx',
        dataIndex: 'userIdx',
        className: 'table-column-center',
      },
      {
        title: '상태',
        dataIndex: 'frStatus',
        className: 'table-column-center',
        render: (data) => <>{frStatusString[data]}</>,
      },
      {
        title: '사업자번호',
        dataIndex: 'businessNumber',
        className: 'table-column-center',
      },
      {
        title: '상점명',
        dataIndex: 'frName',
        className: 'table-column-center',
      },
      {
        title: '대표자명',
        dataIndex: 'ownerName',
        className: 'table-column-center',
      },
      {
        title: '주소',
        dataIndex: 'addr1',
        className: 'table-column-center',
      },
      {
        title: '이메일',
        dataIndex: 'userEmail',
        className: 'table-column-center',
      },
      {
        title: '최초가맹비',
        dataIndex: 'chargeDate',
        className: 'table-column-center',
        render: (data) => (
          <>
            {moment(data).format('YYYY') > 2900
              ? '무제한'
              : moment(data).format('YYYY-MM-DD')}
          </>
        ),
      },
      {
        title: '가맹비',
        dataIndex: 'dues',
        className: 'table-column-center',
        render: (data) => <>{data === -1 ? '자동' : data}</>,
      },
      {
        title: '사유',
        dataIndex: 'duesMemo',
        className: 'table-column-center duesMemo',
      },
      {
        title: '편집',
        className: 'table-column-center',
        render: (data, row) => (
          <Button onClick={() => this.openModifyModal(row)}>편집하기</Button>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getList();
    console.log(this.props.branchIdx);
  }

  getList = () => {
    const pagination = this.state.pagination;
    httpGet(
      httpUrl.franchiseList,
      [
        this.props.branchIdx,
        this.state.branchName,
        this.state.frName,
        this.state.businessNumber,
        pagination.current,
        pagination.pageSize,
      ],
      {}
    ).then((res) => {
      console.log(res);
      if (res.result === 'SUCCESS') {
        this.setState({
          tableData: res.data.franchises,
          pagination: {
            ...this.state.pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        });
      }
    });
  };

  onSearch = (value, type) => {
    console.log(value);
    switch (type) {
      case 'branchName':
        this.setState({
          branchName: value,
          pagination: {
            current: 1,
            pageSize: 10,
          },
        });
        break;
      case 'frName':
        this.setState({
          frName: value,
          pagination: {
            current: 1,
            pageSize: 10,
          },
        });
        break;
      case 'businessNumber':
        this.setState({
          businessNumber: value,
          pagination: {
            current: 1,
            pageSize: 10,
          },
        });
        break;
    }
    this.getList();
  };

  parseExcelJson = (data) => {
    let result = [
      {
        userIdx: 'idx',
        frStatus: '상태',
        businessNumber: '사업자번호',
        frName: '상점명',
        ownerName: '대표자명',
        addr1: '주소',
        userEmail: '이메일',
        registDate: '가입일',
        recommender: '영업담당자',
        chargeDate: '최초가맹비',
        dues: '가맹비',
        duesMemo: '사유',
        simplifiedTaxPayer: '간이사업자여부'
      },
    ];
    data.forEach((item) => {
      result.push({
        userIdx: item.userIdx,
        frStatus: statusString[item.frStatus],
        businessNumber: item.businessNumber,
        frName: item.frName,
        ownerName: item.ownerName,
        addr1: item.addr1,
        userEmail: item.userEmail,
        registDate: moment(item.registDate).format('YYYY-MM-DD'),
        recommender: item.recommender,
        chargeDate: moment(item.chargeDate).format('YYYY') > 2900
          ? '무제한' : moment(item.chargeDate).format('YYYY-MM-DD'),
        dues: item.dues === -1 ? '자동' : item.dues,
        duesMemo: item.duesMemo,
        simplifiedTaxPayer: item.simplifiedTaxPayer && item.companyId !== 'connect9' ? '간이사업자': '일반사업자'
      });
    });

    return result;
  };

  onDownload = () => {
    const pagination = this.state.paginationExcel;
    httpGet(
      httpUrl.franchiseList,
      [
        this.props.branchIdx,
        this.state.branchName,
        this.state.frName,
        this.state.businessNumber,
        pagination.current,
        pagination.pageSize,
      ],
      {}
    ).then((res) => {
      const excelJson = this.parseExcelJson(res.data.franchises);
      const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
      const wb = xlsx.utils.book_new();
      ws['!cols'] = [];
      ws['!cols'][2] = { width: 20 };
      ws['!cols'][3] = { width: 15 };
      ws['!cols'][4] = { width: 20 };
      ws['!cols'][5] = { width: 15 };
      ws['!cols'][8] = { width: 20 };
      xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
      xlsx.writeFile(wb, '가맹비설정.xlsx');
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  openModifyModal = (row) => {
    this.setState({
      modalOpen: true,
      frData: row,
    });
  };
  closenModifyModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    return (
      <div>
        <div style={{ marginBottom: 20 }}>
          <Search
            placeholder="지점명 검색"
            enterButton
            allowClear
            onChange={(e) => this.setState({ branchName: e.target.value })}
            onSearch={(value) => this.onSearch(value, 'branchName')}
            style={{
              width: 220,
            }}
          />
          <Search
            placeholder="가맹점 검색"
            enterButton
            allowClear
            onChange={(e) => this.setState({ frName: e.target.value })}
            onSearch={(value) => this.onSearch(value, 'frName')}
            style={{
              width: 220,
              marginLeft: 10,
            }}
          />
          <Search
            placeholder="사업자번호 검색"
            enterButton
            allowClear
            onChange={(e) => this.setState({ businessNumber: e.target.value })}
            onSearch={(value) => this.onSearch(value, 'businessNumber')}
            style={{
              width: 220,
              marginLeft: 10,
            }}
          />
          {Const.excelEnable && (
            <Button
              className="download-btn"
              style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
              onClick={() => this.onDownload()}>
              <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
              엑셀 다운로드
            </Button>
          )}
        </div>

        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.tableData}
          columns={this.columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={this.expandedRowRender}
          expandRowByClick={true}
        />

        {this.state.modalOpen && (
          <ModifyDuesSettingDialog
            data={this.state.frData}
            close={this.closenModifyModal}
            getList={this.getList}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};
export default connect(mapStateToProps, null)(FranchiseDuesSetting);
