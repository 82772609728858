import { Button, Input, Radio, Select, Spin, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  CloseOutlined,
  SearchOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { allBranchList } from '../../lib/util/agencyUtil';
import moment from 'moment/moment';
import axios from 'axios';
import FileSaver from 'file-saver';
import { comma } from '../../lib/util/numberUtil';
import Const from '../../const';

const AgencyTaxReturnFr = (props) => {
  const loginInfo = props.loginInfo;
  const CancelToken = axios.CancelToken;
  const source = useRef(null);
  const startDate = props.date.startDate;
  const endDate = props.date.endDate;
  const Option = Select.Option;
  const [branches] = useState(allBranchList());
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [mainList, setMainList] = useState([]);
  const [detailList, setDetailList] = useState([]);
  const [detailOpen, setDetailOpen] = useState(false);
  const [onDownlaodModal, setOnDownlaodModal] = useState(false);
  const [downloadType, setDownloadType] = useState(0);
  const [belongBranch, setBelongBranch] = useState(loginInfo.branch.companyId);
  const [frIdx, setFrIdx] = useState(0);
  const [main, setMain] = useState({
    totalCallFee: 0,
    totalCallFeeVat: 0,
    totalDeliveryCount: 0,
    totalDeliveryPrice: 0,
    totalDeliveryPriceVat: 0,
    totalDues: 0,
    totalDuesActualCollect: 0,
    totalDuesDeliveryPrice: 0,
    totalDuesDeliveryPriceVat: 0,
    totalDuesVat: 0,
  });
  const [detail, setDetail] = useState({
    totalDeliveryPrice: 0,
    totalDeliveryPriceVat: 0,
    totalCallFee: 0,
    totalCallFeeVat: 0,
    totalDuesDeliveryPrice: 0,
    totalDuesDeliveryPriceVat: 0,
  });
  const [selectFrInfo, setSelectFrInfo] = useState({
    frIdx: 0,
    frNameExtra: '',
    ownerName: '',
  });

  useEffect(async () => {
    await getData();
    setDetailList([]);
    setFrIdx(0);
    setDetailOpen(false);
    setSelectFrInfo({
      frIdx: 0,
      frNameExtra: '',
      ownerName: '',
    })
    return () => {
      if (source.current !== null) {
        source.current.cancel();
      }
    };
  }, [belongBranch, props.searchBtn]);

  const mainColumns = [
    '과세여부',
    '가맹점명',
    '사업자번호',
    '대표자명',
    '배달건수',
    '배달비(건별가맹비 포함)',
    '배달비 부가세',
    '가맹비',
    '가맹비 부가세',
    '건별 가맹비',
    '건별 가맹비 부가세',
    '콜 수수료',
    '콜 수수료 부가세',
    '',
  ];
  const detailColumns = [
    '주문번호',
    '가맹점명',
    '라이더 소속 대리점',
    '라이더 ID',
    '라이더명',
    '배달비',
    '배달비 부가세',
    '콜수수료',
    '콜수수료 부가세',
    '건별 가맹비',
    '건별 가맹비 부가세',
    '',
  ];
  const getDataFromMongodb = async (customParams, url) => {
    if (source.current !== null) {
      // already exists request
      source.current.cancel();
    }
    source.current = CancelToken.source();
    setIsLoading(true);
    try {
      return await axios.get(url, {
        baseURL: Const.settlementUrl,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: customParams,
        cancelToken: source.current.token,
      });
    } catch (e) {
      console.error('getting ncash-deposit-list is fail: ' + e);
    }
  };
  const getData = async (params) => {
    let branch = [...branches];
    let companyId = [];
    if (
      belongBranch === loginInfo.branch.companyId ||
      belongBranch === '대리점 전체'
    ) {
      branch.forEach((value, index) => {
        if (value.companyId !== loginInfo.branch.companyId)
          companyId.push(value.companyId);
      });
    } else companyId = [belongBranch];

    let customParams = {
      startDate: startDate,
      endDate: endDate,
      companyId: companyId.join(),
    };
    if (search !== '' || search != null) customParams.keyword = search;
    const url = '/tax-return-v2/franchise';
    const datas = await getDataFromMongodb(customParams, url);
    if (datas.data) {
      setMainList(() => {
        return datas.data.taxFranchises;
      });
      setMain({
        totalCallFee: datas.data.totalCallFee,
        totalCallFeeVat: datas.data.totalCallFeeVat,
        totalDeliveryCount: datas.data.totalDeliveryCount,
        totalDeliveryPrice: datas.data.totalDeliveryPrice,
        totalDeliveryPriceVat: datas.data.totalDeliveryPriceVat,
        // totalDues: datas.data.totalDues,
        totalDuesActualCollect: datas.data.totalDuesActualCollect,
        totalDuesVat: datas.data.totalDuesVat,
        totalDuesDeliveryPrice: datas.data.totalDuesDeliveryPrice,
        totalDuesDeliveryPriceVat: datas.data.totalDuesDeliveryPriceVat,
      });
    }
    setIsLoading(false);
  };

  const getDetailData = async (frIdx, frNameExtra, ownerName) => {
    let branch = [...branches];
    let companyId = [];
    if (
      belongBranch === loginInfo.branch.companyId ||
      belongBranch === '대리점 전체'
    ) {
      branch.forEach((value, index) => {
        if (value.companyId !== loginInfo.branch.companyId)
          companyId.push(value.companyId);
      });
    } else companyId = [belongBranch];
    let customParams = {
      startDate: startDate,
      endDate: endDate,
      companyId: companyId.join(),
      frIdx: frIdx,
      frNameExtra: frNameExtra,
      ownerName: ownerName,
    };
    const url = '/tax-return-v2/franchise/detail';
    const datas = await getDataFromMongodb(customParams, url);
    if (datas) {
      setDetailList(() => {
        return datas.data.taxFranchiseDetails;
      });
      setDetail({
        totalDeliveryPrice: datas.data.totalDeliveryPrice,
        totalDeliveryPriceVat: datas.data.totalDeliveryPriceVat,
        totalCallFee: datas.data.totalCallFee,
        totalCallFeeVat: datas.data.totalCallFeeVat,
      });
    }
    setIsLoading(false);
  };

  const getExcelDataFromMongodb = async (params, url) => {
    if (source.current !== null) {
      source.current.cancel();
    }
    source.current = CancelToken.source();
    try {
      return await axios.get(url, {
        baseURL: Const.settlementUrl,
        params: params,
        responseType: 'arraybuffer',
        cancelToken: source.current.token,
      });
    } catch (e) {
      console.error('getting tax-invoice-excel-data is failed: ' + e);
    }
  };
  const onDownloadContent = async (taxinvoice) => {
    if (isLoading) return;
    let params = {};
    let url = '';
    let str = '';
    
    let branch = [...branches];
    let companyId = [];
    if (
      belongBranch === loginInfo.branch.companyId ||
      belongBranch === '대리점 전체'
    ) {
      branch.forEach((value, index) => {
        if (value.companyId !== loginInfo.branch.companyId)
          companyId.push(value.companyId);
      });
    } else companyId = [belongBranch];
    
    if (taxinvoice) {
      url = '/tax-return-v2/franchise-tax-return/excel';
      params = {
        startDate: startDate,
        endDate: endDate,
        companyId: companyId.join(),
      };
      str = '세금계산용';
    } else if (downloadType === 0) {
      params = {
        startDate: startDate,
        endDate: endDate,
        companyId: companyId.join(),
        frIdx: selectFrInfo.frIdx,
        frNameExtra: selectFrInfo.frNameExtra,
        ownerName: selectFrInfo.ownerName,
      };
      url = '/tax-return-v2/franchise/excel';
      str = '전체내역';
    } else {
      params = {
        startDate: props.startDate,
        endDate: props.endDate,
        companyId: companyId.join(),
        frIdx: selectFrInfo.frIdx,
        frNameExtra: selectFrInfo.frNameExtra,
        ownerName: selectFrInfo.ownerName,
      };
      url = '/tax-return-v2/franchise/detail/excel';
      str = '상세내역';
    }
    setIsLoading(true);
    if (downloadType === 0 && (search !== null || search !== ''))
      params.keyword = search;
    const response = await getExcelDataFromMongodb(params, url);
    if (response) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(
        blob,
        `${str}_${moment(props.startDate).format('YYMMDD')}_${moment(
          props.endDate
        ).format('YYMMDD')}.xlsx`
      );
      Modal.success({
        content: '엑셀파일 다운로드가 완료되었습니다.',
      });
      setIsLoading(false);
      setOnDownlaodModal(false);
    } else {
      Modal.error({
        content: '다운로드에 실패했습니다. 관리자에게 문의해주세요.',
      });
      setOnDownlaodModal(false);
    }
    setIsLoading(false);
  };

  return (
    <div id={'agencyTaxReturn'}>
      <div className={'ncl-ctg-box'}>
        <div className={'ncl-select-box'}>
          <Select
            defaultValue={'대리점 전체'}
            style={{
              flex: 1,
            }}
            onChange={(value) => {
              setMainList([]);
              setBelongBranch(value);
            }}>
            {branches.map((value) => {
              return (
                <Option key={value.branchName} value={value.companyId}>
                  {value.branchName}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className={'ncl-input-box'} style={{ width: 240 }}>
          <Input
            onPressEnter={() => getData(true)}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={'주문번호/라이더명/가맹점명/사업자번호'}
            style={{
              border: 'none',
              paddingLeft: 10,
              width: 200,
            }}
          />
          <SearchOutlined
            style={{ padding: '0 10px', cursor: 'pointer' }}
            onChange={() => getData(true)}
          />
        </div>
        <div className={'ncl-excel-box'} style={{ marginLeft: 'auto' }}>
          <div
            className={'ncl-excel-btn'}
            onClick={() => onDownloadContent(true)}>
            세금계산용 다운로드{' '}
            <VerticalAlignBottomOutlined style={{ color: '#fff' }} />
          </div>
          <div
            className={'ncl-excel-btn'}
            onClick={() => setOnDownlaodModal(true)}>
            전체 내역 다운로드{' '}
            <VerticalAlignBottomOutlined style={{ color: '#fff' }} />
          </div>
        </div>
      </div>
      {isLoading && (
        <div className={'spin-overlay'}>
          <Spin className={'spin'} />
        </div>
      )}
      <div className={'agency-tr-tbl'} style={{ marginTop: 15 }}>
        <div
          className={'agency-tr-table-container'}
          style={{ minWidth: 1080, maxWidth: 1710, flex: 1 }}>
          <div className={'agency-tr-main-table-columns fr'}>
            {mainColumns.map((col, index) => {
              if (index === 13) return <p>{col}</p>;
              else if (index === 12)
                return <div style={{ borderRight: 0 }}>{col}</div>;
              else return <div>{col}</div>;
            })}
          </div>
          {mainList.length > 0 ? (
            <div className={'agency-tr-main-table-content'}>
              {mainList.map((main, index) => {
                return (
                  <div
                    className={'agency-tr-main-table-row fr'}
                    onClick={() => {
                      setDetailOpen(true);
                      setFrIdx(main.frIdx);
                      setDetailList([]);
                      setSelectFrInfo({
                        frIdx: main.frIdx,
                        frNameExtra: main.frNameExtra,
                        ownerName: main.ownerName,
                      });
                      getDetailData(
                        main.frIdx,
                        main.frNameExtra,
                        main.ownerName
                      );
                    }}
                    style={{
                      fontWeight: frIdx === main.frIdx ? 'bold' : 'normal',
                    }}>
                    <div>{main.simplifiedTaxPayer ? '간이' : '일반'}</div>
                    <div>
                      {main.frNameExtra ? main.frNameExtra : main.frName}
                    </div>
                    <div>{main.businessNumber}</div>
                    <div>{main.ownerName}</div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.deliveryCount)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.deliveryPrice)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.deliveryPriceVat)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.duesActualCollect)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.duesVat)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.duesDeliveryPrice)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.duesDeliveryPriceVat)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.callFee)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.callFeeVat)}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={'agency-tr-main-table-content2'}>
              조회되는 데이터가 없습니다.
            </div>
          )}
          <div className={'agency-tr-main-table-footer'}>
            {mainColumns.map((footer, index) => {
              if (index === 4)
                return (
                  <div className={'totalBox'}>
                    {comma(main.totalDeliveryCount)}
                  </div>
                );
              else if (index === 5)
                return (
                  <div className={'totalBox'}>
                    {comma(main.totalDeliveryPrice)}
                  </div>
                );
              else if (index === 6)
                return (
                  <div className={'totalBox'}>
                    {comma(main.totalDeliveryPriceVat)}
                  </div>
                );
              else if (index === 7)
                return (
                  <div className={'totalBox'}>
                    {comma(main.totalDuesActualCollect)}
                  </div>
                );
              else if (index === 8)
                return (
                  <div className={'totalBox'}>{comma(main.totalDuesVat)}</div>
                );
              else if (index === 9)
                return (
                  <div className={'totalBox'}>
                    {comma(main.totalDuesDeliveryPrice)}
                  </div>
                );
              else if (index === 10)
                return (
                  <div className={'totalBox'}>
                    {comma(main.totalDuesDeliveryPriceVat)}
                  </div>
                );
              else if (index === 11)
                return (
                  <div className={'totalBox'}>{comma(main.totalCallFee)}</div>
                );
              else if (index === 12)
                return (
                  <div className={'totalBox'}>
                    {comma(main.totalCallFeeVat)}
                  </div>
                );
              else if (index === 13) return;
              else return <div />;
            })}
          </div>
        </div>
        {detailOpen && (
          <div
            className={'agency-tr-table-container'}
            style={{
              minWidth: 800,
              maxWidth: 1360,
              flex: 0.9,
              marginLeft: 10,
            }}>
            <div className={'detail-tbl-columns'}>
              {detailColumns.map((col, index) => {
                if (index === 11) return <p>{col}</p>;
                else if (index === 10)
                  return <div style={{ borderRight: 0 }}>{col}</div>;
                return <div>{col}</div>;
              })}
            </div>
            <div className={'detail-tbl'}>
              {detailList.length > 0 &&
                detailList.map((detail, index) => {
                  return (
                    <div
                      key={detail.createDate}
                      className={'agency-tr-detail-table-row'}>
                      <div className={'detail-tbl-date'}>
                        기간: {detail.createDate}
                      </div>
                      {detail.items.map((item, index) => {
                        return (
                          <div
                            key={item.orderIdx + index}
                            className={'detail-tbl-item'}>
                            <div>{item.orderIdx}</div>
                            <div>
                              {item.frNameExtra ? item.frNameExtra : ''}
                            </div>
                            <div>{item.riderBranch}</div>
                            <div>{item.riderUserId}</div>
                            <div>{item.exactRiderName}</div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <span style={{ marginRight: 10 }}>
                                {comma(item.deliveryPrice)}
                              </span>
                            </div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <span style={{ marginRight: 10 }}>
                                {comma(item.deliveryPriceVat)}
                              </span>
                            </div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <span style={{ marginRight: 10 }}>
                                {comma(item.callFee)}
                              </span>
                            </div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <span style={{ marginRight: 10 }}>
                                {comma(item.callFeeVat)}
                              </span>
                            </div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <span style={{ marginRight: 10 }}>
                                {comma(item.duesDeliveryPrice)}
                              </span>
                            </div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <span style={{ marginRight: 10 }}>
                                {comma(item.duesDeliveryPriceVat)}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                      <div className={'detail-tbl-total'}>
                        {detailColumns.map((item, index) => {
                          if (index === 0)
                            return (
                              <div style={{ fontWeight: 'bold', flex: 2 }}>
                                <span style={{ paddingLeft: 10 }}>
                                  배달 건수: {comma(detail.totalDeliveryCount)}{' '}
                                  건
                                </span>
                              </div>
                            );
                          else if (index === 1) return;
                          else if (index === 5)
                            return (
                              <div className={'detail-bold'}>
                                <span>{comma(detail.totalDeliveryPrice)}</span>
                              </div>
                            );
                          else if (index === 6)
                            return (
                              <div className={'detail-bold'}>
                                <span>
                                  {comma(detail.totalDeliveryPriceVat)}
                                </span>
                              </div>
                            );
                          else if (index === 7)
                            return (
                              <div className={'detail-bold'}>
                                <span>{comma(detail.totalCallFee)}</span>
                              </div>
                            );
                          else if (index === 8)
                            return (
                              <div className={'detail-bold'}>
                                <span>{comma(detail.totalCallFeeVat)}</span>
                              </div>
                            );
                          else if (index === 9)
                            return (
                              <div className={'detail-bold'}>
                                <span>{detail.totalDuesDeliveryPrice}</span>
                              </div>
                            );
                          else if (index === 10)
                            return (
                              <div className={'detail-bold'}>
                                <span>{detail.totalDuesDeliveryPriceVat}</span>
                              </div>
                            );
                          else if (index === 11) return;
                          else return <div />;
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className={'footer-container'}>
              <div className={'agency-tax-return-table-footer'}>
                {detailColumns.map((item, index) => {
                  if (index === 0)
                    return (
                      <div className={'totalBox'}>
                        {comma(detail.totalDuesDeliveryPriceVat)}
                      </div>
                    );
                  else if (index === 1)
                    return (
                      <div className={'totalBox'}>
                        {comma(detail.totalDuesDeliveryPrice)}
                      </div>
                    );
                  else if (index === 2)
                    return (
                      <div className={'totalBox'}>
                        {comma(detail.totalCallFeeVat)}
                      </div>
                    );
                  else if (index === 3)
                    return (
                      <div className={'totalBox'}>
                        {comma(detail.totalCallFee)}
                      </div>
                    );
                  else if (index === 4)
                    return (
                      <div className={'totalBox'}>
                        {comma(detail.totalDeliveryPriceVat)}
                      </div>
                    );
                  else if (index === 5)
                    return (
                      <div className={'totalBox'}>
                        {comma(detail.totalDeliveryPrice)}
                      </div>
                    );
                  else if (index === 11) return;
                  else return <div />;
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      {onDownlaodModal && (
        <div>
          <div
            className="Dialog-overlay"
            onClick={() => setOnDownlaodModal(false)}
          />
          <div className="taxReturn-Dialog">
            <CloseOutlined
              style={{ marginLeft: 'auto', fontSize: 20, cursor: 'pointer' }}
              onClick={() => setOnDownlaodModal(false)}
            />
            <div>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}>
                전체 내역 중 엑셀 파일로 <br /> 저장할 내용을 선택하세요
              </p>
              <Radio.Group
                onChange={(e) => setDownloadType(e.target.value)}
                defaultValue={0}>
                <Radio value={0}>통계(좌측)</Radio>
                <Radio value={1} disabled={!detailOpen}>
                  상세(우측)
                </Radio>
              </Radio.Group>
            </div>
            <div>
              <Button
                className={'taxReturn-Dialog-btn'}
                onClick={() => setOnDownlaodModal(false)}>
                취소
              </Button>
              <Button
                onClick={() => onDownloadContent(false)}
                className={'taxReturn-Dialog-btn'}
                style={{
                  marginLeft: 10,
                  background: '#FADC00',
                  fontWeight: 'bold',
                }}>
                다운로드
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AgencyTaxReturnFr;
