import { Button, Input, Modal, Select, Table } from 'antd';
import React, { Component } from 'react';
import { httpPost, httpUrl, purchaseName } from '../../api/httpClient';
import '../../css/main.css';
import CloseIcon from '../../img/close.png';

class RegisterOrderByHand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      orderIdx: '',
      purchaseName: '',
      cardNo: '',
      authNum: '',
      message1: '',
      purchaseCode: 0,
      authDate: '',
      totalPrice: 0,
      frIdx: null,
      remainAmount: 0,
      selectedPurchaseName: '',
      pgType: 1,
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    console.log('this.props.orderIdx');
    console.log(this.props.orderIdx);
    let self = this;
    let frIdx = self.props.frIdx;
    httpPost(httpUrl.getPgTypeByFrIdx, [], { frIdx })
      .then((res) => {
        console.log(res);
        if (res.result === 'SUCCESS' && res.data !== 0) {
          self.setState(
            {
              pgType: res.data,
            },
            () => console.log(self.state.pgType)
          );
        } else {
          self.registError();
        }
      })
      .catch((e) => {
        self.registError();
      });
  };

  handleDuplicateOrder = () => {
    let self = this;
    // let authDate = new Date(self.state.authDate);

    Modal.confirm({
      title: <div>수동등록</div>,
      content: <div>입력하신 정보로 수동등록하시겠습니까?</div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.updateCardRegister, [], {
          frIdx: self.props.frIdx,
          orderIdx: self.props.orderIdx,
          remainAmount: self.props.remainAmount,
          purchaseName: self.state.selectedPurchaseName,
          cardNo: self.state.cardNo,
          authNum: self.state.authNum,
          message1: self.state.message1,
          purchaseCode: self.state.purchaseCode,
          authDate: self.state.authDate,
          totalPrice: self.state.totalPrice,
          pgType: self.state.pgType,
        })
          .then((res) => {
            if (res.result === 'SUCCESS') {
              Modal.info({
                title: '수동등록성공',
                content: '수동 등록에 성공하였습니다.',
              });
              self.setState(() => self.props.close());
            } else {
              Modal.info({
                title: '등록 실패',
                content: '등록에 실패했습니다.',
              });
            }
          })
          .catch((e) => {
            Modal.info({
              title: '등록 실패',
              content: '등록에 실패했습니다.',
            });
            throw e;
          });
      },
    });
  };

  render() {
    const { data, close } = this.props;

    const { Option } = Select;

    const inputs = [{}];

    const columns = [
      {
        title: '매입사명',
        className: 'table-column-center',
        render: () => (
          <>
            <span>
              <Select
                className="desk"
                placeholder="미선택"
                style={{ display: 'inline-block', width: 150 }}
                onChange={(value) => {
                  value < 10
                    ? this.setState({
                        purchaseCode: '0' + value,
                        selectedPurchaseName: purchaseName[value],
                      })
                    : this.setState({
                        purchaseCode: value,
                        selectedPurchaseName: purchaseName[value],
                      });
                }}>
                {purchaseName.map((value, idx) => {
                  return <Option value={idx}>{value}</Option>;
                })}
              </Select>
            </span>
          </>
        ),
      },
      {
        title: '매입사코드',
        className: 'table-column-center',
        render: (data, row) => (
          <>
            <Input
              style={{ display: 'inline-block', width: 50 }}
              value={this.state.purchaseCode}
              disabled
            />
          </>
        ),
      },
      {
        title: '카드번호',
        className: 'table-column-center',
        render: (data, row) => (
          <>
            <Input
              style={{ display: 'inline-block', width: 200 }}
              onChange={(e) => this.setState({ cardNo: e.target.value })}
              placeholder="ex)940915******0825"
            />
          </>
        ),
      },
      {
        title: '승인번호',
        className: 'table-column-center',
        render: (data, row) => (
          <>
            <Input
              style={{ display: 'inline-block', width: 200 }}
              onChange={(e) => this.setState({ authNum: e.target.value })}
              placeholder="ex)46000628"
            />
          </>
        ),
      },
      {
        title: '카드종류',
        className: 'table-column-center',
        render: (data, row) => (
          <>
            <Input
              style={{ display: 'inline-block', width: 150 }}
              onChange={(e) => this.setState({ message1: e.target.value })}
              placeholder="ex)카카오뱅크체크"
            />
          </>
        ),
      },
      {
        title: '승인일시(YYMMDDhhmmss)',
        className: 'table-column-center',
        render: (data, row) => (
          <>
            <Input
              style={{ display: 'inline-block', width: 200 }}
              onChange={(e) => this.setState({ authDate: e.target.value })}
              placeholder="ex)220422121700"
            />
          </>
        ),
      },
      {
        title: '총액',
        className: 'table-column-center',
        render: (data, row) => (
          <>
            <Input
              style={{ display: 'inline-block', width: 100 }}
              onChange={(e) => this.setState({ totalPrice: e.target.value })}
            />
          </>
        ),
      },
      {
        title: '등록',
        className: 'table-column-center',
        render: (data, row) => (
          <Button onClick={this.handleDuplicateOrder}>카드세부정보등록</Button>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="taskWork-Dialog" style={{ textAlign: 'left' }}>
          <div className="taskWork-content">
            {/* 타이틀 */}
            <div className="taskWork-title2">
              <span>카드 세부정보 수동등록</span>
              <img
                //style={{marginLeft: "50px"}}
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="taskWork-close"
                alt="close"
              />
            </div>

            <Table dataSource={inputs} columns={columns} pagination={false} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegisterOrderByHand;
