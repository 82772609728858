import { Button, DatePicker, Form, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import xlsx from 'xlsx';
import { httpGet, httpGetRenewMakeUrl, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { comma, accountFormat } from '../../lib/util/numberUtil';
import Const from '../../const';
import ExcelIcon from '../../img/excel.png';

const Aggregation = ({ props }) => {
  const formRef = React.createRef();
  const [list, setList] = useState([]);
  const [branchList, setbranchList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 100,
  });
  useEffect(async () => {
    getBranchList();
  }, []);
  useEffect(async () => {
    await getList();
  }, [pagination.current, pagination.pageSize]);

  const getList = async () => {
    console.log(formRef.current.getFieldValue('date'));

    let params = {};
    if (formRef.current.getFieldValue('branchIdx'))
      params['branchIdx'] = formRef.current.getFieldValue('branchIdx');
    if (formRef.current.getFieldValue('date')) {
      params['startDate'] = formRef.current
        .getFieldValue('date')[0]
        .format('YYYY-MM-DD');
      params['endDate'] = formRef.current
        .getFieldValue('date')[1]
        .format('YYYY-MM-DD');
    }
    params['pageSize'] = pagination.pageSize;
    params['pageNum'] = pagination.current;

    const res = await httpGetRenewMakeUrl(httpUrl.getSmList, params, {});

    setList(res.data.settlementList);
    setPagination({ ...pagination, total: res.data.totalCount });
  };
  const getBranchList = async () => {
    const res = await httpGet(httpUrl.getBranchList, [1, 10000], {});
    setbranchList([{ idx: null, branchName: '전체' }, ...res.data.branch]);
  };
  const handleTableChange = (pagination) => setPagination(pagination);
  const columns = [
    {
      title: '일자',
      dataIndex: 'statDate',
      className: 'table-column-center',
      width: 150,
      fixed: 'left',
    },
    {
      title: '지점명',
      dataIndex: 'branchIdx',
      className: 'table-column-center',
      width: 120,
      fixed: 'left',
      render: (data) => (
        <div>
          {branchList.find((branch) => branch.idx === data)
            ? branchList.find((branch) => branch.idx === data).branchName
            : '-'}
        </div>
      ),
    },
    {
      title: '부가세',
      dataIndex: 'prepaidVatSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '정직원배달건',
      dataIndex: 'employeeDeliveryCnt',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '정직원배달금액',
      dataIndex: 'employeeDeliverySum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '라이더배달건',
      dataIndex: 'riderDeliveryCnt',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '라이더배달금액',
      dataIndex: 'riderDeliveryPriceSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '라이더수수료',
      dataIndex: 'deliveryPriceFeeSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '세금',
      dataIndex: 'taxSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '고용보험',
      dataIndex: 'employmentInsuranceSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '산재보험',
      dataIndex: 'workCoverSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '상해보험',
      dataIndex: 'accidentInsuranceSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '렌트',
      dataIndex: 'leaseSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '렌트(경기)',
      dataIndex: 'leaseKynggiSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '렌트(타대행)',
      dataIndex: 'leaseOtherSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '수리',
      dataIndex: 'bikeRepairSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '수리(경기)',
      dataIndex: 'bikeRepairKynggiSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '수리(타대행)',
      dataIndex: 'bikeRepairOtherSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '대출',
      dataIndex: 'loanSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '대출(아우라)',
      dataIndex: 'loanAuraSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '대출(타대행)',
      dataIndex: 'loanOtherSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '경기바이크',
      dataIndex: 'kynggiSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '가맹비',
      dataIndex: 'duesSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '냠냠몰',
      dataIndex: 'mallSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '충전(라이더)',
      dataIndex: 'riderChargeSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '충전(가맹점)',
      dataIndex: 'franChargeSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '충전(회사)',
      dataIndex: 'hqChargeSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '충전수수료',
      dataIndex: 'chargeFeeSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '출금',
      dataIndex: 'riderWithdrawSum',
      className: 'table-column-center',
      width: 120,
      render: (data, row) => (
        <div>{accountFormat(row.riderWithdrawSum + row.franWithdrawSum)}</div>
      ),
    },
    {
      title: '포스판매',
      dataIndex: 'posSalesSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '관리자-기타',
      dataIndex: 'riderAdminSendSum',
      className: 'table-column-center',
      width: 120,
      render: (data, row) => (
        <div>{accountFormat(row.riderAdminSendSum + row.franAdminSendSum)}</div>
      ),
    },
    {
      title: '관리자-선충전',
      dataIndex: 'preChargeSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '관리자-배상',
      dataIndex: 'compensationSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '관리자-코인전환',
      dataIndex: 'transferCoinSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '프로모션',
      dataIndex: 'promotionSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
    {
      title: '기타',
      dataIndex: 'etcSum',
      className: 'table-column-center',
      width: 120,
      render: (data) => <div>{accountFormat(data)}</div>,
    },
  ];

  const parseExcelJson = (data) => {
    let result = [];
    let headerJson = {};
    columns.forEach((column) => {
      headerJson[column.dataIndex] = column.title;
    });
    result.push(headerJson);

    data.forEach((item) => {
      let copiedItem = { ...item };
      Object.keys(item).forEach((key) => {
        if (columns.findIndex((column) => column.dataIndex === key) < 0) {
          delete copiedItem[key];
        }
      });
      result.push({
        ...copiedItem,
        riderWithdrawSum: item.riderWithdrawSum + item.franWithdrawSum,
        riderAdminSendSum: item.riderAdminSendSum + item.franAdminSendSum,
        branchIdx: branchList.find((branch) => branch.idx === item.branchIdx)
          ? branchList.find((branch) => branch.idx === item.branchIdx)
              .branchName
          : '-',
      });
    });

    return result;
  };

  const onDownload = async () => {
    let params = {};
    if (formRef.current.getFieldValue('branchIdx'))
      params['branchIdx'] = formRef.current.getFieldValue('branchIdx');
    if (formRef.current.getFieldValue('date')) {
      params['startDate'] = formRef.current
        .getFieldValue('date')[0]
        .format('YYYY-MM-DD');
      params['endDate'] = formRef.current
        .getFieldValue('date')[1]
        .format('YYYY-MM-DD');
    }
    params['pageSize'] = 10000;
    params['pageNum'] = 1;

    const res = await httpGetRenewMakeUrl(httpUrl.getSmList, params, {});
    const excelJson = parseExcelJson(res.data.settlementList);
    const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
    const wb = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(wb, ws, '일자별_지점_정산');
    xlsx.writeFile(wb, '일자별_지점_정산.xlsx');
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Form ref={formRef} style={{ display: 'flex', flexDirection: 'row' }}>
          <Form.Item style={{ width: 200 }} name="branchIdx">
            <Select
              onChange={() => {
                setPagination({
                  total: 0,
                  current: 1,
                  pageSize: 100,
                });
                getList();
              }}
              defaultValue={null}>
              {branchList.map((branch) => {
                return (
                  <Select.Option value={branch.idx}>
                    {branch.branchName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="date">
            <DatePicker.RangePicker
              onChange={() => {
                setPagination({
                  total: 0,
                  current: 1,
                  pageSize: 100,
                });
                getList();
              }}
              placeholder={['시작일', '종료일']}
            />
          </Form.Item>
        </Form>
        {Const.excelEnable && (
          <Button
            className="download-btn"
            style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
            onClick={() => onDownload()}>
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            엑셀 다운로드
          </Button>
        )}
      </div>

      <Table
        rowKey={(record) => record.idx}
        dataSource={list}
        columns={columns}
        pagination={pagination}
        onChange={handleTableChange}
        scroll={{ y: 600, x: '100%' }}
      />
    </>
  );
};

export default Aggregation;
