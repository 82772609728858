import { Button, Form, Input, Modal, Table, Tag } from 'antd';
import React, { Component } from 'react';
import XLSX from 'xlsx';
import { httpDelete, httpPost, httpUrl } from '../../api/httpClient';
import { customAlert, customError } from '../../api/Modals';
import RegistBatchWorkDialog from '../../components/dialog/RegistBatchWorkDialog';
import SearchRiderOrFrDialog from '../../components/dialog/SearchRiderOrFrDialog';
import SelectBox from '../../components/input/SelectBox';
import { batchWorkCategoryString, kindString } from '../../lib/util/codeUtil';
import { formatDateToDay } from '../../lib/util/dateUtil';
import { comma } from '../../lib/util/numberUtil';
import ExcelIcon from '../../img/excel.png';

class NCashList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSearchRiderModal: false,
      taskWorkOpen: false, //작업 등록
      updateTaskOpen: false,
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      batchworkNcashIdx: null,
      kind: '',

      excelInputOpen: false,
      excelRegistData: null,
    };
    this.formRef = React.createRef();
    this.excelInputRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onRefresh = () => {
    this.setState(
      {
        pagination: {
          total: 0,
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  getList = () => {
    console.log({
      pageNum: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      kind: this.state.kind,
    });
    httpPost(httpUrl.riderBatchWorkList, [], {
      pageNum: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      kind: this.state.kind,
    })
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState(
          {
            list: res.data.riderBatchWorkList,
            pagination,
          },
          () => console.log(this.state.list)
        );
      })
      .catch((e) => {
        customError('목록 에러', '에러가 발생하여 목록을 불러올수 없습니다.');
      });
  };

  // 일차감 삭제
  onDelete = (row) => {
    let self = this;
    Modal.confirm({
      title: '일차감 삭제',
      content: <div> {row.title} 를 삭제하시겠습니까?</div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpDelete(httpUrl.riderBatchWorkDelete, [], {
          idx: row.idx,
        })
          .then((res) => {
            if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
              customAlert(
                '일차감 삭제',
                row.title + ' 일차감이 삭제되었습니다.'
              );
              self.getList();
            } else
              customError(
                '삭제 오류',
                '삭제 오류가 발생하였습니다. 다시 시도해 주십시오.'
              );
          })
          .catch((error) => {
            customError(
              '삭제 오류',
              '일차감에 라이더가 있을시 라이더 삭제후 다시 진행해주세요.'
            );
          });
      },
    });
  };

  onAddRider = (riderData) => {
    // console.log(JSON.stringify(riderData + " + " + JSON.stringify(this.state.userIdx)))
    console.log({
      userIdx: riderData.userIdx,
      batchWorkNcashIdx: this.state.batchworkNcashIdx,
    });
    httpPost(httpUrl.userBatchWorkCreate, [], {
      userIdx: riderData.userIdx,
      batchWorkNcashIdx: this.state.batchworkNcashIdx,
    })
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          customAlert(
            '일차감 대상 추가',
            (riderData.riderName ? riderData.riderName : riderData.frName) +
              ' 추가되었습니다.'
          );
          this.getList();
        } else
          customError(
            '추가 오류',
            '오류가 발생하였습니다. 다시 시도해 주십시오.'
          );
      })
      .catch((error) => {
        customError(
          '추가 오류',
          '오류가 발생하였습니다. 해당 라이더가 이미 추가 되있을 시 추가되지 않습니다.'
        );
      });
  };

  onDeleteRider = (data, userIdx) => {
    var self = this;
    console.log({
      batchWorkNcashIdx: data.idx,
      userIdx,
    });
    httpDelete(httpUrl.userBatchWorkDelete, [], {
      batchWorkNcashIdx: data.idx,
      userIdx,
    })
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          customAlert('일차감 라이더 삭제', '일차감 라이더가 삭제되었습니다.');
          self.getList();
        } else
          customError(
            '삭제 오류',
            '오류가 발생하였습니다. 다시 시도해 주십시오.'
          );
      })
      .catch((error) => {
        customError(
          '삭제 오류',
          '오류가 발생하였습니다. 다시 시도해 주십시오.'
        );
      });
  };

  onChangeStatus = (value) => {
    this.setState(
      {
        kind: value === '0' ? '' : value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  readExcel = async (file) => {
    let self = this;
    if (file) {
      const start = new Date();

      let reader = new FileReader();
      reader.onload = async () => {
        let data = reader.result;
        let workBook = XLSX.read(data, { type: 'binary' });
        const rows = XLSX.utils.sheet_to_json(
          workBook.Sheets[workBook.SheetNames[0]],
          { defval: '' }
        );
        self.setState({ excelRegistData: rows }, () =>
          this.handleExcelRegist()
        );
      };
      reader.readAsBinaryString(file);
    }
  };

  handleExcelRegist = async () => {
    let failedIdxList = [];
    if (this.state.excelRegistData) {
      const excelRegistData = [...this.state.excelRegistData];
      for (let i = 0; i < excelRegistData.length; i++) {
        const data = excelRegistData[i];
        const formData = {
          category: data['일/월구분'],
          ncashDelta: String(data['차감금액']),
          title: data['차감명'],
          startDate: data['시작일자'] + ' 00:00',
          endDate: data['종료일자'] + ' 00:00',
          kind: data['구분'],
          memo: '',
        };
        console.log(formData);
        const createRes = await this.createBatchWork(formData);
        if (!createRes) {
          failedIdxList.push(i + 1);
        }
      }
      if (failedIdxList.length === 0) {
        Modal.info({
          title: '등록성공',
          content: '모든 차감 등록에 성공했습니다.',
        });
      } else {
        Modal.warn({
          title: '등록 실패',
          content: `${failedIdxList}번째 차감 등록에 실패했습니다.`,
        });
      }
    }
    this.getList();
  };

  createBatchWork = async (formData) => {
    try {
      const res = await httpPost(httpUrl.riderBatchWorkCreate, [], formData);
      if (res.data && res.data === 'SUCCESS') return true;
      return false;
    } catch (e) {
      return false;
    }
  };

  // 라이더추가 dialog
  openSearchRiderModal = (batchworkNcashIdx) => {
    this.setState({ openSearchRiderModal: true, batchworkNcashIdx });
  };
  closeSearchRiderModal = () => {
    this.setState({ openSearchRiderModal: false, batchworkNcashIdx: null });
  };

  //일차감 작업등록
  openTaskWorkModal = () => {
    this.setState({ taskWorkOpen: true });
  };
  closeTaskWorkModal = () => {
    this.setState({ taskWorkOpen: false });
  };

  //일차감 작업수정
  openUpdateTaskWorkModal = (row) => {
    this.setState({ updateTaskOpen: true, dialogData: row });
  };
  closeUpdateTaskWorkModal = () => {
    this.setState({ updateTaskOpen: false });
  };

  toggleExcelInput = () => {
    this.setState({ excelInputOpen: !this.state.excelInputOpen });
  };

  render() {
    const columns = [
      {
        title: '구분',
        dataIndex: 'kind',
        className: 'table-column-center',
        render: (data) => <div>{kindString[data]}</div>,
      },
      {
        title: '일/월차감',
        dataIndex: 'category',
        className: 'table-column-center',
        render: (data) => <div>{batchWorkCategoryString[data]}</div>,
      },
      {
        title: '차감명',
        dataIndex: 'title',
        className: 'table-column-center',
      },
      {
        title: '차감금액',
        dataIndex: 'ncashDelta',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '기간제한시작일',
        dataIndex: 'startDate',
        className: 'table-column-center',
        render: (data) => <div>{formatDateToDay(data)}</div>,
      },
      {
        title: '기간제한종료일',
        dataIndex: 'endDate',
        className: 'table-column-center',
        render: (data) => <div>{formatDateToDay(data)}</div>,
      },
      {
        title: '대상추가',
        dataIndex: 'idx',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn"
              onClick={() => this.openSearchRiderModal(data)}>
              추가
            </Button>
          </div>
        ),
      },
      {
        title: '수정',
        dataIndex: 'title',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn"
              onClick={() => this.openUpdateTaskWorkModal(row)}>
              수정
            </Button>
          </div>
        ),
      },
      {
        dataIndex: 'title',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn surchargeTab"
              onClick={() => this.onDelete(row)}>
              삭제
            </Button>
          </div>
        ),
      },
    ];
    const expandedRowRender = (record) => {
      // alert(JSON.stringify(record.users))
      const dropColumns = [
        {
          dataIndex: 'riderName',
          className: 'table-column-center',
          render: (data, row) => (
            <>
              <Tag
                key={row.userIdx}
                style={{ fontSize: 14, padding: 5 }}
                closable
                onClose={() => this.onDeleteRider(record, row.idx)}>
                {data ? data : row.frName}
              </Tag>
            </>
          ),
        },
      ];
      return (
        <Table
          className="subTable"
          rowKey={(record) => `record: ${record.idx}`}
          columns={dropColumns}
          dataSource={record.users}
          pagination={false}
        />
      );
    };

    const { close } = this.props;

    return (
      <React.Fragment>
        <div className="taskScheduler-Dialog">
          <div className="taskScheduler-content">
            <div className="taskScheduler-inner">
              <div className="taskScheduler-btn">
                <div className="taskScheduler-btn-01">
                  {this.state.taskWorkOpen && (
                    <RegistBatchWorkDialog
                      close={this.closeTaskWorkModal}
                      callback={this.onRefresh}
                    />
                  )}
                  {this.state.updateTaskOpen && (
                    <RegistBatchWorkDialog
                      close={this.closeUpdateTaskWorkModal}
                      callback={this.onRefresh}
                      data={this.state.dialogData}
                    />
                  )}
                  <SelectBox
                    // placeholder={'전체'}
                    style={{ width: 200, marginBottom: 20 }}
                    value={
                      this.state.kind === ''
                        ? kindString[0]
                        : kindString[this.state.kind]
                    }
                    code={Object.keys(kindString)}
                    codeString={kindString}
                    onChange={(value) => {
                      if (parseInt(value) !== this.state.kind) {
                        this.onChangeStatus(value);
                      }
                    }}
                  />

                  <Button
                    style={{ marginLeft: 10 }}
                    className="taskScheduler-btn"
                    onClick={this.openTaskWorkModal}>
                    일차감 등록
                  </Button>

                  <a href="/batchwork_regist_templete.ods" download>
                    <Button
                      className="download-btn"
                      style={{
                        float: 'right',
                        marginLeft: 10,
                        marginBottom: 20,
                      }}
                      onClick={{}}>
                      <img
                        style={{ display: 'inline' }}
                        src={ExcelIcon}
                        alt=""
                      />
                      양식 다운로드
                    </Button>
                  </a>

                  <Button
                    style={{ marginLeft: 10 }}
                    className="download-btn"
                    onClick={this.toggleExcelInput}>
                    <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
                    엑셀 등록
                  </Button>

                  {this.state.excelInputOpen && (
                    <div style={{ display: 'flex', height: 32 }}>
                      <Input ref={this.excelInputRef} type="file" />
                      <Button
                        onClick={() =>
                          this.readExcel(
                            this.excelInputRef.current.input.files[0]
                          )
                        }>
                        일괄등록
                      </Button>
                    </div>
                  )}
                </div>
              </div>

              <Form ref={this.formIdRef} onFinish={this.handleIdSubmit}>
                <div className="listBlock">
                  <Table
                    rowKey={(record) => record.idx}
                    dataSource={this.state.list}
                    columns={columns}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                    expandedRowRender={expandedRowRender}
                  />
                </div>
              </Form>
              {this.state.openSearchRiderModal && (
                <SearchRiderOrFrDialog
                  close={this.closeSearchRiderModal}
                  callback={(riderData) => this.onAddRider(riderData)}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NCashList;
