import React, { useEffect, useRef, useState } from 'react';
import { comma } from '../../lib/util/numberUtil';
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Select,
  Table,
  Tooltip,
} from 'antd';
import { SearchOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import axios from 'axios';
import Const from '../../const';
import FileSaver from 'file-saver';
import {
  agencyNcashAllForAgency,
  agencyNcashAllForBranch,
  agencySelfList,
  selectCategoryForAgency,
  selectCategoryForBranch,
  selectCategoryForBranchForFr,
  selectCategoryForBranchForRider,
} from '../../lib/util/agencyUtil';

const AgencyNcashAllTbl = (props) => {
  const loginInfo = props.loginInfo;
  const [branches] = useState(agencySelfList());
  const today = moment().format('YYYY-MM-DD');
  const Option = Select.Option;
  const CancelToken = axios.CancelToken;
  const source = useRef(null);

  const [list, setList] = useState([]);
  const [category, setCategory] = useState();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [isLoading, setIsLoading] = useState(false);
  const [exceldownloading, setExceldownloading] = useState(false);
  const [search, setSearch] = useState(null);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalWithdrawal, setTotalWithdrawal] = useState(0);
  const [belongBranch, setBelongBranch] = useState(loginInfo.branch.companyId);
  const [branchNameForExcel, setBranchNameForExcel] = useState('');
  const checkboxOption = ['입금', '출금'];
  const [type, setType] = useState([]);
  const [kind, setKind] = useState(null);
  const [selectLabel, setSelectLabel] = useState('전체');
  const [userType, setUserType] = useState(0);
  const [params, setParams] = useState({
    kind: null,
    category: '',
    type: [],
    userType: 0,
    belongBranch: loginInfo.branch.companyId,
    selectLabel,
  });
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 30,
  });
  const getNcashtDate = async (customParams) => {
    console.log(customParams);
    if (source.current !== null) {
      // already exists request
      source.current.cancel();
    }
    source.current = CancelToken.source();
    setIsLoading(true);
    try {
      return await axios.get('deposit/list', {
        baseURL: Const.settlementUrl,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: customParams,
        cancelToken: source.current.token,
      });
    } catch (e) {
      setIsLoading(false);
      console.error('getting ncash-deposit-list is fail: ' + e);
    }
  };
  const getData = async (paging) => {
    if (exceldownloading) return;
    let currPage = pagination.current;
    if (paging) {
      currPage = 1;
    }
    const kindNum = params.kind === '' ? null : params.kind;
    const typeParams = type.length < 1 ? checkboxOption : type;
    const paramsCategory =
      params.category === '' || params.category === '전체'
        ? null
        : params.category;
    const uType =
      loginInfo.branch.companyId === params.belongBranch ? 0 : params.userType;
    let customParams = {
      startDate: startDate,
      endDate: endDate,
      companyId: params.belongBranch,
      search: search,
      category: paramsCategory,
      ncashType: typeParams.join(),
      kind: kindNum,
      page: currPage,
      size: pagination.pageSize,
      region: Const.region,
      userType: uType,
    };
    const datas = await getNcashtDate(customParams);
    if (datas) {
      setList(() => {
        return datas.data.results;
      });
      setPagination({
        ...pagination,
        current: currPage,
        total: datas.data.totalCount,
      });
      setTotalDeposit(datas.data.totalDepositAmount);
      setTotalWithdrawal(datas.data.totalWithdrawalAmount);
      setIsLoading(false);
    }
    if (source.current === null) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    return () => {
      if (source.current !== null) {
        source.current.cancel();
      }
    };
  }, [params, pagination.current, type, props.refresh]);

  const downloadExcelApi = async (customParamsForExcel, url) => {
    if (source.current !== null) {
      // already exists request
      source.current.cancel();
    }
    source.current = CancelToken.source();
    try {
      return await axios.get(url, {
        baseURL: Const.settlementUrl,
        params: customParamsForExcel,
        cancelToken: source.current.token,
        responseType: 'arraybuffer',
      });
    } catch (e) {
      console.error('ncashCompanyList-execl-downloading is failed: ' + e);
    }
  };

  const onDownIoadExcel = async () => {
    let result = false;
    if (isLoading) return;
    setIsLoading(true);
    setExceldownloading(true);
    result = await onDownloadExcel();
    if (result) {
      Modal.success({
        content: '엑셀파일 다운로드가 완료되었습니다.',
      });
      setIsLoading(false);
      setExceldownloading(false);
    } else {
      Modal.error({
        content:
          '다운로드에 실패했습니다. 검색 기간을 줄여서 다시 시도하시거나 관리자에게 문의해주세요.',
      });
      setIsLoading(false);
      setExceldownloading(false);
    }
  };
  const onDownloadExcel = async () => {
    const agencyUrl = 'deposit/list/excel/ofAgency';
    const branchUrl = 'deposit/list/excel';
    const url =
      loginInfo.branch.companyId === params.belongBranch
        ? agencyUrl
        : branchUrl;
    const kindNum = params.kind === '' ? null : params.kind;
    const paramsCategory =
      params.category === '' || params.category === '전체'
        ? null
        : params.category;
    const typeParams = type.length < 1 ? checkboxOption : type;
    const uType =
      loginInfo.branch.companyId === params.belongBranch ? 0 : params.userType;
    let customParamsForExcel = {
      startDate: startDate,
      endDate: endDate,
      companyId: params.belongBranch,
      search: search,
      kind: kindNum,
      category: paramsCategory,
      ncashType: typeParams.join(),
      userType: uType,
    };
    const response = await downloadExcelApi(customParamsForExcel, url);
    if (response) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      let filename = branchNameForExcel
        ? branchNameForExcel
        : loginInfo.branch.companyId;
      FileSaver.saveAs(
        blob,
        `${filename}_전체내역_${moment(startDate).format('YYMMDD')}.xlsx`
      );
      return true;
    } else return false;
  };

  const onChangeBranch = (value) => {
    let beforeBranch = params.belongBranch;
    if (beforeBranch === value) return;
    if (
      (beforeBranch === loginInfo.branch.companyId && beforeBranch !== value) ||
      (beforeBranch !== loginInfo.branch.companyId &&
        loginInfo.branch.companyId === value)
    ) {
      setParams({
        kind: null,
        category: '전체',
        type: [],
        userType: 0,
        belongBranch: value,
      });
      setSelectLabel('전체');
    } else {
      setParams({
        ...params,
        belongBranch: value,
      });
    }
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const selectCategory =
    loginInfo.branch.companyId === params.belongBranch
      ? selectCategoryForAgency
      : params.userType === 1
      ? selectCategoryForBranchForRider
      : params.userType === 2
      ? selectCategoryForBranchForFr
      : selectCategoryForBranch;

  const columns =
    loginInfo.branch.companyId === params.belongBranch
      ? agencyNcashAllForAgency
      : agencyNcashAllForBranch;

  const onChangeDate = (type, _, date) => {
    if (type === 'start') {
      setStartDate(moment(date).format('YYYY-MM-DD'));
      const temp = moment(date).add(30, 'd').format('YYYY-MM-DD');
      if (moment(endDate).isAfter(temp)) setEndDate(temp);
    } else {
      setEndDate(moment(date).format('YYYY-MM-DD'));
    }
  };

  let lastYearDate = moment(today).add(-1, 'years').format('YYYY-12-31');
  let nextYearDate = moment(today).add(1, 'years').format('YYYY-01-01');

  const disabledDate = (current) => {
    if (startDate) {
      return Math.abs(current.diff(startDate, 'days')) > 30;
    } else
      return (
        current < moment(lastYearDate, 'YYYY-MM-DD') ||
        current > moment(nextYearDate, 'YYYY-MM-DD')
      );
  };

  const disabledStartDate = (current) => {
    return (
      current < moment(lastYearDate, 'YYYY-MM-DD') ||
      current > moment(nextYearDate, 'YYYY-MM-DD')
    );
  };
  console.log(selectLabel);
  return (
    <div>
      <div className={'ncl-menu-wrap'}>
        <div className={'ncl-menu-totalsum'}>
          <p style={{ color: '#FA2020', marginRight: 16 }}>
            총 출금 합계: {comma(totalWithdrawal)}
          </p>
          <p style={{ color: '#2036FA' }}>
            총 입금 합계: {comma(totalDeposit)}
          </p>
        </div>
        <div className={'ncl-select-box'}>
          <Select
            defaultValue={loginInfo.branch.branchName}
            style={{
              flex: 1,
            }}
            onChange={(value, item) => {
              setList([]);
              setBranchNameForExcel(item.name);
              onChangeBranch(value);
            }}>
            {branches.map((value) => {
              return (
                <Option
                  key={value.idx}
                  value={value.companyId}
                  name={value.branchName}>
                  {value.branchName}
                </Option>
              );
            })}
          </Select>
        </div>
        {loginInfo.branch.companyId !== params.belongBranch && (
          <div className={'ncl-select-box'}>
            <Select
              defaultValue={'구분'}
              onChange={(value, item) => {
                setList([]);
                setParams({
                  ...params,
                  kind: null,
                  type: [],
                  userType: value,
                  category: item.category,
                });
                setPagination({
                  ...pagination,
                  current: 1,
                });
                setSelectLabel('전체');
              }}
              style={{ flex: 1 }}
              options={[
                { value: 0, label: '구분', category: '' },
                { value: 1, label: '라이더', category: 'All' },
                { value: 2, label: '가맹점', category: 'All' },
              ]}
            />
          </div>
        )}
        <div className={'ncl-select-box'} style={{ marginRight: 10 }}>
          <Select
            defaultValue={'전체'}
            value={selectLabel}
            onChange={(value, item) => {
              setList([]);
              setParams({
                ...params,
                kind: item.kind,
                category: item.category,
              });
              setSelectLabel(value);
              setPagination({
                ...pagination,
                current: 1,
              });
            }}
            style={{ flex: 1 }}>
            {selectCategory.map((value) => {
              return (
                <Option
                  key={value.label}
                  value={value.label}
                  kind={value.kind}
                  category={value.category}>
                  {value.label}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className={'ncl-input-box'} style={{ width: 140 }}>
          <DatePicker
            style={{ border: 'none', flex: 1 }}
            onChange={(_, date) => onChangeDate('start', _, date)}
            value={moment(startDate)}
            disabledDate={disabledStartDate}
            allowClear={false}
          />
        </div>
        <span style={{ marginRight: 10 }}>-</span>
        <div className={'ncl-input-box'} style={{ width: 140, marginLeft: 0 }}>
          <DatePicker
            style={{ border: 'none', flex: 1 }}
            onChange={(_, date) => onChangeDate('end', _, date)}
            value={moment(endDate)}
            disabledDate={disabledDate}
            allowClear={false}
          />
        </div>
        <Button
          style={{
            height: 40,
            borderColor: '#c1c1c1',
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => getData(true)}>
          검색
        </Button>
        {loginInfo.branch.companyId !== params.belongBranch && (
          <Checkbox.Group
            className={'ncl-checkbox'}
            options={checkboxOption}
            defaultValue={checkboxOption}
            onChange={(check) => setType(check)}
          />
        )}
        <div
          style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          <div style={{ fontSize: 16, fontWeight: 'bold', marginRight: 10 }}>
            예치금 잔액: {comma(loginInfo.ncash)} 원
          </div>
          <div className={'ncl-ncash-btn'} onClick={() => props.modal(true)}>
            예치금 지급
          </div>
        </div>
      </div>
      <div className={'ncl-ctg-box'}>
        <div
          onClick={() => props.callback('all')}
          className={
            props.type === 'all' ? 'ncl-ctn-btn active' : 'ncl-ctn-btn'
          }>
          전체 내역
        </div>
        <div
          onClick={() => props.callback('delivery')}
          className={
            props.type === 'all' ? 'ncl-ctn-btn' : 'ncl-ctn-btn active'
          }>
          배달 내역
        </div>
        <div className={'ncl-input-box'} style={{ width: 240 }}>
          <Input
            onPressEnter={() => getData(true)}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={'라이더명/가맹점명/대리점명/비고'}
            style={{
              border: 'none',
              paddingLeft: 10,
              width: 200,
            }}
          />
          <SearchOutlined
            style={{ padding: '0 10px', cursor: 'pointer' }}
            onClick={() => getData(true)}
          />
        </div>
        <div className={'ncl-excel-box'}>
          <Tooltip
            title="기간 설정은 한달 단위로 선택이 가능합니다."
            placement="topRight">
            <div className={'ncl-excel-btn'} onClick={onDownIoadExcel}>
              전체 내역 엑셀다운로드
              <VerticalAlignBottomOutlined />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={'ncl-table'}>
        <Table
          rowKey={(record) => record.idx}
          dataSource={list}
          columns={columns}
          pagination={pagination}
          loading={isLoading}
          rowClassName={(record, index) => index % 2 !== 0 && 'ncl-table-row'}
          onChange={(pagination) => setPagination(pagination)}
        />
      </div>
    </div>
  );
};
export default AgencyNcashAllTbl;
