import React from 'react';
import { Layout } from 'antd';

const Footer = () => {
  return (
    <Layout.Footer
      style={{
        height: '10vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      ADMIN ©2021 Created by CONNECT9.
    </Layout.Footer>
  );
};

export default Footer;
