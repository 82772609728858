import { escape } from "lodash";

const getStringByteSize = (string) => {
    let stringByteLength = 0;

    for (let i = 0; i < string.length; i++) {
        if (escape(string.charAt(i)).length >= 4)
            stringByteLength += 3;
        else if (escape(string.charAt(i)) == "%A7")
            stringByteLength += 3;
        else
            if (escape(string.charAt(i)) != "%0D")
                stringByteLength++;
    }

    return stringByteLength;
}

export { getStringByteSize };

