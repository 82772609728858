import { SET_AGENCY_BRANCH } from '../actions/branchAction';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from '../const';

const branchInitialState = {
  branches: [],
};

const branchReducer = (state = branchInitialState, action) => {
  switch (action.type) {
    case SET_AGENCY_BRANCH:
      reactLocalStorage.setObject(
        con.appName + '#agencyBranch',
        action.branches
      );
      return {
        ...state,
        branches: action.branches,
      };
    default:
      return state;
  }
};
export default branchReducer;
