import { Button, Checkbox, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import CloseIcon from '../../img/close.png';

const AdminMenuAuthDialog = ({ data, close }) => {
  const [list, setList] = useState([]);
  const [authMenuIdxList, setAuthMenuIdxList] = useState([]);

  const getList = async () => {
    const res = await httpGet(httpUrl.menuList, [], {});
    if (res.data && res.result === 'SUCCESS') {
      setList(res.data.menues);
    }
  };

  const getAuthList = async () => {
    const res = await httpGet(httpUrl.adminWithAuthList, [data.idx], {});
    if (res.data && res.result === 'SUCCESS') {
      setAuthMenuIdxList(res.data.adminWithAuthes.map((x) => x.menuIdx));
    }
  };

  useEffect(async () => {
    await getList();
    await getAuthList();
  }, []);

  const handleToggleCheckBox = (checked, menuIdx) => {
    if (checked) {
      setAuthMenuIdxList(authMenuIdxList.concat(menuIdx));
    } else {
      setAuthMenuIdxList(authMenuIdxList.filter((idx) => idx !== menuIdx));
    }
  };

  const handleSubmit = async () => {
    console.log({
      adminUserIdx: data.idx,
      menuIdxList: authMenuIdxList,
    });
    try {
      const res = await httpPost(httpUrl.updateAdminUserMenuAuth, [], {
        adminUserIdx: data.idx,
        menuIdxList: authMenuIdxList,
      });
      console.log(res);
      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '메뉴권한 설정 완료',
          content: `${data.id}의 메뉴권한을 설정했습니다.`,
        });
        close();
      } else {
        Modal.warn({
          title: '메뉴권한 설정 실패',
          content: '메뉴권한 설정에 실패했습니다.',
        });
      }
    } catch (e) {
      Modal.warn({
        title: '메뉴권한 설정 실패',
        content: '메뉴권한 설정에 실패했습니다.',
      });
    }
  };

  return (
    <React.Fragment>
      <div className="Dialog-overlay" onClick={close} />
      <div
        className="admim-menu-Dialog"
        style={{ height: '80%', overflowY: 'auto' }}>
        <div className="admin-menu-header">
          <div className="admin-title">메뉴권한설정 ({data.id})</div>
          <img
            onClick={close}
            style={{ display: 'inline' }}
            src={CloseIcon}
            className="dialog-close"
            alt="img"
          />
        </div>

        <div className="admin-menu-body">
          <div className="admin-menu-container">
            {list.map((parent) => {
              return (
                <div className="admin-menu-inner-container">
                  <div className="menu-title">
                    <Checkbox
                      key={parent.idx}
                      checked={authMenuIdxList.includes(parent.idx)}
                      onChange={(e) =>
                        handleToggleCheckBox(e.target.checked, parent.idx)
                      }>
                      {parent.nameKr}
                    </Checkbox>
                  </div>
                  <div>
                    {parent.subMenus.map((child) => {
                      return (
                        <div className="menu-subTitle">
                          <Checkbox
                            key={child.idx}
                            checked={authMenuIdxList.includes(child.idx)}
                            onChange={(e) =>
                              handleToggleCheckBox(e.target.checked, child.idx)
                            }
                            disabled={!authMenuIdxList.includes(parent.idx)}>
                            {child.nameKr}
                          </Checkbox>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="menuBtn">
            <Button onClick={() => handleSubmit()}>설정하기</Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminMenuAuthDialog;
