import { Button, Form, Input, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
// import ClickSwitch from '../components/input/ClickSwitch';
import '../../css/main.css';
import CloseIcon from '../../img/close.png';

const FormItem = Form.Item;
const Option = Select.Option;

class RegistAdminDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      isModifyMode: false,
      branchList: [],
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.data) {
      this.formRef.current.setFieldsValue({ ...this.props.data });
      this.setState({
        isModifyMode: true,
      });
    }
    this.getBranchList();
  }

  getBranchList = () => {
    httpGet(httpUrl.getBranchList, [1, 10000], {}).then((res) => {
      console.log(res);
      this.setState({
        branchList: res.data.branch,
      });
    });
  };

  // 유저 생성 api
  createHandleSubmit = () => {
    console.log(this.formRef.current.getFieldsValue().branchIdx);
    httpPost(httpUrl.createAdminUser, [], {
      id: this.formRef.current.getFieldsValue().id,
      branchIdx: this.formRef.current.getFieldsValue().branchIdx,
      password: this.formRef.current.getFieldsValue().password,
      phone: this.formRef.current.getFieldsValue().phone,
      email: this.formRef.current.getFieldsValue().email,
    }).then((res) => {
      console.log(res);
      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '등록 성공',
          content: '어드민(운영자) 등록에 성공했습니다.',
        });
        this.props.close();
      } else {
        Modal.warn({
          title: '등록 실패',
          content: '어드민(운영자) 등록에 실패했습니다.',
        });
      }
    });
  };

  // 유저 정보 변경 api
  modifyHandleSubmit = () => {
    httpPost(httpUrl.updateAdminUser, [], {
      idx: this.props.data.idx,
      branchIdx: this.formRef.current.getFieldsValue().branchIdx,
      password: this.formRef.current.getFieldsValue().password,
      phone: this.formRef.current.getFieldsValue().phone,
      email: this.formRef.current.getFieldsValue().email,
    }).then((res) => {
      console.log(res);
      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '변경 성공',
          content: '어드민(운영자) 정보 변경에 성공했습니다.',
        });
        this.props.close();
      } else {
        Modal.warn({
          title: '변경 실패',
          content: '어드민(운영자) 정보 변경에 실패했습니다.',
        });
      }
    });
  };

  render() {
    const { close, data } = this.props;
    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="systemKey-Dialog">
          <div className="systemKey-content">
            <div className="regist-header">
              <div className="systemKey-title">
                {this.state.isModifyMode ? '운영자 수정' : '운영자 생성'}
              </div>
              <img
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="dialog-close"
                alt="img"
              />
            </div>
            <div className="systemKey-inner">
              <Form
                ref={this.formRef}
                onFinish={
                  this.state.isModifyMode
                    ? this.modifyHandleSubmit
                    : this.createHandleSubmit
                }>
                <div className="regist-warp">
                  <div className="regist-title">지점</div>
                  <FormItem
                    name="branchIdx"
                    className="regist-title"
                    initialValue={data ? data.branchIdx : '필수선택사항입니다'}
                    rules={[
                      {
                        required: true,
                        message: '지점을 선택해주세요',
                      },
                    ]}>
                    <Select
                      onChange={(value) => {
                        this.setState({ branchIdx: value });
                      }}>
                      {this.state.branchList.map((value, index) => {
                        return (
                          <Option value={value.idx}>{value.branchName}</Option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </div>
                <div className="regist-warp">
                  <div className="regist-title">아이디</div>
                  {this.state.isModifyMode ? (
                    <div className="regist-title">{data.id}</div>
                  ) : (
                    <FormItem
                      name="id"
                      className="regist-title"
                      rules={[
                        {
                          required: true,
                          message: '아이디를 입력해주세요',
                        },
                      ]}>
                      <Input
                        className="regist-input"
                        placeholder="아이디를 입력해주세요."
                      />
                    </FormItem>
                  )}
                </div>
                <div className="regist-warp password">
                  <div className="regist-title">비밀번호</div>
                  <FormItem
                    name="password"
                    className="regist-title"
                    rules={[
                      {
                        required: true,
                        message: '비밀번호를 입력해주세요',
                      },
                    ]}>
                    <Input
                      className="regist-input"
                      placeholder="비밀번호를 입력해주세요."
                      type="password"
                    />
                  </FormItem>
                </div>
                {this.state.isModifyMode && (
                  <p
                    style={{
                      // width: 300,
                      marginBottom: 0,
                      color: 'red',
                      fontSize: 12,
                    }}>
                    * 정보 수정시 비밀번호를 입력하지 않으면 기존 비밀번호로
                    유지됩니다.
                  </p>
                )}

                <div className="regist-warp">
                  <div className="regist-title">핸드폰</div>
                  {this.state.isModifyMode ? (
                    <FormItem name="phone" className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder={data.phone}
                      />
                    </FormItem>
                  ) : (
                    <FormItem name="phone" className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder="핸드폰번호를 입력해주세요."
                      />
                    </FormItem>
                  )}
                </div>
                <div className="regist-warp">
                  <div className="regist-title">이메일</div>
                  {this.state.isModifyMode ? (
                    <FormItem name="email" className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder={data.email}
                      />
                    </FormItem>
                  ) : (
                    <FormItem name="email" className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder="이메일을 입력해주세요."
                      />
                    </FormItem>
                  )}
                </div>

                <Button
                  type="primary"
                  htmlType="submit"
                  className="create-control-submit-btn"
                  style={{ width: '100%' }}>
                  {this.state.isModifyMode ? '수정하기' : '등록하기'}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { login } = state;
  return { login };
};

export default withRouter(connect(mapStateToProps, null)(RegistAdminDialog));
