import { Button, DatePicker, Input, Table, Select, Modal } from 'antd';
import React, { Component } from 'react';
import { httpPost, httpUrl } from '../../api/httpClient';
import CreateControlAgentDialog from '../../components/dialog/CreateControlAgentDialog';
import LoginLimitDialog from '../../components/dialog/LoginLimitDialog';
import '../../css/main.css';
import { formatDates } from '../../lib/util/dateUtil';
import { statusString, statusCode } from '../../lib/util/codeUtil';
import SelectBox from '../../components/input/SelectBox';
import {customAlert, updateError} from "../../api/Modals";

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

class ControlAgentManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      systemKeyDialogOpen: false, //메뉴권한설정
      systemDialogOpen: false, //메뉴권한설정2
      createControlAgentDialogOpen: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      list: [],
      searchId: '',
      userStatus: 1,
    };
    this.columns = [
      {
        title: '순번',
        dataIndex: 'idx',
        className: 'table-column-center',
      },
      {
        title: '상태',
        dataIndex: 'userStatus',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            <SelectBox
              defaultValue={statusString[1]}
              code={Object.keys(statusString)}
              codeString={statusString}
              value={statusString[data]}
              onChange={(value) => {
                const self = this;
                Modal.confirm({
                  title: '관제요원 상태 변경',
                  content: `관제요원 상태를 ${statusString[value]}로 변경하시겠습니까?`,
                  okText: '확인',
                  cancelText: '취소',
                  onOk() {
                    const menuAuthArr = [...row.menuAuths];
                    httpPost(httpUrl.updateControlAgent, [], {
                      idx: row.idx,
                      branchIdx: row.branchIdx,
                      id: row.id,
                      password: row.password,
                      phone: row.phone.split('-').join(''),
                      riderName: row.riderName,
                      userStatus: value,
                      menuAuths: menuAuthArr,
                    }).then((res) => {
                      if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
                        self.getList();
                      } else {
                        Modal.info({
                          title: '변경 실패',
                          content: '관제요원 상태 변경에 실패했습니다.',
                        });
                      }
                    });
                  },
                });
              }}
            />
          </div>
        ),
      },
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '아이디',
        dataIndex: 'id',
        className: 'table-column-center',
      },
      {
        title: '이름',
        dataIndex: 'riderName',
        className: 'table-column-center',
      },
      {
        title: '핸드폰',
        dataIndex: 'phone',
        className: 'table-column-center',
      },
      {
        title: '최종 로그인 일시',
        dataIndex: 'loginDate',
        className: 'table-column-center',
        render: (data) => formatDates(data),
      },
      {
        title: '디바이스',
        dataIndex: 'signupDeviceId',
        className: 'table-column-center',
        render: (data, row) => (
            <div>
              <Button
                className="tabBtn"
                onClick={() => this.onResetSignupDeviceId(row)}
                disabled={!data}>
                초기화
              </Button>
            </div>
        ),
      },
      {
        title: '수정',
        dataIndex: '',
        className: 'table-column-center',
        render: (data, row) => (
          <Button onClick={() => this.openCreateControlAgent(row)}>
            수정하기
          </Button>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getList();
  }

  setDate = (date) => {
    console.log(date);
  };
  openSystemKeyDialogModal = () => {
    this.setState({ systemKeyDialogOpen: true });
  };
  closeSystemKeyDialogModal = () => {
    this.setState({ systemKeyDialogOpen: false });
  };
  openCreateControlAgent = (row) => {
    this.setState(
      {
        selectedRow: row,
      },
      () => this.setState({ createControlAgentDialogOpen: true })
    );
  };
  closeCreateControlAgent = () => {
    this.setState({ createControlAgentDialogOpen: false });
  };
  openLoginLimitDialog = () => {
    this.setState({
      loginLimitDialogOpen: true,
    });
  };
  closeLoginLimitDialog = () => {
    this.setState({
      loginLimitDialogOpen: false,
    });
  };

  getList = () => {
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let id = this.state.searchId;
    let userStatus = this.state.userStatus;
    httpPost(httpUrl.getControlUser, [], {
      pageNum,
      pageSize,
      id,
      userStatus,
    }).then((res) => {
      if (res.data !== 'FAIL') {
        this.setState({
          list: res.data.controlUsers,
          pagination: {
            ...this.state.pagination,
            total: res.data.totalCount,
          },
        });
      }
    });
  };

  handleTableChange = (pagination) => {
    this.setState(
      {
        pagination,
      },
      () => this.getList()
    );
  };

  onCheckType = (value) => {
    this.setState({ userStatus: value }, () => this.getList());
  };

  onSearch = (searchId) => {
    this.setState(
      {
        searchId: searchId,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  onResetSignupDeviceId = (row) => {
    let self = this;
    Modal.confirm({
      title: <div> {'디바이스 초기화'} </div>,
      content: <div> {'디바이스를 초기화하시겠습니까?'} </div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.clearSignupDeviceId, [], {
          userIdx: row.idx,
        })
            .then((result) => {
              if (result.result === 'SUCCESS' && result.data?.toUpperCase() === 'SUCCESS') {
                customAlert('완료', '디바이스가 초기화되었습니다.');
              } else updateError();
              self.getList();
            })
            .catch((error) => {
              updateError();
            });
      },
    });
  };

  render() {
    return (
      <>
        {/* {this.state.systemKeyDialogOpen && (
          <SystemKeyDialog close={this.closeSystemKeyDialogModal} />
        )}

        <Button
          style={{ marginBottom: 20 }}
          onClick={this.openSystemKeyDialogModal}
        >
          메뉴권한 설정
        </Button> */}

        {this.state.createControlAgentDialogOpen && (
          <CreateControlAgentDialog
            data={this.state.selectedRow}
            getList={this.getList}
            close={this.closeCreateControlAgent}
          />
        )}
        <SelectBox
          placeholder={'사용'}
          defaultValue={statusCode[1]}
          code={Object.keys(statusCode)}
          codeString={statusCode}
          value={statusCode[this.state.userStatus]}
          onChange={this.onCheckType}
        />
        <Search
          placeholder="아이디 검색"
          enterButton
          allowClear
          onSearch={this.onSearch}
          style={{
            width: 220,
            marginLeft: 10,
            marginBottom: 20,
          }}
        />
        <Button
          style={{ marginBottom: 20, marginLeft: 10 }}
          onClick={() => this.openCreateControlAgent(null)}>
          관제요원 생성
        </Button>

        {this.state.loginLimitDialogOpen && (
          <LoginLimitDialog close={this.closeLoginLimitDialog} />
        )}
        <Button
          style={{ marginBottom: 20, marginLeft: 10 }}
          onClick={() => this.openLoginLimitDialog()}>
          접속제한 해제
        </Button>

        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) =>
            record.status === 'COMPLETE' ? 'table-disabled' : ''
          }
          dataSource={this.state.list}
          columns={this.columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

export default ControlAgentManage;
