import { Form, Input, Select, Table } from 'antd';
import React, { Component } from 'react';
import '../../css/main.css';
import { comma } from '../../lib/util/numberUtil';
import CloseIcon from '../../img/close.png';

const Search = Input.Search;
const FormItem = Form.Item;
const Option = Select.Option;

class ChargeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    var list = [
      {
        franIdx: '냠냠박스1지점',
        chargeFee: 20000,
        date: '2021-06-03',
      },
      {
        franIdx: '냠냠박스1지점',
        chargeFee: 20000,
        date: '2021-06-01',
      },
      {
        franIdx: '냠냠박스1지점',
        chargeFee: 20000,
        date: '2021-05-22',
      },
      {
        franIdx: '냠냠박스1지점',
        chargeFee: 20000,
        date: '2021-05-20',
      },
      {
        franIdx: '냠냠박스1지점',
        chargeFee: 20000,
        date: '2021-04-03',
      },
    ];

    this.setState({
      list: list,
    });
  };

  render() {
    const columns = [
      {
        title: '가맹점명',
        dataIndex: 'franIdx',
        className: 'table-column-center',
      },
      {
        title: '가맹점번호',
        dataIndex: 'franNum',
        className: 'table-column-center',
      },
      {
        title: '가맹점주소',
        dataIndex: 'franAddr',
        className: 'table-column-center',
      },

      {
        title: '충전금액',
        dataIndex: 'chargeFee',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}원</div>,
      },
      {
        title: '날짜',
        dataIndex: 'date',
        className: 'table-column-center',
      },
    ];

    const { close, data } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="charge-Dialog">
          <div className="charge-content">
            <div className="charge-title">충전내역</div>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="dialog-close"
              alt="img"
            />

            <div className="charge-inner">
              <div className="contentBlock"></div>

              <div className="contentBlock">
                <Table
                  rowKey={(record) => record.idx}
                  rowClassName={(record) =>
                    record.status === 'COMPLETE' ? 'table-disabled' : ''
                  }
                  dataSource={this.state.list}
                  columns={columns}
                  pagination={this.state.pagination}
                  onChange={this.handleTableChange}
                  expandedRowRender={this.expandedRowRender}
                  expandRowByClick={true}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChargeDialog;
