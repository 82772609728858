import { Form, Input, Table, Button, Modal } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl, httpPost } from '../../api/httpClient';
import {
  bikeStatusType,
  ownerType,
  bikeResponseErrorCode,
  bikeLocationType,
} from '../../lib/util/codeUtil';
import { internationalAge } from '../../lib/util/ageUtil';
import { formatDates } from '../../lib/util/dateUtil';
import { comma } from '../../lib/util/numberUtil';
import BikeHistoryDialog from '../../components/dialog/BikeHistoryDialog';
import BikeAssignDialog from '../../components/dialog/BikeAssignDialog';
import '../../css/main.css';

const FormItem = Form.Item;

class BikeReleaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      bikeStatus: 1,
      isBikeHistoryOpen: false,
      isBikeAssignOpen: false,
      bikeLocation: {},
    };
    this.formRef = React.createRef();
  }

  async componentDidMount() {
    await this.getList();
    this.getBikeLocation();
  }

  getBikeLocation = () => {
    httpPost(httpUrl.bikeLocationInfo, [], {}).then((res) => {
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data);
        res.data.map((item) => (bikeLocationType[item.idx] = item.branchName));

        this.setState(
          {
            bikeLocation: bikeLocationType,
          },
          () => {
            console.log('bikeLocation');
            console.log(this.state.bikeLocation);
          }
        );
      }
    });
  };

  getList = async () => {
    const pagination = this.state.pagination;
    try {
      const res = await httpGet(
        httpUrl.getBikeReleaseList,
        [pagination.current, pagination.pageSize],
        {}
      );
      console.log(res);
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.bikeRelease,
          pagination: {
            ...this.state.pagination,
            total: res.data.totalCount,
          },
        });
      }
    } catch (error) {
      console.error('getBikeReleaseList error : ', error);
    }
  };

  bikeStatusChange = (record, checkValue) => {
    let bikeIdx = record.bikeIdx;
    let bikeStatus =
      checkValue === 'receive' ? 2 : record.bikeStatus === 2 ? 4 : 1;
    let branchName = record.branchName;
    let riderName = record.riderName;
    let riderIdx = record.riderIdx;
    httpPost(httpUrl.bikeStatusChange, [], {
      bikeIdx,
      bikeStatus,
      branchName,
      riderName,
      riderIdx,
    })
      .then((res) => {
        console.log(res);
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          Modal.info({
            title: '성공',
            content: '상태 변경에 성공했습니다.',
          });
        } else if (res.data.startsWith('INVALID')) {
          Modal.warn({
            title: '등록실패',
            content: `상태 변경에 실패했습니다. ${
              bikeResponseErrorCode[res.data]
            }을(를) 확인해 주세요.`,
          });
        } else {
          Modal.warn({
            title: '실패',
            content: '상태 변경에 실패했습니다.',
          });
        }
        this.getList();
      })
      .catch((error) => {
        Modal.warn({
          title: '실패',
          content: `상태 변경에 실패했습니다. (${error.response.data.message})`,
        });
      });
  };

  nullCheck = (data) => {
    return data === null || data === undefined ? '-' : data;
  };

  openBikeAssignDialog = (row) => {
    this.setState({
      isBikeAssignOpen: true,
      selectedRow: row,
    });
  };

  closeBikeAssignDialog = async() => {
    await this.getList();
    this.setState({ isBikeAssignOpen: false });
  };

  openBikeHistoryDialog = (row) => {
    this.setState({
      isBikeHistoryOpen: true,
      selectedRow: row,
    });
  };

  closeBikeHistoryDialog = () => {
    this.setState({ isBikeHistoryOpen: false }, () => {
      this.getList();
    });
  };

  getInsuranceAge = (data) => {
    if (data == null) {
      return '-';
    } else if (data === 0) {
      return '전연령';
    } else {
      return '만 ' + data + '세';
    }
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  render() {
    const columns = [
      {
        title: '라이더 정보',
        children: [
          {
            title: '지점',
            dataIndex: 'branchName',
            className: 'table-column-center',
          },
          {
            title: '이름',
            dataIndex: 'riderName',
            className: 'table-column-center',
          },
          {
            title: '만나이',
            dataIndex: 'registrationNumber',
            className: 'table-column-center',
            render: (data) => (
              <div>
                {data !== null && data !== '*'
                  ? '만 ' + internationalAge(data) + '세'
                  : '-'}
              </div>
            ),
          },
        ],
      },
      {
        title: '-',
        children: [
          {
            title: '바이크상태',
            dataIndex: 'bikeStatus',
            className: 'table-column-center',
            render: (data) => (
              <div>
                {data === null ? bikeStatusType[1] : bikeStatusType[data]}
              </div>
            ),
          },
          {
            title: '바이크위치',
            dataIndex: 'unassignBikeLocation',
            className: 'table-column-center',
            render: (data) => this.state.bikeLocation[data],
          },
          {
            title: '-',
            dataIndex: 'bikeStatus',
            className: 'table-column-center',
            width: '10%',
            render: (data, row) => (
              <div>
                {bikeStatusType[data] === '배정완료' ? (
                  <Button
                    onClick={() => {
                      this.bikeStatusChange(row, 'receive');
                    }}>
                    수령완료
                  </Button>
                ) : (
                  ''
                )}
                {bikeStatusType[data] === '운행중' ||
                bikeStatusType[data] === '수리입고' ||
                bikeStatusType[data] === '배정완료' ? (
                  <Button
                    onClick={() => {
                      this.bikeStatusChange(row, 'cancel');
                    }}>
                    취소
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      this.openBikeAssignDialog(row);
                    }}>
                    바이크 배정
                  </Button>
                )}
              </div>
            ),
          },
        ],
      },
      {
        title: '바이크 정보',
        children: [
          {
            title: '차종',
            dataIndex: 'modelName',
            className: 'table-column-center',
            render: (data, row) =>
              bikeStatusType[row.bikeStatus] === '운행중'
                ? this.nullCheck(data)
                : '-',
          },
          {
            title: '차량번호',
            dataIndex: 'bikeNumber',
            className: 'table-column-center',
            render: (data, row) =>
              bikeStatusType[row.bikeStatus] === '운행중'
                ? this.nullCheck(data)
                : '-',
          },
          {
            title: '연식',
            dataIndex: 'modelYear',
            className: 'table-column-center',
            render: (data, row) =>
              bikeStatusType[row.bikeStatus] === '운행중'
                ? this.nullCheck(data)
                : '-',
          },
          {
            title: '차대번호',
            dataIndex: 'viNumber',
            className: 'table-column-center',
            render: (data, row) =>
              bikeStatusType[row.bikeStatus] === '운행중'
                ? this.nullCheck(data)
                : '-',
          },
          {
            title: '명의자',
            dataIndex: 'owner',
            className: 'table-column-center',
            render: (data, row) =>
              bikeStatusType[row.bikeStatus] === '운행중'
                ? this.nullCheck(ownerType[data])
                : '-',
          },
          {
            title: '보험나이',
            dataIndex: 'insuranceAge',
            className: 'table-column-center',
            render: (data, row) =>
              bikeStatusType[row.bikeStatus] === '운행중'
                ? this.getInsuranceAge(data)
                : '-',
          },
          {
            title: '보험만기일',
            dataIndex: 'insuranceEndDate',
            className: 'table-column-center',
            render: (data, row) =>
              bikeStatusType[row.bikeStatus] === '운행중'
                ? this.nullCheck(formatDates(data))
                : '-',
          },
          {
            title: '렌트비',
            dataIndex: 'rentFee',
            className: 'table-column-center',
            render: (data, row) =>
              bikeStatusType[row.bikeStatus] === '운행중' ? (
                <div>{comma(data) === 0 ? '-' : comma(data)}</div>
              ) : (
                <>-</>
              ),
          },
        ],
      },
      {
        title: '-',
        children: [
          {
            title: '히스토리',
            dataIndex: 'bikeHistory',
            className: 'table-column-center',
            width: '3%',
            render: (data, row) => (
              <div>
                <Button
                  onClick={() => {
                    this.openBikeHistoryDialog(row);
                  }}>
                  보기
                </Button>
              </div>
            ),
          },
        ],
      },
    ];

    return (
      <FormItem>
        {this.state.isBikeHistoryOpen && (
          <BikeHistoryDialog
            data={this.state.selectedRow}
            close={this.closeBikeHistoryDialog}
          />
        )}
        {this.state.isBikeAssignOpen && (
          <BikeAssignDialog
            data={this.state.selectedRow}
            close={this.closeBikeAssignDialog}
          />
        )}
        <Table
          rowKey={(record) => record}
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </FormItem>
    );
  }
}

export default BikeReleaseList;
