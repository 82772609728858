import {Button, Input, Modal, Table} from 'antd';
import React, { Component } from 'react';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { comma } from '../../lib/util/numberUtil';
import CreateBranchShareFeeDialog from '../../components/dialog/CreateBranchShareFeeDialog';
import FormItem from "antd/lib/form/FormItem";
import {customAlert, customError} from "../../api/Modals";
const Search = Input.Search;

class BranchShareFeeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      list: [],
      branchNameList: { 0: '전체지점' },
      branchNameValue: 0,
      searchBranchName: '',
      isCreateBranchShareFeeOpen: false,
    };
  }

  componentDidMount() {
    this.getBranchInfo();
    this.getList();
  }

  getBranchInfo = async () => {
    let branchNameList = this.state.branchNameList;
    try {
      const res = await httpPost(httpUrl.branchListInfoExceptAgency, [], {});
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data);
        res.data.branch.map(
            (item) => (branchNameList[item.idx] = item.branchName)
        );
        this.setState({
          branchNameList: branchNameList,
        });
      }
    } catch (error) {
      console.warn('getBranchInfo error: {}', error);
    }
  };


  getList = () => {
    const { pagination } = this.state;
    const pageNum = pagination.current;
    const pageSize = pagination.pageSize;
    const branchName = this.state.searchBranchName;
    httpGet(httpUrl.getBranchShareFeeList, [branchName, pageNum, pageSize], {}).then((res) => {
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.list,
        pagination: pagination,
      });
    });
  };

  openCreateBranchShareFeeDialog = (row) => {
    this.setState({
      isCreateBranchShareFeeOpen: true,
      selectedRow: row,
    });
  };

  closeCreateBranchShareFeeDialog = () => {
    this.setState({ isCreateBranchShareFeeOpen: false }, () => {
      this.getList();
    });
  };

  deleteBranchShareFee = (row) => {
    let self = this;
    Modal.confirm({
      title: '지점간 공유콜 수수료 삭제',
      content: '지점간 공유콜 수수료를 삭제 하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.deleteBranchShareFee, [], {
          branchIdx1: row.branchIdx1,
          branchIdx2: row.branchIdx2,
        })
        .then((result) => {
          if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
            customAlert(
                '수수료 삭제 완료',
                '공유 지점 수수료 삭제 완료 하였습니다.'
            );
            self.getList();
          } else {
            customError(
                '수수료 삭제 에러',
                '공유 지점 수수료 삭제에 실패하였습니다'
            );
          }
        })
        .catch((e) => {
          customError(
              '수수료 삭제 에러',
              '서버 에러로 인해 공유 지점 수수료 삭제에 실패하였습니다'
          );
        });
      },
    });
  };

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
        {
          pagination: pager,
        },
        () => this.getList()
    );
  };

  render() {
    const columns = [
      {
        title: '지점1',
        dataIndex: 'branchName1',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '지점2',
        dataIndex: 'branchName2',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '수수료',
        dataIndex: 'fee',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '수정',
        className: 'table-column-center',
        render: (data, row) => (
            <div>
              <Button
                  onClick={() => {
                    this.openCreateBranchShareFeeDialog(row);
                  }}>
                {'수정'}

              </Button>
              <Button
                  onClick={() => {
                    this.deleteBranchShareFee(row);
                  }}>
                {'삭제'}

              </Button>
            </div>
        ),
      },
    ];

    return (
        <FormItem>
          {this.state.isCreateBranchShareFeeOpen && (
              <CreateBranchShareFeeDialog
                  data={this.state.selectedRow}
                  close={this.closeCreateBranchShareFeeDialog}
              />
          )}
          <div style={{fontSize: '20px'}}>
          지점 공유콜 수수료 목록
          </div>
          <div style={{display: 'inline-block'}}>
            <Button
                style={{
                  borderColor: '#888',
                }}
                onClick={() => {
                  this.openCreateBranchShareFeeDialog(null);
                }}
                htmlType="submit">
              등록하기
            </Button>
            <Search
                placeholder="지점 검색"
                enterButton
                allowClear
                onChange={(e) => this.setState({ searchBranchName: e.target.value })}
                onSearch={this.getList}
                style={{
                  width: 220,
                }}
            />
          </div>

        <Table
            rowKey={(record) => record.idx}
            dataSource={this.state.list}
            columns={columns}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
        />
        </FormItem>
    );
  }
}

export default BranchShareFeeList;
