import {Button, Form, Input, Modal, Select} from 'antd';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {httpGet, httpPost, httpUrl} from '../../../api/httpClient';
import '../../../css/main.css';
import CloseIcon from '../../../img/close.png';

const FormItem = Form.Item;
const Option = Select.Option;

class BranchOfRiderFixedCommissionDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 5,
            },
            isModifyMode: false,
            branchList: [],
        };
        this.formRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.data) {
            this.formRef.current.setFieldsValue({...this.props.data});
            this.setState({
                isModifyMode: true,
            });
        }
        this.getBranchList();
    }

    getBranchList = () => {
        httpGet(httpUrl.getBranchList, [1, 10000], {}).then((res) => {
            this.setState({
                branchList: res.data.branch,
            });
        });
    };

    // 생성 api
    createHandleSubmit = () => {
        const getFieldsValue = this.formRef.current.getFieldsValue();
        httpPost(httpUrl.createBranchOrderShareCommission, [], {
            branchIdx: getFieldsValue.branchIdx,
            targetBranchIdx: getFieldsValue.targetBranchIdx,
            targetCommission: getFieldsValue.targetCommission,
        }).then((res) => {
            if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
                Modal.info({
                    title: '등록 성공',
                    content: '고정수수료 등록에 성공했습니다.',
                });
                this.props.close();
                this.props.getList();
            } else if (res.data === 'SETTING_TARGET_EQUALS') {
                Modal.warn({
                    title: '등록 실패',
                    content: '설정지점과 대상지점이 동일합니다.',
                });
            } else if (res.data === 'INAPPROPRIATE_COMMISSION') {
                Modal.warn({
                    title: '등록 실패',
                    content: '고정수수료 값을 다시 한번 확인해주세요.',
                });
            } else if (res.data === 'DATA_ALREADY_EXISTS') {
                Modal.warn({
                    title: '등록 실패',
                    content: '이미 동일한 데이터가 존재하는지 확인해주세요.',
                });
            } else {
                Modal.warn({
                    title: '등록 실패',
                    content: '고정수수료 등록에 실패했습니다.',
                });
            }
        });
    };

    // 유저 정보 변경 api
    modifyHandleSubmit = () => {
        const getFieldsValue = this.formRef.current.getFieldsValue();
        httpPost(httpUrl.updateBranchOrderShareCommission, [], {
            branchIdx: getFieldsValue.branchIdx,
            targetBranchIdx: getFieldsValue.targetBranchIdx,
            targetCommission: getFieldsValue.targetCommission,
        }).then((res) => {
            if (res.data === 'SUCCESS') {
                Modal.info({
                    title: '변경 성공',
                    content: '고정수수료 금액 변경에 성공했습니다.',
                });
                this.props.close();
                this.props.getList();
            } else if (res.data === 'NO_DATA') {
                Modal.warn({
                    title: '변경 실패',
                    content: '변경하고자 하는 데이터를 조회하는데 실패했습니다.',
                });
            } else if (res.data === 'INAPPROPRIATE_COMMISSION') {
                Modal.warn({
                    title: '등록 실패',
                    content: '고정수수료 값을 다시 한번 확인해주세요.',
                });
            } else {
                Modal.warn({
                    title: '변경 실패',
                    content: '고정수수료 금액 변경에 실패했습니다.',
                });
            }
        });
    };

    render() {
        const {close, data} = this.props;
        return (
            <React.Fragment>
                <div className="Dialog-overlay" onClick={close}/>
                <div className="systemKey-Dialog">
                    <div className="systemKey-content">
                        <div className="regist-header">
                            <div className="systemKey-title">
                                {this.state.isModifyMode ? '라이더지점 고정수수료 수정' : '라이더지점 고정수수료 등록'}
                            </div>
                            <img
                                onClick={close}
                                style={{display: 'inline'}}
                                src={CloseIcon}
                                className="dialog-close"
                                alt="img"
                            />
                        </div>
                        <div className="systemKey-inner">
                            <Form
                                ref={this.formRef}
                                onFinish={
                                    this.state.isModifyMode
                                        ? this.modifyHandleSubmit
                                        : this.createHandleSubmit
                                }>
                                <div className="regist-warp">
                                    <div className="regist-title">설정지점</div>
                                    <FormItem
                                        name="branchIdx"
                                        className="regist-title"
                                        initialValue={data && data.branchIdx}
                                        rules={[
                                            {
                                                required: true,
                                                message: '지점을 선택해주세요',
                                            },
                                        ]}>
                                        <Select
                                            disabled={this.state.isModifyMode}
                                            onChange={(value) => {
                                                this.setState({branchIdx: value});
                                            }}>
                                            {this.state.branchList.map((value, index) => {
                                                return (
                                                    <Option value={value.idx}>{value.branchName}</Option>
                                                );
                                            })}
                                        </Select>
                                    </FormItem>
                                </div>
                                <div className="regist-warp">
                                    <div className="regist-title">대상지점</div>
                                    <FormItem
                                        name="targetBranchIdx"
                                        className="regist-title"
                                        initialValue={data && data.targetBranchIdx}
                                        rules={[
                                            {
                                                required: true,
                                                message: '지점을 선택해주세요',
                                            },
                                        ]}>
                                        <Select
                                            disabled={this.state.isModifyMode}
                                            onChange={(value) => {
                                                this.setState({targetBranchIdx: value});
                                            }}>
                                            {this.state.branchList.map((value, index) => {
                                                return (
                                                    <Option value={value.idx}>{value.branchName}</Option>
                                                );
                                            })}
                                        </Select>
                                    </FormItem>
                                </div>
                                <div className="regist-warp">
                                    <div className="regist-title">고정수수료</div>
                                    <FormItem
                                        name="targetCommission"
                                        className="regist-title"
                                        initialValue={this.state.isModifyMode ? data.targetCommission : 0}
                                        rules={[
                                            {
                                                required: true,
                                                message: '금액을 입력해주세요',
                                            },
                                        ]}>
                                        <Input
                                            className="regist-input"
                                            placeholder="금액을 입력해주세요."
                                            type="number"
                                        />
                                    </FormItem>
                                </div>

                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="create-control-submit-btn"
                                    style={{width: '100%'}}>
                                    {this.state.isModifyMode ? '수정하기' : '등록하기'}
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {login} = state;
    return {login};
};

export default withRouter(connect(mapStateToProps, null)(BranchOfRiderFixedCommissionDialog));
