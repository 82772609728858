import {Button, Table} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { comma } from '../../lib/util/numberUtil';
import FormItem from "antd/lib/form/FormItem";
import BranchSettlementDialog
  from "../../components/dialog/BranchSettlementDetailDialog";

const today = new moment();
const dateFormat = 'YYYY-MM-DD';
const todayString = today.format(dateFormat);
const viewMonthString = moment(todayString).date() == 1 ? moment(todayString).subtract(1, 'month').month() + 1 : moment(todayString).month() + 1;

class BranchSettlementAllList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
      },
      list: [],
      beforeData: [],
      branchNameList: { 0: '전체지점' },
      isDetailSettlementOpen: false,
      targetDate: moment(todayString).format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    this.getBranchInfo();
    this.getList();
  }

  getBranchInfo = async () => {
    let branchNameList = this.state.branchNameList;
    try {
      const res = await httpPost(httpUrl.branchListInfo, [], {});
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data);
        res.data.branch.map(
            (item) => (branchNameList[item.idx] = item.branchName)
        );
        this.setState({
          branchNameList: branchNameList,
        });
      }
    } catch (error) {
      console.warn('getBranchInfo error: {}', error);
    }
  };

  getList = () => {
    const { pagination } = this.state;
    const pageNum = pagination.current;
    const pageSize = pagination.pageSize;
    const targetDate = this.state.targetDate;
    httpGet(httpUrl.getBranchSettlementAllList, [targetDate, pageNum, pageSize], {}).then((res) => {
      console.log('res');
      console.log(res);
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      let d = moment(todayString).date();
      this.setState({
        list: res.data.list,
        pagination: pagination,
      });
    });
  };

  openDetailSettlementDialog = (row) => {
    const data = {
      ...row,
      beforeData: row,
    }
    this.setState({
      isDetailSettlementOpen: true,
      selectedRow: data,
    });
  };

  closeDetailSettlementDialog = () => {
    this.setState({ isDetailSettlementOpen: false }, () => {
      //this.getList(); // 실시간이 아니기때문에 궂이 다시 리스트 가져올 필요가 없음
    });
  };

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
        {
          pagination: pager,
        },
        () => this.getList()
    );
  };

  render() {
    const { branchNameList } = this.state;
    const columns = [
      {
        title: '지점명',
        dataIndex: 'branchIdx',
        className: 'table-column-center',
        render: (data) => <div>{branchNameList[data]}</div>,
      },
      {
        title: viewMonthString + '월 매출 (전일 까지)',
        dataIndex: 'totalSales',
        className: 'table-column-center',
        render: (data) => <div style={{color: '#6699ff', fontWeight: 'bold'}}>{comma(data)}</div>,
      },
      {
        title: '매출 상세 내역',
        className: 'table-column-center',
        render: (data, row) => (
            <div>
              <Button
                  onClick={() => {
                    this.openDetailSettlementDialog(row);
                  }}>
                {'내역 보기'}
              </Button>
            </div>
        ),
      },
    ];

    return (
        <FormItem>
          {this.state.isDetailSettlementOpen && (
              <BranchSettlementDialog
                  data={this.state.selectedRow}
                  isSales={true}
                  dateRange={1}
                  close={this.closeDetailSettlementDialog}
              />
          )}

          <div style={{fontSize: '20px'}}>
            {'지점별 ' + viewMonthString + '월 매출 현황'}
          </div>

        <Table
            dataSource={this.state.list}
            columns={columns}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
        />
        </FormItem>
    );
  }
}

export default BranchSettlementAllList;
