import { Button, Form, Input, Modal } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React, { Component } from 'react';
import { httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import CloseIcon from '../../img/close.png';
import moment from 'moment';

class RiderPointModifyDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableMinus: null,
      enablePlus: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    console.log(this.props.data);
  }

  handleSubmit = () => {
    let self = this;
    const formValue = self.formRef.current.getFieldsValue();

    if (!self.state.enablePlus && !self.state.enableMinus) {
      Modal.warn({
        content: '추가점수 혹은 차감점수를 입력하세요!',
      });
      return;
    }
    if (formValue.memo.length < 5) {
      Modal.warn({
        content: '사유는 5글자 이상 입력하세요!',
      });
      return;
    }

    if (formValue.pointPlus === undefined)
      self.formRef.current.setFieldsValue({
        ...formValue,
        pointPlus: 0,
      });
    if (formValue.pointMinus === undefined)
      self.formRef.current.setFieldsValue({
        ...formValue,
        pointMinus: 0,
      });

    console.log({ ...formValue });
    httpPost(httpUrl.updateRiderPoint, [], {
      ...formValue,
      riderIdx: self.props.data.riderIdx,
    }).then((res) => {
      console.log(res);
      if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
        Modal.info({
          title: '등록 성공',
          content: '지급/차감 에 성공했습니다.',
        });
        self.props.close();
      } else if (res.data === '') {
        //수정 실패 사유 세분화
      }
    });
  };

  render() {
    const { close, data } = this.props;
    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="modifyRiderLevel-Dialog">
          <div className="modifyRiderLevel-content">
            <div className="modifyRiderLevel-title">지급 / 차감</div>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="dialog-close"
              alt="img"
            />
            <div className="modifyRiderLevel-inner">
              <Form ref={this.formRef} onFinish={this.handleSubmit}>
                <div className="modifyRiderLevel-innerbox">
                  <div className="contentBlock">
                    <div className="mainTitle">지점명</div>
                    <div className="formItem">
                      <Input
                        className="override-input"
                        style={{ width: 300 }}
                        value={data.branchName}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">ID명</div>
                    <div className="formItem">
                      <Input
                        className="override-input"
                        style={{ width: 300 }}
                        value={data.riderId}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">기사명</div>
                    <div className="formItem">
                      <Input
                        className="override-input"
                        style={{ width: 300 }}
                        value={data.riderName}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">누적점수</div>
                    <div className="formItem">
                      <Input
                        className="override-input"
                        style={{ width: 100 }}
                        value={data.point}
                        disabled
                      />{' '}
                      점
                    </div>
                    {data.lastUpdateDate !== null ? (
                      <div style={{ marginLeft: '40px', marginTop: '5px' }}>
                        {'(현재: ' +
                          moment(data.lastUpdateDate).format('YYYY-MM-DD') +
                          ')'}
                      </div>
                    ) : (
                      <div style={{ marginLeft: '40px', marginTop: '5px' }}>
                        {'(현재: 내역없음)'}
                      </div>
                    )}
                  </div>
                  <div className="contentBlock" style={{ marginTop: '20px' }}>
                    <div className="mainTitle">(+)추가 점수</div>
                    <div className="formItem">
                      <FormItem name="pointPlus" className="selectItem">
                        <Input
                          placeholder="점수를 입력하세요"
                          className="override-input"
                          style={{ width: 220 }}
                          allowClear
                          disabled={this.state.enableMinus}
                          onChange={(e) => {
                            if (isNaN(e.target.value)) {
                              Modal.warn({ content: '숫자만 입력해주세요!' });
                              return;
                            }
                            this.setState({
                              enablePlus: e.target.value,
                              enableMinus: null,
                            });
                          }}
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">(-)차감 점수</div>
                    <div className="formItem">
                      <FormItem name="pointMinus" className="selectItem">
                        <Input
                          placeholder="점수를 입력하세요"
                          className="override-input"
                          style={{ width: 220 }}
                          allowClear
                          disabled={this.state.enablePlus}
                          onChange={(e) => {
                            if (isNaN(e.target.value)) {
                              Modal.warn({ content: '숫자만 입력해주세요!' });
                              return;
                            }
                            this.setState({
                              enablePlus: null,
                              enableMinus: e.target.value,
                            });
                          }}
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">지급/차감 사유</div>
                    <div className="formItem">
                      <FormItem
                        name="memo"
                        className="selectItem"
                        rules={[
                          {
                            required: true,
                            message: '사유를 입력해주세요.',
                          },
                        ]}>
                        <Input
                          placeholder="사유를 입력하세요"
                          className="override-input"
                          style={{ width: 300 }}
                        />
                      </FormItem>
                    </div>
                  </div>
                  <Button
                    type="primary"
                    style={{ width: 400, fontWeight: 'bold' }}
                    htmlType="submit">
                    완료
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RiderPointModifyDialog;
