import { Button, Table, Input, Modal } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl, httpPost } from '../../api/httpClient';
import {customAlert, customError, updateError} from '../../api/Modals';
import RegistBranchHeadOfficeListDialog from '../../components/dialog/RegistBranchHeadOfficeListDialog';
import '../../css/main.css';
const Search = Input.Search;

class BranchHeadOfficeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      branchName: '',
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      registBranchModalOpen: false, //지점등록
      rowBranchList: [],
    };
    this.columns = [
      {
        title: '총판번호',
        dataIndex: 'headBranchIdx',
        className: 'table-column-center',
      },
      {
        title: '총판 지점명',
        dataIndex: 'headBranchName',
        className: 'table-column-center',
      },
      {
        title: '지점번호',
        dataIndex: 'branchIdx',
        className: 'table-column-center',
      },
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '삭제',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn"
              onClick={() => {
                this.onClickDelete(row);
              }}>
              삭제
            </Button>
          </div>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getList();
  }

  // 리스트 조회 api
  getList = () => {
    const pageSize = this.state.pagination.pageSize; //221108 hms
    const pageNum = this.state.pagination.current; //221108 hms
    httpGet(httpUrl.getBranchHeadOfficeList, [this.state.branchName,pageNum, pageSize], {})
      .then((res) => {
        if (res.result === 'SUCCESS') {
          this.setState({
            list: res.data.list,
            pagination: {
              ...this.state.pagination,
              current: res.data.currentPage,
              total: res.data.totalCount,
            },
          });
        }
      })
      .catch((e) => {
        customError('목록 에러', '에러가 발생하여 목록을 불러올수 없습니다.');
      });
  };

  // 페이징
  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  openCreateBranchModal = (row) => {
    this.setState({ registBranchModalOpen: true });
  };
  closeCreateBranchModal = () => {
    this.setState({ registBranchModalOpen: false }, () => {
      this.getList();
    });
  };

  onSearchBranch = (value) => {
    this.setState(
        {
          branchName: value,
          pagination: {
            current: 1,
            pageSize: 10,
          },
        },
        () => {
          this.getList();
        }
    );
  };

  onClickDelete = (row) => {
    let self = this;
    Modal.confirm({
      title: '총판-지점 정보 삭제',
      content: '해당 정보를 삭제하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.deleteBranchHeadOfficeList, [], { branchIdx: row.branchIdx, headBranchIdx: row.headBranchIdx })
        .then((result) => {
          if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
            customAlert('완료', '해당 정보를 삭제했습니다.');
          } else updateError();
          self.getList();
        })
        .catch((error) => {
          customError(
              '삭제오류',
              '에러가 발생하였습니다. 다시 시도해주세요.'
          );
        });
      },
    });
  }

  render() {
    return (
      <>
        {this.state.registBranchModalOpen && (
          <RegistBranchHeadOfficeListDialog close={this.closeCreateBranchModal} />
        )}

        <Search
            placeholder="지점명 검색"
            enterButton
            allowClear
            onSearch={this.onSearchBranch}
            style={{
              width: 220,
            }}
        />
        <Button
          className="tabBtn"
          onClick={() => {
            this.openCreateBranchModal();
          }}
          style={{
            marginBottom: 20,
          }}>
          지점 등록
        </Button>
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={this.columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

export default BranchHeadOfficeList;
