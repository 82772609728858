import React, { useEffect, useState } from 'react';
import AgencyNcashAllTbl from './AgencyNcashAllTbl';
import AgencyNcashDeliveryTbl from './AgencyNcashDeliveryTbl';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from '../../const';
import AgencyDepositDialog from '../../components/agencyDialog/AgencyDepositDialog';
import { httpGet, httpUrl } from "../../api/httpClient";
import { useDispatch } from "react-redux";
import { login } from "../../actions/loginAction";
const AgencyNcashList = () => {
  const agencyBranch = reactLocalStorage.getObject(
    con.appName + '#agencyBranch'
  );
  const loginInfo = reactLocalStorage.getObject(con.appName + '#adminUser');
  const dispatch = useDispatch();
  const [type, setType] = useState('all');
  const [depositModalOpen, setDepositModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const handelDepositModal = (value) => {
    setDepositModalOpen(value);
  };
  const changeType = (value) => {
    setType(value);
  };
  const getBalance = async () => {
    const ncash = await httpGet(httpUrl.hoBalance, [], {});
    if (ncash) {
      dispatch(login({...loginInfo, ncash: ncash.data}) )
    }
  };
  const onRefresh = () => {
    setRefresh(!refresh);
  };
  return (
    <div id={'ncashCompanyList'}>
      <h1 className={'agency-page-title'}>내역조회</h1>
      <div>
        {type === 'all' ? (
          <AgencyNcashAllTbl
            loginInfo={loginInfo}
            callback={(value) => changeType(value)}
            refresh={refresh}
            type={type}
            modal={(value) => handelDepositModal(value)}
          />
        ) : (
          <AgencyNcashDeliveryTbl
            loginInfo={loginInfo}
            callback={(value) => changeType(value)}
            type={type}
          />
        )}
        {depositModalOpen && (
          <AgencyDepositDialog
            branch={agencyBranch}
            getBalance={()=> getBalance()}
            refresh={() => onRefresh()}
            close={() => handelDepositModal(false)}
          />
        )}
      </div>
    </div>
  );
};
export default AgencyNcashList;
