import { Button, Table, Typography, Modal } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { kindString, StaticCategory } from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';
import { connect } from 'react-redux';
import ExcelIcon from '../../img/excel.png';

import xlsx from 'xlsx';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/ko';
import { tuple } from 'antd/lib/_util/type';

moment.locale('ko');

const { Text } = Typography;
const today = new Date();
const year = today.getFullYear();
const month = today.getMonth();

class StaticsTableAll extends Component {
  constructor(props) {
    super(props);
    const data = this.props;
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 15,
      },
      startDate: data.startDate,
      endDate: data.endDate,
      changeFilter: data.changeFilter,
      selectBranch: data.selectBranch,
      businessNumber: data.businessNumber,
      frName: data.frName,
      riderName: data.riderName,
      registrationNumber: data.registrationNumber,

      copyList: [],
    };

    this.change_fr = ['DUES_PAY_RESTORE', 'DELIVERY_PRICE_RECEIVE'];
    this.change_deliveryPrice = ['DELIVERY_PRICE_SEND'];
    this.columns = [
      {
        title: '일시',
        dataIndex: 'createDate',
        className: 'tableCellCenter',
        render: (data) => <>{moment(data).format('YYYY-MM-DD HH:mm:ss')}</>,
        width: 160,
      },
      {
        title: '관리지점',
        dataIndex: 'branchName',
        className: 'tableCellCenter',
        width: 100,
      },
      {
        title: '주문번호',
        dataIndex: 'orderIdx',
        className: 'tableCellCenter',
        width: 100,
      },
      {
        title: '분류',
        dataIndex: 'category',
        className: 'tableCellCenter',
        width: 140,
        render: (data) => <>{StaticCategory[data]}</>,
      },
      {
        title: '비고',
        className: 'statics-table-border tableCellCenter',
        dataIndex: 'adminId',
        width: 100,
        render: (data, row) => {
          if (
            row.category === 'NCASH_SEND_TO_FR_BY_ADMIN' ||
            row.category === 'NCASH_SEND_TO_RIDER_BY_ADMIN'
          ) {
            return (
              <Button
                style={{ border: 'none' }}
                onClick={() => {
                  this.openMemo(row.memo);
                }}>
                <ExclamationCircleOutlined />
                {data ? `변경자 : ${data}` : ''}
              </Button>
            );
          } else return <div>{data ? `변경자 : ${data}` : ''}</div>;
        },
      },
      {
        title: '가맹점',
        className: 'statics-table-orange statics-table-border',
        children: [
          {
            title: '정보',
            children: [
              {
                title: '가맹점명',
                dataIndex: 'frName',
                className: 'tableCellCenter',
                width: 150,
              },
              {
                title: '사업자번호',
                dataIndex: 'businessNumber',
                className: 'tableCellCenter',
                width: 100,
              },
            ],
          },
          {
            title: '배달건별',
            children: [
              {
                title: '배달비 공금가액',
                children: [
                  {
                    title: '기본',
                    dataIndex: 'basicDeliveryPrice',
                    width: 100,
                    className: 'tableCellRight',
                    render: (data, row) => {
                      const basicDeliveryPrice = row.category.includes(
                        '_RESTORE'
                      )
                        ? data * -1
                        : data;
                      return (
                        <div>
                          {row.frName && data && comma(basicDeliveryPrice)}
                        </div>
                      );
                    },
                  },
                  {
                    title: '할증',
                    dataIndex: 'extraDeliveryPrice',
                    className: 'tableCellRight',
                    width: 100,
                    render: (data, row) => {
                      const extraDeliveryPrice = row.category.includes(
                        '_RESTORE'
                      )
                        ? data * -1
                        : data;
                      return (
                        <div>
                          {row.frName && data && comma(extraDeliveryPrice)}
                        </div>
                      );
                    },
                  },
                  {
                    title: '계',
                    //체크
                    dataIndex: 'deliveryPrice',
                    className: 'tableCellRight',
                    width: 100,
                    render: (data, row) => {
                      const deliveryPrice = row.category.includes('_RESTORE')
                        ? data * -1
                        : data;
                      return (
                        <div>{row.frName && data && comma(deliveryPrice)}</div>
                      );
                    },
                  },
                ],
              },
              {
                title: '부가세',
                dataIndex: 'deliveryVat',
                width: 100,
                className: 'tableCellRight',
                render: (data, row) => {
                  const deliveryVat = row.category.includes('_RESTORE')
                    ? data * -1
                    : data;
                  return <div>{row.frName && data && comma(deliveryVat)}</div>;
                },
              },
              {
                title: '배달비합계',
                dataIndex: 'totalDeliveryPrice',
                width: 100,
                className: 'tableCellRight',
                render: (data, row) => {
                  if (row.category.includes('_RESTORE')) {
                    return (
                      <div>
                        {row.frName && row.deliveryPrice && comma(data * -1)}
                      </div>
                    );
                  } else
                    return (
                      <div>
                        {row.frName && row.deliveryPrice && comma(data)}
                      </div>
                    );
                },
              },
            ],
          },
          {
            title: '가맹비(월)',
            children: [
              {
                title: '공급가액',
                dataIndex: 'duePrice',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.frName && data && comma(data)} </div>
                ),
              },
              {
                title: '부가세',
                dataIndex: 'duePriceVat',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div>{row.frName && data && comma(data)}</div>
                ),
              },
              {
                title: '합계',
                dataIndex: 'totalDuePrice',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div>{row.frName && data && comma(data)}</div>
                ),
              },
            ],
          },
          {
            title: '프로모션',
            dataIndex: 'promotionFee',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => (
              <div> {data && row.userType === 2 ? comma(data) : ''} </div>
            ),
          },
          {
            title: '선충전',
            dataIndex: 'frChargePrice',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => (
              <div> {row.frName && data && comma(data)} </div>
            ),
          },
          {
            title: '기타',
            className: 'statics-table-border tableCellRight',
            dataIndex: 'etcFee',
            width: 100,
            render: (data, row) => (
              <div> {data && row.userType === 2 ? comma(data) : ''} </div>
            ),
          },
        ],
      },
      {
        title: '라이더',
        className: 'statics-table-orange statics-table-border',
        children: [
          {
            title: '정보',
            children: [
              {
                title: '라이더명',
                dataIndex: 'riderName',
                className: 'tableCellCenter',
                width: 140,
              },
              {
                title: '주민번호',
                dataIndex: 'registrationNumber',
                className: 'tableCellCenter',
                width: 130,
                render: (data, row) => {
                  if (row.riderName && data) {
                    const regiNum1 = data.substring(0, 6);
                    const regiNum2 = data.substring(6, data.length);
                    return <div>{regiNum1 + '-' + regiNum2}</div>;
                  }
                },
              },
              {
                title: '기사수수료 일차감여부',
                dataIndex: 'dailyBatch',
                className: 'tableCellCenter',
                width: 90,
                render: (data) => <div>{data > 0 ? 'O' : ''}</div>,
              },
            ],
          },
          {
            title: '배달건별',
            children: [
              {
                title: '지급대상액 배달비 (기본+할증)',
                dataIndex: 'deliveryPrice',
                className: 'tableCellRight',
                width: 90,
                render: (data, row) => {
                  const deliveryPrice = row.category.includes('_RESTORE')
                    ? data
                    : data * -1;
                  if (row.riderLevel > 2) return <></>;
                  return (
                    <div>{row.riderName && data && comma(deliveryPrice)}</div>
                  );
                },
              },
              {
                title: '제외',
                children: [
                  {
                    title: '기사수수료',
                    dataIndex: 'riderDeliveryFee',
                    className: 'tableCellRight',
                    width: 100,
                    render: (data, row) => {
                      const riderDeliveryFee = row.category.includes('_RESTORE')
                        ? data * -1
                        : data;
                      return (
                        <div>
                          {row.riderName && data && comma(riderDeliveryFee)}
                        </div>
                      );
                    },
                  },
                  {
                    title: '고용보험',
                    dataIndex: 'insuranceFee',
                    className: 'tableCellRight',
                    width: 100,
                    render: (data, row) => {
                      return <div>{row.riderName && data && comma(data)}</div>;
                    },
                  },
                ],
              },
              {
                title: '배달 프로모션',
                // dataIndex: 'memo',
                className: 'tableCellRight',
                width: 100,
                // render: (data, row) => <div> {data.includes("프로모션") ? "-"+comma(row.ncashDelta) : ""} </div>,
              },
              {
                title: '실지급액',
                dataIndex: 'riderPayout',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => {
                  return <div>{row.riderName && data && comma(data)}</div>;
                },
              },
            ],
          },
          {
            title: '배달 페널티',
            dataIndex: 'penaltyFee',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => (
              <div> {row.riderName && data && comma(data)} </div>
            ),
          },
          {
            title: '라이더 일차감',
            children: [
              {
                title: '렌트비',
                dataIndex: 'dailyBikeRentFee',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
              {
                title: '대여금',
                dataIndex: 'dailyRentFee',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
              {
                title: '산재보험',
                dataIndex: 'dailyInsuranceFee',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
              {
                title: '수리비(특수)',
                dataIndex: 'dailyFixFeeExcep',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
            ],
          },

          {
            title: '프로모션',
            dataIndex: 'promotionFee',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => (
              <div> {row.riderName && data && comma(data)} </div>
            ),
          },
          {
            title: '수리비',
            dataIndex: 'riderFixFee',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => (
              <div> {row.riderName && data && comma(data)} </div>
            ),
          },
          {
            title: '리스비',
            dataIndex: 'riderLeaseFee',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => (
              <div> {row.riderName && data && comma(data)} </div>
            ),
          },
          {
            title: '산재보험',
            dataIndex: 'riderIndustrialInsuranceFee',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => (
              <div> {row.riderName && data && comma(data)} </div>
            ),
          },
          {
            title: '상해보험',
            dataIndex: 'riderAccidentInsuranceFee',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => (
              <div> {row.riderName && data && comma(data)} </div>
            ),
          },
          {
            title: '과태료',
            dataIndex: 'riderFineFee',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => (
              <div> {row.riderName && data && comma(data)} </div>
            ),
          },
          {
            title: '물품대',
            dataIndex: 'riderBuyProduct',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => (
              <div> {row.riderName && data && comma(data)} </div>
            ),
          },
          {
            title: '기타',
            dataIndex: 'etcFee',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => (
              <div> {row.riderName && data && comma(data)} </div>
            ),
          },
          {
            title: '합계',
            dataIndex: 'totalDeducted',
            className: 'statics-table-border tableCellRight',
            width: 100,
            render: (data, row) => (
              <div> {row.riderName && data && comma(data)} </div>
            ),
          },
        ],
      },
      {
        title: '본사 예치금',
        className: 'statics-table-orange2',
        width: 100,
        // rowspan: 2,
        onCell: (_, index) => {
          if (index === 0) {
            return { rowSpan: 2 };
          }
          return {};
        },
        children: [
          {
            title: '입금',
            dataIndex: '',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => {
              const delivery = row.category.includes('_RESTORE')
                ? row.totalDeliveryPrice * -1
                : row.totalDeliveryPrice;
              const inputFrData =
                delivery +
                row.totalDuePrice +
                row.promotionFee +
                row.frChargePrice +
                row.etcFee;
              const inputRiderData = row.riderPayout + row.totalDeducted;
              if (row.frName && inputFrData > 0) {
                return <div>{comma(Math.abs(inputFrData))}</div>;
              } else if (row.riderName && inputRiderData > 0) {
                return <div>{comma(Math.abs(inputRiderData))}</div>;
              } else return <></>;
            },
          },
          {
            title: '출금',
            dataIndex: '',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => {
              const delivery = row.category.includes('_RESTORE')
                ? row.totalDeliveryPrice * -1
                : row.totalDeliveryPrice;
              const inputFrData =
                delivery +
                row.totalDuePrice +
                row.promotionFee +
                row.frChargePrice +
                row.etcFee;
              const inputRiderData = row.riderPayout + row.totalDeducted;
              if (row.frName && inputFrData < 0) {
                return <div>{comma(Math.abs(inputFrData))}</div>;
              } else if (row.riderName && inputRiderData < 0) {
                return <div>{comma(Math.abs(inputRiderData))}</div>;
              } else return <></>;
            },
          },
          {
            title: '잔액',
            dataIndex: 'c9Ncash',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => <div>{comma(data)}</div>,
          },
        ],
      },
    ];
  }

  componentDidMount() {
    console.log('전체페이지');
    this.getList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.endDate !== this.props.endDate ||
      prevProps.changeFilter !== this.props.changeFilter ||
      prevProps.selectBranch !== this.props.selectBranch ||
      prevProps.businessNumber !== this.props.businessNumber ||
      prevProps.frName !== this.props.frName ||
      prevProps.riderName !== this.props.riderName ||
      prevProps.registrationNumber !== this.props.registrationNumber
    ) {
      this.setState(
        {
          list: [],
          copyList: [],
          pagination: {
            ...this.state.pagination,
            current: 1,
          },
        },
        () => this.getList()
      );
    }
  }

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({ pagination: pager }, () => this.getList());
  };

  openMemo = (memo) => {
    Modal.info({
      title: '내용',
      content: <div> {memo} </div>,
      onOk() {},
    });
  };
  getList = async () => {
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let branchIdx = this.props.selectBranch;
    let businessNumber = this.props.businessNumber;
    let category = 'all';
    let frName = this.props.frName;
    let riderName = this.props.riderName;
    let registrationNumber = this.props.registrationNumber;
    let startDate = this.props.startDate;
    let endDate = moment(this.props.endDate).add(1, 'd').format('YYYY-MM-DD');
    const res = await httpGet(
      httpUrl.staticsCostList,
      [
        pageNum,
        pageSize,
        branchIdx,
        businessNumber,
        category,
        frName,
        riderName,
        registrationNumber,
        startDate,
        endDate,
      ],
      {}
    );
    if (res.result === 'SUCCESS') {
      console.log(res);
      this.setState({
        list: res.data.stCost,
        copyList: res.data.stCost,
        pagination: {
          ...this.state.pagination,
          current: res.data.currentPage,
          total: res.data.totalCount,
        },
      });
    }
    if (this.state.changeFilter > 0) {
      this.selectList(this.state.changeFilter);
    }
  };

  selectList = (type) => {
    const selectType =
      type === 1 ? this.state.change_deliveryPrice : this.state.change_fr;
    let changeList = this.state.copyList.filter((x) =>
      selectType.includes(x.category)
    );
    this.setState({ copyList: changeList });
  };

  //excel column
  parseExcelJson = (data) => {
    let result = [
      {
        createDate: '일시',
        branchName: '관리지점',
        orderIdx: '주문번호',
        category: '분류',
        adminId: '비고',
        frName: '가맹점',
        businessNumber: '가맹점',
        basicDeliveryPrice: '가맹점',
        extraDeliveryPrice: '가맹점',
        frDeliveryPrice: '가맹점',
        deliveryVat: '가맹점',
        totalDeliveryPrice: '가맹점',
        duePrice: '가맹점',
        duePriceVat: '가맹점',
        totalDuePrice: '가맹점',
        frPromotionFee: '가맹점',
        frChargePrice: '가맹점',
        frEtcFee: '가맹점',
        riderName: '라이더',
        registrationNumber: '라이더',
        dailyBatch: '라이더',
        deliveryPrice: '라이더',
        riderDeliveryFee: '라이더',
        insuranceFee: '라이더',
        untitle: ' 라이더',
        riderPayout: '라이더',
        penaltyFee: '라이더',
        dailyBikeRentFee: '라이더',
        dailyRentFee: '라이더',
        dailyInsuranceFee: '라이더',
        dailyFixFeeExcep: '라이더',
        promotionFee: '라이더',
        riderFixFee: '라이더',
        riderLeaseFee: '라이더',
        riderIndustrialInsuranceFee: '라이더',
        riderAccidentInsuranceFee: '라이더',
        riderFineFee: '라이더',
        riderBuyProduct: '라이더',
        etcFee: '라이더',
        totalDeducted: '라이더',
        deposit: '본사예치금',
        withdrawal: '본사예치금',
        balance: '본사예치금',
      },
      {
        createDate: '일시',
        branchName: '관리지점',
        orderIdx: '주문번호',
        category: '분류',
        adminId: '비고',
        frName: '정보',
        businessNumber: '정보',
        basicDeliveryPrice: '배달건별',
        extraDeliveryPrice: '배달건별',
        frDeliveryPrice: '배달건별',
        deliveryVat: '배달건별',
        totalDeliveryPrice: '배달건별',
        duePrice: '가맹비(월)',
        duePriceVat: '가맹비(월)',
        totalDuePrice: '가맹비(월)',
        frPromotionFee: '프로모션',
        frChargePrice: '선충전',
        frEtcFee: '기타',
        riderName: '정보',
        registrationNumber: '정보',
        dailyBatch: '정보',
        deliveryPrice: '배달건별',
        riderDeliveryFee: '배달건별',
        insuranceFee: '배달건별',
        untitle: ' 배달건별',
        riderPayout: '배달건별',
        penaltyFee: '배달페널티',
        dailyBikeRentFee: '라이더 일차감',
        dailyRentFee: '라이더 일차감',
        dailyInsuranceFee: '라이더 일차감',
        dailyFixFeeExcep: '라이더 일차감',
        promotionFee: '프로모션',
        riderFixFee: '수리비',
        riderLeaseFee: '리스비',
        riderIndustrialInsuranceFee: '산재보험',
        riderAccidentInsuranceFee: '상해보험',
        riderFineFee: '과태료',
        riderBuyProduct: '물품대',
        etcFee: '기타',
        totalDeducted: '합계',
        deposit: '입금',
        withdrawal: '출금',
        balance: '잔액',
      },
      {
        createDate: '일시',
        branchName: '관리지점',
        orderIdx: '주문번호',
        category: '분류',
        adminId: '비고',
        frName: '가맹점명',
        businessNumber: '사업자번호',
        basicDeliveryPrice: '배달비 공금가액',
        extraDeliveryPrice: '배달비 공금가액',
        frDeliveryPrice: '배달비 공금가액',
        deliveryVat: '부가세',
        totalDeliveryPrice: '배달비합계',
        duePrice: '공급가액',
        duePriceVat: '부가세',
        totalDuePrice: '합계',
        frPromotionFee: '프로모션',
        frChargePrice: '선충전',
        frEtcFee: '기타',
        riderName: '라이더명',
        registrationNumber: '주민번호',
        dailyBatch: '기사수수료\n일차감여부',
        deliveryPrice: '지급대상액 배달비 (기본+할증)',
        riderDeliveryFee: '제외',
        insuranceFee: '제외',
        untitle: '배달프로모션',
        riderPayout: '실지급액',
        penaltyFee: '배달페널티',
        dailyBikeRentFee: '렌트비',
        dailyRentFee: '대여금',
        dailyInsuranceFee: '산재보험',
        dailyFixFeeExcep: '수리비(특수)',
        promotionFee: '프로모션',
        riderFixFee: '수리비',
        riderLeaseFee: '리스비',
        riderIndustrialInsuranceFee: '산재보험',
        riderAccidentInsuranceFee: '상해보험',
        riderFineFee: '과태료',
        riderBuyProduct: '물품대',
        etcFee: '기타',
        totalDeducted: '합계',
        deposit: '입금',
        withdrawal: '출금',
        balance: '잔액',
      },
      {
        createDate: '일시',
        branchName: '관리지점',
        orderIdx: '주문번호',
        category: '분류',
        adminId: '비고',
        frName: '가맹점명',
        businessNumber: '사업자번호',
        basicDeliveryPrice: '기본',
        extraDeliveryPrice: '할증',
        frDeliveryPrice: '계',
        deliveryVat: '부가세',
        totalDeliveryPrice: '배달비합계',
        duePrice: '공급가액',
        duePriceVat: '부가세',
        totalDuePrice: '합계',
        frPromotionFee: '프로모션',
        frChargePrice: '선충전',
        frEtcFee: '기타',
        riderName: '라이더명',
        registrationNumber: '주민번호',
        dailyBatch: '기사수수료\n일차감여부',
        deliveryPrice: '지급대상액 배달비 (기본+할증)',
        riderDeliveryFee: '기사수수료',
        insuranceFee: '고용보험',
        untitle: '배달프로모션',
        riderPayout: '실지급액',
        penaltyFee: '배달페널티',
        dailyBikeRentFee: '렌트비',
        dailyRentFee: '대여금',
        dailyInsuranceFee: '산재보험',
        dailyFixFeeExcep: '수리비(특수)',
        promotionFee: '프로모션',
        riderFixFee: '수리비',
        riderLeaseFee: '리스비',
        riderIndustrialInsuranceFee: '산재보험',
        riderAccidentInsuranceFee: '상해보험',
        riderFineFee: '과태료',
        riderBuyProduct: '물품대',
        etcFee: '기타',
        totalDeducted: '합계',
        deposit: '입금',
        withdrawal: '출금',
        balance: '잔액',
      },
    ];

    let totalBasicPayment = 0;
    let totalExtraPayment = 0;
    let totalPrice = 0;
    let totalVatPrice = 0;
    let totalSumPrice = 0;
    // 가맹비
    let totalInputPrice = 0;
    let totalInputVat = 0;
    let totalFrFee = 0;
    // 가맹점 선충전
    let totalFrChargePrice = 0;
    // 가맹점 프로모션
    let totalFrPromotion = 0;
    let totalFrEtc = 0;
    // 라이더 배달
    let totalRiderPrice = 0;
    let totalRiderFee = 0;
    let totalRiderInsurance = 0;
    let totalRiderSumPrice = 0;
    let totalPenaltyFee = 0;
    // 라이더 일차감
    let totalDailyBikeRentFee = 0;
    let totalDailyRentFee = 0;
    let totalDailyInsuranceFee = 0;
    let totalDailyFixFeeExcep = 0;
    // 라이더 건별
    let totalPromotionFee = 0;
    let totalRiderFixFee = 0;
    let totalRiderLeaseFee = 0;
    let totalIndustrialInsuranceFee = 0;
    let totalAccidentInsuranceFee = 0;
    let totalRiderFineFee = 0;
    let totalRiderBuyProduct = 0;
    let totalRiderEtc = 0;
    let totalRiderSum = 0;
    // 본사
    let totalC9Input = 0;
    let totalC9Output = 0;
    let totalc9AfterNcash = 0;

    data.forEach((item) => {
      const frRestore = item.category.includes('_RESTORE') ? -1 : 1;
      const riderRestore = item.category.includes('_RESTORE') ? 1 : -1;
      let regiNum1 = '';
      let regiNum2 = '';
      const regiNum = item.registrationNumber;
      if (item.riderName && item.registrationNumber) {
        regiNum1 = regiNum.substring(0, 6);
        regiNum2 = regiNum.substring(6, regiNum.length);
      }
      const excelDelivery = item.category.includes('_RESTORE')
        ? item.totalDeliveryPrice * -1
        : item.totalDeliveryPrice;
      const excelInputFrData =
        excelDelivery +
        item.totalDuePrice +
        item.promotionFee +
        item.frChargePrice +
        item.etcFee;
      const excelInputRiderData = item.riderPayout + item.totalDeducted;
      let resDeposit = 0;
      if (item.frName && excelInputFrData > 0) {
        resDeposit = Math.abs(excelInputFrData);
      } else if (item.riderName && excelInputRiderData > 0) {
        resDeposit = Math.abs(excelInputRiderData);
      } else {
        resDeposit = 0;
      }
      let resWithdrawal = 0;
      if (item.frName && excelInputFrData < 0) {
        resWithdrawal = Math.abs(excelInputFrData);
      } else if (item.riderName && excelInputRiderData < 0) {
        resWithdrawal = Math.abs(excelInputRiderData);
      } else {
        resWithdrawal = 0;
      }
      result.push({
        createDate: item.createDate,
        branchName: item.branchName,
        orderIdx: item.orderIdx,
        category: StaticCategory[item.category],
        frName: item.frName,
        businessNumber: item.businessNumber,
        basicDeliveryPrice: item.frName && item.basicDeliveryPrice * frRestore,
        extraDeliveryPrice: item.frName && item.extraDeliveryPrice * frRestore,
        frDeliveryPrice: item.frName && item.deliveryPrice * frRestore,
        deliveryVat: item.frName && item.deliveryVat * frRestore,
        totalDeliveryPrice: item.frName && item.totalDeliveryPrice * frRestore,
        duePrice: item.frName && item.duePrice,
        duePriceVat: item.frName && item.duePriceVat,
        totalDuePrice: item.frName && item.totalDuePrice,
        frPromotionFee: item.frName && item.promotionFee,
        frChargePrice: item.frName && item.frChargePrice,
        frEtcFee: item.frName && item.etcFee,
        riderName: item.riderName,
        registrationNumber: item.riderName && regiNum1 + '-' + regiNum2,
        dailyBatch: item.riderName && (item.dailyBatch === 0 ? '' : 'O'),
        deliveryPrice: item.riderName && item.deliveryPrice * riderRestore,
        riderDeliveryFee: item.riderName && item.riderDeliveryFee * frRestore,
        insuranceFee: item.riderName && item.insuranceFee,
        riderPayout: item.riderName && item.riderPayout,
        penaltyFee: item.riderName && item.penaltyFee,
        dailyBikeRentFee: item.riderName && item.dailyBikeRentFee,
        dailyRentFee: item.riderName && item.dailyRentFee,
        dailyInsuranceFee: item.riderName && item.dailyInsuranceFee,
        dailyFixFeeExcep: item.riderName && item.dailyFixFeeExcep,
        promotionFee: item.riderName && item.promotionFee,
        riderFixFee: item.riderName && item.riderFixFee,
        riderLeaseFee: item.riderName && item.riderLeaseFee,
        riderIndustrialInsuranceFee:
          item.riderName && item.riderIndustrialInsuranceFee,
        riderAccidentInsuranceFee:
          item.riderName && item.riderAccidentInsuranceFee,
        riderFineFee: item.riderName && item.riderFineFee,
        riderBuyProduct: item.riderName && item.riderBuyProduct,
        etcFee: item.riderName && item.etcFee,
        totalDeducted: item.riderName && item.totalDeducted,
        deposit: resDeposit,
        withdrawal: resWithdrawal,
        balance: item.c9Ncash,
      });

      if (item.frName) {
        totalBasicPayment += item.category.includes('_RESTORE')
          ? item.basicDeliveryPrice * -1
          : item.basicDeliveryPrice;
        totalExtraPayment += item.category.includes('_RESTORE')
          ? item.extraDeliveryPrice * -1
          : item.extraDeliveryPrice;
        totalPrice += item.category.includes('_RESTORE')
          ? item.deliveryPrice * -1
          : item.deliveryPrice;
        totalVatPrice += item.category.includes('_RESTORE')
          ? item.deliveryVat * -1
          : item.deliveryVat;
        totalSumPrice += item.category.includes('_RESTORE')
          ? item.totalDeliveryPrice * -1
          : item.totalDeliveryPrice;
        totalInputPrice += item.duePrice * -1;
        totalInputVat += item.duePriceVat * -1;
        totalFrFee += item.totalDuePrice * -1;
        totalFrChargePrice += item.frChargePrice;
        totalFrPromotion += item.promotionFee;
        totalFrEtc += item.etcFee;
      }
      if (item.riderName) {
        totalRiderPrice += item.category.includes('_RESTORE')
          ? item.deliveryPrice * -1
          : item.deliveryPrice;
        totalRiderFee += item.category.includes('_RESTORE')
          ? item.riderDeliveryFee * -1
          : item.riderDeliveryFee;
        totalRiderInsurance += item.insuranceFee;
        totalRiderSumPrice += item.riderPayout;
        totalDailyBikeRentFee += item.dailyBikeRentFee;
        totalDailyRentFee += item.dailyRentFee;
        totalDailyInsuranceFee += item.dailyInsuranceFee;
        totalPenaltyFee += item.penaltyFee;
        totalDailyFixFeeExcep += item.dailyFixFeeExcep;
        totalPromotionFee += item.promotionFee;
        totalRiderLeaseFee += item.riderLeaseFee;
        totalIndustrialInsuranceFee += item.riderIndustrialInsuranceFee;
        totalAccidentInsuranceFee += item.riderAccidentInsuranceFee;
        totalRiderFixFee += item.riderFixFee;
        totalRiderFineFee += item.riderFineFee;
        totalRiderBuyProduct += item.riderBuyProduct;
        totalRiderEtc += item.etcFee;
        totalRiderSum += item.totalDeducted;
      }
      const delivery = item.category.includes('_RESTORE')
        ? item.totalDeliveryPrice * -1
        : item.totalDeliveryPrice;
      if (item.frName && delivery > 0) {
        totalC9Input += delivery;
      } else if (item.frName && delivery < 0) {
        totalC9Output += delivery;
      }
      if (item.totalDuePrice > 0) {
        totalC9Input += item.totalDuePrice;
      } else if (item.totalDuePrice < 0) {
        totalC9Output += item.totalDuePrice;
      }
      if (item.promotionFee > 0) {
        totalC9Input += item.promotionFee;
      } else if (item.promotionFee < 0) {
        totalC9Output += item.promotionFee;
      }
      if (item.frChargePrice > 0) {
        totalC9Input += item.frChargePrice;
      } else if (item.frChargePrice < 0) {
        totalC9Output += item.frChargePrice;
      }
      if (item.etcFee > 0 && item.frName) {
        totalC9Input += item.etcFee;
      } else if (item.etcFee < 0 && item.frName) {
        totalC9Output += item.etcFee;
      }
      if (item.riderPayout > 0) {
        totalC9Input += item.riderPayout;
      } else if (item.riderPayout < 0) {
        totalC9Output += item.riderPayout;
      }
      if (item.totalDeducted > 0) {
        totalC9Input += item.totalDeducted;
      } else if (item.totalDeducted < 0) {
        totalC9Output += item.totalDeducted;
      }
      totalc9AfterNcash += item.c9Ncash;
    });

    result.push({
      basicDeliveryPrice: totalBasicPayment,
      extraDeliveryPrice: totalExtraPayment,
      frDeliveryPrice: totalPrice,
      deliveryVat: totalVatPrice,
      totalDeliveryPrice: totalSumPrice,
      duePrice: totalInputPrice,
      duePriceVat: totalInputVat,
      totalDuePrice: totalFrFee,
      frPromotionFee: totalFrPromotion,
      frChargePrice: totalFrChargePrice,
      frEtcFee: totalFrEtc,
      deliveryPrice: totalRiderPrice,
      riderDeliveryFee: totalRiderFee,
      insuranceFee: totalRiderInsurance,
      riderPayout: totalRiderSumPrice,
      penaltyFee: totalPenaltyFee,
      dailyBikeRentFee: totalDailyBikeRentFee,
      dailyRentFee: totalDailyRentFee,
      dailyInsuranceFee: totalDailyInsuranceFee,
      dailyFixFeeExcep: totalDailyFixFeeExcep,
      promotionFee: totalPromotionFee,
      riderFixFee: totalRiderFixFee,
      riderLeaseFee: totalRiderLeaseFee,
      riderIndustrialInsuranceFee: totalIndustrialInsuranceFee,
      riderAccidentInsuranceFee: totalAccidentInsuranceFee,
      riderFineFee: totalRiderFineFee,
      riderBuyProduct: totalRiderBuyProduct,
      etcFee: totalRiderEtc,
      totalDeducted: totalRiderSum,
      deposit: totalC9Input,
      withdrawal: Math.abs(totalC9Output),
      balance: totalc9AfterNcash,
    });

    return result;
  };

  onDownload = async () => {
    const data = await this.getExcelList();
    if (!data) return;

    const excelJson = this.parseExcelJson(data);

    const ws = xlsx.utils.json_to_sheet(excelJson, {
      skipHeader: true,
      range: 2,
    });
    const wb = xlsx.utils.book_new();

    // excel columns merge
    const merges = [
      { s: { c: 0, r: 0 }, e: { c: 0, r: 3 } }, //일시
      { s: { c: 1, r: 0 }, e: { c: 1, r: 3 } }, // 관리지점
      { s: { c: 2, r: 0 }, e: { c: 2, r: 3 } }, // 주문번호
      { s: { c: 3, r: 0 }, e: { c: 3, r: 3 } }, // 분류
      { s: { c: 4, r: 0 }, e: { c: 4, r: 3 } }, // 비고
      { s: { c: 5, r: 0 }, e: { c: 17, r: 0 } }, // 가맹점
      { s: { c: 18, r: 0 }, e: { c: 39, r: 0 } }, // 라이더
      { s: { c: 5, r: 1 }, e: { c: 6, r: 1 } }, // 가맹점-정보
      { s: { c: 7, r: 1 }, e: { c: 11, r: 1 } }, // 배달건별
      { s: { c: 12, r: 1 }, e: { c: 14, r: 1 } }, // 가맹비(월)
      { s: { c: 15, r: 1 }, e: { c: 15, r: 3 } }, // 프로모션
      { s: { c: 16, r: 1 }, e: { c: 16, r: 3 } }, // 선충전
      { s: { c: 17, r: 1 }, e: { c: 17, r: 3 } }, // 기타
      { s: { c: 5, r: 2 }, e: { c: 5, r: 3 } }, // 가맹점명
      { s: { c: 6, r: 2 }, e: { c: 6, r: 3 } }, // 사업자번호
      { s: { c: 7, r: 2 }, e: { c: 9, r: 2 } }, // 배달비공금가액
      { s: { c: 10, r: 2 }, e: { c: 10, r: 3 } }, // 부가세
      { s: { c: 11, r: 2 }, e: { c: 11, r: 3 } }, // 배달비합계
      { s: { c: 12, r: 2 }, e: { c: 12, r: 3 } }, // 공급가액
      { s: { c: 13, r: 2 }, e: { c: 13, r: 3 } }, // 부가세
      { s: { c: 14, r: 2 }, e: { c: 14, r: 3 } }, // 합계
      { s: { c: 18, r: 1 }, e: { c: 20, r: 1 } }, // 라이더-정보
      { s: { c: 21, r: 1 }, e: { c: 25, r: 1 } }, // 배달건별
      { s: { c: 18, r: 2 }, e: { c: 18, r: 3 } }, // 라이더명
      { s: { c: 19, r: 2 }, e: { c: 19, r: 3 } }, // 주민번호
      { s: { c: 20, r: 2 }, e: { c: 20, r: 3 } }, // 기사수수료
      { s: { c: 21, r: 2 }, e: { c: 21, r: 3 } }, // 지급대상액
      { s: { c: 22, r: 2 }, e: { c: 23, r: 2 } }, // 제외
      { s: { c: 24, r: 2 }, e: { c: 24, r: 3 } }, // 배달프로모션
      { s: { c: 25, r: 2 }, e: { c: 25, r: 3 } }, // 실지급액
      { s: { c: 26, r: 1 }, e: { c: 26, r: 3 } }, // 배달페널티
      { s: { c: 27, r: 1 }, e: { c: 30, r: 1 } }, // 라이더 일차감
      { s: { c: 27, r: 2 }, e: { c: 27, r: 3 } }, // 렌트비
      { s: { c: 28, r: 2 }, e: { c: 28, r: 3 } }, // 대여금
      { s: { c: 29, r: 2 }, e: { c: 29, r: 3 } }, // 산재보험
      { s: { c: 30, r: 2 }, e: { c: 30, r: 3 } }, // 수리비(특수)
      { s: { c: 31, r: 1 }, e: { c: 31, r: 3 } }, // 프로모션
      { s: { c: 32, r: 1 }, e: { c: 32, r: 3 } }, // 수리비
      { s: { c: 33, r: 1 }, e: { c: 33, r: 3 } }, // 리스비
      { s: { c: 34, r: 1 }, e: { c: 34, r: 3 } }, // 산재보험
      { s: { c: 35, r: 1 }, e: { c: 35, r: 3 } }, // 상해보험
      { s: { c: 36, r: 1 }, e: { c: 36, r: 3 } }, // 과태료
      { s: { c: 37, r: 1 }, e: { c: 37, r: 3 } }, // 물품대
      { s: { c: 38, r: 1 }, e: { c: 38, r: 3 } }, // 기타
      { s: { c: 39, r: 1 }, e: { c: 39, r: 3 } }, // 합계
      { s: { c: 40, r: 0 }, e: { c: 42, r: 0 } }, // 본사예치금
      { s: { c: 40, r: 1 }, e: { c: 40, r: 3 } }, // 입금
      { s: { c: 41, r: 1 }, e: { c: 41, r: 3 } }, // 출금
      { s: { c: 42, r: 1 }, e: { c: 42, r: 3 } }, // 잔액
    ];
    ws['!merges'] = merges;
    // excel columns width
    const colWidth = [
      { width: 20 },
      { width: 10 },
      { width: 10 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 15 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 15 },
      { width: 20 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
    ];
    ws['!cols'] = colWidth;

    xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
    xlsx.writeFile(wb, '매출/비용 정산.xlsx');
  };

  getExcelList = async () => {
    let pageNum = 1;
    let pageSize = 1000000;
    let branchIdx = this.props.selectBranch;
    let businessNumber = this.props.businessNumber;
    let category = 'all';
    let frName = this.props.frName;
    let riderName = this.props.riderName;
    let registrationNumber = this.props.registrationNumber;
    let startDate = this.props.startDate;
    let endDate = moment(this.props.endDate).add(1, 'd').format('YYYY-MM-DD');
    const res = await httpGet(
      httpUrl.staticsCostList,
      [
        pageNum,
        pageSize,
        branchIdx,
        businessNumber,
        category,
        frName,
        riderName,
        registrationNumber,
        startDate,
        endDate,
      ],
      {}
    );
    return res.data.stCost;
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ textAlign: 'right', marginBottom: 36, marginTop: -70 }}>
          <Button
            className="download-btn"
            style={{ width: 160 }}
            onClick={() => this.onDownload()}>
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            엑셀 다운로드
          </Button>
        </div>
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.copyList}
          columns={this.columns}
          className="staticsCost-table"
          pagination={false}
          // onChange={this.handleTableChange}
          bordered
          summary={(pageData) => {
            // 가맹점 배달
            let totalBasicPayment = 0;
            let totalExtraPayment = 0;
            let totalPrice = 0;
            let totalVatPrice = 0;
            let totalSumPrice = 0;
            // 가맹비
            let totalInputPrice = 0;
            let totalInputVat = 0;
            let totalFrFee = 0;
            // 가맹점 선충전
            let totalFrChargePrice = 0;
            // 가맹점 프로모션
            let totalFrPromotion = 0;
            let totalFrEtc = 0;
            // 라이더 배달
            let totalRiderPrice = 0;
            let totalRiderFee = 0;
            let totalRiderInsurance = 0;
            let totalRiderSumPrice = 0;
            let totalPenaltyFee = 0;
            // 라이더 일차감
            let totalDailyBikeRentFee = 0;
            let totalDailyRentFee = 0;
            let totalDailyInsuranceFee = 0;
            let totalDailyFixFeeExcep = 0;
            // 라이더 건별
            let totalPromotionFee = 0;
            let totalRiderFixFee = 0;
            let totalRiderLeaseFee = 0;
            let totalIndustrialInsuranceFee = 0;
            let totalAccidentInsuranceFee = 0;
            let totalRiderFineFee = 0;
            let totalRiderBuyProduct = 0;
            let totalRiderEtc = 0;
            let totalRiderSum = 0;
            // 본사
            let totalC9Input = 0;
            let totalC9Output = 0;
            let totalc9AfterNcash = 0;

            pageData.forEach(
              ({
                frName,
                riderName,
                basicDeliveryPrice,
                extraDeliveryPrice,
                deliveryPrice,
                deliveryVat,
                totalDeliveryPrice,
                duePrice,
                duePriceVat,
                totalDuePrice,
                promotionFee,
                frChargePrice,
                etcFee,
                riderDeliveryFee,
                insuranceFee,
                penaltyFee,
                riderPayout,
                dailyBikeRentFee,
                dailyRentFee,
                dailyInsuranceFee,
                dailyFixFeeExcep,
                riderFixFee,
                riderLeaseFee,
                riderIndustrialInsuranceFee,
                riderAccidentInsuranceFee,
                riderFineFee,
                riderBuyProduct,
                totalDeducted,
                c9Ncash,
                category,
              }) => {
                // const totalDed =
                if (frName) {
                  totalBasicPayment += category.includes('_RESTORE')
                    ? basicDeliveryPrice * -1
                    : basicDeliveryPrice;
                  totalExtraPayment += category.includes('_RESTORE')
                    ? extraDeliveryPrice * -1
                    : extraDeliveryPrice;
                  totalPrice += category.includes('_RESTORE')
                    ? deliveryPrice * -1
                    : deliveryPrice;
                  totalVatPrice += category.includes('_RESTORE')
                    ? deliveryVat * -1
                    : deliveryVat;
                  totalSumPrice += category.includes('_RESTORE')
                    ? totalDeliveryPrice * -1
                    : totalDeliveryPrice;
                  totalInputPrice += duePrice * -1;
                  totalInputVat += duePriceVat * -1;
                  totalFrFee += totalDuePrice * -1;
                  totalFrChargePrice += frChargePrice;
                  totalFrPromotion += promotionFee;
                  totalFrEtc += etcFee;
                }
                if (riderName) {
                  totalRiderPrice += category.includes('_RESTORE')
                    ? deliveryPrice * -1
                    : deliveryPrice;
                  totalRiderFee += category.includes('_RESTORE')
                    ? riderDeliveryFee * -1
                    : riderDeliveryFee;
                  totalRiderInsurance += insuranceFee;
                  totalRiderSumPrice += riderPayout;
                  totalDailyBikeRentFee += dailyBikeRentFee;
                  totalDailyRentFee += dailyRentFee;
                  totalDailyInsuranceFee += dailyInsuranceFee;
                  totalPenaltyFee += penaltyFee;
                  totalDailyFixFeeExcep += dailyFixFeeExcep;
                  totalPromotionFee += promotionFee;
                  totalRiderLeaseFee += riderLeaseFee;
                  totalIndustrialInsuranceFee += riderIndustrialInsuranceFee;
                  totalAccidentInsuranceFee += riderAccidentInsuranceFee;
                  totalRiderFixFee += riderFixFee;
                  totalRiderFineFee += riderFineFee;
                  totalRiderBuyProduct += riderBuyProduct;
                  totalRiderEtc += etcFee;
                  totalRiderSum += totalDeducted;
                }
                const delivery = category.includes('_RESTORE')
                  ? totalDeliveryPrice * -1
                  : totalDeliveryPrice;
                if (frName && delivery > 0) {
                  totalC9Input += delivery;
                } else if (frName && delivery < 0) {
                  totalC9Output += delivery;
                }
                if (totalDuePrice > 0) {
                  totalC9Input += totalDuePrice;
                } else if (totalDuePrice < 0) {
                  totalC9Output += totalDuePrice;
                }
                if (promotionFee > 0) {
                  totalC9Input += promotionFee;
                } else if (promotionFee < 0) {
                  totalC9Output += promotionFee;
                }
                if (frChargePrice > 0) {
                  totalC9Input += frChargePrice;
                } else if (frChargePrice < 0) {
                  totalC9Output += frChargePrice;
                }
                if (etcFee > 0 && frName) {
                  totalC9Input += etcFee;
                } else if (etcFee < 0 && frName) {
                  totalC9Output += etcFee;
                }
                if (riderPayout > 0) {
                  totalC9Input += riderPayout;
                } else if (riderPayout < 0) {
                  totalC9Output += riderPayout;
                }
                if (totalDeducted > 0) {
                  totalC9Input += totalDeducted;
                } else if (totalDeducted < 0) {
                  totalC9Output += totalDeducted;
                }
                totalc9AfterNcash += c9Ncash;
              }
            );
            return (
              <>
                <Table.Summary.Row
                  style={{
                    backgroundColor: '#cbedfa',
                    color: '#000',
                    fontWeight: 'bold',
                    borderColor: '#000',
                  }}>
                  <Table.Summary.Cell
                    index={1}
                    colSpan={7}
                    style={{ textAlign: 'center' }}>
                    합계
                  </Table.Summary.Cell>

                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalBasicPayment)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalExtraPayment)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalPrice)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalVatPrice)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalSumPrice)}</Text>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalInputPrice)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalInputVat)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalFrFee)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalFrPromotion)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalFrChargePrice)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalFrEtc)}</Text>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell
                    index={1}
                    colSpan={3}
                    style={{ textAlign: 'center' }}>
                    합계
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalRiderPrice)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalRiderFee)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalRiderInsurance)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalRiderSumPrice)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalPenaltyFee)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalDailyBikeRentFee)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalDailyRentFee)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalDailyInsuranceFee)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalDailyFixFeeExcep)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalPromotionFee)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalRiderFixFee)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalRiderLeaseFee)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">
                      {comma(totalIndustrialInsuranceFee)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">
                      {comma(totalAccidentInsuranceFee)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalRiderFineFee)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalRiderBuyProduct)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalRiderEtc)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalRiderSum)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(Math.abs(totalC9Input))}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(Math.abs(totalC9Output))}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">
                      {comma(Math.abs(totalc9AfterNcash))}
                    </Text>
                  </Table.Summary.Cell>

                  {/* <Table.Summary.Cell ><Text type="danger">{totalDeliveryDues}</Text></Table.Summary.Cell> */}
                </Table.Summary.Row>
              </>
            );
          }}
        />
        <div className="page-btn">
          <Button
            style={{ margin: '10px' }}
            onClick={() => {
              if (this.state.pagination.current <= 1) return;

              const pagination = { ...this.state.pagination };
              pagination.current = pagination.current - 1;
              this.setState({ pagination }, this.getList);
            }}>
            이전
          </Button>
          <Button
            style={{ margin: '10px' }}
            onClick={() => {
              const pagination = { ...this.state.pagination };
              if (this.state.list.length < pagination.pageSize) {
                alert('마지막 페이지입니다.');
                return;
              }
              pagination.current = pagination.current + 1;
              this.setState({ pagination }, this.getList);
            }}>
            다음
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginInfo: state.login.loginInfo,
  };
};
export default connect(mapStateToProps, null)(StaticsTableAll);
