import {Button, Form, Modal, Table} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import {httpGet, httpPost, httpUrl} from '../../api/httpClient';
import SelectBox from '../../components/input/SelectBox';
import '../../css/main.css';
import {
  branchSettlementElementTypeCode,
} from '../../lib/util/codeUtil';
import CloseIcon from '../../img/close.png';
import { comma } from '../../lib/util/numberUtil';
import BranchSettlementDialog from './BranchSettlementDialog';
import ModifyBranchSettlementDialog from "./ModifyBranchSettlementDialog";
import BranchSettlementHistoryDialog from "./BranchSettlementHistoryDialog";
import {customAlert, customError} from "../../api/Modals";
import Const from '../../const';


const today = new moment();
const dateFormat1 = 'YYYY-MM-01';
const dateFormat2 = 'YYYY-MM-DD';
const todayString = today.format(dateFormat2);
const lastMonthString = today.subtract(1, 'M').format(dateFormat1);

class RegistBranchSettlementDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        current: 1,
        pageSize: 20,
        hideOnSinglePage: true,
      },
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      list5: [],
      list6: [],
      list7: [],
      list8: [],
      list9: [],
      branchNameList: { 0: '지점 선택' },
      branchNameValue: 0,
      isComplete: false,
      isDetailSettlementOpen: false,
      isElementSettlementOpen: false,
      isBranchSettlementHistoryOpen: false,
      startDate: moment(lastMonthString).format('YYYY-MM-01'),
      endDate: moment(lastMonthString).format('YYYY-MM-') + moment(lastMonthString).daysInMonth(),
      settlementDate: '',
      isPossibleModify: false,
      branchSettlementIdx: 0,
      branchProfitPercent: 0,
    };
    this.formRef = React.createRef();

  }


  componentDidMount() {
    let d = moment(todayString).date();
    if (d >= 1 && d <= Const.branchSettlementPossibleLastDay) {
      this.setState({
        isPossibleModify : true,
      })
    }

    this.getBranchInfo();

    if (this.props.data) {
      this.formRef.current.setFieldsValue({ ...this.props.data });
      this.setState({
        ...this.props.data,
        branchSettlementIdx: this.props.data.idx,
        firstBikeStatus: this.props.data.bikeStatus,
        branchNameValue: this.props.data.branchIdx,
        isComplete: this.props.data.completeDatetime ? true : false,
        settlementDate: this.props.data.settlementDate,
        startDate: this.props.data.settlementDate? moment(this.props.data.settlementDate).format('YYYY-MM-01') : this.state.startDate,
        endDate: this.props.data.settlementDate? moment(this.props.data.settlementDate).format('YYYY-MM-') + moment(this.props.data.settlementDate).daysInMonth()
            : this.state.endDate,
      }, () => {
        this.getList();
      });
    }
  }

  getBranchInfo = async () => {
    let branchNameList = this.state.branchNameList;
    try {
      const res = await httpPost(httpUrl.branchListInfo, [], {});
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data);
        res.data.branch.map(
            (item) => (branchNameList[item.idx] = item.branchName)
        );
        this.setState({
          branchNameList: branchNameList,
        });
      }
    } catch (error) {
      console.warn('getBranchInfo error: {}', error);
    }
  };

  onClickCompleteOrRestore = async (complete) => {
    let branchIdx = this.state.branchNameValue;
    let settlementDate = this.state.settlementDate;
    let isComplete = complete;
    try {
      const res = await httpPost(httpUrl.branchSettlementComplete, [], {branchIdx, settlementDate, isComplete});
      if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
        console.log('res');
        console.log(res.data);

        this.setState({
          isComplete: isComplete,
        });
        if (complete) {
          this.openDetailSettlementDialog();
        }
      } else if (res.data === 'INVALID_SETTLEMENT_DAY') {
        customError('정산 에러',
            '정산 확정 및 수정은 1~' + Const.branchSettlementPossibleLastDay + '일까지 가능 합니다.');
      } else if (res.data === '' && complete == false) {
        customError('정산 수정 불가',
            '이미 확정 및 지급 된 정산입니다. 수정은 이번 달 정산만 가능합니다.',);
      } else if (res.data === 'NOT_EXIST_GUARANTEED_AMOUNT') {
        customError('정산 확정 불가',
            '정산 확정 하려면 보존 금액을 입력 해야 합니다.',);
      }
    } catch (error) {
      console.warn('getBranchInfo error: {}', error);
    }
  };


  getList = () => {
    const { pagination } = this.state;
    const pageNum = pagination.current;
    const pageSize = pagination.pageSize;
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    const branchIdx = this.state.branchNameValue;
    const dataRange = 0;
    httpGet(httpUrl.getAutoCalcBranchSettlement, [startDate, endDate, dataRange, branchIdx, pageNum, pageSize], {}).then((res) => {
      console.log('res');
      console.log(res);
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      let tempList1 = [
        {
          type: '스테이션 손익',
          money: res.data.info.totalSales - res.data.info.totalCost,
        },
        {
          type: 25,
          money: res.data.info.branchManagerProfit,
        },
        {
          type: '실정산 금액',
          money: res.data.info.branchManagerProfit - res.data.info.monthlyPaymentByPromotion - res.data.info.monthlyPaymentByEtc2,
        },
        {
          type: 26,
          money: res.data.info.branchDepositAmount,
        },
      ];
      let tempList2 = [
        {
          type: 1,
          money: res.data.info.totalSales,
        },
      ];
      let tempList3 = [
        {
          type: 2,
          money: res.data.info.deliveryPrice,
          memo: res.data.deliveryPriceMemo,
        },
        {
          type: 4,
          money: res.data.info.deliveryFeeForRider,
          memo: res.data.deliveryFeeForRiderMemo,
        },
        {
          type: 5,
          money: res.data.info.franchiseFee,
          memo: res.data.franchiseFeeMemo,
        },
      ];
      let tempList4 = [
        {
          type: 6,
          money: res.data.info.totalCost,
        },
      ];
      let tempList5 = [
        {
          type: 29,
          money: res.data.info.subsidizeCost,
          memo: res.data.subsidizeCostMemo,
        },
        {
          type: 30,
          money: res.data.info.industrialAccidentCost,
          memo: res.data.industrialAccidentCostMemo,
        },
        {
          type: 31,
          money: res.data.info.employmentInsuranceCost,
          memo: res.data.employmentInsuranceCostMemo,
        },
        {
          type: 8,
          money: res.data.info.hireCost,
          memo: res.data.hireCostMemo,
        },
        {
          type: 9,
          money: res.data.info.maintenanceCost,
          memo: res.data.maintenanceCostMemo,
        },
        {
          type: 10,
          money: res.data.info.rentalCost,
          memo: res.data.rentalCostMemo,
        },
        {
          type: 13,
          money: res.data.info.salesIncentivesCost,
          memo: res.data.salesIncentivesCostMemo,
        },
        {
          type: 14,
          money: res.data.info.consumableCost,
          memo: res.data.consumableCostMemo,
        },
        {
          type: 22,
          money: res.data.info.bikeMaintenanceCost,
          memo: res.data.bikeMaintenanceCostMemo,
        },
        {
          type: 20,
          money: res.data.info.refundFood,
          memo: res.data.refundFoodMemo,
        },
        {
          type: 33,
          money: res.data.info.businessCardCost,
          memo: res.data.businessCardCostMemo,
        },
        {
          type: 34,
          money: res.data.info.nameTagCost,
          memo: res.data.nameTagCostMemo,
        },
        {
          type: 35,
          money: res.data.info.leafletCost,
          memo: res.data.leafletCostMemo,
        },
        {
          type: 36,
          money: res.data.info.prechargeUncollectedCost,
          memo: res.data.prechargeUncollectedCostMemo,
        },
        {
          type: 15,
          money: res.data.info.etcCost,
          memo: res.data.etcCostMemo,
        },
      ];
      let tempList6 = [
        {
          type: '기타2',
          detailType: '당월 기타2 금액',
          money: res.data.info.etc2Cost,
          memo: res.data.etc2CostMemo,
        },
        {
          type: '기타2',
          detailType: '기타2 이월 된 금액',
          money: res.data.info.remainingEtc2Amount,
          memo: '',
        },
        {
          type: '기타2',
          detailType: '기타2 차감된 금액',
          money: res.data.info.monthlyPaymentByEtc2,
          memo: '',
        },
      ];
      let tempList7 = [
        {
          type: '(이월)프로모션',
          detailType: '(5) [기타2] 제외한 이월 된 모든 금액',
          money: res.data.info.promotionBeforeRemainingAmount,
          memo: '',
        },
        {
          type: '(당월)프로모션',
          detailType: '(1)지점 인수 금액',
          money: res.data.info.promotionBranchBuy,
          memo: res.data.branchBuyMemo,
        },
        {
          type: '(당월)프로모션',
          detailType: '(2)라이더 예치금 지급',
          money: res.data.info.amounts.paymentAmountForRider,
          memo: '',
        },
        {
          type: '(당월)프로모션',
          detailType: '(3)가맹점 예치금 지급',
          money: res.data.info.amounts.paymentAmountForFranchise,
          memo: '',
        },
        {
          type: '(당월)프로모션',
          detailType: '(4)라이더 프로모션',
          money: res.data.info.amounts.deliveryFeeForRider,
          memo: '',
        },
      ];

      let tempList8 = [
        {
          type: '(5) + (당월 프로모션 부담 금액) [(2)+(3)+(4) * 40%]',
          money: res.data.info.promotionBranchBurdenTotal,
        },
        {
          type: '(당월)프로모션 차감 한도(30%)',
          money: res.data.info.promotionBranchManagerPayment,
        },
        {
          type: '(당월)프로모션 차감된 금액',
          money: res.data.info.monthlyPaymentByPromotion,
        },
      ];

      let tempList9 = [
        {
          type: 46,
          money: res.data.info.guaranteedAmount,
          memo: res.data.guaranteedAmountMemo,
        },
        {
          type: 47,
          money: res.data.info.promotionRemainingAmount,
          memo: res.data.promotionRemainingAmountMemo,
        },
        {
          type: 48,
          money: res.data.info.corporateCardAmount,
          memo: res.data.corporateCardAmountMemo,
        },
      ];

      this.setState({
        list: res.data.list,
        branchSettlementIdx: res.data.info.idx,
        list1: tempList1,
        list2: tempList2,
        list3: tempList3,
        list4: tempList4,
        list5: tempList5,
        list6: tempList6,
        list7: tempList7,
        list8: tempList8,
        list9: tempList9,
        isComplete: res.data.info.completeDatetime ? true : false,
        settlementDate: res.data.info.settlementDate,
        branchProfitPercent: res.data.branchProfitPercent * 100,
      });
    });
  };

  handleSubmit = () => {

  };

  openDetailSettlementDialog = () => {
    this.setState({
      isDetailSettlementOpen: true,
    });
  };

  closeDetailSettlementDialog = () => {
    this.setState({ isDetailSettlementOpen: false }, () => {
      this.getList();
    });
  };

  openElementSettlementDialog = (row) => {
    let rowData = row;
    // type이 기타2와 프로모션일 경우 값 수정
    if (rowData.type == '기타2') {
      rowData.type = 16;
    } else if (rowData.detailType == '(1)지점 인수 금액') {
      rowData.type = 37;
    }

    if (this.state.branchSettlementIdx == null) {
      const startDate = this.state.startDate;
      const endDate = this.state.endDate;
      const branchIdx = this.state.branchNameValue;

      httpGet(httpUrl.getBranchSettlementIdx, [startDate, endDate, 0, branchIdx, 1, 10], {}).then((res) => {
        console.log('res');
        console.log(res);
        this.setState({
          branchSettlementIdx: res.data,
          isElementSettlementOpen: true,
          selectedRow: rowData,
        });
      });
    } else {
      this.setState({
        isElementSettlementOpen: true,
        selectedRow: rowData,
      });
    }
  };

  closeElementSettlementDialog = () => {
    this.setState({ isElementSettlementOpen: false }, () => {
      this.getList();
    });
  };

  openBranchSettlementHistoryDialog = (row) => {
    let rowData = row;
    // type이 기타2와 프로모션일 경우 값 수정
    if (rowData.type == '기타2') {
      rowData.type = 16;
    } else if (rowData.detailType == '(1)지점 인수 금액') {
      rowData.type = 37;
    }
    if (this.state.branchSettlementIdx == null) {
      const startDate = this.state.startDate;
      const endDate = this.state.endDate;
      const branchIdx = this.state.branchNameValue;

      httpGet(httpUrl.getBranchSettlementIdx, [startDate, endDate, 0, branchIdx, 1, 10], {}).then((res) => {
        console.log('res');
        console.log(res);
        this.setState({
          branchSettlementIdx: res.data,
          isBranchSettlementHistoryOpen: true,
          selectedRow: rowData,
        });
      });
    } else {
      this.setState({
        isBranchSettlementHistoryOpen: true,
        selectedRow: rowData,
      });
    }
  };

  closeBranchSettlementHistoryDialog = () => {
    this.setState({ isBranchSettlementHistoryOpen: false }, () => {
      this.getList();
    });
  };

  render() {
    const columns0 = [
      {
        title: '구분',
        dataIndex: 'type',
        className: 'table-column-center',
        width: '150px',
        render: (data) => (<div> {branchSettlementElementTypeCode[data] == null ? data : branchSettlementElementTypeCode[data]} </div>),
      },
      {
        title: '금액',
        dataIndex: 'money',
        calssName: 'table-column-center',
        width: '150px',
        render: (data) => <div>{data == null ? '' : comma(data)}</div>,
      },
      {
        title: '수동&자동 계산',
        className: 'table-column-left',
        width: '150px',
        render: (data, row) => (
            <div>
              <Button
                  disabled={this.state.isPossibleModify && !this.state.isComplete ? false : true}
                  onClick={() => {
                    this.openElementSettlementDialog(row);
                  }}>
                수정
              </Button>
            </div>
        ),
      },
      {
        title: '수정내역',
        dataIndex: 'memo',
        className: 'table-column-text',
      },
      {
        title: '상세보기',
        className: 'table-column-text',
        width: '150px',
        render: (data, row) => (
            <div>
              <Button
                  onClick={() => {
                    this.openBranchSettlementHistoryDialog(row);
                  }}>
                보기
              </Button>
            </div>
        ),
      },
    ];

    const columns1 = [
      {
        title: '구분',
        dataIndex: 'type',
        className: 'table-column-center',
        render: (data, row) => (<div> {branchSettlementElementTypeCode[data] == null ? data : branchSettlementElementTypeCode[data]} </div>),
      },
      {
        title: '금액',
        dataIndex: 'money',
        calssName: 'table-column-center',
        render: (data) => <div>{data == null ? '' : comma(data)}</div>,
      },
    ];
    const columns2 = [
      {
        title: '매출 상세',
        calssName: 'table-column-center',
        children: columns0,
      },
    ];
    const columns3 = [
      {
        title: '비용 상세',
        calssName: 'table-column-center',
        children: columns0,
      },
    ];
    const columns4 = [
      {
        title: '구분',
        dataIndex: 'type',
        className: 'table-column-center',
        render: (data) => (<div> {data} </div>),
      },
      {
        title: '분류',
        dataIndex: 'detailType',
        className: 'table-column-center',
        render: (data) => (<div> {data} </div>),
      },
      {
        title: '금액',
        dataIndex: 'money',
        calssName: 'table-column-center',
        height: '10',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '수동&자동 계산',
        className: 'table-column-left',
        render: (data, row, rowIndex) => {
          return row.detailType == '당월 기타2 금액' || row.detailType == '(1)지점 인수 금액' ? (<div>
            <Button
                disabled={this.state.isPossibleModify && !this.state.isComplete
                    ? false : true}
                onClick={() => {
                  this.openElementSettlementDialog(row);
                }}>
              수정
            </Button>
          </div>) : (<div></div>);
        },
      },
      {
        title: '수정내역',
        dataIndex: 'memo',
        className: 'table-column-text',
      },
      {
        title: '상세보기',
        className: 'table-column-text',
        render: (data, row, rowIndex) => {
          return row.detailType == '당월 기타2 금액' || row.detailType == '(1)지점 인수 금액' ? (<div>
            <Button
                onClick={() => {
                  this.openBranchSettlementHistoryDialog(row);
                }}>
              보기
            </Button>
          </div>) : (<div></div>);
        },
      },
    ];

    const { close, data } = this.props;
    const { branchNameList } = this.state;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div
          className="systemKey-Dialog"
          style={{ height: '100%', width: '43%' }}>
          <div className="systemKey-content" style={{ height: '100%' }}>
            <div className="systemKey-title">
              <div>
              {data ? '지점 정산 수정' : '지점 정산 등록'}
              <SelectBox
                  value={branchNameList[this.state.branchNameValue]}
                  code={Object.keys(branchNameList)}
                  codeString={branchNameList}
                  style={{width: '160px', padding: '10px'}}
                  disabled={this.state.branchNameValue > 0 ? true : false}
                  onChange={(value) => {
                    if (this.state.isPossibleModify) {
                      if (parseInt(value) !== this.state.branchNameValue) {
                        if (value > 0) {
                          this.setState({ branchNameValue: parseInt(value) }, () =>
                              this.getList()
                          );
                        }
                      }
                    } else {
                      customAlert(
                          '정산 등록 불가',
                          '정산 가능 기간이 아닙니다. 매월 1~' + Const.branchSettlementPossibleLastDay + '일에 가능합니다.'
                      );
                    }
                  }}
              />
              <div style={{display:'inline-block', fontSize: '15px'}}>
                정산 기간 : {this.state.startDate} ~ {this.state.endDate}
              </div>
                <div style={{display:'inline-block', fontSize: '25px'}}>
                  &nbsp;&nbsp;영업 이익율 : {this.state.branchProfitPercent + ' %'}
                </div>
            </div>
            </div>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="dialog-close"
              alt="exit"
            />
            <div className="systemKey-inner2" style={{ height: '90%' }}>
              <Form
                ref={this.formRef}
                onFinish={this.handleSubmit}
                className="create-control-form">

                <div className="settlement-container">
                  <Table
                    className="searchSettlement-table"
                    dataSource={this.state.list1}
                    columns={columns1}
                    pagination={this.state.pagination}
                    style={{ border: '1px solid black'}}
                  />
                </div>
                <div style={{paddingTop: '20px'}}></div>
                <div className="settlement-container">
                <div>
                  <Table
                      className="searchSettlement-table"
                      dataSource={this.state.list2}
                      columns={columns1}
                      pagination={this.state.pagination}
                      style={{ width: '800px', border: '1px solid black'}}
                  />
                  <Table
                      className="searchSettlement-table"
                      dataSource={this.state.list3}
                      columns={columns2}
                      pagination={this.state.pagination}
                      style={{ width: '800px', border: '1px solid black'}}
                  />
                  <div style={{paddingTop: '20px'}}></div>
                  <Table
                      className="searchSettlement-table"
                      dataSource={this.state.list4}
                      columns={columns1}
                      pagination={this.state.pagination}
                      style={{ width: '800px', border: '1px solid black'}}
                  />
                  <Table
                      className="searchSettlement-table"
                      dataSource={this.state.list5}
                      columns={columns3}
                      pagination={this.state.pagination}
                      style={{ width: '800px', border: '1px solid black'}}
                  />
                  <div style={{paddingTop: '20px'}}></div>
                  <Table
                      className="searchSettlement-table"
                      dataSource={this.state.list6}
                      columns={columns4}
                      pagination={this.state.pagination}
                      style={{ width: '800px', border: '1px solid black'}}
                  />
                  <div style={{paddingTop: '20px'}}></div>
                  <Table
                      className="searchSettlement-table"
                      dataSource={this.state.list7}
                      columns={columns4}
                      pagination={this.state.pagination}
                      style={{ width: '800px', border: '1px solid black'}}
                  />
                  <Table
                      className="searchSettlement-table"
                      dataSource={this.state.list8}
                      columns={columns1}
                      pagination={this.state.pagination}
                      style={{ width: '800px', border: '1px solid black'}}
                  />
                  <Table
                      className="searchSettlement-table"
                      dataSource={this.state.list9}
                      columns={columns0}
                      pagination={this.state.pagination}
                      style={{ width: '800px', border: '1px solid black'}}
                  />
                </div>
                </div>

                {this.state.isDetailSettlementOpen && (
                    <BranchSettlementDialog
                        data={{
                          startDate: this.state.startDate,
                          endDate: this.state.endDate,
                          branchIdx: this.state.branchNameValue,
                        }}
                        close={this.closeDetailSettlementDialog}
                    />
                )}

                {this.state.isElementSettlementOpen && (
                    <ModifyBranchSettlementDialog
                        data={{
                          type: this.state.selectedRow.type,
                          branchSettlementIdx: this.state.branchSettlementIdx,
                        }}
                        closeModify={this.closeElementSettlementDialog}
                    />
                )}

                {this.state.isBranchSettlementHistoryOpen && (
                    <BranchSettlementHistoryDialog
                        data={{
                          type: this.state.selectedRow.type,
                          branchSettlementIdx: this.state.branchSettlementIdx,
                        }}
                        close={this.closeBranchSettlementHistoryDialog}
                    />
                )}
              </Form>
            </div>

            <div className="registBike-btn">
              <Button
                type="primary"
                disabled={this.state.isPossibleModify ? false : true}
                onClick={() => {
                  if ( moment(this.state.settlementDate).isAfter(lastMonthString)) {
                    if (this.state.isComplete) {
                      this.onClickCompleteOrRestore(false);
                    } else {
                      customAlert(
                          '정산 수정 불가',
                          '정산 확정 후 수정 가능.'
                      );
                    }
                  } else {
                    customAlert(
                        '정산 수정 불가',
                        '정산 수정이 불가능한 항목입니다. (2개월 이전 수정 불가)'
                    );
                  }
                }}
                htmlType="submit"
                style={
                !this.state.isComplete ?
                {
                  backgroundColor: 'gray',
                  borderColor: 'black',
                  color: 'white',
                  width: 100,
                  height: 30,
                  fontSize: 13,
                  marginTop: 5,
                }:
                    {
                      backgroundColor: 'yellow',
                      borderColor: 'black',
                      color: 'black',
                      width: 100,
                      height: 30,
                      fontSize: 13,
                      marginTop: 5,
                    }}>
                수정
              </Button>
              <Button
                  type="primary"
                  onClick={() => {
                    this.onClickCompleteOrRestore(true);

                  }}
                  htmlType="submit"
                  style={{
                    backgroundColor: 'black',
                    borderColor: 'black',
                    color: 'white',
                    width: 100,
                    height: 30,
                    fontSize: 13,
                    marginTop: 5,
                  }}>
                {
                  this.state.isComplete ? '정산 확인' : '정산 확정'
                }
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegistBranchSettlementDialog;
