import React, { useEffect, useState } from 'react';
import { comma } from '../../lib/util/numberUtil';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from '../../const';
import { parseInt } from 'lodash';

const AgencyDashboardAgencyTbl = (props) => {
  const loginInfo = reactLocalStorage.getObject(con.appName + '#adminUser');
  const [sales, setSales] = useState([]);
  const [costes, setCostes] = useState([]);
  const [vates, setVates] = useState([]);
  const [restData, setRestData] = useState({
    totalSalesAmount: 0,
    totalCostAmount: 0,
    totalTaxAmount: 0,
    totalNetProfit: 0,
    prechargeMisuAmount: 0,
    batchWorkNcashMisuAmount: 0,
    depositTransactionMisuAmount: 0,
  });

  useEffect(() => {
    if (Object.keys(props.data).length !== 0) {
      settingData(props.data);
    }
  }, [props.data]);
  const settingData = (data) => {
    let salesData = [
      {
        title: '제휴본사수수료',
        subTitle: '',
        count: data.companyFeesCount,
        amount: data.companyFees,
      },
      // {
      //   title: '3.3% 수수료',
      //   subTitle: '(가맹비 3.3%)',
      //   count: data.threeDotThreeFeeCount,
      //   amount: data.threeDotThreeFeeAmount,
      // },
      {
        title: '프로그램 사용료',
        subTitle: '',
        count: data.agencyFeeSalesCount,
        amount: data.agencyFeeSalesAmount,
      },
    ];
    let costData = [
      {
        title: '프로그램 사용료',
        subTitle: '',
        count: data.agencyFeeCount,
        amount: data.agencyFeeAmount,
      },
      {
        title: '선충전',
        subTitle: '',
        count: data.prechargeCostCount,
        amount: data.prechargeCostAmount,
      },
      {
        title: '프로모션',
        subTitle: '',
        count: data.promotionCount,
        amount: data.promotionAmount,
      },
      {
        title: '대여금',
        subTitle: '',
        count: data.loanCostCount,
        amount: data.loanCostAmount,
      },
      {
        title: '기타 비용',
        subTitle: '',
        count: data.etcCostCount,
        amount: data.etcCostAmount,
      },
    ];
    let vatData = [
      {
        title: '배달비 부가세',
        subTitle: '',
        count: data.deliveryVatCount,
        amount: data.deliveryVatAmount,
      },
      {
        title: '가맹비 부가세',
        subTitle: '',
        count: data.duesVatCount,
        amount: data.duesVatAmount,
      },
      // {
      //   title: '공유콜 매입 부가세',
      //   subTitle: '',
      //   count: data.shareCallVatCostCount,
      //   amount: data.shareCallVatCostAmount,
      // },
      // {
      //   title: '라이더 원천세',
      //   subTitle: '',
      //   count: data.withholdingTaxCount,
      //   amount: data.withholdingTax,
      // },
      // {
      //   title: '프로그램 사용료 매입 부가세',
      //   subTitle: '',
      //   count: data.agencyFeeVatCount,
      //   amount: data.agencyFeeVatAmount,
      // },
    ];
    const num = 9;
    const salesClone = [...salesData];
    const constClone = [...costData];
    const vatClone = [...vatData];
    const filterSales = salesClone.filter((x) => x.amount !== 0);
    const filterCostes = constClone.filter((x) => x.amount !== 0);
    const filterVat = vatClone.filter((x) => x.amount !== 0);
    const salesNum = parseInt(Math.floor(num - filterSales.length));
    const constNum = parseInt(Math.floor(num - filterCostes.length));
    const vatNum = parseInt(Math.floor(num - filterVat.length));
    const initial = { title: '', subTitle: '', count: 0, amount: 0 };
    for (let i = 0; i < salesNum; i++) {
      filterSales.push(initial);
    }
    for (let i = 0; i < constNum; i++) {
      filterCostes.push(initial);
    }
    for (let i = 0; i < vatNum; i++) {
      filterVat.push(initial);
    }
    setSales(filterSales);
    setCostes(filterCostes);
    setVates(filterVat);
    setRestData({
      totalSalesAmount: data.totalSalesAmount,
      totalCostAmount: data.totalCostAmount,
      totalTaxAmount: data.totalTaxAmount,
      totalNetProfit: data.totalNetProfit,
      prechargeMisuAmount: data.prechargeMisuAmount,
      batchWorkNcashMisuAmount: data.batchWorkNcashMisuAmount,
      depositTransactionMisuAmount: data.depositTransactionMisuAmount,
    });
  };
  return (
    <div id={'settlement'}>
      <div className="settleDetail">
        {/* 매출 */}
        <div className="settleDetailContent">
          <div className="settleDetailTitle">수입 <span style={{fontSize: 12}}>(VAT 포함)</span></div>
          <div className="settleDetailBox">
            <div className="settleDetailCategoryBox-title">
              <div className="settleDetailDiv">구분</div>
              <div
                className="settleDetailCount"
                style={{ fontWeight: 'bold', textAlign: 'center' }}>
                건수 합계
              </div>
              <div
                className="settleDetailTotal"
                style={{ fontWeight: 'bold', textAlign: 'center' }}>
                금액 합계
              </div>
            </div>
            {sales.map((value, index) => {
              return (
                <div
                  className={
                    index % 2 === 0
                      ? 'settleDetailCategoryBox'
                      : 'settleDetailCategoryBox odd'
                  }
                  key={index}>
                  <div className="settleDetailDiv">
                    {value.amount !== 0 && <p>{value.title}</p>}
                    {value.amount !== 0 && value.subTitle && (
                      <p className="settleDetailSub">{value.subTitle}</p>
                    )}
                  </div>
                  <div className="settleDetailCount">
                    {value.amount !== 0 && comma(value.count) + '건'}
                  </div>
                  <div className="settleDetailTotal">
                    {value.amount !== 0 && comma(value.amount) + '원'}
                  </div>
                </div>
              );
            })}
            <div className="settleDetailSumBox">
              수입 합계
              <span style={{ marginLeft: 16, fontSize: 20 }}>
                {comma(restData.totalSalesAmount)} 원
              </span>
            </div>
          </div>
        </div>
        {/* 비용 */}
        <div className="settleDetailContent" style={{ margin: '0 20px' }}>
          <div className="settleDetailTitle">비용 <span style={{ fontSize: 12 }}>(VAT 포함)</span></div>
          <div className="settleDetailBox">
            <div className="settleDetailCategoryBox-title">
              <div className="settleDetailDiv">구분</div>
              <div className="settleDetailCount" style={{ fontWeight: 'bold' }}>
                건수 합계
              </div>
              <div className="settleDetailTotal" style={{ fontWeight: 'bold' }}>
                금액 합계
              </div>
            </div>
            {costes.map((value, index) => {
              return (
                <div
                  className={
                    index % 2 === 0
                      ? 'settleDetailCategoryBox'
                      : 'settleDetailCategoryBox odd'
                  }
                  key={index}>
                  <div className="settleDetailDiv">
                    {value.amount !== 0 && <p>{value.title}</p>}
                    {value.amount !== 0 && value.subTitle && (
                      <p className="settleDetailSub">{value.subTitle}</p>
                    )}
                  </div>
                  <div className="settleDetailCount">
                    {value.amount !== 0 && comma(value.count) + '건'}
                  </div>
                  <div className="settleDetailTotal">
                    {value.amount !== 0 && comma(value.amount) + '원'}
                  </div>
                </div>
              );
            })}
            <div className="settleDetailSumBox">
              비용 합계
              <span style={{ marginLeft: 16, fontSize: 20 }}>
                {comma(restData.totalCostAmount)} 원
              </span>
            </div>
          </div>
        </div>

        {/* 부가세 */}
        <div className="settleDetailContent">
          <div className="settleDetailTitle">총 대리점 세금</div>
          <div className="settleDetailBox">
            <div className="settleDetailCategoryBox-title">
              <div className="settleDetailDiv">구분</div>
              <div className="settleDetailCount" style={{ fontWeight: 'bold' }}>
                건수 합계
              </div>
              <div className="settleDetailTotal" style={{ fontWeight: 'bold' }}>
                금액 합계
              </div>
            </div>
            {vates.map((value, index) => {
              return (
                <div
                  className={
                    index % 2 === 0
                      ? 'settleDetailCategoryBox'
                      : 'settleDetailCategoryBox odd'
                  }
                  key={index}>
                  <div className="settleDetailDiv">
                    {value.amount !== 0 && <p>{value.title}</p>}
                    {value.amount !== 0 && value.subTitle && (
                      <p className="settleDetailSub">{value.subTitle}</p>
                    )}
                  </div>
                  <div className="settleDetailCount">
                    {value.amount !== 0 && comma(value.count) + '건'}
                  </div>
                  <div className="settleDetailTotal">
                    {value.amount !== 0 && comma(value.amount) + '원'}
                  </div>
                </div>
              );
            })}

            <div className="settleDetailSumBox">
              예상 세금 합계
              <span style={{ marginLeft: 16, fontSize: 20 }}>
                {comma(restData.totalTaxAmount)} 원
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="settleDetail" style={{ marginTop: 20 }}>
        <div className="settleSubContent" style={{ marginRight: 10 }}>
          전체 미회수 합계{' '}
          <span style={{ marginLeft: 16, color: '#f00000' }}>
            {comma(restData.batchWorkNcashMisuAmount)} 원
          </span>
        </div>
        <div className="settleSubContent" style={{ marginLeft: 10 }}>
          선충전 합계{' '}
          <span style={{ marginLeft: 16, color: '#f00000' }}>
            {comma(restData.prechargeMisuAmount)} 원
          </span>
        </div>
      </div>
      <div className="settleDetail" style={{ marginTop: 20, height: 100 }}>
        <div
          style={{
            flex: 2,
            backgroundColor: '#222',
            color: '#fff',
            padding: 8,
            paddingLeft: 24,
            paddingRight: 24,
            display: 'flex',
            alignItems: 'center',
          }}>
          <div
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            }}>
            총 예상 수익
            <span
              style={{ fontSize: 14, fontWeight: 'normal', marginLeft: 16 }}>
              수입 {comma(restData.totalSalesAmount)}원 - 비용{' '}
              {comma(restData.totalCostAmount)}원
            </span>
          </div>
          <div
            style={{
              marginLeft: 'auto',
              fontSize: 28,
              fontWeight: 'bold',
            }}>
            {comma(restData.totalNetProfit)}{' '}
            <span style={{ fontSize: 18, fontWeight: 'normal' }}>원</span>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            backgroundColor: '#222',
            color: '#fff',
            padding: '0 24px',
            display: 'flex',
            alignItems: 'center',
            marginLeft: 20,
          }}>
          <div
            style={{
              fontSize: 24,
              fontWeight: 'bold',
            }}>
            예치금 잔액
          </div>
          <div
            style={{
              marginLeft: 'auto',
              fontSize: 28,
              fontWeight: 'bold',
            }}>
            {comma(loginInfo.ncash)}{' '}
            <span style={{ fontSize: 18, fontWeight: 'normal' }}>원</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AgencyDashboardAgencyTbl;
