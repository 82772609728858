import { Button, Input, Modal, Radio, Table } from 'antd';
import React, { useState } from 'react';
import { httpPost, httpUrl } from '../../api/httpClient';

// TODO 사업자번호 겹쳐서 요청오는 경우 고려해야 됨
const ManualMapping = () => {
  const [posCode, setPosCode] = useState(null);
  const [list, setList] = useState([]);
  const [duplicateList, setDuplicateList] = useState([]);

  const parseSpdTable = (rawData) => {
    let parsedList = [];

    const lines = rawData.split(`\n`);
    lines.forEach((line) => {
      const words = line.split(`\t`);

      const [posStoreCode, frName, businessNumber, result] = words;

      parsedList.push({
        posStoreCode,
        frName,
        businessNumber,
        result,
      });
    });

    const dpList = [];
    parsedList.forEach((a) => {
      parsedList.forEach((b) => {
        if (
          a.posStoreCode !== b.posStoreCode &&
          a.businessNumber === b.businessNumber
        )
          dpList.push(a);
      });
    });

    if (dpList.length > 0) {
      dpList.forEach((dp) => {
        parsedList = parsedList.filter(
          (p) => p.businessNumber !== dp.businessNumber
        );
      });
    }

    setList(parsedList);
    setDuplicateList(dpList);
  };

  const parseKonTable = (rawData) => {
    console.log(rawData.split(`\n\n`));

    const words = rawData.split(`\n\n`);
    if (rawData && words.length % 6 !== 0) {
      Modal.warn({
        title: '입력오류',
        content: '형식이 잘못됨.',
      });
      return;
    }

    let parsedList = [];
    let row = {};
    for (let i = 0; i < words.length; i++) {
      if (i % 6 === 0) row['requestDate'] = words[i];
      else if (i % 6 === 1) row['frName'] = words[i];
      else if (i % 6 === 2)
        row['businessNumber'] = words[i].split('-').join('');
      else if (i % 6 === 3) row['frAddress'] = words[i];
      else if (i % 6 === 4) row['frPhone'] = words[i];
      else if (i % 6 === 5) {
        row['posStoreCode'] = words[i];

        if (!Object.keys(row).length === 6) {
          parsedList.push({ result: '잘못된데이터' });
          continue;
        }

        row['result'] = '처리전';
        parsedList.push(row);
        row = {};
      }
    }

    const dpList = [];
    parsedList.forEach((a) => {
      parsedList.forEach((b) => {
        if (
          a.posStoreCode !== b.posStoreCode &&
          a.businessNumber === b.businessNumber
        )
          dpList.push(a);
      });
    });

    if (dpList.length > 0) {
      dpList.forEach((dp) => {
        parsedList = parsedList.filter(
          (p) => p.businessNumber !== dp.businessNumber
        );
      });
    }

    setList(parsedList);
    setDuplicateList(dpList);
  };

  const handleSubmit = async () => {
    if (posCode === 'spd') {
      const res = await httpPost(httpUrl.manualMapping, [], {
        posCode,
        spdManualMappingRequest: list,
      });

      let newList = [];
      list.forEach((request) => {
        const targetRes = res.data.results.find(
          (result) =>
            result.spdManualMappingRequest.posStoreCode === request.posStoreCode
        );
        if (targetRes === null) {
          newList.push({
            ...request,
            result: 'RES_NOT_FOUND',
            resultMsg: null,
          });
        } else {
          newList.push({
            ...request,
            result: targetRes.resultCd,
            resultMsg: targetRes.resultMsg,
          });
        }
      });

      setList(newList);

      Modal.info({
        title: '매핑결과',
        content: res.data.resultCd,
      });
    } else if (posCode === 'kon') {
      const res = await httpPost(httpUrl.manualMapping, [], {
        posCode,
        konManualMappingRequest: list,
      });
      console.log(res);
      let newList = [];
      list.forEach((request) => {
        const targetRes = res.data.results.find(
          (result) =>
            result.konManualMappingRequest.posStoreCode === request.posStoreCode
        );
        if (targetRes === null) {
          newList.push({
            ...request,
            result: 'RES_NOT_FOUND',
            resultMsg: null,
          });
        } else {
          newList.push({
            ...request,
            result: targetRes.resultCd,
            resultMsg: targetRes.resultMsg,
          });
        }
      });

      setList(newList);

      Modal.info({
        title: '매핑결과',
        content: res.data.resultCd,
      });
    } else
      Modal.warn({
        title: '처리실패',
        content: '포스코드 미선택',
      });
  };

  const spdCols = [
    {
      title: '매장코드',
      dataIndex: 'posStoreCode',
      className: 'table-column-center',
    },
    {
      title: '매장명',
      dataIndex: 'frName',
      className: 'table-column-center',
    },
    {
      title: '사업자번호',
      dataIndex: 'businessNumber',
      className: 'table-column-center',
    },
    {
      title: '처리결과',
      dataIndex: 'result',
      className: 'table-column-center',
      render: (data, row) => (
        <div style={{ color: resultFontColorCode[data] }}>
          {resultCode[data]
            ? data === 'MAPPED_WITH_OTHER_CODE'
              ? `${resultCode[data]}(${row.resultMsg})`
              : resultCode[data]
            : data}
        </div>
      ),
    },
  ];

  const konCols = [
    {
      title: '요청일자',
      dataIndex: 'requestDate',
      className: 'table-column-center',
    },
    {
      title: '매장명',
      dataIndex: 'frName',
      className: 'table-column-center',
    },
    {
      title: '사업자번호',
      dataIndex: 'businessNumber',
      className: 'table-column-center',
    },
    {
      title: '주소',
      dataIndex: 'frAddress',
      className: 'table-column-center',
    },
    {
      title: '전화번호',
      dataIndex: 'frPhone',
      className: 'table-column-center',
    },
    {
      title: '직가맹ID',
      dataIndex: 'posStoreCode',
      className: 'table-column-center',
    },
    {
      title: '처리결과',
      dataIndex: 'result',
      className: 'table-column-center',
      render: (data, row) => (
        <div style={{ color: resultFontColorCode[data] }}>
          {resultCode[data]
            ? data === 'MAPPED_WITH_OTHER_CODE'
              ? `${resultCode[data]}(${row.resultMsg})`
              : resultCode[data]
            : data}
        </div>
      ),
    },
  ];

  const resultCode = {
    SUCCESS: '매핑성공',
    FAIL: '매핑실패',
    NO_STORE: '가맹점 조회실패',
    MAPPED_WITH_OTHER_CODE: '다른코드로 이미 매핑됨',
  };

  const resultFontColorCode = {
    SUCCESS: 'blue',
    FAIL: 'red',
    NO_STORE: 'red',
    MAPPED_WITH_OTHER_CODE: 'green',
  };

  return (
    <div>
      <div
        style={{
          marginBottom: 30,
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Radio.Group
          value={posCode}
          onChange={(e) => setPosCode(e.target.value)}>
          <Radio value={'spd'}>스파이더</Radio>
          <Radio value={'kon'}>코나아이</Radio>
        </Radio.Group>

        <Button type="primary" onClick={async () => await handleSubmit()}>
          확인
        </Button>
      </div>

      <Input.TextArea
        style={{ height: '50vh' }}
        onChange={(e) => {
          if (posCode === 'spd') parseSpdTable(e.target.value);
          else parseKonTable(e.target.value);
        }}
      />

      <Table
        dataSource={list}
        columns={posCode === 'spd' ? spdCols : konCols}
        pagination={false}
      />

      {duplicateList.length > 0 && (
        <Table
          dataSource={duplicateList}
          columns={posCode === 'spd' ? spdCols : konCols}
          pagination={false}
        />
      )}
    </div>
  );
};

export default ManualMapping;
