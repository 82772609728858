import React, { useEffect, useRef, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Form, Button, Radio, Select, Input, DatePicker, Modal } from 'antd';
import {
  batchWorkCategoryForAgency,
  btachWorkKindForAgency,
  btachWorkKindForFr,
} from '../../lib/util/codeUtil';
import moment from 'moment/moment';
import AgencySelectBranchDialog from './AgencySelectBranchDialog';
import AgencySearchFrDialog from './AgencySearchFrDialog';
import { httpPost, httpUrl } from '../../api/httpClient';
const { RangePicker } = DatePicker;

const AgencyRegistBatchWorkDialog = (props) => {
  console.log(props)
  const formRef = useRef(null);
  const FormItem = Form.Item;
  const today = moment().format('YYYY-MM-DD 00:00');
  const loginInfo = props.loginInfo;
  const [userType, setUserType] = useState(props.data ? props.data.userType : 99);
  const [targetName, setTargetName] = useState('');
  const [kind, setKind] = useState(props.data ? props.data.kind : '');
  const [startDate, setStartDate] = useState(
    props.data ? moment(props.data.startDate).format('YYYY-MM-DD 23:59') : today
  );
  const [endDate, setEndDate] = useState(
    props.data
      ? moment(props.data.endDate).format('YYYY-MM-DD 23:59')
      : moment(today).add(1, 'M').format('YYYY-MM-DD 23:59')
  );
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [targetIdx, setTargetIdx] = useState(0);

  useEffect(() => {
  }, []);

  const comfirmModal = () => {
    if (!props.data && (targetName === null || targetName === '')) {
      Modal.error({
        content: '대상을 선택해주세요.'
      })
      return;
    }
    Modal.confirm({
      title: props.data ? '일차감 수정' : '일차감 등록',
      content: props.data
        ? '일차감을 등록하시겠습니까?'
        : '일차감을 등록하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        props.data ? handleUpdateSubmit() : handleSubmit();
      },
    });
  };
  const handleSubmit = async () => {
    const res = await httpPost(httpUrl.createAgencyBatchWorkNcash, [], {
      ...formRef.current.getFieldsValue(),
      userIdx: targetIdx,
      branchIdx: loginInfo.branchIdx,
      category: 1, //일,월차감 구분이고, 제휴본사는 사용하지 않지만 필수항목이라 일차감으로 설정
      startDate: moment(startDate).format('YYYY-MM-DD 00:00'),
      endDate: moment(endDate).format('YYYY-MM-DD 23:59'),
    });
    if (res.data === 'SUCCESS') {
      props.close();
      props.callback();
    }
  };

  const handleUpdateSubmit = async () => {
    const res = await httpPost(httpUrl.updateAgencyBatchWorkNcash, [], {
      idx: props.data.idx,
      ...formRef.current.getFieldsValue(),
      startDate: moment(startDate).format('YYYY-MM-DD 00:00'),
      endDate: moment(endDate).format('YYYY-MM-DD 23:59'),
    });
    if (res.data === 'SUCCESS') {
      props.close();
      props.callback();
    }
  };
  const callback = (data) => {
    const dataName = userType === 99 ? data.branchName : data.frName;
    const dataIdx = userType === 99 ? data.idx : data.userIdx;
    setTargetIdx(dataIdx);
    setTargetName(dataName);
  };
  const onChangeDate = (dateString) => {
    setStartDate(
      dateString != null ? moment(dateString[0]).format('YYYY-MM-DD 00:00') : ''
    );
    setEndDate(
      dateString != null ? moment(dateString[1]).format('YYYY-MM-DD 23:59') : ''
    );
  };
  const openSearchModal = () => {
    setSearchModalOpen(!searchModalOpen);
  };

  return (
    <div>
      <div className="Dialog-overlay" onClick={props.close} />
      <div className="agency-batchwork-Dialog">
        <div className="agency-batchwork-content">
          <div className="agency-batchwork-title">
            <h1>{props.data ? '일차감 수정' : '일차감 등록'}</h1>
            <Button
              onClick={props.close}
              icon={<CloseOutlined style={{ fontSize: 24 }} />}
              type="text"
              shape="circle"
            />
          </div>
          <Form ref={formRef} onFinish={comfirmModal}>
            <div className={'agency-batchwork-form'}>
              <p className={'agency-form-title'}>대상</p>
              <FormItem
                className={'agency-formItem'}
                name="userType"
                initialValue={props.data ? props.data.userType : 99}
                rules={[
                  {
                    required: true,
                    message: '대상을 선택해주세요',
                  },
                ]}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <Radio.Group
                    value={userType}
                    disabled={props.data}
                    onChange={(e) => {
                      setUserType(e.target.value);
                      setTargetName(null);
                      setKind(null);
                      formRef.current.setFieldsValue({
                        ...formRef.current.getFieldsValue(),
                        kind: null,
                      });
                    }}>
                    <Radio value={99}>대리점</Radio>;
                    <Radio value={2}>가맹점</Radio>;
                  </Radio.Group>
                  {userType === 99 ? (
                    <Button
                      disabled={props.data}
                      style={{ marginLeft: 'auto' }}
                      onClick={openSearchModal}>
                      대리점 검색
                    </Button>
                  ) : (
                    <Button
                      disabled={props.data}
                      style={{ marginLeft: 'auto' }}
                      onClick={openSearchModal}>
                      가맹점 검색
                    </Button>
                  )}
                </div>
              </FormItem>
              <div className={'agency-formItem'} style={{ marginBottom: 24 }}>
                <p className={'agency-form-title'}>
                  {userType === 99 ? '대리점명' : '가맹점명'}
                </p>
                <Input
                  value={props.data ? props.data.userName : targetName}
                  style={{ flex: 1, height: 42 }}
                  disabled={true}
                  placeholder={'검색을 해주세요'}
                />
              </div>
              <p className={'agency-form-title'}>구분</p>
              <FormItem
                name="kind"
                className={'agency-formItem'}
                initialValue={props.data && props.data.kind}
                rules={[{ required: true, message: '구분을 선택해주세요' }]}>
                <Select
                  style={{ flex: 1 }}
                  placeholder={
                    props.data
                      ? batchWorkCategoryForAgency[props.data.kind]
                      : '구분을 선택해주세요'
                  }
                  onChange={(value) => setKind(value)}>
                  {userType === 99
                    ? btachWorkKindForAgency.map((item) => {
                        return (
                          <Select.Option
                            key={item.label}
                            value={item.value}
                            style={{ height: 42 }}>
                            {item.label}
                          </Select.Option>
                        );
                      })
                    : btachWorkKindForFr.map((item) => {
                        return (
                          <Select.Option key={item.label} value={item.value}>
                            {item.label}
                          </Select.Option>
                        );
                      })}
                </Select>
              </FormItem>
              <p className={'agency-form-title'}>차감명</p>
              <FormItem
                name="title"
                className={'agency-formItem'}
                initialValue={props.data && props.data.title}
                rules={[
                  {
                    required: true,
                    message: '차감명을 입력해주세요.',
                  },
                ]}>
                <Input
                  style={{ flex: 1, height: 42 }}
                  placeholder={'차감명을 입력해주세요'}
                />
              </FormItem>
              <p className={'agency-form-title'}>차감금액</p>
              <FormItem
                name="ncashDelta"
                className={'agency-formItem'}
                initialValue={props.data && props.data.ncashDelta}
                rules={[{ required: true, message: '금액을 입력해주세요.' }]}>
                <Input
                  style={{ flex: 1, height: 42 }}
                  placeholder={'차감 금액을 입력해주세요'}
                />
              </FormItem>
              <div className={'agency-formItem'} style={{ marginBottom: 6 }}>
                <p className={'agency-form-title'}>기간제한</p>
                <RangePicker
                  placeholder={['시작일', '종료일']}
                  value={
                    props.data
                      ? [
                          moment(props.data.startDate, 'YYYY-MM-DD'),
                          moment(props.data.endDate, 'YYYY-MM-DD'),
                        ]
                      : [
                          moment(startDate, 'YYYY-MM-DD'),
                          moment(endDate, 'YYYY-MM-DD'),
                        ]
                  }
                  onChange={onChangeDate}
                  style={{ flex: 1, height: 42 }}
                />
              </div>
              <span style={{ color: '#888' }}>
                {' '}
                *선택한 기간동안 새벽 5시에 차감됩니다
              </span>
            </div>
            <Button
              style={{
                backgroundColor: '#FFE45D',
                width: '100%',
                height: 50,
                fontSize: 16,
                fontWeight: 'bold',
                borderColor: '#888',
              }}
              htmlType="submit">
              {props.data ? '수정' : '등록'}
            </Button>
          </Form>
        </div>
      </div>
      {searchModalOpen &&
        (userType === 2 ? (
          <AgencySearchFrDialog close={openSearchModal} callback={callback} />
        ) : (
          <AgencySelectBranchDialog
            close={openSearchModal}
            callback={callback}
          />
        ))}
    </div>
  );
};
export default AgencyRegistBatchWorkDialog;
