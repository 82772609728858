import { Button, Table, Typography } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { kindString, StaticCategory } from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');

const { Text } = Typography;

class StaticsTableFr extends Component {
  constructor(props) {
    super(props);
    const data = this.props;
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 15,
      },
      startDate: data.startDate,
      endDate: data.endDate,
      changeFilter: data.changeFilter,
      selectBranch: data.selectBranch,
      businessNumber: data.businessNumber,
      frName: data.frName,
      riderName: data.riderName,
      registrationNumber: data.registrationNumber,

      copyList: [],
    };

    this.change_fr = ['DUES_PAY_RESTORE', 'DELIVERY_PRICE_RECEIVE'];
    this.change_deliveryPrice = ['DELIVERY_PRICE_SEND'];
    this.columns = [
      {
        title: '일시',
        dataIndex: 'createDate',
        className: 'tableCellCenter',
        render: (data) => <>{moment(data).format('YYYY-MM-DD HH:mm:ss')}</>,
        width: 160,
      },
      {
        title: '관리지점',
        dataIndex: 'branchName',
        className: 'tableCellCenter',
        width: 100,
      },
      {
        title: '주문번호',
        dataIndex: 'orderIdx',
        className: 'tableCellCenter',
        width: 40,
      },
      {
        title: '분류',
        dataIndex: 'category',
        className: 'tableCellCenter',
        width: 140,
        render: (data) => <>{StaticCategory[data]}</>,
      },
      {
        title: '비고',
        className: 'statics-table-border tableCellCenter',
        dataIndex: 'adminId',
        render: (data) => <>{data ? `변경자 : ${data}` : ''}</>,
        width: 100,
      },
      {
        title: '가맹점',
        className: 'statics-table-orange statics-table-border',
        children: [
          {
            title: '정보',
            children: [
              {
                title: '가맹점명',
                dataIndex: 'frName',
                className: 'tableCellCenter',
                width: 150,
              },
              {
                title: '사업자번호',
                dataIndex: 'businessNumber',
                className: 'tableCellCenter',
                width: 100,
              },
            ],
          },
          {
            title: '가맹비(월)',
            className: 'statics-table-border',
            children: [
              {
                title: '공급가액',
                dataIndex: 'duePrice',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => <div> {comma(data)} </div>,
              },
              {
                title: '부가세',
                dataIndex: 'duePriceVat',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => <div>{comma(data)}</div>,
              },
              {
                title: '합계',
                dataIndex: 'totalDuePrice',
                className: 'statics-table-border tableCellRight',
                width: 100,
                render: (data, row) => <div>{comma(data)}</div>,
              },
            ],
          },
        ],
      },
      {
        title: '본사 예치금',
        className: 'statics-table-orange2',
        width: 100,
        children: [
          {
            title: '입금',
            dataIndex: 'totalDuePrice',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => {
              if (!row.category.includes('_RESTORE')) {
                return <div> {comma(data)} </div>;
              }
            },
          },
          {
            title: '출금',
            dataIndex: 'totalDuePrice',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => {
              if (row.category.includes('_RESTORE')) {
                return <div> {comma(data)} </div>;
              }
            },
          },
        ],
      },
    ];
  }

  componentDidMount() {
    console.log('가맹비');
    this.getList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.endDate !== this.props.endDate ||
      prevProps.changeFilter !== this.props.changeFilter ||
      prevProps.selectBranch !== this.props.selectBranch ||
      prevProps.businessNumber !== this.props.businessNumber ||
      prevProps.frName !== this.props.frName ||
      prevProps.riderName !== this.props.riderName ||
      prevProps.registrationNumber !== this.props.registrationNumber
    ) {
      this.setState(
        {
          list: [],
          copyList: [],
          pagination: {
            ...this.state.pagination,
            current: 1,
          },
        },
        () => this.getList()
      );
    }
  }

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({ pagination: pager }, () => this.getList());
  };

  getList = async () => {
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let branchIdx = this.props.selectBranch;
    let businessNumber = this.props.businessNumber;
    let category = 'fr';
    let frName = this.props.frName;
    let riderName = this.props.riderName;
    let registrationNumber = this.props.registrationNumber;
    let startDate = this.props.startDate;
    let endDate = moment(this.props.endDate).add(1, 'd').format('YYYY-MM-DD');
    const res = await httpGet(
      httpUrl.staticsCostList,
      [
        pageNum,
        pageSize,
        branchIdx,
        businessNumber,
        category,
        frName,
        riderName,
        registrationNumber,
        startDate,
        endDate,
      ],
      {}
    );
    if (res.result === 'SUCCESS') {
      console.log(res);
      this.setState({
        list: res.data.stCost,
        copyList: res.data.stCost,
        pagination: {
          ...this.state.pagination,
          current: res.data.currentPage,
          total: res.data.totalCount,
        },
      });
    }
    if (this.state.changeFilter > 0) {
      this.selectList(this.state.changeFilter);
    }
  };

  selectList = (type) => {
    const selectType =
      type === 1 ? this.state.change_deliveryPrice : this.state.change_fr;
    let changeList = this.state.setList.filter((x) =>
      selectType.includes(x.category)
    );
    this.setState({ copyList: changeList });
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ dispaly: 'flex', alignItems: 'center' }}>
          {/* <Button
                        className="download-btn"
                        style={{ width: 160}}
                        onClick={() => this.onDownload()}>
                        <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
                        엑셀 다운로드
                    </Button>    */}
        </div>
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.copyList}
          columns={this.columns}
          className="staticsCost-table"
          pagination={false}
          // onChange={this.handleTableChange}
          bordered
          summary={(pageData) => {
            let totalInputPrice = 0;
            let totalInputVat = 0;
            let totalFrFee = 0;
            let totalc9NcashDelta = 0;
            let totalc9AfterNcash = 0;
            pageData.forEach(
              ({ category, duePrice, duePriceVat, totalDuePrice }) => {
                totalInputPrice += duePrice * -1;
                totalInputVat += duePriceVat * -1;
                totalFrFee += totalDuePrice * -1;
                totalc9NcashDelta +=
                  !category.includes('_RESTORE') && totalDuePrice * -1;
                totalc9AfterNcash +=
                  category.includes('_RESTORE') && totalDuePrice * -1;
              }
            );
            return (
              <>
                <Table.Summary.Row
                  style={{
                    backgroundColor: '#cbedfa',
                    color: '#000',
                    fontWeight: 'bold',
                    borderColor: '#000',
                  }}>
                  <Table.Summary.Cell
                    index={1}
                    colSpan={7}
                    style={{ textAlign: 'center' }}>
                    합계
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger">
                      {totalInputPrice < 0
                        ? comma(totalInputPrice * -1)
                        : comma(totalInputPrice)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger">
                      {totalInputVat < 0
                        ? comma(totalInputVat * -1)
                        : comma(totalInputVat)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger">
                      {totalFrFee < 0
                        ? comma(totalFrFee * -1)
                        : comma(totalFrFee)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">
                      {totalc9NcashDelta < 0
                        ? comma(totalc9NcashDelta * -1)
                        : comma(totalc9NcashDelta)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">
                      {totalc9AfterNcash < 0
                        ? comma(totalc9AfterNcash * -1)
                        : comma(totalc9AfterNcash)}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
        <div className="page-btn">
          <Button
            style={{ margin: '10px' }}
            onClick={() => {
              if (this.state.pagination.current <= 1) return;

              const pagination = { ...this.state.pagination };
              pagination.current = pagination.current - 1;
              this.setState({ pagination, copyList: [] }, this.getList);
            }}>
            이전
          </Button>
          <Button
            style={{ margin: '10px' }}
            onClick={() => {
              const pagination = { ...this.state.pagination };
              if (this.state.list.length < pagination.pageSize) {
                alert('마지막 페이지입니다.');
                return;
              }
              pagination.current = pagination.current + 1;
              this.setState({ pagination, copyList: [] }, this.getList);
            }}>
            다음
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginInfo: state.login.loginInfo,
  };
};
export default connect(mapStateToProps, null)(StaticsTableFr);
