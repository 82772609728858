import { Button, Image, Input, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { httpGet, httpUrl, imageUrl } from '../../api/httpClient';
import RegistSkinDialog from '../../components/dialog/skinPopup/RegistSkinDialog';
import SelectBox from '../../components/input/SelectBox';
import {
  skinPopupCategoryCode,
  skinPopupStatusCode,
} from '../../lib/util/codeUtil';

const today = new Date();
const Search = Input.Search;

class SkinPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      list: [],
      startDate: moment('1900-01-01')
        .clone()
        .startOf('day')
        .format('YYYY-MM-DD'),
      endDate: moment(today)
        .clone()
        .add(1, 'days')
        .startOf('day')
        .format('YYYY-MM-DD'),
      title: '',
      category: 0,
      openSkinPopup: false,
      modifySkinPopup: false,
      branchList: { 0: '전체지점' },
      branchNameValue: 0,
    };
  }

  componentDidMount() {
    this.getList();
    this.getBranchList();
  }

  getList = () => {
    const { pagination, startDate, endDate, category, title, branchNameValue } =
      this.state;
    const branchIdx = branchNameValue;

    httpGet(
      httpUrl.getSkinPopupList,
      [
        pagination.current,
        pagination.pageSize,
        startDate,
        endDate,
        category,
        title,
        branchIdx,
      ],
      {}
    ).then((res) => {
      console.log('res');
      console.log(res);
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.list,
          pagination: {
            ...pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        });
      }
    });
  };

  getBranchList = async () => {
    const object = this.state.branchList;
    const res = await httpGet(httpUrl.getBranchList, [1, 1000], {});
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data.branch);
        res.data.branch.map((item) => {
          object[item.idx] = item.branchName;
        });

        this.setState(
          {
            branchList: object,
          },
          () => {
            console.log('branchList');
            console.log(this.state.branchList);
          }
        );
      }
  };

  onChangeStatus = (value) => {
    console.log(typeof value);
    console.log(value);
    this.setState(
      {
        category: value === '0' ? 0 : value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  onChangeBranch = (value) => {
    this.setState(
      {
        branchNameValue: value === '0' ? 0 : value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onSearch = (value) => {
    this.setState(
      {
        title: value,
      },
      () => this.getList()
    );
  };

  openRegistSkinDialog = () => {
    this.setState({
      openSkinPopup: true,
    });
  };

  closeRegistSkinDialog = () => {
    this.setState({
      openSkinPopup: false,
    });
  };

  openModifySkinDialog = (row) => {
    this.setState(
      {
        modifySkinPopup: true,
        data: row,
      },
      () => console.log(row)
    );
  };

  closeModifySkinDialog = () => {
    this.setState({
      modifySkinPopup: false,
    });
  };

  render() {
    const columns = [
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '생성일',
        dataIndex: 'createDate',
        className: 'table-column-center',
        render: (data) => <div>{moment(data).format('YYYY-MM-DD')}</div>,
      },
      {
        title: '분류',
        dataIndex: 'category',
        className: 'table-column-center',
        render: (data) => <div>{skinPopupCategoryCode[data]}</div>,
      },
      {
        title: '제목',
        dataIndex: 'title',
        className: 'table-column-center',
      },
      {
        title: '상태',
        dataIndex: 'deleted',
        className: 'table-column-center',
        render: (data) => (
          <div>{skinPopupStatusCode[data] === '삭제' ? '삭제' : '노출'}</div>
        ),
      },
      {
        title: '이미지',
        dataIndex: 'uploadFileIdx',
        className: 'table-column-center',
        render: (data) => (
          <Image
            style={{ display: 'inline-block' }}
            width={120}
            src={imageUrl(data)}
          />
        ),
      },
      {
        title: '수정',
        className: 'table-column-center',
        render: (row) => (
          <div>
            <Button onClick={() => this.openModifySkinDialog(row)}>수정</Button>
          </div>
        ),
      },
    ];

    const copy = Object.assign({ 0: '전체' }, { ...skinPopupCategoryCode });
    const { branchList } = this.state;

    return (
      <>
        <div>
          <SelectBox
            style={{ marginBottom: 20, width: '160px' }}
            value={
              this.state.category === 0 ? copy[0] : copy[this.state.category]
            }
            code={Object.keys(copy)}
            codeString={copy}
            onChange={(value) => {
              if (parseInt(value) !== this.state.category) {
                this.onChangeStatus(value);
              }
            }}
          />
          <SelectBox
            style={{ marginBottom: 20, marginLeft: '10px', width: '120px' }}
            value={
              this.state.branchNameValue === 0
                ? branchList[0]
                : branchList[this.state.branchNameValue]
            }
            code={Object.keys(branchList)}
            codeString={branchList}
            onChange={(value) => {
              if (parseInt(value) !== this.state.branchNameValue) {
                this.onChangeBranch(value);
              }
            }}
          />
          <Search
            placeholder="제목 검색"
            enterButton
            allowClear
            onSearch={this.onSearch}
            style={{
              width: '220px',
              marginLeft: 10,
            }}
          />
          <Button
            style={{ float: 'right' }}
            onClick={() => this.openRegistSkinDialog()}>
            스킨/팝업등록
          </Button>
        </div>
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
        {this.state.openSkinPopup && (
          <RegistSkinDialog
            getList={this.getList}
            close={this.closeRegistSkinDialog}
          />
        )}
        {this.state.modifySkinPopup && (
          <RegistSkinDialog
            data={this.state.data}
            getList={this.getList}
            close={this.closeModifySkinDialog}
          />
        )}
      </>
    );
  }
}

export default SkinPopup;
