import SelectBox from '../../components/input/SelectBox';
import { Button, Modal, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { httpGet, httpGetWithObjectParam, httpUrl } from '../../api/httpClient';
import CopyBranchDongRateDialog from '../../components/dialog/branch/CopyBranchDongRateDialog';

const BranchDongRateInfo = () => {
  const [openCopyDongRateModal, setOpenCopyDongRateModal] = useState(false);
  const [selectDongRate, setSelectDongRate] = useState(null);
  const [branchList, setBranchList] = useState([]);
  const [branchIdx, setBranchIdx] = useState('');
  const [dongRateList, setDongRateList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    getAllBranchList().then();
  }, []);

  useEffect(() => {
    getDongRateList();
  }, [branchIdx, pagination.current, pagination.pageSize]);

  const getAllBranchList = async () => {
    try {
      const res = await httpGet(httpUrl.getBranchListAll, []);
      if (res == null) {
        Modal.warn({
          title: '지점 목록 조회 실패',
          content: '지점 목록 조회에 실패했습니다.',
        });
      } else {
        res.data.unshift({
          idx: '',
          branchName: '전체',
        });
        setBranchList(res.data);
      }
    } catch (e) {
      console.error('Error while getAllBranchList', e);
    }
  };

  const getDongRateList = () => {
    httpGet(
      httpUrl.getDongRateList,
      [pagination.current, pagination.pageSize, branchIdx],
      {}
    ).then((result) => {
      setDongRateList(result.data.dongRates);
      setPagination({
        ...pagination,
        total: result?.data?.totalCount,
      });
    });
  };

  const copyDongRate = (row) => {
    setSelectDongRate(row);
    handleCopyDialogOpen();
  };

  const handleCopyDialogOpen = () => {
    setOpenCopyDongRateModal(true);
  };

  const handleCopyDialogClose = () => {
    setSelectDongRate(null);
    setOpenCopyDongRateModal(false);
    getDongRateList();
  };

  const columns = [
    {
      title: '지점 번호',
      dataIndex: 'branchIdx',
      className: 'table-column-center',
    },
    {
      title: '지점 이름',
      dataIndex: 'branchName',
      className: 'table-column-center',
    },
    {
      title: '요금제 번호',
      dataIndex: 'idx',
      className: 'table-column-center',
    },
    {
      title: '요금제 이름',
      dataIndex: 'name',
      className: 'table-column-center',
    },
    {
      title: '요금제 복사',
      // dataIndex: 'name',
      className: 'table-column-center',
      render: (row) => (
        <Button onClick={() => copyDongRate(row)}>요금제 복사</Button>
      ),
    },
  ];

  return (
    <div>
      {openCopyDongRateModal && (
        <CopyBranchDongRateDialog
          close={handleCopyDialogClose}
          branchList={branchList}
          dongRateList={dongRateList}
          selectDongRate={selectDongRate}></CopyBranchDongRateDialog>
      )}
      <div className="flex justify-between">
        <Select
          style={{
            width: 200,
          }}
          value={branchIdx}
          onChange={(value) => setBranchIdx(value)}>
          {branchList.map((branch) => {
            return (
              <Select.Option key={branch.idx} value={branch.idx}>
                {branch.branchName}
              </Select.Option>
            );
          })}
        </Select>
        <Button onClick={handleCopyDialogOpen}>전체복사</Button>
      </div>
      <div>
        <Table
          rowKey={(row) => row.idx}
          dataSource={dongRateList}
          pagination={pagination}
          columns={columns}
          onChange={(value) => setPagination(value)}></Table>
      </div>
    </div>
  );
};

export default BranchDongRateInfo;
