import { DatePicker, Input, Table } from 'antd';
import React, { Component } from 'react';
import '../../css/main.css';
import {httpGet, httpUrl} from "../../api/httpClient";
import {customError} from "../../api/Modals";
import moment from "moment";

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

class RiderLocationAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      list: [],
      adminId: '',
      startDate: new moment().format('YYYY-MM-DD'),
      endDate: new moment().format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
      const { pagination, startDate } = this.state;
      const adminId = this.state.adminId;
      const pageSize = pagination.pageSize;
      const pageNum = pagination.current;
      const endDate = moment(this.state.endDate).add(1, 'd').format("YYYY-MM-DD");
      httpGet(httpUrl.getSearchRiderLocationInfo, [adminId, pageSize, pageNum, startDate, endDate], {})
          .then((res) => {
              const pagination = { ...this.state.pagination };
              pagination.current = res.data.currentPage;
              pagination.total = res.data.totalCount;
              this.setState(
                  {
                      list: res.data.list,
                      pagination,
                  },
              );
          })
          .catch((e) => {
              customError('목록 에러', '에러가 발생하여 목록을 불러올수 없습니다.');
          });

  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onSearch = (value) => {
    this.setState({
            adminId: value,
            pagination: {
                current: 1,
                pageSize: 10,
            },
        },
        () => {
            this.getList();
        }
    );
  };

  onChangeDate = (dateString) => {
    this.setState({
            startDate:
                dateString != null
                    ? moment(dateString[0]).format('YYYY-MM-DD')
                    : new moment('2021-07-01').format('YYYY-MM-DD'),
            endDate:
                dateString != null
                    ? moment(dateString[1]).format('YYYY-MM-DD')
                    : new moment().format('YYYY-MM-DD'),
            pagination: {
                current: 1,
                pageSize: 10,
            },
        },
        () => {
            this.getList();
        }
    );
  };

  render() {
    const columns = [
      {
        title: '접속 일자',
        dataIndex: 'accessDate',
        className: 'table-column-center',
        render: (data) => {
          const formatDate = moment(data).format("YYYY-MM-DD HH:mm:ss");
          const date = formatDate.split(' ')[0];
          return <div>{date}</div>;
        },
      },
      {
        title: '접속시간',
        dataIndex: 'accessDate',
        className: 'table-column-center',
        render: (data) => {
          const formatDate = moment(data).format("YYYY-MM-DD HH:mm:ss");
          const date = formatDate.split(' ')[1];
          return <div>{date}</div>;
        },
      },
      {
        title: '접속 종료 일자',
        dataIndex: 'accessOutDate',
        className: 'table-column-center',
        render: (data) => {
            //accessOutDate는 null 가능한 값이기 때문에 처리해줘야함.
            let formatDate;
            let date = "-";
            if (data) {
                formatDate = moment(data).format("YYYY-MM-DD HH:mm:ss");
                date = formatDate.split(' ')[0];
            }
          return <div>{date}</div>;
        },
      },
      {
        title: '접속 종료 시간',
        dataIndex: 'accessOutDate',
        className: 'table-column-center',
        render: (data) => {
            //accessOutDate는 null 가능한 값이기 때문에 처리해줘야함.
            let formatDate;
            let date = "-";
            if (data) {
                formatDate = moment(data).format("YYYY-MM-DD HH:mm:ss");
                date = formatDate.split(' ')[1];
            }
          return <div>{date}</div>;
        },
      },
      {
        title: '관리자 ID',
        dataIndex: 'adminId',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '핸드폰번호',
        dataIndex: 'phone',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '조회 화면',
        className: 'table-column-center',
        render: () => <div>{'지도관제'}</div>,
      },
    ];

    const dateFormat = 'YYYY-MM-DD';
    return (
      <>
        <Search
          placeholder="관리자 ID, 성명"
          enterButton
          allowClear
          onSearch={this.onSearch}
          style={{
            width: 220,
            marginBottom: 20,
          }}
        />

        <RangePicker
            placeholder={['시작일', '종료일']}
            value={[
                moment(this.state.startDate, dateFormat),
                moment(this.state.endDate, dateFormat),
            ]}
            onChange={this.onChangeDate}
            style={{ width: 350, float: 'left' }}
        />

        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

export default RiderLocationAccess;
