import { DatePicker, Table } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import moment from 'moment';
import { comma } from '../../lib/util/numberUtil';

const RangePicker = DatePicker.RangePicker;
const dateFormat = 'YYYY/MM';

class StaticsNcash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      startDate: moment().add(-2, 'M').format('YYYY-MM-01'),
      endDate: moment().format('YYYY-MM-DD'),
      list: [],
      frTotal: null,
      riderTotal: null,
      connectTotal: null,
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    const pageNum = this.state.pagination.current;
    const pageSize = this.state.pagination.pageSize;
    const startDate = this.state.startDate;
    const endDate = moment(this.state.endDate).format('YYYY-MM-DD 23:59:59');
    httpGet(
      httpUrl.staticsNcash,
      [pageNum, pageSize, startDate, endDate],
      {}
    ).then((res) => {
      if (res.result === 'SUCCESS') {
        // console.log("1111111111111111111111 " + JSON.stringify(res.data))
        // console.log("DDDDDDDDDDDDDDDDDDDDDD " + JSON.stringify(res.data.stCost))
        this.setState({
          list: res.data.ncashStatics,
          pagination: {
            total: res.data.totalCount,
            current: res.data.currentPage,
            pageSize: res.data.totalCount,
          },
          frTotal: res.data.frNcash,
          riderTotal: res.data.riderNcash,
          connectTotal: res.data.connectNcash,
        });
      }
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      }
      // () => this.getList()
    );
  };

  onChangeDate = (_, dateString) => {
    console.log('eeeeeeeeee ' + dateString);
    let endDatePlus = moment(dateString[1]).add(1, 'M').format('YYYY-MM-01');
    this.setState(
      {
        startDate:
          dateString != null
            ? moment(dateString[0]).format('YYYY-MM-01')
            : new moment().add(-1, 'M').format('YYYY-MM-DD 00:00'),
        endDate:
          dateString != null
            ? moment(endDatePlus).add(-1, 'd').format('YYYY-MM-DD')
            : new moment().format('YYYY-MM-DD'),
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => this.getList()
    );
  };

  render() {
    const columns = [
      {
        dataIndex: 'createMonth',
        className: 'table-column-center',
        width: 90,
      },
      {
        title: '가맹비',
        dataIndex: 'duesPay',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: '고용보험',
        dataIndex: 'employInsurance',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: '건별수수료 + 정직원배달',
        dataIndex: 'deliveryPriceFullTime',
        className: 'table-column-center',
        width: 110,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: (
          <div>
            리스비
            <br />
            (냠냠바이크)
          </div>
        ),
        dataIndex: 'leaseBike',
        className: 'table-column-center',
        width: 110,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: '대출상환',
        dataIndex: 'loanRepay',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: '산재보험비',
        dataIndex: 'compensationInsurance',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: (
          <div>
            수리비
            <br />
            (냠냠바이크)
          </div>
        ),
        dataIndex: 'repairBike',
        className: 'table-column-center',
        width: 110,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: '경기바이크',
        dataIndex: 'kyungkiBike',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: (
          <div>
            대출상환
            <br />
            (타대행)
          </div>
        ),
        dataIndex: 'loanRepayElse',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: (
          <div>
            대출상환
            <br />
            (아우라)
          </div>
        ),
        dataIndex: 'loanRepayAura',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: (
          <div>
            리스비
            <br />
            (냠냠바이크경기)
          </div>
        ),
        dataIndex: 'leaseKyungkiBike',
        className: 'table-column-center',
        width: 135,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: (
          <div>
            수리비
            <br />
            (냠냠바이크경기)
          </div>
        ),
        dataIndex: 'repairKyungkiBike',
        className: 'table-column-center',
        width: 135,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: (
          <div>
            리스비
            <br />
            (타대행)
          </div>
        ),
        dataIndex: 'leaseElse',
        className: 'table-column-center',
        width: 90,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: (
          <div>
            수리비
            <br />
            (타대행)
          </div>
        ),
        dataIndex: 'repairElse',
        className: 'table-column-center',
        width: 90,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: '단체상해보험',
        dataIndex: 'groupAccidentInsurance',
        className: 'table-column-center',
        width: 115,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: '포스구매',
        dataIndex: 'posPurchase',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: '기사수수료',
        dataIndex: 'riderFee',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: '선충전',
        dataIndex: 'preCharge',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: '음식배상',
        dataIndex: 'foodCompensation',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: '코인이관',
        dataIndex: 'coinTransfer',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: '프로모션',
        dataIndex: 'promotion',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
      {
        title: '냠냠몰구매',
        dataIndex: 'mallPurchase',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data ? comma(data) : 0}</div>,
      },
    ];
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 20,
          }}>
          <div>
            <div className="deposit-box">
              <div className="box-title dbt">상점잔액 :</div>
              <div className="box-content dbc">
                {comma(this.state.frTotal)}
                <div className="box-sub"> 원</div>
              </div>
            </div>
            <div className="deposit-box">
              <div className="box-title dbt">라이더잔액 :</div>
              <div className="box-content dbc">
                {comma(this.state.riderTotal)}
                <div className="box-sub"> 원</div>
              </div>
            </div>
            <div className="deposit-box">
              <div className="box-title dbt">본사잔액 :</div>
              <div className="box-content dbc">
                {comma(this.state.connectTotal)}
                <div className="box-sub"> 원</div>
              </div>
            </div>
          </div>
          <div>
            <RangePicker
              picker="month"
              value={[
                moment(this.state.startDate, 'YYYY-MM'),
                moment(this.state.endDate, 'YYYY-MM'),
              ]}
              format={'YYYY-MM'}
              onChange={this.onChangeDate}
              placeholder={['시작일', '종료일']}
              // style={{ width: 300, marginBottom: 20, float: "right" }}
              defaultValue={[
                moment(this.state.startDate, dateFormat),
                moment(this.state.endDate, dateFormat),
              ]}
            />
          </div>
        </div>
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={columns}
          pagination={false}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

export default StaticsNcash;
