import { Button, Form, Input, Modal, Table } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import xlsx from 'xlsx';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import RiderAccountDialog from '../../components/dialog/RiderAccountDialog';
import RiderAccountModifyRecord from '../../components/dialog/RiderAccountModifyRecord';
import RiderNotUploadFileDialog from '../../components/dialog/RiderNotUploadFileDialog';
import RiderPointModifyDialog from '../../components/dialog/RiderPointModifyDialog';
import RiderUnUseListDialog from '../../components/dialog/RiderUnUseListDialog';
import RiderUploadFileListDialog from '../../components/dialog/RiderUploadFileListDialog';
import Const from '../../const';
import '../../css/main.css';
import ExcelIcon from '../../img/excel.png';
import {
  mapPaystoryVacntResultCd,
  RiderApprovedCode,
  riderGroup,
  riderLevel,
  statusCode,
  statusString,
} from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';
import moment from 'moment';
import SelectBox from '../../components/input/SelectBox';
import {isNumber} from "lodash";
import {showSimpleModal} from "../../lib/util/etcUtil";
import SearchRiderDialog from '../../components/dialog/SearchRiderDialog';

const FormItem = Form.Item;
const Search = Input.Search;

class RiderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      riderName: '',
      riderPhone: '',
      // riderStatus: 0,
      isRegistRiderOpen: false,
      riderUploadFileListOpen: false,
      data: null,
      riderNotUploadListOpen: false,
      riderAccountModalOpen: false,
      riderAccountModifyOpen: false,
      riderInfo: {},
      modifyRiderPoint: false,
      branchName: '',
      vaccountNumber: '',
      userStatus: 0,
      remainingPaystoryVacnt: 0,
      selectedRider: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
    this.getRemainingPaystoryVacnt();
  }

  // 라이더 검색
  onSearchRider = (value) => {
    this.setState(
      {
        riderName: value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  onSearchBranch = (value) => {
    this.setState(
      {
        branchName: value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  onSearchVaccountNumber = (value) => {
    this.setState(
      {
        vaccountNumber: value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  parseExcelJson = (data) => {
    let result = [
      {
        riderIdx: '기사idx',
        userStatus: '상태',
        branchName: '지점명',
        riderName: '기사명',
        exactRiderName: '본명',
        registrationNumber: '주민번호',
        riderPhone: '기사연락처',
        userId: '아이디',
        riderLevel: '직급',
        driverLicenseNumber: '면허정보',
        ncash: '코인잔액',
        misu: '미수금',
        riderSettingGroupIdx: '기사그룹',
        bank: '계좌은행',
        bankAccount: '계좌번호',
        ownerName: '예금주',
        riderPointLevel: '레벨',
        discountDeliveryPriceFee: '수수료',
        vaccountBank: '가상계좌은행',
        vaccountNumber: '가상계좌번호',
        vaccountDepositor: '가상계좌예금주',
        isAllPaperUploaded: '서류제출여부',
        joinDate: '가입일',
        fullTimeEmployee: '근로자여부',
      },
    ];
    data.forEach((item) => {
      result.push({
        riderIdx: item.userIdx,
        userStatus: statusString[item.userStatus],
        branchName: item.branchName,
        riderName: item.riderName,
        exactRiderName: item.exactRiderName,
        registrationNumber: item.registrationNumber,
        riderPhone: item.riderPhone,
        userId: item.userId,
        riderLevel: riderLevel[item.riderLevel],
        driverLicenseNumber: item.driverLicenseNumber,
        ncash: item.ncash,
        misu: this.getTotalMisuFromUser(item),
        riderSettingGroupIdx: riderGroup[item.riderSettingGroupIdx],
        bank: item.bank && item.bank.split(',')[0],
        bankAccount: item.bankAccount,
        ownerName: item.depositor,
        riderPointLevel: item.riderPointLevel,
        discountDeliveryPriceFee:
          item.deliveryPriceFeeType == 0
            ? item.discountDeliveryPriceFee + '%(정률)'
            : comma(item.discountDeliveryPriceFee) + '원(정량)',
        vaccountBank: item.vaccountBank,
        vaccountNumber: item.vaccountNumber,
        vaccountDepositor: item.vaccountDepositor,
        isAllPaperUploaded: item.isAllPaperUploaded === 1 ? '완료' : '미완',
        joinDate: new moment(item.joinDate).format('YYYY-MM-DD'),
        fullTimeEmployee: item.fullTimeEmployee === true ? '근로자' : '계약직',
      });
    });

    return result;
  };

  onDownload = () => {
    httpGet(
      httpUrl.riderList,
      [
        this.props.branchIdx,
        this.state.riderName,
        this.state.riderPhone,
        1,
        100000000,
        this.state.branchName,
        this.state.vaccountNumber,
        this.state.userStatus
      ],
      {}
    ).then((res) => {
      const excelJson = this.parseExcelJson(res.data.riders);
      const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
      const wb = xlsx.utils.book_new();
      ws['!cols'] = [];
      ws['!cols'][2] = { width: 15 };
      ws['!cols'][3] = { width: 20 };
      xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
      xlsx.writeFile(wb, '라이더목록.xlsx');
    });
  };

  getList = () => {
    const pagination = this.state.pagination;
    httpGet(
      httpUrl.riderList,
      [
        this.props.branchIdx,
        this.state.riderName,
        this.state.riderPhone,
        pagination.current,
        pagination.pageSize,
        this.state.branchName,
        this.state.vaccountNumber,
        this.state.userStatus
      ],
      {}
    ).then((res) => {
      // console.log(res);
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.riders,
          pagination: {
            ...this.state.pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        });
      }
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  // 라이더 계좌 수정 모달
  closeRiderAccountDialog = () => {
    this.setState({ riderAccountModalOpen: false }, () => this.getList());
  };
  // 라이더 계좌 수정 이력
  closeRiderAccountModifyRecord = () => {
    this.setState({ riderAccountModifyOpen: false }, () => this.getList());
  };

  // 콜 미수행 목록 모달
  openUnuseListDialog = () => {
    this.setState({ unUseListOpen: true });
  };
  closeUnuseListDialog = () => {
    this.setState({ unUseListOpen: false });
  };

  // 라이더 서류제출 완료 모달
  closeRiderUplodeFileDialog = () => {
    this.setState({ riderUploadFileListOpen: false });
  };

  // 라이더 서류미제출 모달
  closeRiderNotUplodeFileDialog = () => {
    this.setState({ riderNotUploadListOpen: false });
  };

  openSearchRiderModal = (row) => {
    this.setState({ openSearchRiderModal: true, selectedRider: row });
  };
  closeSerchRiderModal = () => {
    this.setState({ openSearchRiderModal: false });
  };

  //삭제 알림창

  delete = () => {
    alert('공지사항을 삭제합니다.');
  };

  clearTaxSupport = async (userIdx) => {
    const res = await httpGet(httpUrl.clearTaxSupport, [userIdx], {});
    console.log(res);
    if (res.data) {
      Modal.info({
        title: '처리완료',
        content: '지급처리 되었습니다.',
      });
      this.getList();
    } else
      Modal.warn({
        title: '처리실패',
        content: '지급처리에 실패했습니다.',
      });
  };

  getRiderCurrentPoint = (row) => {
    try {
      console.log(row);
      const riderIdx = row.userIdx;
      httpGet(httpUrl.getRiderCurrentPoint, [riderIdx], {})
        .then((res) => {
          console.log('getRiderCurrentPoint');
          console.log(res);
          if (
            res.result === 'SUCCESS' &&
            res.data !== null &&
            res.data !== undefined
          ) {
            this.setState({
              riderInfo: res.data,
              modifyRiderPoint: true,
            });
          } else if (res.result === 'NOT_FOUND_POINT_INFO') {
            Modal.warn({
              title: '정보조회 에러',
              content: '포인트 정보를 조회할 수 없습니다.',
            });
          } else {
            Modal.warn({
              title: '지급차감 에러',
              content:
                '해당 라이더에게 지급차감을 할 수 없습니다. 관리자에게 문의해주세요.',
            });
            return;
          }
        })
        .catch((e) => {
          console.warn(e);
          Modal.warn({
            title: '지급차감 에러',
            content:
              '해당 라이더에게 지급차감을 할 수 없습니다. 관리자에게 문의해주세요.',
          });
          return;
        });
    } catch (error) {
      console.error('getRiderCurrentPoint ERROR: {}', error);
    }
  };

  handleApproveRider = async (rider) => {
    Modal.confirm({
      title: '승인처리',
      content: `${
        rider.riderName ? rider.riderName : '-'
      } 라이더를 승인처리 하시겠습니까?`,
      onOk: async () => await this.approveRider(rider.userIdx),
      onCancel: () => {},
    });
  };

  approveRider = async (riderIdx) => {
    const res = await httpPost(httpUrl.approveRider, [], { userIdx: riderIdx });
    if (res.data) {
      Modal.info({
        title: '승인처리 완료',
        content: '라이더 승인처리에 성공했습니다.',
      });
      this.getList();
    } else
      Modal.warn({
        title: '승인처리 실패',
        content: '라이더 승인처리에 실패했습니다.',
      });
  };

  getTotalMisuFromUser = (user) => {
    return (
      (user.misu ? user.misu : 0) +
      (user.misu2 ? user.misu2 : 0) +
      (user.misu3 ? user.misu3 : 0)
    );
  };

  mapPaystoryVacnt = (rider) => {
    if (0 >= this.state.remainingPaystoryVacnt) {
      showSimpleModal('가상계좌 발급 실패', mapPaystoryVacntResultCd.INSUFFICIENT_VACNT);
      return;
    }

    Modal.confirm({
      title: '가상계좌발급',
      content: `${rider.riderName} 라이더에게 가상계좌를 발급하시겠습니까?`,
      onOk: async () => {
        try {
          const res = await httpPost(httpUrl.mapPaystoryVacnt, [rider.userIdx], {});
          if (res.result !== 'SUCCESS') {
            showSimpleModal('가상계좌 발급 실패', mapPaystoryVacntResultCd[res.result]);
          } else {
            const result = res.data?.result;
            showSimpleModal(`가상계좌 ${((result === 'SUCCESS') ? '성공' : '실패')}`, mapPaystoryVacntResultCd[result]);
          }
        } catch (e) {
          showSimpleModal('ERROR : 가상계좌 발급 실패', e.message);
        } finally {
          this.getList();
          await this.getRemainingPaystoryVacnt();
        }
      },
      onCancel: async () => {
        await this.getRemainingPaystoryVacnt();
      },
    });
  };

  getRemainingPaystoryVacnt = async () => {
    const res = await httpGet(httpUrl.remainingPaystoryVacnt, [], {});
    const count = res.data?.count;
    if (res.result === 'SUCCESS' && res.data?.result === 'SUCCESS' && isNumber(count)) {
      this.setState({remainingPaystoryVacnt: count}, () => { /*alert( `remainingPaystoryVacnt : ${this.state.remainingPaystoryVacnt}`)*/ } );
    }
  };

  moveVaccount = (rider) => {
    Modal.confirm({
      title: '가상계좌이관',
      content: `${this.state.selectedRider.riderName}의 가상계좌를 ${rider.riderName}로 이관하시겠습니까?`,
      onOk: () => {
        httpPost(httpUrl.moveVaccount, [], {
          userIdx: this.state.selectedRider.userIdx,
          newUserIdx: rider.userIdx
        })
        .then(res=>{
          if(res.data === "SUCCESS") {
            Modal.info({
              title: "이관 성공",
              content: "이관에 성공하였습니다."
            })
            this.getList()
          } else if(res.data === "ALREADY_MATCHED") {
            Modal.error({
              title: "이관 실패",
              content: "이미 가상계좌가 있는 사용자입니다."
            })
          } else if(res.data === "NOT_USER") {
            Modal.error({
              title: "이관 실패",
              content: "라이더가 아닌 사용자 입니다."
            })
          } else {
            Modal.error({
              title: "이관 실패",
              content: "이관에 실패하였습니다."
            })
          }
        })
        .catch(e=>{
          Modal.error({
            title: "이관 실패",
            content: "서버 에러로 인해 이관에 실패하였습니다."
          })
        })
      }
    })
  }

  render() {
    const columns = [
      {
        title: '상태',
        dataIndex: 'userStatus',
        className: 'table-column-center',
        render: (data) => <div>{statusString[data]}</div>,
      },
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '기사명',
        dataIndex: 'riderName',
        className: 'table-column-center',
      },
      {
        title: '아이디',
        dataIndex: 'userId',
        className: 'table-column-center',
      },
      {
        title: '직급',
        dataIndex: 'riderLevel',
        className: 'table-column-center',
        render: (data) => <div>{riderLevel[data]}</div>,
      },
      {
        title: '면허정보',
        dataIndex: 'driverLicenseNumber',
        className: 'table-column-center',
      },
      {
        title: '코인잔액',
        dataIndex: 'ncash',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '미수금',
        dataIndex: 'misu',
        className: 'table-column-center',
        render: (data, row) => (
          <div>{comma(this.getTotalMisuFromUser(row))}</div>
        ),
      },
      {
        title: '기사그룹',
        dataIndex: 'riderSettingGroupIdx',
        className: 'table-column-center',
        render: (data) => <div>{riderGroup[data]}</div>,
      },
      {
        title: '은행명',
        dataIndex: 'bank',
        className: 'table-column-center',
        render: (data) => <div>{data && data.split(',')[0]}</div>,
      },
      {
        title: '계좌번호',
        dataIndex: 'bankAccount',
        className: 'table-column-center',
      },
      {
        title: '예금주',
        dataIndex: 'depositor',
        className: 'table-column-center',
      },
      {
        title: '계좌관리',
        className: 'table-column-center',
        render: (data, row) => (
          <>
            <Button
              onClick={() => {
                this.setState({
                  riderAccountModalOpen: true,
                  data: row,
                });
              }}>
              계좌수정
            </Button>
          </>
        ),
      },
      {
        title: `가상계좌 (남은 수:${this.state.remainingPaystoryVacnt})`,
        className: 'table-column-center',
        render: (data, row) => (
          <>
            <div>
              {
                (row.vaccountBank && row.vaccountNumber) ?
                    ( `${row.vaccountBank} / ${row.vaccountNumber}`) : (<Button onClick={() => this.mapPaystoryVacnt(row)}> 발급하기 </Button>)
              }
            </div>
          </>
        ),
      },
      {
        title: '가상계좌이관',
        className: 'table-column-center',
        render: (row) => {
          if (row.vaccountNumber)
            return <Button
              onClick={() => {
                this.openSearchRiderModal(row);
              }}>
              가상계좌이관
            </Button>
        },
      },
      {
        title: '수정이력',
        className: 'table-column-center',
        render: (data, row) => (
          <>
            <Button
              onClick={() => {
                this.setState({
                  riderAccountModifyOpen: true,
                  data: row,
                });
              }}>
              보기
            </Button>
          </>
        ),
      },
      {
        title: '세금지원금',
        dataIndex: 'taxSupport',
        className: 'table-column-center',
        render: (data, row) => (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {comma(data)}
            <Button onClick={() => this.clearTaxSupport(row.userIdx)}>
              지급처리
            </Button>
          </div>
        ),
      },
      {
        title: '지급/차감',
        className: 'table-column-center',
        render: (row) => (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button onClick={() => this.getRiderCurrentPoint(row)}>
              지급/차감
            </Button>
          </div>
        ),
      },
      {
        title: '서류제출',
        dataIndex: 'files',
        className: 'table-column-center',
        key: (row) => `files:${row.files}`,
        render: (data, row) => {
          return row.files.length > 0 ? (
            <div>
              <Button
                onClick={() => {
                  this.setState({
                    riderUploadFileListOpen: true,
                    data: row,
                  });
                }}>
                제출완료
              </Button>
            </div>
          ) : (
            <div>
              <Button disabled>미제출</Button>
            </div>
          );
        },
      },
      {
        title: '승인처리',
        dataIndex: 'idx',
        className: 'table-column-center',
        render: (data, row) =>
          row.approvedCode == RiderApprovedCode.APPROVED ? (
            '승인'
          ) : (
            <Button onClick={async () => await this.handleApproveRider(row)}>
              승인처리
            </Button>
          ),
      },
      {
        title: '레벨',
        dataIndex: 'riderPointLevel',
        className: 'table-column-center',
      },
      {
        title: '수수료',
        dataIndex: 'discountDeliveryPriceFee',
        className: 'table-column-center',
        render: (data, row) => {
          return row.deliveryPriceFeeType == 0 ? (
            <div>{data} %(정률)</div>
          ) : (
            <div>{comma(data)} 원(정량)</div>
          );
        },
      },
      {
        title: '근로자',
        dataIndex: 'fullTimeEmployee',
        className: 'table-column-center',
        render: (data, row) => {
          return <div>{data === true ? '근로자' : '계약직'}</div>;
        },
      },
    ];

    return (
      <FormItem>
        {this.state.openSearchRiderModal && (
          <SearchRiderDialog
            onSelect={(selectedRider) => this.moveVaccount(selectedRider)}
            close={this.closeSerchRiderModal}
          />
        )}
        <SelectBox
          className="selectRiderBox"
          style={{ width: 200 }}
          value={statusCode[this.state.userStatus]}
          code={Object.keys(statusCode)}
          codeString={statusCode}
          onChange={(value) => {
            if (parseInt(value) !== this.state.userStatus) {
              this.setState(
                {
                  userStatus: parseInt(value),
                  pagination: {
                    current: 1,
                    pageSize: 10,
                  },
                },
                () => this.getList()
              );
            }
          }}
        />
        <Search
          placeholder="지점 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ branchName: e.target.value })}
          onSearch={this.onSearchBranch}
          style={{
            width: 220,
            marginLeft: 10
          }}
        />
        <Search
          placeholder="라이더 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ riderName: e.target.value })}
          onSearch={this.onSearchRider}
          style={{
            width: 220,
            marginLeft: 10
          }}
        />
        <Search
          placeholder="가상계좌번호 검색"
          enterButton
          allowClear
          onSearch={this.onSearchVaccountNumber}
          style={{
            width: 220,
            marginLeft: 20,
          }}
        />


        {Const.excelEnable && (
          <Button
            className="download-btn"
            style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
            onClick={() => this.onDownload()}>
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            엑셀 다운로드
          </Button>
        )}
        <Button
          className="download-btn"
          style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
          onClick={() => this.openUnuseListDialog()}>
          콜 미수행 목록
        </Button>
        <Button
          className="download-btn"
          style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
          onClick={() => {
            this.setState({
              riderNotUploadListOpen: true,
            });
          }}>
          서류 미제출자 목록
        </Button>
        {this.state.riderAccountModalOpen && (
          <RiderAccountDialog
            data={this.state.data}
            close={this.closeRiderAccountDialog}
          />
        )}
        {this.state.riderAccountModifyOpen && (
          <RiderAccountModifyRecord
            data={this.state.data}
            close={this.closeRiderAccountModifyRecord}
          />
        )}

        {this.state.unUseListOpen && (
          <RiderUnUseListDialog
            close={this.closeUnuseListDialog}
            getList={this.getList}
          />
        )}
        {this.state.riderUploadFileListOpen && (
          <RiderUploadFileListDialog
            close={this.closeRiderUplodeFileDialog}
            data={this.state.data}
          />
        )}
        {this.state.riderNotUploadListOpen && (
          <RiderNotUploadFileDialog
            close={this.closeRiderNotUplodeFileDialog}
          />
        )}
        {this.state.modifyRiderPoint && (
          <RiderPointModifyDialog
            data={this.state.riderInfo}
            close={() =>
              this.setState(
                {
                  modifyRiderPoint: false,
                },
                () => this.getList()
              )
            }
          />
        )}
        <Table
          rowKey={(record) => record}
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </FormItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};
export default withRouter(connect(mapStateToProps, null)(RiderList));
