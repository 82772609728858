import { Button, Form, Input, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { httpPost, httpUrl } from '../../../api/httpClient';
import '../../../css/main.css';
import CloseIcon from '../../../img/close.png';
import { CloseOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

class RegistHeadOfficeReqWithdrawDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
    };
    this.formRef = React.createRef();
  }

  createHandleSubmit = () => {
    console.log(this.formRef.current.getFieldsValue().branchIdx);
    httpPost(httpUrl.branchHeadOfficeReqWithdraw, [], {
      amount: this.formRef.current.getFieldsValue().amount,
    }).then((res) => {
      console.log(res);
      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '출금 성공',
          content: '출금 완료 했습니다.',
        });
        this.props.close();
      } else if (res.data === 'NOT_ENOUGH_NCASH') {
        Modal.warn({
          title: '출금 실패',
          content: '잔액이 부족합니다.',
        });
      } else if (res.data === 'NOT_BRANCH') {
        Modal.warn({
          title: '출금 실패',
          content: '출금 불가능 유저입니다.',
        });
      } else {
        Modal.warn({
          title: '출금 실패',
          content: '출금에 실패 했습니다.',
        });
      }
    });
  };

  render() {
    const { close, data } = this.props;
    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="agency-batchwork-Dialog">
          <div className="agency-batchwork-content" style={{ width: 500 }}>
            <div className="agency-batchwork-title">
              <h1>대리점 본사 출금</h1>
              <Button
                onClick={close}
                icon={<CloseOutlined style={{ fontSize: 24 }} />}
                type="text"
                shape="circle"
              />
            </div>
            <Form ref={this.formRef} onFinish={this.createHandleSubmit}>
              <div className={"agency-batchwork-form"}>
                <p className={"agency-form-title"}>출금</p>
                <FormItem
                  name="amount"
                  className={"agency-formItem"}
                  rules={[
                    {
                      required: true,
                      message: "출금 금액을 입력해주세요"
                    }
                  ]}>
                  <Input
                    style={{ flex: 1, height: 48 }}
                    type="number"
                    placeholder="출금 금액을 숫자로 입력해주세요"></Input>
                </FormItem>
              </div>
              <Button
                htmlType="submit"
                className="create-control-submit-btn"
                style={{
                  backgroundColor: '#FFE45D',
                  width: '100%',
                  height: 50,
                  fontSize: 16,
                  fontWeight: 'bold',
                  borderColor: '#888',
                }}>
                {'출금하기'}
              </Button>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { login } = state;
  return { login };
};

export default withRouter(
  connect(mapStateToProps, null)(RegistHeadOfficeReqWithdrawDialog)
);
