import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { httpGet, httpPost, httpUrl, makeUrl } from '../../../api/httpClient';
import '../../../css/main.css';
import { mallProductStatusCode } from '../../../lib/util/codeUtil';
import CloseIcon from '../../../img/close.png';

const RegistMallProductDialog = ({
  selectedProduct,
  close,
  getProductList,
}) => {
  const productSizeCode = [
    { label: 'XS', value: 'XS' },
    { label: 'S', value: 'S' },
    { label: 'M', value: 'M' },
    { label: 'L', value: 'L' },
    { label: 'LL', value: 'LL' },
    { label: '3L', value: '3L' },
    { label: '4L', value: '4L' },
    { label: 'XL', value: 'XL' },
    { label: '2XL', value: '2XL' },
    { label: '3XL', value: '3XL' },
    { label: '4XL', value: '4XL' },
    { label: '5XL', value: '5XL' },
    { label: 'FREE', value: 'FREE' },
  ];

  const formRef = React.createRef();

  const [categoryList, setCategoryList] = useState([]);
  const [file, setFile] = useState(null);
  const [imageFileChanged, setImageFileChanged] = useState(false);

  useEffect(async () => {
    if (selectedProduct) {
      formRef.current.setFieldsValue(selectedProduct);
    }
    const res = await httpGet(httpUrl.getMallCategoryList, [], {});
    console.log(res.data);
    setCategoryList(res.data);
  }, []);

  const handleSubmit = async () => {
    const formData = formRef.current.getFieldsValue();
    if (imageFileChanged) {
      const uploadRes = await uploadS3ImageFile();
      if (uploadRes?.data?.data?.result) {
        formData['imageFileIdx'] = -1; // uploadRes.data?.data?.idx;
        formData['cloudFileIdx'] = uploadRes.data?.data?.cloudFileIdx;
      } else {
        Modal.warn({
          title: '등록실패',
          content: '파일업로드에 실패했습니다.',
        });
      }
    }
    formData['idx'] = selectedProduct ? selectedProduct.idx : null;
    console.log('formData');
    console.log(formData);

    const res = await httpPost(
      selectedProduct ? httpUrl.updateMallProduct : httpUrl.createMallProduct,
      [],
      formData
    );

    if (res.data) {
      getProductList();
      close();
    } else
      Modal.warn({
        title: '등록실패',
        content: '상품등록에 실패했습니다.',
      });
  };

/* 이미지 업로드 S3로 변경 이전 업로드 함수 주석 처리.
  const handleImageFile = async () => {
    if (!file) return;
    var formData = new FormData();
    formData.append('file', file);

    const res = await axios.post(makeUrl(httpUrl.FileUpload, []), formData, {
      withCredentials: true,
    });
    if (
      res.status === 200 &&
      res.data.result === 'SUCCESS' &&
      res.data.data.result
    ) {
      console.log(res);
      return res;
    } else {
      return {
        result: 'SUCCESS',
        data: {
          data: false,
        },
      };
    }
  };
*/
  const uploadS3ImageFile = async () => {
    if (!file) return;
    var formData = new FormData();
    formData.append('file', file);

    const res = await axios.post(makeUrl(httpUrl.FileUploadS3, []), formData, {
      withCredentials: true,
    });

    // alert(`uploadS3ImageFile ` + JSON.stringify(res));


    if (res.status === 200 && res.data?.result === 'SUCCESS' && res.data?.data?.result) {
      console.log(res);
      return res;
    } else {
      return {
        result: 'SUCCESS',
        data: {
          data: false,
        },
      };
    }
  };

  return (
    <React.Fragment>
      <div className="Dialog-overlay" onClick={close} />
      <div className="systemKey-Dialog">
        <div className="systemKey-content">
          <div className="systemKey-title">몰상품추가</div>
          <img
            onClick={close}
            style={{ display: 'inline' }}
            src={CloseIcon}
            className="dialog-close"
            alt="img"
          />

          <div className="systemKey-inner">
            <Form
              ref={formRef}
              onFinish={handleSubmit}
              className="create-control-form">
              <div className="create-control-form-item-title">상품명</div>
              <Form.Item
                name="name"
                className="create-control-form-item"
                rules={[
                  {
                    required: true,
                    message: '상품명을 입력해주세요',
                  },
                ]}>
                <Input className="create-control-input" placeholder="상품명" />
              </Form.Item>

              <div className="create-control-form-item-title">가격</div>
              <Form.Item
                name="price"
                className="create-control-form-item"
                rules={[
                  {
                    required: true,
                    message: '가격를 입력해주세요',
                  },
                ]}>
                <Input className="create-control-input" placeholder="가격" />
              </Form.Item>

              <div className="create-control-form-item-title">분류</div>
              <Form.Item
                name="categoryIdx"
                className="create-control-form-item"
                rules={[
                  {
                    required: true,
                    message: '분류를 입력해주세요',
                  },
                ]}>
                <Select>
                  {categoryList.map((category) => {
                    return (
                      <Select.Option value={category.idx}>
                        {category.category}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <div className="create-control-form-item-title">상태</div>
              <Form.Item
                name="status"
                className="create-control-form-item"
                rules={[
                  {
                    required: true,
                    message: '상태를 입력해주세요',
                  },
                ]}>
                <Select>
                  {Object.keys(mallProductStatusCode).map((key) => {
                    return (
                      <Select.Option value={parseInt(key)}>
                        {mallProductStatusCode[key]}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <div className="create-control-form-item-title">사이즈</div>
              <Form.Item
                name="productSizes"
                className="create-control-form-item"
                rules={[
                  {
                    required: true,
                    message: '상태를 입력해주세요',
                  },
                ]}>
                <Checkbox.Group
                  options={productSizeCode}
                  value={productSizeCode}
                  onChange={(value) => {
                    if (value.includes('FREE')) {
                      formRef.current.setFieldsValue({
                        ...selectedProduct,
                        productSizes: ['FREE'],
                      });
                    }
                  }}
                />
              </Form.Item>

              <div className="create-control-form-item-title">이미지</div>
              <Form.Item
                className="create-control-form-item"
                rules={[
                  {
                    required: true,
                    message: '상태를 입력해주세요',
                  },
                ]}>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                    setImageFileChanged(true);
                  }}
                />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                className="checreate-control-submit-btn"
                style={{ width: '100%' }}>
                등록
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RegistMallProductDialog;
