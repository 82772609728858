const comma = (a) => {
  if (!a) return 0;
  if (a === 0 || a === '0') return 0;
  var parts = a.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
};
const accountFormat = (a) => {
  if (!a) return <div>-</div>;
  if (a === 0 || a === '0') return <div>-</div>;
  var parts = a.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return (
    <div style={{ color: a > 0 ? 'blue' : a < 0 ? 'red' : 'black' }}>
      {parts.join('.')}원
    </div>
  );
};
export { comma, accountFormat };
