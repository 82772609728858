import { Button, Table, Modal, Input, Select } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl, httpPost } from '../../api/httpClient';
import { customError } from '../../api/Modals';
import RegistBranchDialog from '../../components/dialog/RegistBranchDialog';
import '../../css/main.css';
import {
  deliveryStatShow,
  distShowSetting,
  addrUpdateEnabled,
  sortOrder,
  distType,
  riderLevel,
  BranchVisible, mapPaystoryVacntResultCd,
} from '../../lib/util/codeUtil';
import {comma} from "../../lib/util/numberUtil";
import moment from "moment";
import xlsx from 'xlsx';
import Const from '../../const';
import ExcelIcon from '../../img/excel.png';

import {isNumber} from "lodash";
import {showSimpleModal} from "../../lib/util/etcUtil";

const Search = Input.Search;

const COMPANY_ID_CONNECT9 = 'connect9';

class BranchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      registBranchModalOpen: false, //지점등록
      modifyBranchModalOpen: false, //지점정보변경
      rowBranchList: [],
      branchName: '',
      headBranchList: [],
      headBranchIdx: "",
      remainingPaystoryVacnt: 0,
    };

  }

  componentDidMount() {
    this.getList();
    this.getHeadBranchList();

  }

  // 리스트 조회 api
  getList = () => {
    const pageSize = this.state.pagination.pageSize; //221108 hms
    const pageNum = this.state.pagination.current; //221108 hms
    const branchName = this.state.branchName;
    const headBranchIdx = this.state.headBranchIdx;
    httpGet(httpUrl.getBranchListSetting, [pageNum, pageSize, branchName, headBranchIdx], {})
      .then((res) => {
        if (res.result === 'SUCCESS') {
          this.setState({
            list: res.data.branch,
            pagination: {
              ...this.state.pagination,
              current: res.data.currentPage,
              total: res.data.totalCount,
            },
          });
        }
      })
      .catch((e) => {
        customError('목록 에러', '에러가 발생하여 목록을 불러올수 없습니다.');
      })
    .finally(()=> { this.getRemainingPaystoryVacnt() });
  };

  getHeadBranchList = () => {
    httpGet(httpUrl.getHeadBranchList, [], {})
    .then(res=> {
      if(res.result === 'SUCCESS') {
        this.setState({
          headBranchList: res.data
        })
      }
    })
  }

  parseExcelJson = (data) => {
    let result = [
      {
        isAgency: '구분',
        visible: '지점 사용여부',
        headBranchName: '제휴본사',
        branchName: '지점명',
        tel: '전화번호',
        startTime: '시작시간',
        endTime: '종료시간',
        agencyFee: '프로그램 사용료',
        agencyFeeStartDate: '프로그램 사용료 적용시간',
        companyId:'회사계정'
      },
    ];
    data.forEach((item) => {
      result.push({
        isAgency: item.companyId === 'connect9' ? '직영점' : '대리점',
        visible: item.visible ? '사용':'미사용',
        headBranchName: item.headBranchName,
        branchName: item.branchName,
        tel: item.tel,
        startTime: item.startTime,
        endTime: item.endTime,
        agencyFee: item.agencyFee,
        agencyFeeStartDate: item.agencyFeeStartDate ? moment(item.agencyFeeStartDate).format("YYYY-MM-DD"): '',
        companyId: item.companyId,
      });
    });

    return result;
  };

  onDownload = () => {
    httpGet(
      httpUrl.getBranchListSetting,
      [
        1,
        1000000,
        this.state.branchName,
        this.state.headBranchIdx,
      ],
      {}
    ).then((res) => {
      const excelJson = this.parseExcelJson(res.data.branch);
      const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
      const wb = xlsx.utils.book_new();
      ws['!cols'] = [];
      ws['!cols'][6] = { width: 15 };
      ws['!cols'][7] = { width: 22 };
      xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
      xlsx.writeFile(wb, '지점목록.xlsx');
    });
  };

  // 페이징
  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onSearchBranch = (value) => {
    this.setState(
      {
        branchName: value,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      },
      () => {
        this.getList();
        // this.getExcelList();
      }
    );
  };

  onSearchHeadBranch = (value) => {
    this.setState(
      {
        headBranchIdx: value,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      },
      () => {
        this.getList();
        // this.getExcelList();
      }
    );
  };

  //지점 정보 등록 modal 221109 hms
  openCreateBranchModal = (row) => {
    this.setState({ registBranchModalOpen: true });
  };
  closeCreateBranchModal = () => {
    this.setState({ registBranchModalOpen: false }, () => {
      this.getList();
    });
  };

  //지점 정보 변경 modal 221110 hms
  openModifyBranchModal = (row) => {
    console.log(row);
    this.setState({ modifyBranchModalOpen: true, rowBranchList: row });
  };
  closeModifyBranchModal = () => {
    this.setState({ modifyBranchModalOpen: false }, () => {
      this.getList();
    });
  };

  mapPaystoryVacntBranch = (branch) => {
    if (COMPANY_ID_CONNECT9 === branch.companyId) {
      showSimpleModal('가상계좌 발급 실패', 'connect9은 발급 불가능');
      return;
    }

    if (0 >= this.state.remainingPaystoryVacnt) {
      showSimpleModal('가상계좌 발급 실패', mapPaystoryVacntResultCd.INSUFFICIENT_VACNT);
      return;
    }

    Modal.confirm({
      title: '가상계좌발급',
      content: `${branch.branchName} 지점에 가상계좌를 발급하시겠습니까?`,
      onOk: async () => {
        try {
          const res = await httpPost(httpUrl.mapPaystoryVacntForBranch, [branch.idx], {});
          if (res.result !== 'SUCCESS') {
            showSimpleModal('가상계좌 발급 실패', mapPaystoryVacntResultCd[res.result]);
          } else {
            const result = res.data?.result;
            showSimpleModal(`가상계좌 ${((result === 'SUCCESS') ? '성공' : '실패')}`, mapPaystoryVacntResultCd[result]);
          }
        } catch (e) {
          showSimpleModal('ERROR : 가상계좌 발급 실패', e.message);
        } finally {
          this.getList();
          await this.getRemainingPaystoryVacnt();
        }
      },
      onCancel: async () => {
        await this.getRemainingPaystoryVacnt();
      },
    });
  };
  getRemainingPaystoryVacnt = async () => {
    const res = await httpGet(httpUrl.remainingPaystoryVacnt, [], {});
    const count = res.data?.count;
    if (res.result === 'SUCCESS' && res.data?.result === 'SUCCESS' && isNumber(count)) {
      this.setState({remainingPaystoryVacnt: count}, () => { /* alert( `remainingPaystoryVacnt : ${this.state.remainingPaystoryVacnt}, res count:${count}`) */ } );
    }
  };

  render() {
    const columns = [
      {
        title: '지점번호',
        dataIndex: 'idx',
        className: 'table-column-center',
      },
      {
        title: '제휴본사',
        dataIndex: 'headBranchName',
        className: 'table-column-center',
      },
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '위도',
        dataIndex: 'latitude',
        className: 'table-column-center',
        render: (data) => Math.round(data * 100) / 100,
      },
      {
        title: '경도',
        dataIndex: 'longitude',
        className: 'table-column-center',
        render: (data) => Math.round(data * 100) / 100,
      },
      {
        title: '전화번호',
        dataIndex: 'tel',
        className: 'table-column-center',
      },
      {
        title: '시작시간',
        dataIndex: 'startTime',
        className: 'table-column-center',
      },
      {
        title: '종료시간',
        dataIndex: 'endTime',
        className: 'table-column-center',
      },
      {
        title: '가맹점 배달현황 통계 노출여부',
        dataIndex: 'deliveryStatShow',
        className: 'table-column-center',
        render: (data) => <>{deliveryStatShow[data]}</>,
      },
      {
        title: '최대배달수수료',
        dataIndex: 'maxDeliveryFee',
        className: 'table-column-center',
      },
      {
        title: '라이더 취소 기본 페널티',
        dataIndex: 'riderCancelPenalty',
        className: 'table-column-center',
      },
      {
        title: '기본배달비',
        dataIndex: 'basicDeliveryPrice',
        className: 'table-column-center',
      },
      {
        title: '라이더앱 거리설정 노출여부',
        dataIndex: 'distShowSetting',
        className: 'table-column-center',
        render: (data) => <>{distShowSetting[data]}</>,
      },
      {
        title: '배차완료 후 도착지 수정가능여부',
        dataIndex: 'addrUpdateEnabled',
        className: 'table-column-center',
        render: (data) => <>{addrUpdateEnabled[data]}</>,
      },
      {
        title: '정렬방식',
        dataIndex: 'sortOrder',
        className: 'table-column-center',
        render: (data) => <>{sortOrder[data]}</>,
      },
      {
        title: '거리요금방식',
        dataIndex: 'distType',
        className: 'table-column-center',
        render: (data) => <>{distType[data]}</>,
      },
      {
        title: '스테이션장',
        dataIndex: 'stationDirectorName',
        className: 'table-column-center',
        render: (data) => <>{data? data :'-'}</>,
      },
      {
        title: '스테이션장 직급',
        dataIndex: 'stationDirectorLevel',
        className: 'table-column-center',
        render: (data) => <>{data? riderLevel[data] :'-'}</>,
      },
      {
        title: '영업이익',
        dataIndex: 'branchProfitPercent',
        className: 'table-column-center',
        render: (data) => <>{parseInt(data * 100) + '(%)'}</>,
      },
      {
        title: '회사ID',
        dataIndex: 'companyId',
        className: 'table-column-center',
      },
      {
        title: '지점 사용여부',
        dataIndex: 'visible',
        className: 'table-column-center',
        render: (data) => <>{BranchVisible[data]}</>,
      },
      {
        title: '지점수정',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn"
              onClick={() => {
                this.openModifyBranchModal(row);
              }}>
              수정
            </Button>
          </div>
        ),
      },
      {
        title: '프로그램 사용료',
        dataIndex: 'agencyFee',
        className: 'table-column-center',
        render: (data) => <>{comma(data) + "원"}</>,
      },
      {
        title: '프로그램 사용료 적용시작일',
        dataIndex: 'agencyFeeStartDate',
        className: 'table-column-center',
        render: (data) => <>{data ? moment(data).format('YYYY-MM-DD') : "-"}</>,
      },
      {
        title: `가상계좌 (남은 수:${this.state.remainingPaystoryVacnt})`,
        className: 'table-column-center',
        render: (data, row) => (
          <>
            <div>
              {
                ( (COMPANY_ID_CONNECT9 === row.companyId) ? '' :
                        ( ( row.vaccountBank && row.vaccountNumber) ?
                          ( `${row.vaccountBank} / ${row.vaccountNumber}`) : (<Button onClick={() => this.mapPaystoryVacntBranch(row)}> 발급하기 </Button>)
                        )
                )
              }
            </div>
          </>
        ),
      },
    ];

    return (
      <>
        {/* 지점등록모달 */}
        {this.state.registBranchModalOpen && (
          <RegistBranchDialog close={this.closeCreateBranchModal} />
        )}
        {/* 지점정보수정모달 */}
        {this.state.modifyBranchModalOpen && (
          <RegistBranchDialog
            data={this.state.rowBranchList}
            close={this.closeModifyBranchModal}
          />
        )}

        <Button
          className="tabBtn"
          onClick={() => {
            this.openCreateBranchModal();
          }}
          style={{
            marginBottom: 20,
          }}>
          지점 등록
        </Button>
        <Select
          onChange={(value) => {
            this.onSearchHeadBranch(value)
          }}
          style={{width: 180, marginLeft: 20}}
          value={this.state.headBranchIdx}
        >
          {[{idx: "", branchName: "전체"}, {idx: 0, branchName: "선택없음"}].concat(this.state.headBranchList).map((item) => {
            return (
              <Select.Option value={item.idx} key ={item.idx}>
                {item.branchName}
              </Select.Option>
            );
          })}
        </Select>
        <Search
          placeholder="지점명 검색"
          enterButton
          allowClear
          onSearch={this.onSearchBranch}
          style={{
            width: 220,
            marginLeft: 20
          }}
        />
        {Const.excelEnable && (
          <Button
            className="download-btn"
            style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
            onClick={() => this.onDownload()}>
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            엑셀 다운로드
          </Button>
        )}
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

export default BranchList;
