import { Button, Form, Input, Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  insuranceAgeType,
  bikeResponseErrorCode,
} from '../../lib/util/codeUtil';
import { withRouter } from 'react-router-dom';
import { httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import SelectBox from '../../components/input/SelectBox';
import CloseIcon from '../../img/close.png';

const FormItem = Form.Item;
const today = new Date();
const bikeYear = [
  today.getFullYear(),
  today.getFullYear() - 1,
  today.getFullYear() - 2,
  today.getFullYear() - 3,
  today.getFullYear() - 4,
  today.getFullYear() - 5,
  today.getFullYear() - 6,
  today.getFullYear() - 7,
  today.getFullYear() - 8,
  today.getFullYear() - 9,
];

class RegistBikeRentFeeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModifyMode: false,
      modelYear: bikeYear[0],
      insuranceAge: insuranceAgeType[0],
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.data) {
      console.log(this.props.data);
      this.formRef.current.setFieldsValue({ ...this.props.data });
      this.setState({
        ...this.props.data,
        isModifyMode: true,
      });
    }
  }

  // 각 실패 별 안내문구 적용 필요
  rentFeeSubmit = () => {
    let idx = this.state.isModifyMode ? this.state.idx : null;
    let modelYear = this.state.modelYear;
    let insuranceAge =
      this.state.insuranceAge === insuranceAgeType[0]
        ? 0
        : this.state.insuranceAge;
    httpPost(
      this.state.isModifyMode ? httpUrl.RentFeeModify : httpUrl.RentFeeCreate,
      [],
      {
        idx: idx,
        modelName: this.formRef.current.getFieldsValue().modelName,
        modelYear: modelYear,
        insuranceAge: insuranceAge,
        rentFee: this.formRef.current.getFieldsValue().rentFee,
      }
    ).then((res) => {
      console.log(res);
      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '성공',
          content: `렌트비 ${
            this.state.isModifyMode ? '수정' : '등록'
          }에 성공했습니다.`,
        });
        this.props.close();
      } else if (res.data === 'DUPLICATED') {
        Modal.warn({
          title: '실패',
          content: '중복된 데이터입니다! 확인 후 재등록해주세요.',
        });
      } else if (res.data.startsWith('INVALID')) {
        Modal.warn({
          title: '실패',
          content: `렌트비 ${
            this.state.isModifyMode ? '수정' : '등록'
          }에 실패했습니다. ${
            bikeResponseErrorCode[res.data]
          }을(를) 확인해 주세요.`,
        });
      } else {
        Modal.warn({
          title: '실패',
          content: `렌트비 ${
            this.state.isModifyMode ? '수정' : '등록'
          }에 실패했습니다.`,
        });
      }
    });
  };

  onModelYearChange = (value) => {
    this.setState({ modelYear: bikeYear[value] });
  };

  onInsuranceAgeChange = (value) => {
    this.setState({ insuranceAge: insuranceAgeType[value] });
  };

  render() {
    const { close, data } = this.props;
    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="systemKey-Dialog">
          <div className="systemKey-content">
            <div className="regist-header">
              <div className="systemKey-title">
                {this.state.isModifyMode ? '렌트비 수정' : '렌트비 생성'}
              </div>
              <img
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="dialog-close"
                alt="img"
              />
            </div>
            <div className="systemKey-inner">
              <Form ref={this.formRef} onFinish={this.rentFeeSubmit}>
                <div className="regist-warp">
                  <div className="regist-title">차종</div>
                  <FormItem
                    name="modelName"
                    className="regist-title"
                    rules={[
                      {
                        required: true,
                        message: '차종을 입력해주세요',
                      },
                    ]}>
                    <Input
                      className="regist-input"
                      placeholder={
                        this.state.isModifyMode
                          ? this.state.modelName
                          : '차종을 입력해주세요.'
                      }
                    />
                  </FormItem>
                </div>
                <div className="regist-warp">
                  <div className="regist-title">연식</div>
                  <div>
                    <SelectBox
                      codeString={bikeYear}
                      value={this.state.modelYear}
                      onChange={this.onModelYearChange}
                      style={{
                        width: 280,
                      }}
                    />
                  </div>
                </div>
                <div className="regist-warp">
                  <div className="regist-title">보험나이</div>
                  <SelectBox
                    codeString={insuranceAgeType}
                    value={
                      this.state.insuranceAge === 0
                        ? '전연령'
                        : this.state.insuranceAge
                    }
                    onChange={this.onInsuranceAgeChange}
                    style={{
                      width: 280,
                    }}
                  />
                </div>
                <div className="regist-warp">
                  <div className="regist-title">렌트비</div>
                  <FormItem
                    name="rentFee"
                    className="regist-title"
                    rules={[
                      {
                        required: true,
                        message: '렌트비를 입력해주세요',
                      },
                    ]}>
                    <Input
                      className="regist-input"
                      placeholder={
                        this.state.isModifyMode
                          ? this.state.rentFee
                          : '렌트비를 입력해주세요.'
                      }
                    />
                  </FormItem>
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="create-control-submit-btn"
                  style={{ width: '100%' }}>
                  {this.state.isModifyMode ? '수정하기' : '등록하기'}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { login } = state;
  return { login };
};

export default withRouter(
  connect(mapStateToProps, null)(RegistBikeRentFeeDialog)
);
