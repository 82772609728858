import React, { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { comma } from '../../lib/util/numberUtil';
import { httpPost, httpUrl } from '../../api/httpClient';

const AgencyFeeSettingDialog = (props) => {
  let curBranchHeadOfficeFee = props.fee;
  const [branchHeadOfficeFee, setBranchHeadOfficeFee] = useState(null);
  const handleUpdateHeadOfficeFeeSubmit = () => {
    const regex = /^[0-9]*$/;
    if (!regex.test(branchHeadOfficeFee)) {
      Modal.error({
        content: '숫자로만 금액을 입력해주세요.',
        onOk() {},
      });
      return;
    }
    if (props.branchIdx == null || branchHeadOfficeFee == null) {
      Modal.error({
        content: '금액을 입력해주세요.',
        onOk() {},
      });
      return;
    }
    Modal.confirm({
      title: '본사 대리점 수수료 변경',
      content: <div>{branchHeadOfficeFee}원으로 변경 하시겠습니까?</div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.updateBranchSetting, [], {
          branchIdx: props.branchIdx,
          branchHeadOfficeFee: branchHeadOfficeFee,
        }).then((res) => {
          if (res) {
            Modal.info({
              title: ' 완료',
              content:
                '본사 대리점 수수료 ' +
                branchHeadOfficeFee +
                '원으로 변경 완료했습니다.',
            });
            props.close();
          } else {
            Modal.info({
              title: ' 실패',
              content:
                '본사 대리점 수수료 변경 실패했습니다. 관리자에게 문의해주세요.',
            });
          }
        });
      },
    });
  };

  return (
    <div>
      <div className="Dialog-overlay" onClick={props.close} />
      <div className="agency-batchwork-Dialog">
        <div className="agency-batchwork-content" style={{ width: 500 }}>
          <div className="agency-batchwork-title">
            <h1>본사 대리점 수수료 설정</h1>
            <Button
              onClick={props.close}
              icon={<CloseOutlined style={{ fontSize: 24 }} />}
              type="text"
              shape="circle"
            />
          </div>
          <div className={'agency-batchwork-form'}>
            <p className={'agency-form-title'}>
              현재 본사 대리점 수수료: 건당{' '}
              <span>{comma(curBranchHeadOfficeFee)}</span> 원
            </p>
            <Input
              style={{ flex: 1, height: 48 }}
              type="number"
              placeholder={'변경 할 본사 대리점 수수료를 입력하세요.'}
              onChange={(e) => setBranchHeadOfficeFee(e.target.value)}
            />
          </div>
          <Button
            style={{
              backgroundColor: '#FFE45D',
              width: '100%',
              height: 50,
              fontSize: 16,
              fontWeight: 'bold',
              borderColor: '#888',
            }}
            onClick={handleUpdateHeadOfficeFeeSubmit}>
            {'설정하기'}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default AgencyFeeSettingDialog;
