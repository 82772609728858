import { Button, Form, Input, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import '../../../css/main.css';
import CloseIcon from '../../../img/close.png';
import { CloseOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const Option = Select.Option;

class RegistAffiliationBranchDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      branchList: [],
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getBranchList();
  }

  getBranchList = () => {
    httpGet(httpUrl.getBranchList, [1, 10000], {}).then((res) => {
      console.log(res);
      this.setState({
        branchList: res.data.branch,
      });
    });
  };

  // 관리 대리점 생성 api
  createHandleSubmit = () => {
    console.log(this.formRef.current.getFieldsValue().branchIdx);
    httpPost(httpUrl.createBranchHeadOffice, [], {
      branchIdx: this.formRef.current.getFieldsValue().branchIdx,
    }).then((res) => {
      console.log(res);
      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '등록 성공',
          content: '관리 대리점 등록에 성공했습니다.',
        });
        this.props.close();
      } else if (res.data === 'DUPLICATE') {
        Modal.warn({
          title: '등록 실패',
          content: '이미 등록 된 대리점입니다.',
        });
      } else if (res.data === 'NOT_MYSELF') {
        Modal.warn({
          title: '등록 실패',
          content: '등록 할 수 없는 대리점입니다.',
        });
      } else {
        Modal.warn({
          title: '등록 실패',
          content: '등록에 실패 했습니다.',
        });
      }
    });
  };

  render() {
    const { close, data } = this.props;
    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="agency-batchwork-Dialog">
          <div className="agency-batchwork-content" style={{width: 500}}>
            <div className="agency-batchwork-title">
              <h1>관리 대리점 등록</h1>
              <Button
                onClick={close}
                icon={<CloseOutlined style={{ fontSize: 24 }} />}
                type="text"
                shape="circle"
              />
            </div>

            <Form ref={this.formRef} onFinish={this.createHandleSubmit}>
              <div className={"agency-batchwork-form"}>
                <p className={"agency-form-title"}>지점</p>
                <FormItem
                  name="branchIdx"
                  className={"agency-formItem"}
                  initialValue={data && data.branchIdx}
                  rules={[
                    {
                      required: true,
                      message: "지점을 선택해주세요"
                    }
                  ]}>
                  <Select
                    placeholder={"필수 선택사항입니다"}
                    onChange={(value) => {
                      this.setState({ branchIdx: value });
                    }}>
                    {this.state.branchList.map((value, index) => {
                      return (
                        <Option value={value.idx}>{value.branchName}</Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </div>
              <Button
                style={{
                  backgroundColor: "#FFE45D",
                  width: "100%",
                  height: 50,
                  fontSize: 16,
                  fontWeight: "bold",
                  borderColor: "#888"
                }}
                htmlType="submit">
                {"등록하기"}
              </Button>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { login } = state;
  return { login };
};

export default withRouter(
  connect(mapStateToProps, null)(RegistAffiliationBranchDialog)
);
