import React, { Component } from 'react';
import { Input, Table, Modal } from 'antd';
import {
  bikeSearchType,
  bikeResponseErrorCode,
  bikeLocationType,
} from '../../lib/util/codeUtil';
import { internationalAge } from '../../lib/util/ageUtil';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import SelectBox from '../../components/input/SelectBox';
import '../../css/main.css';
import CloseIcon from '../../img/close.png';

const Search = Input.Search;

class BikeAssignDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      bikeSearchType: 0,
      bikeStatusSearch: 1,
      searchValue: '',
      bikeLocation: {},
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    console.log(this.props.data);
    this.setState(
      {
        isModifyMode: true,
        bikeIdx: this.props.data.idx,
        riderIdx: this.props.data.riderIdx,
        branchName: this.props.data.branchName,
        riderName: this.props.data.riderName,
      },
      () => this.getList()
    );
  }

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onSearchBike = (value) => {
    this.setState(
      {
        pagination: {
          current: 1,
          pageSize: 5,
        },
        modelName: value,
        bikeStatusSearch: this.state.bikeStatusSearch,
      },
      () => {
        this.getList();
      }
    );
  };

  getList = async () => {
    const pagination = this.state.pagination;
    const bikeSearchType = this.state.bikeSearchType;
    let insuranceAge =
      this.props.data.registrationNumber === null
        ? 0
        : internationalAge(this.props.data.registrationNumber);
    const res = await httpGet(
      httpUrl.getHasRentFeeBikeList,
      [
        pagination.current,
        pagination.pageSize,
        bikeSearchType == 1 ? this.state.searchValue : '',
        bikeSearchType == 0 ? this.state.searchValue : '',
        insuranceAge,
      ],
      {}
    );
    console.log(res);
    if (res.result === 'SUCCESS') {
      this.setState({
        list: res.data.bikes,
        pagination: {
          ...this.state.pagination,
          total: res.data.totalCount,
        },
      });
    }

    const res2 = await httpPost(httpUrl.bikeLocationInfo, [], {});
    if (res2.result === 'SUCCESS') {
      console.log('res2');
      console.log(res2.data);
      res2.data.map((item) => (bikeLocationType[item.idx] = item.branchName));

      this.setState(
        {
          bikeLocation: bikeLocationType,
        },
        () => {
          console.log('bikeLocation');
          console.log(this.state.bikeLocation);
        }
      );
    }
  };

  onSearchType = (value) => {
    this.setState({ bikeSearchType: value });
  };

  bikeAssign = (record) => {
    let bikeIdx = record.idx;
    let riderIdx = this.state.riderIdx;
    let modelName = record.modelName;
    let modelYear = record.modelYear;
    let insuranceAge = record.insuranceAge;
    let branchName = this.state.branchName;
    let riderName = this.state.riderName;
    let bikeStatus = 4;
    httpPost(httpUrl.bikeAssign, [], {
      bikeIdx,
      riderIdx,
      modelName,
      modelYear,
      insuranceAge,
      branchName,
      riderName,
      bikeStatus,
    })
      .then((res) => {
        console.log('res');
        console.log(res);
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          Modal.info({
            title: '배정완료',
            content: '바이크 배정완료 했습니다.',
          });
          this.props.close();
        } else if (res.data.startsWith('INVALID')) {
          Modal.warn({
            title: '등록실패',
            content: `상태 변경에 실패했습니다. ${
              bikeResponseErrorCode[res.data]
            }을(를) 확인해 주세요.`,
          });
        } else {
          Modal.warn({
            title: '등록실패',
            content: '상태 변경에 실패했습니다.',
          });
        }
      })
      .catch((error) => {
        Modal.warn({
          title: '실패',
          content: `상태 변경에 실패했습니다. (${error.response.data.message})`,
        });
      });
  };

  render() {
    const columns = [
      {
        title: '차종',
        dataIndex: 'modelName',
        className: 'table-column-center',
      },
      {
        title: '차번',
        dataIndex: 'bikeNumber',
        className: 'table-column-center',
      },
      {
        title: '연식',
        dataIndex: 'modelYear',
        className: 'table-column-center',
      },
      {
        title: '보험나이',
        dataIndex: 'insuranceAge',
        className: 'table-column-center',
        render: (data) => (
          <div> {data === 0 ? '전연령' : '만 ' + data + '세'}</div>
        ),
      },
      {
        title: '미배정바이크위치',
        dataIndex: 'unassignBikeLocation',
        className: 'table-column-center',
        render: (data) => this.state.bikeLocation[data],
      },
    ];

    const { close } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="deposit-Dialog">
          <div className="deposit-content">
            <div>
              <div className="deposit-title">바이크 배정</div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ! 선택한 라이더의
              '만나이' 이하(포함) 바이크만 노출됩니다.
              <img
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="deposit-close"
                alt="닫기"
              />
            </div>

            <div className="deposit-inner">
              <SelectBox
                placeholder={'차종'}
                defaultValue={bikeSearchType[0]}
                code={Object.keys(bikeSearchType)}
                codeString={bikeSearchType}
                value={bikeSearchType[this.state.bikeSearchType]}
                onChange={this.onSearchType}
                style={{
                  width: 150,
                  marginRight: 10,
                }}
              />

              <Search
                placeholder="검색어를 입력해주세요"
                enterButton
                allowClear
                onChange={(e) => this.setState({ searchValue: e.target.value })}
                onSearch={this.onSearchBike}
                style={{
                  width: 300,
                  marginBottom: 30,
                }}
              />

              <Table
                className="searchRider-table"
                rowKey={(record) => record}
                dataSource={this.state.list}
                columns={columns}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      this.bikeAssign(record);
                    },
                  };
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BikeAssignDialog;
