import { Radio } from 'antd';
import { useState } from 'react';
import SearchFranDialog from './SearchFranDialog';
import SearchRiderDialog from './SearchRiderDialog';

const SearchRiderOrFrDialog = ({ close, callback, subBrand }) => {
  const [searchCategory, setSearchCategory] = useState(subBrand ? 1 : 2);
  const msgObject = ({ searchCategory, setSearchCategory }) => (
    <Radio.Group
      value={searchCategory}
      onChange={(e) => {
        setSearchCategory(e.target.value);
        console.log(e.target.value);
      }}>
      <Radio value={1}>라이더검색</Radio>
        {!subBrand && <Radio value={2}>가맹점검색</Radio>}
    </Radio.Group>
  );
  return (
    <div>
      {searchCategory === 1 && (
        <SearchRiderDialog
          close={close}
          callback={callback}
          msgObject={msgObject}
          searchCategory={searchCategory}
          setSearchCategory={setSearchCategory}
        />
      )}
      {searchCategory === 2 && (
        <SearchFranDialog
          close={close}
          callback={callback}
          msgObject={msgObject}
          searchCategory={searchCategory}
          setSearchCategory={setSearchCategory}
        />
      )}
    </div>
  );
};

export default SearchRiderOrFrDialog;
