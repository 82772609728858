import { Form, Table, Button } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import RegistBikeRentFeeDialog from '../../components/dialog/RegistBikeRentFeeDialog';
import { comma } from '../../lib/util/numberUtil';

const FormItem = Form.Item;

class RentFeeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 300,
      },
      isRegistRentFeeOpen: false,
      isModifyRentFeeOpen: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    const pagination = this.state.pagination;
    httpGet(
      httpUrl.RentFeeList,
      [pagination.current, pagination.pageSize],
      {}
    ).then((res) => {
      console.log(res);
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.rentFee,
          pagination: {
            ...this.state.pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        });
      }
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  // 등록
  openRegistBikeRentFeeDialog = () => {
    this.setState({ isRegistRentFeeOpen: true });
  };
  closeRegistBikeRentFeeDialog = () => {
    this.setState({ isRegistRentFeeOpen: false }, () => {
      this.getList();
    });
  };

  // 수정
  openModifyBikeRentFeeDialog = (row) => {
    this.setState({ isModifyRentFeeOpen: true, rentFee: row });
  };

  closeModifyBikeRentFeeDialog = () => {
    this.setState({ isModifyRentFeeOpen: false }, () => {
      this.getList();
    });
  };

  render() {
    const columns = [
      {
        title: '차종',
        dataIndex: 'modelName',
        className: 'table-column-center',
      },
      {
        title: '연식',
        dataIndex: 'modelYear',
        className: 'table-column-center',
      },
      {
        title: '보험나이',
        dataIndex: 'insuranceAge',
        className: 'table-column-center',
        render: (data) => (
          <div>{data === 0 ? '전연령' : '만 ' + data + '세'}</div>
        ),
      },
      {
        title: '렌트비',
        dataIndex: 'rentFee',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}원</div>,
      },
      {
        title: '수정',
        className: 'table-column-center',
        width: '12%',
        render: (data, row) => (
          <div>
            <Button
              onClick={() => {
                this.openModifyBikeRentFeeDialog(row);
              }}>
              수정
            </Button>
          </div>
        ),
      },
    ];

    return (
      <FormItem>
        <Button
          onClick={() => {
            this.openRegistBikeRentFeeDialog();
          }}
          style={{ marginLeft: 20, marginBottom: 30 }}>
          렌트비 등록
        </Button>

        {this.state.isRegistRentFeeOpen && (
          <RegistBikeRentFeeDialog close={this.closeRegistBikeRentFeeDialog} />
        )}

        {this.state.isModifyRentFeeOpen && (
          <RegistBikeRentFeeDialog
            data={this.state.rentFee}
            close={this.closeModifyBikeRentFeeDialog}
          />
        )}

        <Table
          rowKey={(record) => record}
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </FormItem>
    );
  }
}

export default RentFeeList;
