import React, { useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, Radio, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
  adminSendKindForAgency,
  btachWorkKindForAgency,
  btachWorkKindForFr,
  dminSendKindKindForFr,
} from '../../lib/util/codeUtil';
import moment from 'moment';
import SearchFranDialog from '../dialog/SearchFranDialog';
import AgencySelectBranchDialog from './AgencySelectBranchDialog';
import { httpPost, httpUrl } from '../../api/httpClient';
import { customAlert, customError } from '../../api/Modals';
import AgencySearchFrDialog from './AgencySearchFrDialog';

const AgencyDepositDialog = (props) => {
  const formRef = useRef(null);
  const FormItem = Form.Item;

  const [userType, setUserType] = useState(99);
  const [targetName, setTargetName] = useState('');
  const [targetIdx, setTargetIdx] = useState(0);
  const [targetId, setTargetId] = useState('');
  const [searchModalOpen, setSearchModalOpen] = useState(false);

  useEffect(() => {}, []);

  const checkSubmit = () => {
    if (targetName === null || targetName === '') {
      Modal.error({
        content: '대상을 선택해주세요.',
      });
      return;
    }
    Modal.confirm({
      title: '예치금 지금',
      content: `${targetName} 에게 ${formRef.current.getFieldValue(
        'ncashAmount'
      )} 원을 지급하시겠습니까?`,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        handleSubmit();
      },
    });
  };
  const handleSubmit = async () => {
    try {
      const res = await httpPost(httpUrl.depositSend, [], {
        ...formRef.current.getFieldsValue(),
        receiveUserIdx: targetIdx,
        userId: targetId,
        userType: userType,
        ncashMemo: formRef.current.getFieldsValue().ncashMemo
          ? formRef.current.getFieldsValue().ncashMemo
          : '',
      });
      console.log(res);
      if (res.data === 'SUCCESS') {
        customAlert('예치금 지급 완료');
        props.getBalance();
        props.close();
        props.refresh();
      } else if (res.data === 'RECEIVE_USER_NOT_EXISTS') {
        customError(
          '예치금 대상자 미확인 에러',
          '지급받는 대상이 확인되지 않습니다. 다시 시도해보시거나 관리자에게 문의해주세요.'
        );
      } else {
        customError(
          '예치금 지급 에러',
          '서버 에러로 인해 예치금 지급에 실패하였습니다. 관리자에게 문의해주세요.'
        );
      }
    } catch (e) {
      customError(
        '예치금 지급 에러',
        '예치금 잔액을 초과하여 지급할 수 없습니다. 금액을 다시 확인해주시거나, 관리자에게 문의해주세요.'
      );
    }
    props.close();
  };
  const openSearchModal = () => {
    setSearchModalOpen(!searchModalOpen);
  };
  const callback = (data) => {
    const dataName = userType === 99 ? data.branchName : data.frName;
    const dataIdx = userType === 99 ? data.idx : data.userIdx;
    const dataId = userType === 99 ? data.companyId : data.userId;
    setTargetIdx(dataIdx);
    setTargetName(dataName);
    setTargetId(dataId);
  };
  const onchangeKind = () => {};
  return (
    <div id={'agency-deposit-dialog'}>
      <div className="Dialog-overlay" onClick={props.close} />
      <div className="agency-batchwork-Dialog">
        <div className="agency-batchwork-content" style={{ width: 400 }}>
          <div className="agency-batchwork-title">
            <h1>예치금 지급</h1>
            <Button
              onClick={props.close}
              icon={<CloseOutlined style={{ fontSize: 24 }} />}
              type="text"
              shape="circle"
            />
          </div>
          <Form ref={formRef} onFinish={checkSubmit}>
            <div className={'agency-batchwork-form'}>
              <p className={'agency-form-title'}>대상</p>
              <FormItem
                className={'agency-formItem'}
                name="userType"
                initialValue={99}
                rules={[
                  {
                    required: true,
                    message: '대상을 선택해주세요',
                  },
                ]}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <Radio.Group
                    value={userType}
                    onChange={(e) => {
                      setUserType(e.target.value);
                      setTargetIdx(0);
                      setTargetName('');
                      setTargetId('');
                      formRef.current.setFieldsValue({
                        ...formRef.current.getFieldsValue(),
                        kind: null,
                      });
                    }}>
                    <Radio value={99}>대리점</Radio>;
                    <Radio value={2}>가맹점</Radio>;
                  </Radio.Group>
                  {userType === 99 ? (
                    <Button
                      style={{ marginLeft: 'auto' }}
                      onClick={openSearchModal}>
                      대리점 검색
                    </Button>
                  ) : (
                    <Button
                      style={{ marginLeft: 'auto' }}
                      onClick={openSearchModal}>
                      가맹점 검색
                    </Button>
                  )}
                </div>
              </FormItem>
              <div className={'agency-formItem'} style={{ marginBottom: 24 }}>
                <p className={'agency-form-title'}>
                  {userType === 99 ? '대리점명' : '가맹점명'}
                </p>
                <Input
                  style={{ flex: 1, height: 42 }}
                  disabled={true}
                  placeholder={'검색을 해주세요'}
                  value={targetName}
                />
              </div>
              <p className={'agency-form-title'}>구분</p>
              <FormItem
                name="kind"
                className={'agency-formItem'}
                rules={[{ required: true, message: '구분을 선택해주세요' }]}>
                <Select
                  style={{
                    flex: 1,
                  }}
                  placeholder={'구분을 선택해주세요'}>
                  {userType === 99
                    ? adminSendKindForAgency.map((item) => {
                        return (
                          <Select.Option
                            key={item.label}
                            value={item.value}
                            style={{ height: 42 }}>
                            {item.label}
                          </Select.Option>
                        );
                      })
                    : dminSendKindKindForFr.map((item) => {
                        return (
                          <Select.Option key={item.label} value={item.value}>
                            {item.label}
                          </Select.Option>
                        );
                      })}
                </Select>
              </FormItem>
              <p className={'agency-form-title'}>지급금액</p>
              <FormItem
                name="ncashAmount"
                className={'agency-formItem'}
                rules={[{ required: true, message: '금액을 입력해주세요.' }]}>
                <Input
                  style={{ flex: 1, height: 42 }}
                  placeholder={'지급 금액을 입력해주세요'}
                />
              </FormItem>
              <p className={'agency-form-title'}>메모</p>
              <FormItem name="ncashMemo" className={'agency-formItem'}>
                <Input
                  style={{ flex: 1, height: 42 }}
                  placeholder={'차감명을 입력해주세요'}
                />
              </FormItem>
            </div>
            <Button
              style={{
                backgroundColor: '#FFE45D',
                width: '100%',
                height: 50,
                fontSize: 16,
                fontWeight: 'bold',
                borderColor: '#888',
              }}
              htmlType="submit">
              지급하기
            </Button>
          </Form>
        </div>
      </div>
      {searchModalOpen &&
        (userType === 2 ? (
          <AgencySearchFrDialog close={openSearchModal} callback={callback} />
        ) : (
          <AgencySelectBranchDialog
            close={openSearchModal}
            callback={callback}
          />
        ))}
    </div>
  );
};
export default AgencyDepositDialog;
