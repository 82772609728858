import {Button, Radio, Table} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import {
  httpGet,
  httpGetWithoutLoading,
  httpPost,
  httpUrl
} from '../../api/httpClient';
import '../../css/main.css';
import { comma } from '../../lib/util/numberUtil';
import BranchSettlementDetailDialog from '../../components/dialog/BranchSettlementDetailDialog';
import FormItem from "antd/lib/form/FormItem";
import NumberOfCallsIcon from '../../img/number of calls.png';
import DownIcon from '../../img/DOWN.png';
import UpIcon from '../../img/UP.png';
import ChargeIcon from '../../img/charge.png';
import FoodReimbursementIcon from '../../img/food reimbursement.png';
import UncollectedIcon from '../../img/uncollected.png';
import Const from '../../const'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import CountUp from 'react-countup';

const today = new moment();
const dateFormat = 'YYYY-MM-DD';
const todayString = today.format(dateFormat);
const lastdayString = today.subtract(1, 'M').format(dateFormat);
const threeYearAgoString = moment(todayString).subtract(3, 'y').format('YYYY-01-01');


class BranchSettlementInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 100,
        hideOnSinglePage: true,
      },
      list: [],
      branchNameList: {},
      isDetailSettlementOpen: false,
      startDate: moment(todayString).format('YYYY-MM-01'),
      endDate: moment(todayString).format('YYYY-MM-') + moment(todayString).daysInMonth(),
      dateRange: 0,
      callsPerPerson: 0,
      refundFood: 0,
      averageFee: 0,
      branchFee: 0,
      misuAmount: 0,
      monthAgoAverageCall: 0.0,
      currentAverangeCall: 0.0,
      isSales: true,
      beforeData: {
        totalSales: 0,
        deliveryPrice: 0,
        deliveryFeeForRider: 0,
        franchiseFee: 0,
        totalCost: 0,
        hireCost: 0,
        maintenanceCost: 0,
        rentalCost: 0,
        salesIncentivesCost: 0,
        consumableCost: 0,
        bikeMaintenanceCost: 0,
        refundFood: 0,
        etcCost: 0,
        subsidizeCost: 0,
        industrialAccidentCost: 0,
        employmentInsuranceCost: 0,
        businessCardCost: 0,
        nameTagCost: 0,
        leafletCost: 0,
        prechargeUncollectedCost: 0,

      }
    };
  }

  componentDidMount() {
    this.getBranchInfo();
    this.getList();
    this.branchSettlementInfoInterval = setInterval(
        () => this.getList(),
        Const.branchSettlementPollingInterval
    );
  }

  componentWillUnmount() {
    if (this.branchSettlementInfoInterval) clearInterval(this.branchSettlementInfoInterval);
  }

  getBranchInfo = async () => {
    let branchNameList = this.state.branchNameList;
    try {
      const res = await httpPost(httpUrl.branchListInfo, [], {});
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data);
        res.data.branch.map(
            (item) => (branchNameList[item.idx] = item.branchName)
        );
        this.setState({
          branchNameList: branchNameList,
        });
      }
    } catch (error) {
      console.warn('getBranchInfo error: {}', error);
    }
  };

  onChange = (e) => {
    if (e.target.value == 0) {
      let tempList = [];
      if (this.state.list.length > 0) {
        const temp = {
          totalSales: 0,
          deliveryPrice: 0,
          deliveryFeeForRider: 0,
          franchiseFee: 0,
          totalCost: 0,
          hireCost: 0,
          maintenanceCost: 0,
          rentalCost: 0,
          salesIncentivesCost: 0,
          consumableCost: 0,
          bikeMaintenanceCost: 0,
          refundFood: 0,
          etcCost: 0,
          subsidizeCost: 0,
          industrialAccidentCost: 0,
          employmentInsuranceCost: 0,
          businessCardCost: 0,
          nameTagCost: 0,
          leafletCost: 0,
          prechargeUncollectedCost: 0,
        }
        tempList.push(temp);
      }

      this.setState({
        startDate: moment(todayString).format('YYYY-MM-01'),
        endDate: moment(todayString).format('YYYY-MM-') + moment(todayString).daysInMonth(),
        dateRange: e.target.value,
        list: tempList,
      },
          () => {
            this.getList();
          });
      this.branchSettlementInfoInterval = setInterval(
          () => this.getList(),
          Const.branchSettlementPollingInterval
      );
    } else if (e.target.value == 1) {
      this.setState({
        startDate: moment(todayString).format('YYYY-01-01'),
        endDate: moment(lastdayString).format('YYYY-MM-') + moment(lastdayString).daysInMonth(), // 전 월의 말일까지
        dateRange: e.target.value,
      },
          () => {
            this.getList();
          });
      if (this.branchSettlementInfoInterval) clearInterval(this.branchSettlementInfoInterval);
    } else if (e.target.value == 2) {
      this.setState({
        startDate: threeYearAgoString,
        endDate: moment(lastdayString).format('YYYY-MM-') + moment(lastdayString).daysInMonth(), // 전 월의 말일까지
        dateRange: e.target.value,
      },
          () => {
            this.getList();
          });
      if (this.branchSettlementInfoInterval) clearInterval(this.branchSettlementInfoInterval);
    }
  };

  getList = () => {
    if (this.state.list.length > 0 && this.state.dateRange == 0) {
      const before = {
        totalSales: this.state.list[0].totalSales,
        deliveryPrice: this.state.list[0].deliveryPrice,
        deliveryFeeForRider: this.state.list[0].deliveryFeeForRider,
        franchiseFee: this.state.list[0].franchiseFee,
        totalCost: this.state.list[0].totalCost,
        hireCost: this.state.list[0].hireCost,
        maintenanceCost: this.state.list[0].maintenanceCost,
        rentalCost: this.state.list[0].rentalCost,
        salesIncentivesCost: this.state.list[0].salesIncentivesCost,
        consumableCost: this.state.list[0].consumableCost,
        bikeMaintenanceCost: this.state.list[0].bikeMaintenanceCost,
        refundFood: this.state.list[0].refundFood,
        etcCost: this.state.list[0].etcCost,
        subsidizeCost: this.state.list[0].subsidizeCost,
        industrialAccidentCost: this.state.list[0].industrialAccidentCost,
        employmentInsuranceCost: this.state.list[0].employmentInsuranceCost,
        businessCardCost: this.state.list[0].businessCardCost,
        nameTagCost: this.state.list[0].nameTagCost,
        leafletCost: this.state.list[0].leafletCost,
        prechargeUncollectedCost: this.state.list[0].prechargeUncollectedCost,
      }
      this.setState({
        beforeData: before,
      })
    }
    const { pagination } = this.state;
    const pageNum = pagination.current;
    const pageSize = pagination.pageSize;
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    const dateRange = this.state.dateRange;
    httpGetWithoutLoading(httpUrl.getBranchSettlementInfo, [startDate, endDate, dateRange, pageNum, pageSize], {}).then((res) => {
      console.log('res');
      console.log(res);
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      res.data.list.forEach((item, index) => {
        res.data.list[index].promotionTotalRemaining = item.promotionRemainingAmount + item.remainingEtc2Amount;
      });
      this.setState({
        list: res.data.list,
        refundFood: res.data.refundFood,
        misuAmount: res.data.misuAmount,
        averageFee: res.data.averageFee,
        branchFee: res.data.branchFee,
        monthAgoAverageCall: res.data.monthAgoAverageCall,
        currentAverangeCall: res.data.currentAverangeCall,
      });
      if (this.state.dateRange == 0 && this.state.isDetailSettlementOpen && res.data.list.length >= this.state.selectedRowIndex) {
        const data = {
          ...res.data.list[this.state.selectedRowIndex],
          beforeData: this.state.beforeData,
        }
        this.setState({
          selectedRow: data,
        })
      }

    });
  };

  openDetailSettlementDialog = (rowIndex, row, isSales) => {
    const data = {
      ...row,
      beforeData: this.state.beforeData,
    }
    this.setState({
      isDetailSettlementOpen: true,
      selectedRow: data,
      selectedRowIndex: rowIndex,
      isSales: isSales,
    });
  };

  closeDetailSettlementDialog = () => {
    this.setState({ isDetailSettlementOpen: false }, () => {
      this.getList();
    });
  };

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
        {
          pagination: pager,
        },
        () => this.getList()
    );
  };

  render() {
    const columns1 = [
      {
        title: (moment(todayString).month()+1) + '월 매출',
        dataIndex: 'totalSales',
        className: 'table-column-center',
        render: (data, row, rowIndex) => (
            <div>
              <div style={{fontSize: '20px', color: '#6699ff', fontWeight: 'bold'}}>{
                <CountUp start={this.state.beforeData.totalSales} end={data} separator={','}></CountUp>
              }</div>
              <div>
                <Button
                  onClick={() => {
                    this.openDetailSettlementDialog(rowIndex, row, true);
                  }}>
                자세히 보기
                </Button>
              </div>
            </div>
        ),
      },
      {
        title: (moment(todayString).month()+1) + '월 예상 비용',
        dataIndex: 'totalCost',
        className: 'table-column-center',
        render: (data, row, rowIndex) => (
            <div>
              <div style={{fontSize: '20px', color: '#ff3333', fontWeight: 'bold'}}>{
                <CountUp start={this.state.beforeData.totalCost} end={data} separator={','}></CountUp>
              }</div>
              <div>
                <Button
                    onClick={() => {
                      this.openDetailSettlementDialog(rowIndex, row, false);
                    }}>
                  자세히 보기
                </Button>
              </div>
            </div>
        ),
      },
      {
        title: (moment(todayString).month()+1) + '월 수령 예상 금액',
        dataIndex: 'branchDepositAmount',
        className: 'table-column-center',
        render: (data) => <div style={{fontSize: '20px'}}>{Const.branchSettlementViewCountingFlag ? comma(data) : '집계중'}</div>,
      },
        // 보존금액이 들어가면서 현재 총 잔여금액을 계산 할 수 없어서 삭제 처리
      // {
      //   title: '차감 총 예상 잔여금액',
      //   dataIndex: 'promotionRemainingAmount',
      //   className: 'table-column-center',
      //   render: (data) => <div style={{fontSize: '20px'}}>{comma(data)}</div>,
      // },
    ];

    const columns2 = [
      {
        title: '날짜',
        dataIndex: 'settlementDate',
        className: 'table-column-center',
        render: (data) => <div>{data && this.state.dateRange == 2 ? moment(data).format('YYYY') : moment(data).format('YYYY-MM') }</div>,
      },
      {
        title: '매출',
        dataIndex: 'totalSales',
        className: 'table-column-center',
        render: (data, row, rowIndex) => (
            <div>
              {comma(data)}
              <div>
                <Button
                    onClick={() => {
                      this.openDetailSettlementDialog(rowIndex, row, true);
                    }}>
                  자세히 보기
                </Button>
              </div>
            </div>
        ),
      },
      {
        title: '비용',
        dataIndex: 'totalCost',
        className: 'table-column-center',
        render: (data, row, rowIndex) => (
            <div>
              {comma(data)}
              <div>
                <Button
                    onClick={() => {
                      this.openDetailSettlementDialog(rowIndex, row, false);
                    }}>
                  자세히 보기
                </Button>
              </div>
            </div>
        ),
      },
      {
        title: '수령 금액',
        dataIndex: 'branchDepositAmount',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '차감 금액',
        dataIndex: 'promotionBranchManagerPayment',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '차감 총 잔여금액',
        dataIndex: 'promotionRemainingAmount',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
    ];

    return (
        <FormItem>
          {this.state.isDetailSettlementOpen && (
              <BranchSettlementDetailDialog
                  data={this.state.selectedRow}
                  isSales={this.state.isSales}
                  dateRange={this.state.dateRange}
                  close={this.closeDetailSettlementDialog}
              />
          )}
          {this.state.dateRange == 0 ? '(' + this.state.branchNameList[this.props.loginInfo.branchIdx] + ') ' + (moment(todayString).month()+1) + '월 정산 관리'
              : (this.state.dateRange == 1 ? '(' + this.state.branchNameList[this.props.loginInfo.branchIdx] + ') ' + moment(todayString).year() + '년 월별 정산 관리'
                  : '(' + this.state.branchNameList[this.props.loginInfo.branchIdx] + ') ' + '년도별 정산 관리(최근 3년)')}
          <div>
            <Radio.Group
                onChange={this.onChange}
                style={{ marginLeft: '88%'}}
                defaultValue={0}>
              <Radio value={0}>당월</Radio>
              <Radio value={1}>월별</Radio>
              <Radio value={2}>년도별</Radio>
            </Radio.Group>
          </div>

          <Table
              dataSource={this.state.list}
              columns={this.state.dateRange == 0 ? columns1 : columns2}
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
          />
          <div style={{paddingTop: '60px', paddingLeft: '9%'}}>
            <div style={{display: 'inline-block', width: 370, height: '400px', border: '4px solid #cccccc', borderRadius: '15px'}}>
              <img
                  style={{paddingLeft: '40px', width: 300, height: '200px'}}
                  src={NumberOfCallsIcon}
                  alt="img"
              />
              {this.state.dateRange == 0 ?
                  <div>
                    <div style={{paddingTop: '17px'}}></div>
                    <div>
                      <p
                          style={{
                            paddingLeft: '10px',
                            display: 'inline-block',
                            fontWeight: 'bold',
                            fontSize: 22,
                          }}>
                        {'전월달 1인당 콜수'}
                      </p>
                      <p
                          style={{
                            paddingLeft: '10px',
                            display: 'inline-block',
                            fontWeight: 'bold',
                            fontSize: 25,
                            color: '#6699ff',
                          }}>
                        {Const.branchSettlementViewCountingFlag ? this.state.monthAgoAverageCall : '집계중'}
                      </p>
                    </div>
                    <div>
                      <p
                          style={{
                            paddingLeft: '10px',
                            display: 'inline-block',
                            fontWeight: 'bold',
                            fontSize: 22,
                          }}>
                        {'이번달 1인당 예상 콜수'}
                      </p>
                      <p
                          style={{
                            paddingLeft: '10px',
                            display: 'inline-block',
                            fontWeight: 'bold',
                            fontSize: 25,
                            color: '#6699ff',
                          }}>
                        {Const.branchSettlementViewCountingFlag ? this.state.currentAverangeCall : '집계중'}
                      </p>
                      {Const.branchSettlementViewCountingFlag ? <img
                          style={{ display: 'inline', width: 30, height: '25px' }}
                          src={this.state.currentAverangeCall - this.state.monthAgoAverageCall >= 0 ? UpIcon : DownIcon}
                          alt="img"
                      /> : <></>}
                    </div>
                    <p
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: 23,
                          color: '#6699ff',
                        }}>
                      {this.state.currentAverangeCall - this.state.monthAgoAverageCall < 0 ? '가맹정 + 라이더 관리를 진행해주세요.' :
                          '전월 대비 1인당 콜수 ' + (Math.round((this.state.currentAverangeCall - this.state.monthAgoAverageCall) * 10) / 10) + ' 증가'}
                    </p>
                  </div>

                  : <div>
                    <p
                        style={{
                          paddingTop: '33px',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: 23,
                        }}>
                      {this.state.dateRange == 1? '현재 년도 평균 1인당 콜수' : '최근 3년 평균 1인당 콜수'}
                    </p>
                    <p
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: 45,
                          color: '#6699ff',
                        }}>
                      {this.state.currentAverangeCall}
                    </p>
                  </div> }
            </div>
            <div style={{display: 'inline-block'}}>
              {this.state.dateRange != 0 ? <div>
                    <div style={{display: 'inline-block', width: 30}}></div>

                    <div style={{display: 'inline-block', width: 370, height: '400px', border: '4px solid #cccccc', borderRadius: '15px'}}>
                      <img
                          style={{ paddingLeft: '40px', width: 310, height: '240px' }}
                          src={FoodReimbursementIcon}
                          alt="img"
                      />
                      <p
                          style={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: 27,
                          }}>
                        {this.state.dateRange == 1 ? '현재 년도 평균 음식 변상 금액' : '최근 3년 평균 음식 변상 금액'}
                      </p>
                      <p
                          style={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: 34,
                            color: '#6699ff',
                          }}>
                        {this.state.refundFood == null ? '이번달 정산 후 확인 가능' : comma(this.state.refundFood) + '원'}
                      </p>
                    </div>
                  </div> :
                  <div></div>
              }
            </div>


            <div style={{display: 'inline-block', width: 30}}></div>

            <div style={{display: 'inline-block', width: 370, height: '400px', border: '4px solid #cccccc', borderRadius: '15px'}}>
              <img
                  style={{ paddingLeft: '40px', width: 300, height: '200px' }}
                  src={ChargeIcon}
                  alt="img"
              />
              <p
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 14,
                  }}>
                전체 평균 지점 수수료
              </p>
              <p
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 20,
                    color: '#6699ff',
                  }}>
                {Const.branchSettlementViewCountingFlag ? comma(this.state.averageFee) : '집계중'}
              </p>
              <p
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 14,
                  }}>
                {this.state.dateRange == 0? '지점 수수료' : 
                this.state.dateRange == 1 ? '현재 년도 월평균 지점 수수료' : '최근 3년 평균 지점 수수료'}
              </p>
              <p
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 20,
                    color: '#ff3333',
                  }}>
                {Const.branchSettlementViewCountingFlag ? comma(this.state.branchFee) + '원' : '집계중'}
                {Const.branchSettlementViewCountingFlag ? <img
                    style={{ display: 'inline', width: 30, height: '20px' }}
                    src={this.state.averageFee < this.state.branchFee ? UpIcon : DownIcon}
                    alt="img"
                /> : <></>}
              </p>
              <p
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 14,
                    color: '#6699ff',
                  }}>
                {this.state.averageFee < this.state.branchFee ?
                    comma(this.state.branchFee - this.state.averageFee) + '원 증가' :
                    '타 지점보다 ' + comma(this.state.averageFee - this.state.branchFee) + '원 부족. 수수료 관리 필요.'}
              </p>
            </div>

            <div style={{display: 'inline-block'}}>
              {this.state.dateRange != 0 ? <div>
                <div style={{display: 'inline-block', width: 30}}></div>

                <div style={{display: 'inline-block', width: 370, height: '400px', border: '4px solid #cccccc', borderRadius: '15px'}}>
                  <img
                      style={{ paddingLeft: '40px', width: 310, height: '240px' }}
                      src={UncollectedIcon}
                      alt="img"
                  />
                  <p
                      style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: 22,
                      }}>
                    가맹점 예치금 충전 미회수
                  </p>
                  <p
                      style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: 41,
                        color: '#6699ff',
                      }}>
                    {comma(this.state.misuAmount)} 원
                  </p>
                </div>
              </div>: <div></div>
              }
            </div>

          </div>
        </FormItem>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginInfo: state.login.loginInfo,
  };
};

export default withRouter(connect(mapStateToProps, null)(BranchSettlementInfo));
