import {Button, DatePicker, Table} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { comma } from '../../lib/util/numberUtil';
import RegistBranchSettlementDialog from '../../components/dialog/RegistBranchSettlementDialog';
import FormItem from "antd/lib/form/FormItem";
import SelectBox from "../../components/input/SelectBox";
import {customAlert} from "../../api/Modals";
import Const from '../../const';

const today = new moment();
const dateFormat = 'YYYY-MM-DD';
const todayString = today.format(dateFormat);
const lastMonthString = today.subtract(1, 'M').format(dateFormat);
const RangePicker = DatePicker.RangePicker;

class BranchSettlementList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      list: [],
      branchNameList: { 0: '전체지점' },
      branchNameValue: 0,
      isDetailSettlementOpen: false,
      isPossibleModify: false,
      startDate: moment(lastMonthString).format('YYYY-MM-01'),
      endDate: moment(lastMonthString).format('YYYY-MM-') + moment(lastMonthString).daysInMonth(),
    };
  }

  componentDidMount() {
    let d = moment(todayString).date();
    if (d >= 1 && d <= Const.branchSettlementPossibleLastDay) {
      this.setState({
        isPossibleModify : true,
      })
    }

    this.getBranchInfo();
    this.getList();
  }

  getBranchInfo = async () => {
    let branchNameList = this.state.branchNameList;
    try {
      const res = await httpPost(httpUrl.branchListInfoExceptAgency, [], {});
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data);
        res.data.branch.map(
            (item) => (branchNameList[item.idx] = item.branchName)
        );
        this.setState({
          branchNameList: branchNameList,
        });
      }
    } catch (error) {
      console.warn('getBranchInfo error: {}', error);
    }
  };

  onChangeDate = (dateString) => {
    this.setState({
      startDate:
          dateString != null
              ? moment(dateString[0]).format('YYYY-MM-01')
              : lastMonthString,
      endDate:
          dateString != null
              ? moment(dateString[1]).format('YYYY-MM-') + moment(dateString[1]).daysInMonth()
              : lastMonthString,
    }, () => this.getList());
  };

  getList = () => {
    const { pagination } = this.state;
    const pageNum = pagination.current;
    const pageSize = pagination.pageSize;
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    const branchIdx = this.state.branchNameValue;
    httpGet(httpUrl.getBranchSettlementList, [startDate, endDate, branchIdx, pageNum, pageSize], {}).then((res) => {
      console.log('res');
      console.log(res);
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      let d = moment(todayString).date();
      this.setState({
        list: res.data.list,
        pagination: pagination,
        isPossibleModify: d >= 1 && d <= Const.branchSettlementPossibleLastDay ? true : false,
      });
    });
  };

  openDetailSettlementDialog = (row) => {
    this.setState({
      isDetailSettlementOpen: true,
      selectedRow: row,
    });
  };

  closeDetailSettlementDialog = () => {
    this.setState({ isDetailSettlementOpen: false }, () => {
      this.getList();
    });
  };

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
        {
          pagination: pager,
        },
        () => this.getList()
    );
  };

  render() {
    const { branchNameList } = this.state;
    const columns = [
      {
        title: '정산 완료 일자',
        dataIndex: 'settlementDate',
        className: 'table-column-center',
        render: (data) => <div>{data && moment(data).format('YYYY-MM')}</div>,
      },
      {
        title: '지점명',
        dataIndex: 'branchIdx',
        className: 'table-column-center',
        render: (data) => <div>{branchNameList[data]}</div>,
      },
      {
        title: '총 매출',
        dataIndex: 'totalSales',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '총 비용',
        dataIndex: 'totalCost',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '스테이션 손익',
        dataIndex: 'branchProfitAndLoss',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '스테이션 실입금액',
        dataIndex: 'branchDepositAmount',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '정산 수정 여부',
        className: 'table-column-center',
        render: (data, row) => (
            <div>
              <Button
                  style={{
                    backgroundColor: this.state.isPossibleModify && moment(row.settlementDate).isAfter(lastMonthString) && row.completeDatetime == null ? 'yellow':'white',
                  }}
                  onClick={() => {
                    this.openDetailSettlementDialog(row);
                  }}>
                {this.state.isPossibleModify && moment(row.settlementDate).isAfter(lastMonthString) && row.completeDatetime == null ? '수정' : '내역 보기'}
                
              </Button>
            </div>
        ),
      },
    ];

    return (
        <FormItem>
          {this.state.isDetailSettlementOpen && (
              <RegistBranchSettlementDialog
                  data={this.state.selectedRow}
                  close={this.closeDetailSettlementDialog}
              />
          )}
          <div style={{fontSize: '20px'}}>
          지점 정산 목록
          </div>
          <div style={{paddingLeft: 1020, display: 'inline-block'}}>
            <p style={{display: 'inline-block', width: '280px'}}> {'등록 및 수정 가능 기간은 매월 1~'+ Const.branchSettlementPossibleLastDay + '일 입니다.' }</p>
            <Button
                style={{
                  backgroundColor: 'black',
                  borderColor: 'black',
                  color: 'white',
                  width: 100,
                }}
                onClick={() => {
                  if (this.state.isPossibleModify) {
                    this.openDetailSettlementDialog(null);
                  } else {
                    customAlert(
                        '정산 등록 불가',
                        '정산 가능 기간이 아닙니다. 매월 1~' + Const.branchSettlementPossibleLastDay + '일에 가능합니다.'
                    );
                  }

                }}
                htmlType="submit">
              등록하기
            </Button>

            <SelectBox
                value={branchNameList[this.state.branchNameValue]}
                code={Object.keys(branchNameList)}
                codeString={branchNameList}
                style={{width: '160px', padding: '10px'}}
                onChange={(value) => {
                  if (parseInt(value) !== this.state.branchNameValue) {
                    this.setState({ branchNameValue: parseInt(value) }, () =>
                        this.getList()
                    );
                  }
                }}
            />
            <RangePicker
              placeholder={['시작일', '종료일']}
              value={[
                moment(this.state.startDate, dateFormat),
                moment(this.state.endDate, dateFormat),
              ]}
              onChange={this.onChangeDate}
              style={{ width: 350 }}
            />
          </div>

        <Table
            rowKey={(record) => record.idx}
            dataSource={this.state.list}
            columns={columns}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
        />
        </FormItem>
    );
  }
}

export default BranchSettlementList;
