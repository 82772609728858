import { Button, Input, Modal, Table } from 'antd';
import { default as React, useEffect, useState } from 'react';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import {
  approvalStatus,
  approveWithdrawResultCode,
  userTypeToString,
} from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';

const WithdrawApprove = (props) => {
  const Search = Input.Search;

  const statusStyles = [{}, { color: 'blue' }, { color: 'red' }];

  const [list, setList] = useState([]);
  const [searchString, setSearchString] = useState('');

  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });

  useEffect(async () => {
    await getWithdrawApprovalList();
  }, []);

  useEffect(async () => {
    await getWithdrawApprovalList();
  }, [pagination.current]);

  const columns = [
    {
      title: '승인번호',
      dataIndex: 'idx',
      className: 'table-column-center',
    },
    {
      title: '출금요청관리자아이디',
      dataIndex: 'adminId',
      className: 'table-column-center',
    },
    {
      title: '출금대상구분',
      dataIndex: 'userType',
      className: 'table-column-center',
      render: (data) => (data > 2 || data < 1 ? '' : userTypeToString[data]),
    },
    {
      title: '출금대상',
      dataIndex: 'userType',
      className: 'table-column-center',
      render: (data, row) => (data == 1 ? row.riderName : row.frName),
    },
    {
      title: '대표자명(가맹점)',
      dataIndex: 'ownerName',
      className: 'table-column-center',
      render: (data, row) => (row.userType == 2 ? data : '-'),
    },
    {
      title: '출금계좌',
      dataIndex: 'bank',
      className: 'table-column-center',
      render: (data, row) => {
        const [bank, code] = data.split(',');
        return `${bank} / ${row.bankAccount}`;
      },
    },
    {
      title: '출금자명',
      dataIndex: 'depositor',
      className: 'table-column-center',
    },
    {
      title: '주민등록번호/사업자번호',
      dataIndex: 'userType',
      className: 'table-column-center',
      render: (data, row) =>
        data == 1 ? row.registrationNumber : row.businessNumber,
    },
    {
      title: '출금요청액',
      dataIndex: 'rawData',
      className: 'table-column-center',
      render: (data) => {
        const rawData = JSON.parse(data);
        return comma(rawData.amount);
      },
    },
    {
      title: '승인상태',
      dataIndex: 'status',
      className: 'table-column-center',
      render: (data) => (
        <div style={{ fontWeight: 'bold', ...statusStyles[data] }}>
          {approvalStatus[data]}
        </div>
      ),
    },
    {
      title: '출금처리',
      dataIndex: 'status',
      className: 'table-column-center',
      render: (data, row) =>
        data === 0 ? (
          <>
            <Button onClick={async () => await onClickApprove(row.idx)}>
              출금승인
            </Button>

            <Button onClick={async () => await onClickReject(row.idx)}>
              출금거절
            </Button>
          </>
        ) : (
          '-'
        ),
    },
  ];

  const getWithdrawApprovalList = async () => {
    const res = await httpGet(httpUrl.getApprovalList, [
      'WITHDRAW_REQ',
      pagination.current,
      pagination.pageSize,
      searchString,
    ]);

    if (res.result !== 'SUCCESS') {
      Modal.warn({
        title: '조회실패',
        content: '출금승인요청 조회에 실패했습니다.',
      });
      return;
    }

    setList(res.data.approval);
    setPagination({
      ...pagination,
      total: res.data.totalCount,
    });
  };

  const onClickApprove = async (approvalIdx) => {
    Modal.confirm({
      title: '출금승인',
      content: '출금을 승인하시겠습니까?',
      onOk: async () => await approveWithdraw(approvalIdx),
      onCancel: () => {},
    });
  };

  const approveWithdraw = async (approvalIdx) => {
    try {
      const res = await httpPost(httpUrl.approveWithdraw, [], {
        approvalIdx,
      });

      console.log(res);

      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '출금처리성공',
          content: '출금처리에 성공했습니다.',
        });
        await getWithdrawApprovalList();
      } else if (res.data === 'ALREADY_HANDLED_APPROVAL') {
        Modal.warn({
          title: '출금처리실패',
          content: approveWithdrawResultCode[res.data],
        });
        await getWithdrawApprovalList();
      } else {
        Modal.warn({
          title: '출금처리실패',
          content: approveWithdrawResultCode[res.data],
        });
      }
    } catch (e) {
      Modal.warn({
        title: '출금처리실패',
        content: '출금처리에 실패했습니다. 관리자에게 문의하세요.',
      });
    }
  };

  const onClickReject = async (approvalIdx) => {
    Modal.confirm({
      title: '출금거절',
      content: '출금을 거절하시겠습니까?',
      onOk: async () => await rejectWithdraw(approvalIdx),
      onCancel: () => {},
    });
  };

  const rejectWithdraw = async (approvalIdx) => {
    try {
      const res = await httpPost(httpUrl.rejectWithdraw, [], {
        approvalIdx,
      });

      console.log(res);

      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '출금거절처리성공',
          content: '출금거절처리에 성공했습니다.',
        });
        await getWithdrawApprovalList();
      } else if (
        res.data === 'ALREADY_HANDLED_APPROVAL' ||
        res.data === 'APPROVAL_NOT_EXIST'
      ) {
        Modal.warn({
          title: '출금거절처리실패',
          content: approveWithdrawResultCode[res.data],
        });
        await getWithdrawApprovalList();
      } else {
        Modal.warn({
          title: '출금거절처리실패',
          content: approveWithdrawResultCode[res.data],
        });
      }
    } catch (e) {
      Modal.warn({
        title: '출금처리실패',
        content: '출금처리에 실패했습니다. 관리자에게 문의하세요.',
      });
    }
  };

  return (
    <>
      <Search
        placeholder="아이디/가맹점명/라이더명/사업자번호 검색"
        enterButton
        allowClear
        onChange={(e) => setSearchString(e.target.value)}
        onSearch={async () => await getWithdrawApprovalList()}
        style={{
          width: 500,
          marginBottom: 20,
        }}
      />

      <Table
        rowKey={(record) => record.idx}
        dataSource={list}
        columns={columns}
        pagination={pagination}
        onChange={(pagination) => setPagination(pagination)}
      />
    </>
  );
};

export default WithdrawApprove;
