import { DatePicker, Input, Table } from 'antd';
import React, { Component } from 'react';
import '../../css/main.css';
import {httpGet, httpUrl} from "../../api/httpClient";
import {customError} from "../../api/Modals";
import moment from "moment";

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

class RiderLocationProvide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      list: [],
      userId: '',
      startDate: new moment().format('YYYY-MM-DD'),
      endDate: new moment().format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
      const { pagination, startDate } = this.state;
      const userId = this.state.userId;
      const pageSize = pagination.pageSize;
      const pageNum = pagination.current;
      const endDate = moment(this.state.endDate).add(1, 'd').format("YYYY-MM-DD");
      httpGet(httpUrl.getProvideRiderLocationInfo, [userId, pageSize, pageNum, startDate, endDate], {})
          .then((res) => {
              const pagination = { ...this.state.pagination };
              pagination.current = res.data.currentPage;
              pagination.total = res.data.totalCount;
              this.setState(
                  {
                      list: res.data.list,
                      pagination,
                  },
              );
          })
          .catch((e) => {
              customError('목록 에러', '에러가 발생하여 목록을 불러올수 없습니다.');
          });

  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onSearch = (value) => {
    this.setState({
            userId: value,
            pagination: {
                current: 1,
                pageSize: 10,
            },
        },
        () => {
            this.getList();
        }
    );
  };

  onChangeDate = (dateString) => {
      this.setState({
              startDate:
                    dateString != null
                        ? moment(dateString[0]).format('YYYY-MM-DD')
                        : new moment('2021-07-01').format('YYYY-MM-DD'),
                endDate:
                    dateString != null
                        ? moment(dateString[1]).format('YYYY-MM-DD')
                        : new moment().format('YYYY-MM-DD'),
                pagination: {
                    current: 1,
                    pageSize: 10,
                },
            },
            () => {
                this.getList();
            }
        );
  };

  render() {
    const columns = [
      {
        title: '접속 일자',
        dataIndex: 'loginDate',
        className: 'table-column-center',
        render: (data) => {
          const formatDate = moment(data).format("YYYY-MM-DD HH:mm:ss");
          const date = formatDate.split(' ')[0];
          return <div>{date}</div>;
        },
      },
      {
        title: '접속시간',
        dataIndex: 'loginDate',
        className: 'table-column-center',
        render: (data) => {
          const formatDate = moment(data).format("YYYY-MM-DD HH:mm:ss");
          const date = formatDate.split(' ')[1];
          return <div>{date}</div>;
        },
      },
      {
        title: '고유식별번호(디바이스 ID)',
        dataIndex: 'signupDeviceId',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '취득경로',
        dataIndex: 'osInfo',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '라이더 ID',
        dataIndex: 'userId',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '라이더명',
        dataIndex: 'exactRiderName',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '핸드폰번호',
        dataIndex: 'phone',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '제공서비스',
        className: 'table-column-center',
        render: () => <div>{'라이더앱'}</div>,
      },
      {
        title: '제 3자 제공 서비스',
        className: 'table-column-center',
        render: () => <div>{'-'}</div>,
      },
    ];

    const dateFormat = 'YYYY-MM-DD';
    return (
      <>
        <Search
          placeholder="라이더 ID, 라이더명"
          enterButton
          allowClear
          onSearch={this.onSearch}
          style={{
            width: 220,
            marginBottom: 20,
          }}
        />

        <RangePicker
            placeholder={['시작일', '종료일']}
            value={[
                moment(this.state.startDate, dateFormat),
                moment(this.state.endDate, dateFormat),
            ]}
            onChange={this.onChangeDate}
            style={{ width: 350, float: 'left' }}
        />

        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

export default RiderLocationProvide;
