import { Button, Form, Input, Modal } from 'antd';
import React, { Component } from 'react';
import { httpPost, httpUrl } from '../../api/httpClient';
import { customAlert, customError } from '../../api/Modals';
import '../../css/main.css';
import CloseIcon from '../../img/close.png';
import SelectBox from "../input/SelectBox";
const FormItem = Form.Item;


class CreateBranchShareFeeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      branchNameList: { },
      branchNameValue1: 0,
      branchNameValue2: 0,
      fee: 0,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        branchNameValue1: parseInt(this.props.data.branchIdx1),
        branchNameValue2: parseInt(this.props.data.branchIdx2),
        fee: parseInt(this.props.data.fee),
      })
    }
    this.getBranchInfo();
  }

  getBranchInfo = async () => {
    let branchNameList = this.state.branchNameList;
    try {
      const res = await httpPost(httpUrl.branchListInfo, [], {});
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data);
        res.data.branch.map(
            (item) => (branchNameList[item.idx] = item.branchName)
        );
        this.setState({
          branchNameList: branchNameList,
        });
      }
    } catch (error) {
      console.warn('getBranchInfo error: {}', error);
    }
  };

  handleSubmit = () => {
    let self = this;
    const feeValue = self.formRef.current.getFieldValue('fee');
    if (this.props.data) {
      Modal.confirm({
        title: '수수료 수정',
        content: '수수료 수정을 하시겠습니까?',
        okText: '확인',
        cancelText: '취소',
        onOk() {
          httpPost(httpUrl.updateBranchShareFee, [], {
            branchIdx1: self.state.branchNameValue1,
            branchIdx2: self.state.branchNameValue2,
            fee: feeValue,
          })
          .then((result) => {
            if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
              customAlert(
                  '수수료 수정 완료',
                  '공유 지점 수수료 수정 완료 하였습니다.'
              );
            } else {
              customError(
                  '수수료 수정 에러',
                  '공유 지점 수수료 수정에 실패하였습니다'
              );
            }
          })
          .catch((e) => {
            customError(
                '수수료 수정 에러',
                '서버 에러로 인해 공유 지점 수수료 수정에 실패하였습니다'
            );
          });
        },
      });
    } else {
      if (self.state.branchNameValue1 === 0 ||
          self.state.branchNameValue2 === 0 ||
          feeValue === undefined) {
        customError(
            '수수료 등록 에러',
            '공유 지점 수수료 등록에 실패하였습니다'
        );
        return;
      }
      Modal.confirm({
        title: '수수료 등록',
        content: '수수료 신규 등록 하시겠습니까?',
        okText: '확인',
        cancelText: '취소',
        onOk() {
          httpPost(httpUrl.createBranchShareFee, [], {
            branchIdx1: self.state.branchNameValue1,
            branchIdx2: self.state.branchNameValue2,
            fee: feeValue,
          })
          .then((result) => {
            if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
              customAlert(
                  '수수료 등록 완료',
                  '공유 지점 수수료 등록 완료 하였습니다.'
              );
            }  else if (result.data === 'DUPLICATE') {
              customAlert(
                  '수수료 등록 실패',
                  '이미 존재 하는 공유 지점 수수료입니다.'
              );
            } else {
              customError(
                  '수수료 등록 에러',
                  '공유 지점 수수료 등록에 실패하였습니다'
              );
            }
          })
          .catch((e) => {
            customError(
                '수수료 등록 에러',
                '서버 에러로 인해 공유 지점 수수료 등록에 실패하였습니다'
            );
          });
        },
      });
    }
  };

  render() {
    const { close, data } = this.props;
    const { branchNameList } = this.state;

    return (
        <React.Fragment>
          <div className="Dialog-overlay" onClick={close} />
          <div className="deposit-Dialog">
            <div className="deposit-content">
              <div className="deposit-title">{data ? '지점 공유콜 수수료 수정' : '지점 공유콜 수수료 등록'}</div>
              <img
                  onClick={close}
                  style={{ display: 'inline' }}
                  src={CloseIcon}
                  className="dialog-close"
                  alt="img"
              />
              <div className="deposit-inner">
                <Form ref={this.formRef} onFinish={this.handleSubmit}>
                <div className="contentBlock">
                  <div className="mainTitle">지점1</div>
                  <SelectBox
                      value={branchNameList[this.state.branchNameValue1]}
                      code={Object.keys(branchNameList)}
                      codeString={branchNameList}
                      style={{width: '160px', padding: '10px'}}
                      disabled={this.state.branchNameValue1 > 0 ? true : false}
                      onChange={(value) => {
                        if (parseInt(value) !== this.state.branchNameValue1) {
                          if (value > 0) {
                            this.setState({ branchNameValue1: parseInt(value) });
                          }
                        }
                      }}
                  />
                </div>
                <div className="contentBlock">
                  <div className="mainTitle">지점2</div>
                  <SelectBox
                      value={branchNameList[this.state.branchNameValue2]}
                      code={Object.keys(branchNameList)}
                      codeString={branchNameList}
                      style={{width: '160px', padding: '10px'}}
                      disabled={this.state.branchNameValue2 > 0 ? true : false}
                      onChange={(value) => {
                        if (parseInt(value) !== this.state.branchNameValue2) {
                          if (value > 0) {
                            this.setState({ branchNameValue2: parseInt(value) });
                          }
                        }
                      }}
                  />
                </div>
                <div style={{height: '10px'}}></div>
                <div className="contentBlock">
                  <div className="mainTitle">수수료</div>
                  <div className="formItem">
                    <FormItem
                        name="fee"
                        className="override-input"
                        style={{ width: '160px' }}
                        rules={[
                          {
                            required: true,
                            message: '금액을 입력해주세요',
                          },
                        ]}>
                      <Input
                          style={{ width: '160px' }}
                          type="number"
                          placeholder="금액을 입력해주세요."
                      />
                    </FormItem>
                  </div>
                </div>


                <div style={{height: '20px'}}></div>

                <Button
                    type="primary"
                    onClick={()=>{
                      this.handleSubmit();
                    }}
                    style={{ float: 'right' }}>
                  {data ? ('수정') : ('등록')}
                </Button>
                </Form>
              </div>
            </div>
          </div>
        </React.Fragment>
    );
  }
}

export default CreateBranchShareFeeDialog;
