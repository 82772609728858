import { Button, Form, Input, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { httpPost, httpUrl } from '../../api/httpClient';
import {
  branchError,
  deleteComplete,
  deleteError,
  deleteError2,
  duplicateError,
  registComplete,
  registError,
  registError2,
  typeError,
  updateComplete,
  updateError,
} from '../../api/Modals';
import { bankCode } from '../../lib/util/codeUtil';
import CloseIcon from '../../img/close.png';

const Option = Select.Option;
const FormItem = Form.Item;

class DeleteDeliveryPriceBasic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    // this.getList()
  }

  handleSubmit = () => {
    let self = this;
    Modal.confirm({
      title: '정보 삭제',
      content: '배달비 정보를 삭제하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        try {
          console.log('삭제');
          console.log({ ...self.formRef.current.getFieldsValue() });
          httpPost(httpUrl.deleteDeliveryPriceBasic, [], {
            ...self.formRef.current.getFieldsValue(),
          })
            .then((res) => {
              console.log('res');
              console.log(res);
              if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
                deleteComplete();
                self.props.close();
              } else if (res.result === 'SUCCESS' && res.data === 'NOT_EXIST') {
                deleteError2();
              } else {
                deleteError();
                return;
              }
            })
            .catch((e) => {
              deleteError();
            });
        } catch (error) {
          deleteError();
        }
      },
    });
  };

  render() {
    const { data, close } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="registRider-Dialog">
          <div className="registRider-content">
            <div className="registRider-title">배달비삭제</div>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="registRider-close"
              alt="close"
            />

            <Form ref={this.formRef} onFinish={this.handleSubmit}>
              <div className="registRiderLayout">
                <div className="registRiderBox">
                  <div className="registRiderWrapper">
                    <div className="contentBlock">
                      <div className="mainTitle">지점번호</div>
                      <FormItem
                        name="branchIdx"
                        className="selectItem"
                        rules={[
                          {
                            required: true,
                            message: '지점번호를 입력해주세요',
                          },
                        ]}>
                        <Input className="override-input" />
                      </FormItem>
                    </div>
                    <div className="contentBlock">
                      <div className="mainTitle">그룹번호</div>
                      <FormItem
                        name="groupIdx"
                        className="selectItem"
                        rules={[
                          {
                            required: true,
                            message: '그룹번호를 입력해주세요',
                          },
                        ]}>
                        <Input className="override-input" />
                      </FormItem>
                    </div>
                    <div className="submitBlock">
                      <Button type="primary" htmlType="submit">
                        삭제하기
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DeleteDeliveryPriceBasic;
