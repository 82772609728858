import { Button, Form, Input, Modal, Checkbox } from 'antd';
import React, { Component } from 'react';
import { httpPost, httpUrl } from '../../api/httpClient';
// import ClickSwitch from '../components/input/ClickSwitch';
import '../../css/main.css';
import CloseIcon from '../../img/close.png';

const FormItem = Form.Item;

class CreateControlAgentDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      isModifyMode: false,
      menuAuths: new Set(),
      joinCheck: false,
      orderCheck: false,
      frCheck: false,
      riderCheck: false,
      mallCheck: false,
      ncashCheck: false,
      dayCheck: false,
      accountCheck: false,
      mobileCheck: false,
      taxReturnCheck: false,
      deliverySubsidyCheck: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    // this.getList();
    if (this.props.data) {
      this.formRef.current.setFieldsValue({ ...this.props.data, password: '' });
      this.setState({
        isModifyMode: true,
      });

      if (this.props.data.menuAuths != undefined) {
        for(const auth of this.props.data.menuAuths) {
          this.state.menuAuths.add(auth);
          this.onChangeAuth(auth, true);
        }
      }
    }
  }

  onChangeAuth = (menu, e) => {
    if (e) {
      this.state.menuAuths.add(menu);
    } else {
      this.state.menuAuths.delete(menu);
    }

    if (menu == 2) {
      this.setState({joinCheck: e})
    }
    else if (menu == 3) {
      this.setState({orderCheck: e})
    }
    else if (menu == 4) {
      this.setState({frCheck: e})
    }
    else if (menu == 5) {
      this.setState({riderCheck: e})
    }
    else if (menu == 6) {
      this.setState({mallCheck: e})
    }
    else if (menu == 7) {
      this.setState({ncashCheck: e})
    }
    else if (menu == 8) {
      this.setState({dayCheck: e})
    }
    else if (menu == 9) {
      this.setState({accountCheck: e})
    }
    else if (menu == 11) {
      this.setState({mobileCheck: e})
    }
    else if (menu == 12) {
      this.setState({taxReturnCheck: e})
    }
    else if (menu == 101) {
      this.setState({deliverySubsidyCheck: e})
    }
  }

  // getList = () => {
  //   httpGet(
  //     httpUrl.getBranchList,
  //     [this.state.pagination.current, this.state.pagination.pageSize],
  //     {}
  //   ).then((res) => {
  //     console.log(res);
  //     if (res.result === "SUCCESS" && res.data.branch.length > 0) {
  //       this.setState(
  //         {
  //           list: res.data.branch,
  //           pagination: {
  //             ...this.state.pagination,
  //             total: res.data.totalCount,
  //           },
  //         },
  //         () => console.log(this.state)
  //       );
  //     }
  //   });
  // };

  handleSubmit = () => {
    let { branchIdx, id, password, phone, riderName } =
      this.formRef.current.getFieldsValue();
    const menuAuthArr = [...this.state.menuAuths];
    httpPost(
      this.state.isModifyMode
        ? httpUrl.updateControlAgent
        : httpUrl.createControlAgent,
      [],
      this.state.isModifyMode
        ? {
            idx: this.props.data.idx,
            branchIdx,
            id,
            password,
            phone: phone.split('-').join(''),
            riderName,
            menuAuths: menuAuthArr,
          }
        : {
            branchIdx,
            id,
            password,
            phone: phone.split('-').join(''),
            riderName,
            menuAuths: menuAuthArr,
          }
    ).then((res) => {
      console.log(res);
      if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
        Modal.info({
          title: '등록성공',
          content: '관제요원 등록에 성공했습니다.',
        });
        this.props.getList();
        this.props.close();
      } else if (res.data === 'DUPLICATED_ID') {
        Modal.warn({
          title: '등록실패',
          content: '중복된 아이디입니다.',
        });
      } else {
        Modal.warn({
          title: '등록실패',
          content: '관제요원 등록에 실패했습니다.',
        });
      }
    });
  };

  render() {
    const { close } = this.props;

    // const columns = [
    //   {
    //     title: "지점번호",
    //     dataIndex: "idx",
    //     className: "table-column-center",
    //     width: "30%",
    //     render: (data) => (
    //       <div onClick={() => this.handleSelectBranch(data)}>{data}</div>
    //     ),
    //   },
    //   {
    //     title: "지점명",
    //     dataIndex: "branchName",
    //     className: "table-column-center",
    //     width: "70%",
    //     render: (data, row) => (
    //       <div onClick={() => this.handleSelectBranch(row.idx)}>{data}</div>
    //     ),
    //   },
    // ];

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="systemKey-Dialog">
          <div className="systemKey-content">
            <div className="systemKey-title">
              {this.state.isModifyMode ? '관제요원 수정' : '관제요원 생성'}
            </div>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="dialog-close"
              alt="img"
            />

            <div className="systemKey-inner">
              <Form
                ref={this.formRef}
                onFinish={this.handleSubmit}
                className="create-control-form">
                {/* <div className="create-control-side-divider"> */}
                <div className="create-control-form-item-title">지점번호</div>
                <FormItem
                  name="branchIdx"
                  className="create-control-form-item"
                  rules={[
                    {
                      required: true,
                      message: '지점번호를 입력해주세요',
                    },
                  ]}>
                  <Input
                    className="create-control-input"
                    placeholder="지점번호"
                    // disabled
                  />
                </FormItem>

                <div className="create-control-form-item-title">아이디</div>
                <FormItem
                  name="id"
                  className="create-control-form-item"
                  rules={[
                    {
                      required: true,
                      message: '아이디를 입력해주세요',
                    },
                  ]}>
                  <Input
                    className="create-control-input"
                    placeholder="아이디"
                  />
                </FormItem>

                {this.state.isModifyMode && (
                  <p
                    style={{
                      marginLeft: '30%',
                      marginBottom: 0,
                      color: 'red',
                    }}>
                    * 정보 수정시 비밀번호를 입력하지 않으면 기존 비밀번호로
                    유지됩니다.
                  </p>
                )}
                <div className="create-control-form-item-title">비밀번호</div>
                <FormItem
                  name="password"
                  className="create-control-form-item"
                  rules={[
                    {
                      required: this.state.isModifyMode ? false : true,
                      message: '비밀번호를 입력해주세요',
                    },
                  ]}>
                  <Input
                    type="password"
                    className="create-control-input"
                    placeholder="비밀번호"
                  />
                </FormItem>
                <div className="create-control-form-item-title">성명</div>
                <FormItem
                  name="riderName"
                  className="create-control-form-item"
                  rules={[{ required: true, message: '성명을 입력해주세요' }]}>
                  <Input className="create-control-input" placeholder="성명" />
                </FormItem>

                <div className="create-control-form-item-title">전화번호</div>
                <FormItem
                  name="phone"
                  className="create-control-form-item"
                  rules={[
                    { required: true, message: '전화번호를 입력해주세요' },
                  ]}>
                  <Input
                    className="create-control-input"
                    placeholder="전화번호"
                  />
                </FormItem>

                <div style={{borderTop : 'solid 1px', width: '1000px', paddingTop: '10px'}}></div>
                <div className="create-control-form-item-title">권한부여</div>
                <FormItem
                  className="create-control-form-item">
                  <div>
                    <div
                      style={{
                        alignContent: "center",
                        display: "inline-block",
                        width: "80px"
                      }}>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "620px",
                        textAlign: "left",
                        paddingLeft: "20px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: this.state.mobileCheck ? "yellow" : "lightgray"
                      }}>
                      <p
                        style={{
                          paddingTop: "10px",
                          display: "inline-block",
                          width: "50%"
                        }}>
                        모바일 관제
                      </p>
                      <Checkbox
                        style={{ paddingLeft: "35%" }}
                        checked={this.state.mobileCheck}
                        onChange={(e) => {
                          this.onChangeAuth(11, e.target.checked);
                        }}
                      >
                        <p
                          style={{
                            display: "inline-block"
                          }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                  </div>
                  <div style={{ height: "10px" }}></div>
                  <div>
                    <div
                      style={{
                        alignContent: "center",
                        display: "inline-block",
                        width: "80px"
                      }}>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "620px",
                        textAlign: "left",
                        paddingLeft: "20px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: this.state.joinCheck ? "yellow" : "lightgray"
                      }}>
                      <p
                        style={{
                          paddingTop: "10px",
                          display: "inline-block",
                          width: "50%"
                        }}>
                        가입/탈퇴 통계
                      </p>
                      <Checkbox
                        style={{ paddingLeft: "35%" }}
                        checked={this.state.joinCheck}
                        onChange={(e) => {
                          this.onChangeAuth(2, e.target.checked);
                        }}
                      >
                        <p
                          style={{
                            display: "inline-block"
                          }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                  </div>

                  <div style={{ height: "10px" }}></div>
                  <div>
                    <div
                      style={{
                        alignContent: "center",
                        display: "inline-block",
                        width: "80px"
                      }}>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "620px",
                        textAlign: "left",
                        paddingLeft: "20px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: this.state.orderCheck ? "yellow" : "lightgray"
                      }}>
                      <p
                        style={{
                          paddingTop: "10px",
                          display: "inline-block",
                          width: "50%"
                        }}>
                        주문 통계
                      </p>
                      <Checkbox
                        style={{ paddingLeft: "35%" }}
                        checked={this.state.orderCheck}
                        onChange={(e) => {
                          this.onChangeAuth(3, e.target.checked);
                        }}
                      >
                        <p
                          style={{
                            display: "inline-block"
                          }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                  </div>

                  <div style={{ height: "10px" }}></div>
                  <div>
                    <div
                      style={{
                        alignContent: "center",
                        display: "inline-table",
                        width: "80px"
                      }}>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "620px",
                        textAlign: "left",
                        paddingLeft: "20px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: this.state.frCheck ? "yellow" : "lightgray"
                      }}>
                      <p
                        style={{
                          paddingTop: "10px",
                          display: "inline-block",
                          width: "50%"
                        }}>
                        가맹점 관리
                      </p>
                      <Checkbox
                        style={{ paddingLeft: "35%" }}
                        checked={this.state.frCheck}
                        onChange={(e) => {
                          this.onChangeAuth(4, e.target.checked);
                        }}
                      >
                        <p
                          style={{
                            display: "inline-block"
                          }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                  </div>

                  <div style={{ height: "10px" }}></div>
                  <div>
                    <div
                      style={{
                        alignContent: "center",
                        display: "inline-block",
                        width: "80px"
                      }}>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "620px",
                        textAlign: "left",
                        paddingLeft: "20px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: this.state.riderCheck ? "yellow" : "lightgray"
                      }}>
                      <p
                        style={{
                          paddingTop: "10px",
                          display: "inline-block",
                          width: "50%"
                        }}>
                        기사 관리
                      </p>
                      <Checkbox
                        style={{ paddingLeft: "35%" }}
                        checked={this.state.riderCheck}
                        onChange={(e) => {
                          this.onChangeAuth(5, e.target.checked);
                        }}
                      >
                        <p
                          style={{
                            display: "inline-block"
                          }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                  </div>

                  <div style={{ height: "10px" }}></div>
                  <div>
                    <div
                      style={{
                        alignContent: "center",
                        display: "inline-block",
                        width: "80px"
                      }}>
                      (직영점용)
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "620px",
                        textAlign: "left",
                        paddingLeft: "20px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: this.state.mallCheck ? "yellow" : "lightgray"
                      }}>
                      <p
                        style={{
                          paddingTop: "10px",
                          display: "inline-block",
                          width: "50%"
                        }}>
                        상품관리
                      </p>
                      <Checkbox
                        style={{ paddingLeft: "35%" }}
                        checked={this.state.mallCheck}
                        onChange={(e) => {
                          this.onChangeAuth(6, e.target.checked);
                        }}
                      >
                        <p
                          style={{
                            display: "inline-block"
                          }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                  </div>

                  <div style={{ height: "10px" }}></div>
                  <div>
                    <div
                      style={{
                        alignContent: "center",
                        display: "inline-block",
                        width: "80px"
                      }}>
                      (대리점용)
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "620px",
                        textAlign: "left",
                        paddingLeft: "20px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: this.state.ncashCheck ? "yellow" : "lightgray"
                      }}>
                      <p
                        style={{
                          paddingTop: "10px",
                          display: "inline-block",
                          width: "50%"
                        }}>
                        예치금 관리
                      </p>
                      <Checkbox
                        style={{ paddingLeft: "35%" }}
                        checked={this.state.ncashCheck}
                        onChange={(e) => {
                          this.onChangeAuth(7, e.target.checked);
                        }}
                      >
                        <p
                          style={{
                            display: "inline-block"
                          }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                  </div>

                  <div style={{ height: "10px" }}></div>
                  <div>
                    <div
                      style={{
                        alignContent: "center",
                        display: "inline-block",
                        width: "80px"
                      }}>
                      (대리점용)
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "620px",
                        textAlign: "left",
                        paddingLeft: "20px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: this.state.dayCheck ? "yellow" : "lightgray"
                      }}>
                      <p
                        style={{
                          paddingTop: "10px",
                          display: "inline-block",
                          width: "50%"
                        }}>
                        일차감
                      </p>
                      <Checkbox
                        style={{ paddingLeft: "35%" }}
                        checked={this.state.dayCheck}
                        onChange={(e) => {
                          this.onChangeAuth(8, e.target.checked);
                        }}
                      >
                        <p
                          style={{
                            display: "inline-block"
                          }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                  </div>

                  <div style={{ height: "10px" }}></div>
                  <div>
                    <div
                      style={{
                        alignContent: "center",
                        display: "inline-block",
                        width: "80px"
                      }}>
                      (대리점용)
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "620px",
                        textAlign: "left",
                        paddingLeft: "20px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: this.state.accountCheck ? "yellow" : "lightgray"
                      }}>
                      <p
                        style={{
                          paddingTop: "10px",
                          display: "inline-block",
                          width: "50%"
                        }}>
                        계정 관리
                      </p>
                      <Checkbox
                        style={{ paddingLeft: "35%" }}
                        checked={this.state.accountCheck}
                        onChange={(e) => {
                          this.onChangeAuth(9, e.target.checked);
                        }}
                      >
                        <p
                          style={{
                            display: "inline-block"
                          }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                  </div>
                  <div style={{ height: "10px" }}></div>
                  <div>
                    <div
                      style={{
                        alignContent: "center",
                        display: "inline-block",
                        width: "80px"
                      }}>
                      (대리점용)
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "620px",
                        textAlign: "left",
                        paddingLeft: "20px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: this.state.taxReturnCheck ? "yellow" : "lightgray"
                      }}>
                      <p
                        style={{
                          paddingTop: "10px",
                          display: "inline-block",
                          width: "50%"
                        }}>
                        세금신고
                      </p>
                      <Checkbox
                        style={{ paddingLeft: "35%" }}
                        checked={this.state.taxReturnCheck}
                        onChange={(e) => {
                          this.onChangeAuth(12, e.target.checked);
                        }}
                      >
                        <p
                          style={{
                            display: "inline-block"
                          }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                  </div>
                  <div style={{ height: "10px" }}></div>
                  <div>
                    <div
                        style={{
                          alignContent: "center",
                          display: "inline-block",
                          width: "80px"
                        }}>
                      (대리점용)
                    </div>
                    <div
                        style={{
                          display: "inline-block",
                          width: "620px",
                          textAlign: "left",
                          paddingLeft: "20px",
                          border: "1px solid",
                          borderRadius: "10px",
                          backgroundColor: this.state.deliverySubsidyCheck ? "yellow" : "lightgray"
                        }}>
                      <p
                          style={{
                            paddingTop: "10px",
                            display: "inline-block",
                            width: "50%"
                          }}>
                        배달지원금
                      </p>
                      <Checkbox
                          style={{ paddingLeft: "35%" }}
                          checked={this.state.deliverySubsidyCheck}
                          onChange={(e) => {
                            this.onChangeAuth(101, e.target.checked);
                          }}
                      >
                        <p
                            style={{
                              display: "inline-block"
                            }}>
                          권한부여
                        </p>
                      </Checkbox>
                    </div>
                  </div>
                </FormItem>

                <Button
                  type="primary"
                  htmlType="submit"
                  className="create-control-submit-btn"
                  style={{ width: '100%' }}>
                  등록하기
                </Button>
                {/* </div> */}
                {/* <div className="create-control-side-divider">
                  <Table
                    rowKey={(record) => record.idx}
                    rowClassName="create-control-table-row"
                    dataSource={this.state.list}
                    columns={columns}
                    style={{ marginLeft: 20 }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 10,
                    }}
                  >
                    <Pagination
                      simple
                      current={this.state.pagination.current}
                      total={this.state.pagination.total}
                      onChange={this.handleTableChange}
                    />
                  </div>
                </div> */}
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateControlAgentDialog;
