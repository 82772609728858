import { Button, Input, Modal, Radio, Select, Spin } from 'antd';
import {
  CloseOutlined,
  DownOutlined,
  SearchOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import FileSaver from 'file-saver';
import { comma } from '../../lib/util/numberUtil';
import moment from 'moment/moment';
import Const from '../../const';
import { allBranchList } from '../../lib/util/agencyUtil';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from '../../const';

const AgencyTaxReturnShareCall = (props) => {
  const loginInfo = reactLocalStorage.getObject(con.appName + '#adminUser');
  const CancelToken = axios.CancelToken;
  const source = useRef(null);
  const startDate = props.date.startDate;
  const endDate = props.date.endDate;
  const Option = Select.Option;
  const [branches] = useState(allBranchList());
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [mainList, setMainList] = useState([]);
  const [originDetailList, setOriginDetailList] = useState([]);
  const [detailList, setDetailList] = useState([]);
  const [detailOpen, setDetailOpen] = useState(false);
  const [onDownlaodModal, setOnDownlaodModal] = useState(false);
  const [downloadType, setDownloadType] = useState(0);
  const [belongBranch, setBelongBranch] = useState(loginInfo.branch.companyId);
  const [listType, setListType] = useState('매출');
  const [selectBranchIdx, setSelectBranchIdx] = useState('');
  const [selectIndex, setSelectIndex] = useState('');
  const [filterDate, setFilterDate] = useState({
    start: props.date.startDate,
    end: props.date.endDate,
  });
  const [moreBtn, setMoreBtn] = useState(false);

  const [main, setMain] = useState({
    totalDeliveryPrice: 0,
    totalDeliveryPriceVat: 0,
    totalWholeRiderFee: 0,
    totalShareCallFee: 0,
    totalShareCallFeeVat: 0,
    totalShareCallDeliveryPrice: 0,
    totalShareCallDeliveryPriceVat: 0,
  });
  const [detail, setDetail] = useState({
    totalDeliveryPrice: Number(0),
    totalDeliveryPriceVat: Number(0),
    totalRiderDeliveryFee: Number(0),
    totalShareCallFee: Number(0),
    totalShareCallFeeVat: Number(0),
    totalShareCallDeliveryPrice: Number(0),
    totalShareCallDeliveryPriceVat: Number(0),
  });
  useEffect(() => {
    setFilterDate({
      start: props.date.startDate,
      end: props.date.endDate,
    });
  }, [props.date.startDate, props.date.endDate, selectBranchIdx]);

  useEffect(async () => {
    await getData();
    setDetailList([]);
    setSelectBranchIdx('');
    setDetailOpen(false);
    setSelectIndex('');
    return () => {
      if (source.current !== null) {
        source.current.cancel();
      }
    };
  }, [belongBranch, props.searchBtn, listType]);

  useEffect(() => {
    setDetailList([]);
    setDetail({
      totalDeliveryPrice: Number(0),
      totalDeliveryPriceVat: Number(0),
      totalRiderDeliveryFee: Number(0),
      totalShareCallFee: Number(0),
      totalShareCallFeeVat: Number(0),
      totalShareCallDeliveryPrice: Number(0),
      totalShareCallDeliveryPriceVat: Number(0),
    });
  }, [selectBranchIdx]);

  const getDataFromMongodb = async (customParams, url) => {
    if (source.current !== null) {
      source.current.cancel();
    }
    source.current = CancelToken.source();
    try {
      return await axios.get(url, {
        baseURL: Const.settlementUrl,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: customParams,
        cancelToken: source.current.token,
      });
    } catch (e) {
      console.error('getting ncash-deposit-list is fail: ' + e);
    }
  };

  const getData = async (reset) => {
    if (reset) {
      setSelectBranchIdx('');
      setSelectIndex('');
      setDetailOpen(false);
    }
    if (isLoading) return;
    setIsLoading(true);
    let branch = [...branches];
    let branchCompanyId = [];
    if (
      belongBranch === loginInfo.branch.companyId ||
      belongBranch === '대리점 전체'
    ) {
      branch.forEach((value, index) => {
        if (value.companyId !== loginInfo.branch.companyId)
          branchCompanyId.push(value.companyId);
      });
    } else branchCompanyId = [belongBranch];
    let customParams = {
      startDate: startDate,
      endDate: endDate,
      companyId: branchCompanyId.join(),
    };
    if (search !== '' || search != null) customParams.keyword = search;
    const url =
      listType === '매출'
        ? '/tax-return-v2/share-call/sales'
        : '/tax-return-v2/share-call/cost';
    const datas = await getDataFromMongodb(customParams, url);
    if (datas.data) {
      if (listType === '매출') {
        setMainList(() => {
          return datas.data.taxShareCallSalesList;
        });
      } else {
        setMainList(() => {
          return datas.data.taxShareCallCostList;
        });
      }
      setMain({
        totalDeliveryPrice: datas.data.totalDeliveryPrice,
        totalDeliveryPriceVat: datas.data.totalDeliveryPriceVat,
        totalWholeRiderFee: datas.data.totalWholeRiderFee,
        totalShareCallFee: datas.data.totalShareCallFee,
        totalShareCallFeeVat: datas.data.totalShareCallFeeVat,
        totalShareCallDeliveryPrice: datas.data.totalShareCallDeliveryPrice,
        totalShareCallDeliveryPriceVat:
          datas.data.totalShareCallDeliveryPriceVat,
      });
    }
    setIsLoading(false);
  };
  const getDetailData = async (branchIdx, propsDate) => {
    if (isLoading) return;
    setIsLoading(true);
    let branch = [...branches];
    let branchCompanyId = [];
    if (
      belongBranch === loginInfo.branch.companyId ||
      belongBranch === '대리점 전체'
    ) {
      branch.forEach((value, index) => {
        if (value.companyId !== loginInfo.branch.companyId)
          branchCompanyId.push(value.companyId);
      });
    } else branchCompanyId = [belongBranch];
    let propsBranchIdx = branchIdx;
    let selectStartDate = '';
    let selectEndDate = '';
    let newStartDate = '';

    if (propsDate) {
      selectStartDate = propsDate.start;
      selectEndDate = propsDate.end;
      newStartDate = propsDate.start;
    } else {
      newStartDate = moment(endDate)
        .subtract(6, 'days')
        .format('YYYY-MM-DD');
      if (moment(endDate).diff(moment(startDate), 'days') < 7) {
        selectStartDate = startDate;
        selectEndDate = endDate;
      } else {
        selectStartDate = newStartDate;
        selectEndDate = endDate;
      }
    }

    let customParams = {
      startDate: selectStartDate,
      endDate: selectEndDate,
      targetBranchIdx: branchIdx,
      companyId: branchCompanyId.join(),
    };

    const url =
      listType === '매출'
        ? '/tax-return-v2/share-call/sales/detail'
        : '/tax-return-v2/share-call/cost/detail';

    const datas = await getDataFromMongodb(customParams, url);

    let list = [];
    if (datas.data) {
      list =
        listType === '매출'
          ? datas.data.taxShareCallSalesDetails
          : datas.data.taxShareCallCostDetails;
    }

    if (list.length > 0) onCalculator(list);

    let start1 = moment(newStartDate).subtract(7, 'd').format('YYYY-MM-DD');
    let start2 = moment(newStartDate).subtract(1, 'd').format('YYYY-MM-DD');

    if (moment(start1).isBefore(startDate)) {
      start1 = startDate;
    }
    if (moment(start2).isBefore(startDate)) {
      start2 = startDate;
    }

   let filterDate2 = {
      start: start1,
      end: start2,
    };

    if (moment(newStartDate).isBefore(startDate)) {
      filterDate2.end = startDate;
    }

    if (moment(startDate).isBefore(selectStartDate)) {
      if (list.length < 1) getListAgain(propsBranchIdx, filterDate2);
      else setMoreBtn(true);
    } else setMoreBtn(false);

    setIsLoading(false);
    setFilterDate(filterDate2);
  };

  const getListAgain = (propsBranchIdx, propsDate) => {
    if (moment(propsDate.end).isSameOrBefore(startDate)) return;
    getDetailData(propsBranchIdx, propsDate);
  };

  const onCalculator = (list, propsBranchIdx) => {
    let cal_branchIdx = selectBranchIdx;
    if (cal_branchIdx == null || cal_branchIdx != propsBranchIdx) {
      cal_branchIdx = propsBranchIdx;
    }
    const totalDeliveryPrice = list.reduce(
      (sum, current) => sum + current.deliveryPrice,
      0
    );
    const totalDeliveryPriceVat = list.reduce(
      (sum, current) => sum + current.deliveryPriceVat,
      0
    );
    const totalRiderDeliveryFee = list.reduce(
      (sum, current) => sum + current.riderDeliveryFee,
      0
    );
    const totalShareCallFee = list.reduce(
      (sum, current) => sum + current.shareCallFee,
      0
    );
    const totalShareCallFeeVat = list.reduce(
      (sum, current) => sum + current.shareCallFeeVat,
      0
    );
    const totalShareCallDeliveryPrice = list.reduce(
      (sum, current) => sum + current.shareCallDeliveryPrice,
      0
    );
    const totalShareCallDeliveryPriceVat = list.reduce(
      (sum, current) => sum + current.shareCallDeliveryPriceVat,
      0
    );
    if (cal_branchIdx === propsBranchIdx) {
      setDetailList((detail) => {
        return detail.concat(list);
      });
    } else setDetailList(list);

    setDetail((detail) => {
      return {
        totalDeliveryPrice: detail.totalDeliveryPrice + totalDeliveryPrice,
        totalDeliveryPriceVat:
          detail.totalDeliveryPriceVat + totalDeliveryPriceVat,
        totalRiderDeliveryFee:
          detail.totalRiderDeliveryFee + totalRiderDeliveryFee,
        totalShareCallFee: detail.totalShareCallFee + totalShareCallFee,
        totalShareCallFeeVat:
          detail.totalShareCallFeeVat + totalShareCallFeeVat,
        totalShareCallDeliveryPrice:
          detail.totalShareCallDeliveryPrice + totalShareCallDeliveryPrice,
        totalShareCallDeliveryPriceVat:
          detail.totalShareCallDeliveryPriceVat +
          totalShareCallDeliveryPriceVat,
      };
    });
  };

  const mainColumns = [
    '구분',
    '가맹점 소속 대리점',
    '배달비(건별가맹비 포함)',
    '배달비 부가세',
    '배달비 수수료',
    '공유콜 수수료',
    '공유콜 수수료 부가세',
    '공유콜 배달비',
    '공유콜 배달비 매출 부가세',
    '',
  ];
  const detailColumns = [
    '일자',
    '주문번호',
    '가맹점 소속 대리점',
    '가맹점명',
    '라이더 소속 대리점',
    '라이더명',
    '배달비',
    '배달비 부가세',
    '배달비 수수료',
    '공유콜 수수료',
    '공유콜 수수료 부가세',
    '공유콜 배달비',
    '공유콜 배달비 매출 부가세',
    '',
  ];

  const getExcelDataFromMongodb = async (params, url) => {
    if (source.current !== null) {
      source.current.cancel();
    }
    source.current = CancelToken.source();
    try {
      return await axios.get(url, {
        baseURL: Const.settlementUrl,
        params: params,
        responseType: 'arraybuffer',
        cancelToken: source.current.token,
      });
    } catch (e) {
      console.error('getting tax-invoice-excel-data is failed: ' + e);
    }
  };

  const onDownloadContent = async () => {
    if (isLoading) return;
    setIsLoading(true);
    let branch = [...branches];
    let branchCompanyId = [];
    if (
      belongBranch === loginInfo.branch.companyId ||
      belongBranch === '대리점 전체'
    ) {
      branch.forEach((value, index) => {
        if (value.companyId !== loginInfo.branch.companyId)
          branchCompanyId.push(value.companyId);
      });
    } else branchCompanyId = [belongBranch];
    let params = {};
    let url = '';
    let str = '';
    if (downloadType === 0) {
      if (listType === '매출') {
        params = {
          startDate: startDate,
          endDate: endDate,
          companyId: branchCompanyId.join(),
        };
        url = '/tax-return-v2/share-call/sales/excel';
        str = '전체내역';
      } else {
        params = {
          startDate: startDate,
          endDate: endDate,
          companyId: branchCompanyId.join(),
        };
        url = '/tax-return-v2/share-call/cost/excel';
        str = '전체내역';
      }
    } else {
      if (listType === '매출') {
        params = {
          startDate: startDate,
          endDate: endDate,
          targetBranchIdx: selectBranchIdx,
          companyId: branchCompanyId.join(),
        };
        url = '/tax-return-v2/share-call/sales/detail/excel';
        str = '상세내역';
      } else {
        params = {
          startDate: startDate,
          endDate: endDate,
          targetBranchIdx: selectBranchIdx,
          companyId: branchCompanyId.join(),
        };
        url = '/tax-return-v2/rider/detail/excel';
        str = '상세내역';
      }
    }
    if (downloadType === 0 && (search !== null || search !== ''))
      params.keyword = search;
    const response = await getExcelDataFromMongodb(params, url);
    if (response) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(
        blob,
        `${str}_${moment(props.startDate).format('YYMMDD')}_${moment(
          props.endDate
        ).format('YYMMDD')}.xlsx`
      );
      Modal.success({
        content: '엑셀파일 다운로드가 완료되었습니다.',
      });
      setIsLoading(false);
      setOnDownlaodModal(false);
    } else {
      Modal.error({
        content: '다운로드에 실패했습니다. 관리자에게 문의해주세요.',
      });
    }
    setIsLoading(false);
    setOnDownlaodModal(false);
  };

  const onClickBranch = (branchIdx, index) => {
    setDetailOpen(true);
    setSelectBranchIdx(branchIdx);
    setSelectIndex(index);
    setDetailList([]);
    getDetailData(branchIdx);
    setFilterDate({
      start: props.date.startDate,
      end: props.date.endDate,
    });
  };
  return (
    <div id={'agencyTaxReturn'}>
      <div className={'ncl-ctg-box'}>
        <div className={'ncl-input-box taxReturn'} style={{ width: 140 }}>
          <div
            onClick={() => setListType('매출')}
            className={
              listType === '매출' ? 'shareCallBtn active' : 'shareCallBtn'
            }
            style={{ borderRight: '1px solid #c1c1c1' }}>
            매출
          </div>
          <div
            onClick={() => setListType('매입')}
            className={
              listType === '매입' ? 'shareCallBtn active' : 'shareCallBtn'
            }>
            매입
          </div>
        </div>
        <div className={'ncl-select-box'}>
          <Select
            defaultValue={'대리점 전체'}
            style={{
              flex: 1,
            }}
            onChange={(value) => {
              setMainList([]);
              setBelongBranch(value);
            }}>
            {branches.map((value) => {
              return (
                <Option key={value.branchName} value={value.companyId}>
                  {value.branchName}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className={'ncl-input-box'} style={{ width: 240 }}>
          <Input
            onPressEnter={() => getData(true)}
            onChange={(e) => {
              setSearch(e.target.value);
              setDetailOpen(false);
            }}
            placeholder={'지점명'}
            style={{
              border: 'none',
              paddingLeft: 10,
              width: 200,
            }}
          />
          <SearchOutlined
            style={{ padding: '0 10px', cursor: 'pointer' }}
            onClick={() => getData(true)}
          />
        </div>
        <div className={'ncl-excel-box'} style={{ marginLeft: 'auto' }}>
          <div
            className={'ncl-excel-btn'}
            onClick={() => setOnDownlaodModal(true)}>
            전체 내역 다운로드{' '}
            <VerticalAlignBottomOutlined style={{ color: '#fff' }} />
          </div>
        </div>
      </div>
      {isLoading && (
        <div className={'spin-overlay'}>
          <Spin className={'spin'} />
        </div>
      )}
      <div className={'agency-tr-tbl'} style={{ marginTop: 15 }}>
        <div
          className={'agency-tr-table-container'}
          style={{ minWidth: 1080, maxWidth: 1710, flex: 1 }}>
          <div className={'agency-tr-main-table-columns rider'}>
            {mainColumns.map((col, index) => {
              if (index === 1)
                return (
                  <div key={index}>
                    {listType === '매출' ? col : '라이더 소속 대리점'}
                  </div>
                );
              else if (index === 8)
                return (
                  <div key={index} style={{ borderRight: 0 }}>
                    {listType === '매출' ? col : '공유콜 배달비 매입 부가세'}
                  </div>
                );
              else if (index === 9) return <p key={index}>{col}</p>;
              else return <div key={index}>{col}</div>;
            })}
          </div>
          {mainList.length > 0 ? (
            <div className={'agency-tr-main-table-content'}>
              {mainList.map((main, index) => {
                const branchIdx =
                  listType === '매출' ? main.frBranchIdx : main.riderBranchIdx;
                return (
                  <div
                    key={index}
                    className={
                      index % 2 === 0
                        ? 'agency-tr-main-table-row rider'
                        : 'agency-tr-main-table-row rider odd'
                    }
                    onClick={() => {
                      onClickBranch(branchIdx, index);
                      // setDetailOpen(true);
                      // setSelectBranchIdx(branchIdx);
                      // setSelectIndex(index);
                      // setDetailList(()=> {
                      //  return [];
                      //  });
                      // getDetailData(branchIdx);
                    }}
                    style={{
                      fontWeight:
                        selectIndex === index &&
                        (selectBranchIdx === main.frBranchIdx ||
                          selectBranchIdx === main.riderBranchIdx)
                          ? 'bold'
                          : 'normal',
                    }}>
                    <div>{listType}</div>
                    <div>
                      {listType === '매출'
                        ? main.frBranchName
                        : main.riderBranchName}
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.deliveryPrice)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.deliveryPriceVat)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.wholeRiderFee)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.shareCallFee)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.shareCallFeeVat)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.shareCallDeliveryPrice)}</span>
                    </div>
                    <div className={'font-a-r'}>
                      <span>{comma(main.shareCallDeliveryPriceVat)}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={'agency-tr-main-table-content2'}>
              조회되는 데이터가 없습니다.
            </div>
          )}
          <div className={'agency-tr-main-table-footer'}>
            {mainColumns.map((footer, index) => {
              if (index === 2)
                return (
                  <div key={index} className={'totalBox'}>
                    {comma(main.totalDeliveryPrice)}
                  </div>
                );
              else if (index === 3)
                return (
                  <div key={index} className={'totalBox'}>
                    {comma(main.totalDeliveryPriceVat)}
                  </div>
                );
              else if (index === 4)
                return (
                  <div key={index} className={'totalBox'}>
                    {comma(main.totalWholeRiderFee)}
                  </div>
                );
              else if (index === 5)
                return (
                  <div key={index} className={'totalBox'}>
                    {comma(main.totalShareCallFee)}
                  </div>
                );
              else if (index === 6)
                return (
                  <div key={index} className={'totalBox'}>
                    {comma(main.totalShareCallFeeVat)}
                  </div>
                );
              else if (index === 7)
                return (
                  <div key={index} className={'totalBox'}>
                    {comma(main.totalShareCallDeliveryPrice)}
                  </div>
                );
              else if (index === 8)
                return (
                  <div key={index} className={'totalBox'}>
                    {comma(main.totalShareCallDeliveryPriceVat)}
                  </div>
                );
              else if (index === 9) return;
              else return <div key={index} />;
            })}
          </div>
        </div>
        {detailOpen && (
          <div
            className={'agency-tr-table-container'}
            style={{
              minWidth: 800,
              maxWidth: 1260,
              flex: 0.8,
              marginLeft: 10,
            }}>
            <div className={'detail-tbl-columns'}>
              {detailColumns.map((col, index) => {
                if (index === 13) return <p key={index}>{col}</p>;
                else if (index === 12) {
                  if (listType === '매출')
                    return (
                      <div key={index+listType} style={{ borderRight: 0 }}>
                        {col}
                      </div>
                    );
                  else
                    return (
                      <div key={index+listType} style={{ borderRight: 0 }}>
                        공유콜 배달비 매입 부가세
                      </div>
                    );
                }
                return <div key={index}>{col}</div>;
              })}
            </div>
            <div className={'detail-tbl'}>
              {detailList.length > 0 &&
                detailList.map((detail, index) => {
                  return (
                    <div key={detail.createDate}>
                      <div className={'detail-tbl-date'}>
                        기간: {detail.createDate}
                      </div>
                      {detail.items.map((item, index) => {
                        return (
                          <div
                            key={item.frNameExtra + item.createDate + index}
                            className={
                              index % 2 !== 0
                                ? 'detail-tbl-item odd'
                                : 'detail-tbl-item'
                            }>
                            <div>{item.createDate}</div>
                            <div>{item.orderIdx}</div>
                            <div>{item.frBranchName}</div>
                            <div>
                              {item.frNameExtra
                                ? item.frNameExtra
                                : item.frName}
                            </div>
                            <div>{item.riderBranchName}</div>
                            <div>{item.riderName}</div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <span style={{ marginRight: 10 }}>
                                {comma(item.deliveryPrice)}
                              </span>
                            </div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <span style={{ marginRight: 10 }}>
                                {comma(item.deliveryPriceVat)}
                              </span>
                            </div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <span style={{ marginRight: 10 }}>
                                {comma(item.riderDeliveryFee)}
                              </span>
                            </div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <span style={{ marginRight: 10 }}>
                                {comma(item.shareCallFee)}
                              </span>
                            </div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <span style={{ marginRight: 10 }}>
                                {comma(item.shareCallFeeVat)}
                              </span>
                            </div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <span style={{ marginRight: 10 }}>
                                {comma(item.shareCallDeliveryPrice)}
                              </span>
                            </div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <span style={{ marginRight: 10 }}>
                                {comma(item.shareCallDeliveryPriceVat)}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                      <div className={'detail-tbl-total'}>
                        {detailColumns.map((item, index) => {
                          if (index === 0) {
                            return (
                              <div
                                key={index}
                                style={{
                                  paddingLeft: 10,
                                  fontWeight: 'bold',
                                  flex: 2,
                                }}>
                                배달 건수: {comma(detail.deliveryCount)} 건
                              </div>
                            );
                          } else if (index === 1 || index === 13) return;
                          else if (index === 6) {
                            return (
                              <div key={index} className={'detail-bold'}>
                                <span>{comma(detail.deliveryPrice)}</span>
                              </div>
                            );
                          } else if (index === 7) {
                            return (
                              <div key={index} className={'detail-bold'}>
                                <span>{comma(detail.deliveryPriceVat)}</span>
                              </div>
                            );
                          } else if (index === 8) {
                            return (
                              <div key={index} className={'detail-bold'}>
                                <span>{comma(detail.riderDeliveryFee)}</span>
                              </div>
                            );
                          } else if (index === 9) {
                            return (
                              <div key={index} className={'detail-bold'}>
                                <span>{comma(detail.shareCallFee)}</span>
                              </div>
                            );
                          } else if (index === 10) {
                            return (
                              <div key={index} className={'detail-bold'}>
                                <span>{comma(detail.shareCallFeeVat)}</span>
                              </div>
                            );
                          } else if (index === 11) {
                            return (
                              <div key={index} className={'detail-bold'}>
                                <span>
                                  {comma(detail.shareCallDeliveryPrice)}
                                </span>
                              </div>
                            );
                          } else if (index === 12) {
                            return (
                              <div key={index} className={'detail-bold'}>
                                <span>
                                  {comma(detail.shareCallDeliveryPriceVat)}
                                </span>
                              </div>
                            );
                          } else return <div />;
                        })}
                      </div>
                    </div>
                  );
                })}
              {moreBtn && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px 0',
                  }}>
                  <div
                    style={{
                      cursor: 'pointer',
                      color: '#888',
                      fontSize: 16,
                    }}
                    onClick={() => getDetailData(selectBranchIdx, filterDate)}>
                    더보기 <DownOutlined />
                  </div>
                </div>
              )}
            </div>

            <div className={'footer-container'}>
              <div className={'agency-tax-return-table-footer'}>
                {detailColumns.map((item, index) => {
                  if (index === 0)
                    return (
                      <div key={index} className={'totalBox'}>
                        {comma(detail.totalShareCallDeliveryPriceVat)}
                      </div>
                    );
                  else if (index === 1)
                    return (
                      <div key={index} className={'totalBox'}>
                        {comma(detail.totalShareCallDeliveryPrice)}
                      </div>
                    );
                  else if (index === 2)
                    return (
                      <div key={index} className={'totalBox'}>
                        {comma(detail.totalShareCallFeeVat)}
                      </div>
                    );
                  else if (index === 3)
                    return (
                      <div key={index} className={'totalBox'}>
                        {comma(detail.totalShareCallFee)}
                      </div>
                    );
                  else if (index === 4)
                    return (
                      <div key={index} className={'totalBox'}>
                        {comma(detail.totalRiderDeliveryFee)}
                      </div>
                    );
                  else if (index === 5)
                    return (
                      <div key={index} className={'totalBox'}>
                        {comma(detail.totalDeliveryPriceVat)}
                      </div>
                    );
                  else if (index === 6)
                    return (
                      <div key={index} className={'totalBox'}>
                        {comma(detail.totalDeliveryPrice)}
                      </div>
                    );
                  else if (index === 13) return;
                  else return <div key={index} />;
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      {onDownlaodModal && (
        <div>
          <div
            className="Dialog-overlay"
            onClick={() => setOnDownlaodModal(false)}
          />
          <div className="taxReturn-Dialog">
            <CloseOutlined
              style={{ marginLeft: 'auto', fontSize: 20, cursor: 'pointer' }}
              onClick={() => setOnDownlaodModal(false)}
            />
            <div>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}>
                전체 내역 중 엑셀 파일로 <br /> 저장할 내용을 선택하세요
              </p>
              <Radio.Group
                onChange={(e) => setDownloadType(e.target.value)}
                defaultValue={0}>
                <Radio value={0}>통계(좌측)</Radio>
                <Radio value={1} disabled={!detailOpen}>
                  상세(우측)
                </Radio>
              </Radio.Group>
            </div>
            <div>
              <Button
                className={'taxReturn-Dialog-btn'}
                onClick={() => setOnDownlaodModal(false)}>
                취소
              </Button>
              <Button
                onClick={onDownloadContent}
                className={'taxReturn-Dialog-btn'}
                style={{
                  marginLeft: 10,
                  background: '#FADC00',
                  fontWeight: 'bold',
                }}>
                다운로드
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AgencyTaxReturnShareCall;
