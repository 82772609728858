import { Button, DatePicker, Input, Modal, Table } from 'antd';
import React, { Component } from 'react';
import xlsx from 'xlsx';
import { httpGet, httpGetWithoutLoading, httpUrl } from '../../api/httpClient';
import DepositWithdrawDialog from '../../components/dialog/DepositWithdrawDialog';
import ProgressBar from '../../components/ProgressBar';
import '../../css/main.css';
import { approvalStatus, userTypeToString } from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

const initialPaginationExcel = {
  total: Number.MAX_VALUE,
  current: 1,
  pageSize: 1000,
};

const statusStyles = [{}, { color: 'blue' }, { color: 'red' }];

class RequestWithdrawApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      paginationExcel: initialPaginationExcel,
      userId: '',
      list: [],
      searchType: 1,

      progressBarOn: false,

      searchString: '',
    };
    this.progressBarFlag = false;
  }

  async componentDidMount() {
    await this.getList();
  }

  getList = async () => {
    const res = await httpGet(httpUrl.getApprovalList, [
      'WITHDRAW_REQ',
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.searchString,
    ]);

    console.log(res);
    if (res.result !== 'SUCCESS') {
      Modal.warn({
        title: '조회실패',
        content: '출금승인요청 조회에 실패했습니다.',
      });
      return;
    }

    this.setState({
      list: res.data.approval,
      pagination: {
        ...this.state.pagination,
        current: res.data.currentPage,
        total: res.data.totalCount,
      },
    });
  };

  getExcelList = async () => {
    let userId = this.state.userId;
    let userType = this.state.searchType;

    this.progressBarFlag = true;

    const loopGetList = async () => {
      const res = await httpGetWithoutLoading(
        httpUrl.depositWithdrawList,
        [
          this.state.paginationExcel.current,
          this.state.paginationExcel.pageSize,
          userId,
          userType,
        ],
        {}
      );

      return res;
    };

    let totalRes = [];
    let tryCnt = 1;

    let failedPages = [];

    this.setState({
      progressBarOn: true,
    });

    while (this.progressBarFlag) {
      if (this.state.paginationExcel.total < this.state.paginationExcel.current)
        break;

      const loopRes = await loopGetList();

      if (loopRes.data) {
        this.setState({
          paginationExcel: {
            ...this.state.paginationExcel,
            current: this.state.paginationExcel.current + 1,
            total: loopRes.data.totalPage,
          },
        });

        totalRes = totalRes.concat(loopRes.data.withdraws);
      } else if (tryCnt <= 3) {
        tryCnt += 1;
      } else {
        failedPages.push(this.pageNum);

        this.setState({
          paginationExcel: {
            ...this.state.paginationExcel,
            current: this.state.paginationExcel.current + 1,
          },
        });

        tryCnt = 1;
      }
    }

    this.setState({
      progressBarOn: false,
      paginationExcel: initialPaginationExcel,
    });

    return this.progressBarFlag ? totalRes : null;
  };

  quitProgressBar = () => {
    this.progressBarFlag = false;

    this.setState({
      progressBarOn: false,
      paginationExcel: initialPaginationExcel,
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  pressSearch = () => {
    this.setState(
      {
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  onChange = (e) => {
    this.setState(
      {
        searchType: e.target.value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  parseExcelJson = (listExcel) => {
    let result = [
      {
        userId: '아이디',
        userName: '이름',
        bank: '은행명',
        depositor: '예금주명',
        bankAccount: '출금계좌',
        ncashDelta: '출금금액',
        createDate: '출금일시',
      },
    ];
    listExcel.forEach((item) => {
      result.push({
        userId: item.userId,
        userName: item.userName,
        bank: item.bank,
        depositor: item.depositor,
        bankAccount: item.bankAccount,
        ncashDelta: item.ncashDelta,
        createDate: item.createDate,
      });
    });

    return result;
  };

  onDownload = async () => {
    const data = await this.getExcelList();

    if (!data) return;

    const excelJson = this.parseExcelJson(data);
    const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
    const wb = xlsx.utils.book_new();

    ws['!cols'] = [];
    ws['!cols'][0] = { width: 15 };
    ws['!cols'][1] = { width: 15 };
    ws['!cols'][2] = { width: 12 };
    ws['!cols'][4] = { width: 15 };
    ws['!cols'][6] = { width: 20 };
    xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
    xlsx.writeFile(wb, '예치금출금.xlsx');
  };

  openDepositWithdrawModal = () => {
    this.setState({ depositWithdrawOpen: true });
  };
  closeDepositWithdrawModal = () => {
    this.setState({ depositWithdrawOpen: false });
  };

  render() {
    const columns = [
      {
        title: '승인번호',
        dataIndex: 'idx',
        className: 'table-column-center',
      },
      {
        title: '출금요청관리자아이디',
        dataIndex: 'adminId',
        className: 'table-column-center',
      },
      {
        title: '출금대상구분',
        dataIndex: 'userType',
        className: 'table-column-center',
        render: (data) => (data > 2 || data < 1 ? '' : userTypeToString[data]),
      },
      {
        title: '출금대상',
        dataIndex: 'userType',
        className: 'table-column-center',
        render: (data, row) => (data == 1 ? row.riderName : row.frName),
      },
      {
        title: '대표자명(가맹점)',
        dataIndex: 'ownerName',
        className: 'table-column-center',
        render: (data, row) => (row.userType == 2 ? data : '-'),
      },
      {
        title: '출금계좌',
        dataIndex: 'bank',
        className: 'table-column-center',
        render: (data, row) => {
          const [bank, code] = data.split(',');
          return `${bank} / ${row.bankAccount}`;
        },
      },
      {
        title: '출금자명',
        dataIndex: 'depositor',
        className: 'table-column-center',
      },
      {
        title: '주민등록번호/사업자번호',
        dataIndex: 'userType',
        className: 'table-column-center',
        render: (data, row) =>
          data == 1 ? row.registrationNumber : row.businessNumber,
      },
      {
        title: '출금요청액',
        dataIndex: 'rawData',
        className: 'table-column-center',
        render: (data) => {
          const rawData = JSON.parse(data);
          return comma(rawData.amount);
        },
      },
      {
        title: '승인상태',
        dataIndex: 'status',
        className: 'table-column-center',
        render: (data) => (
          <div style={{ fontWeight: 'bold', ...statusStyles[data] }}>
            {approvalStatus[data]}
          </div>
        ),
      },
    ];
    return (
      <>
        <Search
          placeholder="아이디/가맹점명/라이더명/사업자번호 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ searchString: e.target.value })}
          onSearch={this.pressSearch}
          style={{
            width: 500,
            marginBottom: 20,
          }}
        />

        {this.state.depositWithdrawOpen && (
          <DepositWithdrawDialog close={this.closeDepositWithdrawModal} />
        )}
        {this.state.progressBarOn && (
          <ProgressBar
            current={this.state.paginationExcel.current}
            total={this.state.paginationExcel.total}
            quit={this.quitProgressBar}
          />
        )}
        <Button
          style={{ marginBottom: 20, marginLeft: 20 }}
          onClick={this.openDepositWithdrawModal}>
          예치금 출금
        </Button>

        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) =>
            record.status === 'COMPLETE' ? 'table-disabled' : ''
          }
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={this.expandedRowRender}
          expandRowByClick={true}
        />
      </>
    );
  }
}

export default RequestWithdrawApproval;
